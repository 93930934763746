import * as React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import bind from 'bind-decorator';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import GlobalSearch from '@Components/shared/GlobalSearch';
import Icon from '@Components/shared/Icon';
import { AnimatedPlaceholderRect, AnimatedPlaceholderLine } from '@Components/placeholder/PlaceholderComponents';
import oiLogoImg from '@Images/logo/oix-logo-color.svg';
import withWallet from '@Components/hoc/withWallet';
import Dropdown from '@Components/dropdown/Dropdown';
import MobileSearch from '@Components/shared/MobilSearch';
import { getChainInfo, numberFormatDigits, toCustomDecimalsFullFixed } from '@Utils';
import OixSpinner from '@Components/shared/OixSpinner';

import { getWeb3ReactContext } from '@web3-react/core';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { UserActions } from '@Actions/UserActions';
import { SECONDS_IN_MS } from '@AppVariables';

class AppHeader extends React.Component {
  _isMounted = false;
  shouldDisconnect = false;
  disconnectTime = 60 * SECONDS_IN_MS;

  constructor(props) {
    super(props);
    this.userMenuDropdownRef = React.createRef();
    this.state = { width: 0, height: 0, showFullscreenSearch: false, disconnectTimer: null };
  }

  componentDidMount() {
    this._isMounted = true;
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);

    this.setState({
      ctx: getWeb3ReactContext(),
    }); 
  }

  componentDidUpdate(prevProps, prevState) {
    const { cachedWalletExists, isCachedWalletLoading, isFetchingUserByPublicAddress, signing, logout, account } =
      this.props;

    this.shouldDisconnect = !account;

    if (
      ((cachedWalletExists && isCachedWalletLoading) || signing || isFetchingUserByPublicAddress) &&
      !this.state.disconnectTimer
    ) {
      this.setState({
        disconnectTimer: setTimeout(() => {
          if (this.shouldDisconnect) {
            logout();
          }
        }, this.disconnectTime),
      });
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  @bind
  openConnectWalletModal() {
    this.props.openModal('ConnectWalletModal', {
      onOutsideClick: true,
      closeOnOutsideClick: true,
      backdrop: true,
      callback: () => {
        this.props.closeModal();
      },
      profileAccount: this.props.profileAccount,
    });
  }

  @bind
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  @bind
  closeMobileSearch() {
    if (this._isMounted) this.setState({ showFullscreenSearch: false });
  }

  render() {
    const { width, showFullscreenSearch } = this.state;
    const {
      account,
      cachedWalletExists,
      isCachedWalletLoading,
      isFetchingProfile,
      isFetchingTokens,
      isFetchingUserByPublicAddress,
      loadingCurrentAccount,
      location,
      sidebar,
      signing,
      toggleMenu,
      wallet,
      t,
    } = this.props;

    const isMobile = width <= 576;

    const currentChainInfo = getChainInfo(wallet.chainId);
    const currentChainCurrency = currentChainInfo.chainInfo.nativeCurrency;

    return (
      <header id="app-header">
        {sidebar ? (
          <div className="icon-container" onClick={() => toggleMenu()}>
            <Icon src="fas fa-bars" type="fontawesome" />
          </div>
        ) : null}
        <div className={`header-content ${!sidebar && 'no-sidebar'}`}>
          <div className="left">
            <Link className="logo" to="/">
              <img className="pr-1" src={oiLogoImg} />
            </Link>
          </div>
          <div className="center">{!isMobile ? <GlobalSearch width={'50%'} /> : null}</div>
          <div className="right">
            <div className="user-menu">
              {isMobile ? (
                <div className="search-icon mr-2" onClick={() => this.setState({ showFullscreenSearch: true })}>
                  <Icon src="fas fa-search" type="fontawesome" />
                </div>
              ) : null}
              {account ? (
                <>
                  {!isMobile ? (
                    <Link
                      className="icon-hold ml-2 mr-2"
                      to={{ pathname: '/collectible/upload', state: { background: location } }}>
                      <div className="icon-wrap">
                        <div className="icon-white-area">
                          <Icon src="fas fa-plus-circle" type="fontawesome" />
                        </div>
                      </div>
                    </Link>
                  ) : null}
                  {isMobile ? (
                    <Link className="bell-notification mr-2" to="/notifications">
                      <div className="bell bg-color-1">
                        <Icon className="text-white" src="fa fa-bell" type="fontawesome" />
                      </div>
                      <>
                        {account.unviewedNotificationCount && account.unviewedNotificationCount > 0 ? (
                          <div className="notification-count">{/*account.unviewedNotificationCount*/}</div>
                        ) : null}
                      </>
                    </Link>
                  ) : null}
                  <div
                    className="profile-wrap"
                    onClick={() => {
                      this.userMenuDropdownRef.current.open();
                    }}>
                    {!isMobile && (
                      <div className="balance">
                        {numberFormatDigits(
                          toCustomDecimalsFullFixed(wallet.balance, currentChainCurrency.decimals, 2),
                          'en-US',
                          15,
                        )}
                        &nbsp;{currentChainCurrency.symbol}
                      </div>
                    )}
                    <ProfileAvatar
                      height={isMobile ? 40 : 50}
                      hideBanner={isMobile}
                      profileData={account}
                      width={isMobile ? 40 : 50}
                      preventNavigation
                    />
                  </div>
                  <Dropdown ref={this.userMenuDropdownRef} wallet={wallet} />
                </>
              ) : (
                <>
                  {(cachedWalletExists && isCachedWalletLoading) || signing || isFetchingUserByPublicAddress ? (
                    <div className="loading-account">
                      <div className="mr-2">{t('public.loading')}</div>
                      <OixSpinner backgroundColor={'#191B27'} size={32} thick={0.8} />
                    </div>
                  ) : isFetchingTokens || isFetchingProfile || loadingCurrentAccount ? (
                    <div className="loading-placeholder">
                      <AnimatedPlaceholderRect borderRadius={'50%'} height={40} width={40} />
                      <div className="balance">
                        <AnimatedPlaceholderLine height={30} width={60} />
                      </div>
                      <AnimatedPlaceholderRect
                        borderRadius={'50%'}
                        height={isMobile ? 40 : 50}
                        width={isMobile ? 40 : 50}
                      />
                    </div>
                  ) : (
                    <span className="connect-account">
                      <div className="connect-containerr" onClick={this.openConnectWalletModal}>
                        <div className="connect-inner">
                          <Trans i18nKey="public.connectWallet">
                            Connect <span className="connect-wallet">&nbsp;Wallet</span>
                          </Trans>
                        </div>
                      </div>
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {showFullscreenSearch && isMobile && <MobileSearch closeMobileSearch={this.closeMobileSearch} />}
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.user.account,
  ...state.wallet,
  ...state.auth,
});

const mapDispatchToProps = {
  ...UserActions,
  ...AccountActions,
  ...ModalActions,
};

const Connected = withWallet(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withTranslation()(AppHeader)),
);

export default withRouter(Connected);
