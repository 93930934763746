import * as CollectionActionTypes from '@Actions/CollectionActions';

const initialState = () => ({
  loadingProfileCollection: false,
  profileCollectionError: null,

  updatingCollectionProfile: false,
  updatedCollectionProfile: null,
  updateCollectionProfileError: null,

  fetchingCollectionCollectibles: false,
  fetchingCollectiblesError: false,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case CollectionActionTypes.GET_COLLECTION_BY_NAME:
      return {
        ...state,
        loadingProfileCollection: true,
      };
    case CollectionActionTypes.GET_COLLECTION_BY_NAME_SUCCESS:
      return {
        ...state,
        loadingProfileCollection: false,
      };
    case CollectionActionTypes.GET_COLLECTION_BY_NAME_FAILURE:
      return {
        ...state,
        profileCollectionError: action.error,
        loadingProfileCollection: false,
      };
    case CollectionActionTypes.UPDATE_COLLECTION_PROFILE:
      return {
        ...state,
        updatingCollectionProfile: true,
      };
    case CollectionActionTypes.UPDATE_COLLECTION_PROFILE_SUCCESS:
      return {
        ...state,
      };
    case CollectionActionTypes.UPDATE_COLLECTION_PROFILE_FAILURE:
      return {
        ...state,
        updateCollectionProfileError: action.error,
        updatingCollectionProfile: false,
      };
    case CollectionActionTypes.GET_COLLECTION_COLLECTIBLES:
      return {
        ...state,
        fetchingCollectionCollectibles: true,
      };
    case CollectionActionTypes.GET_COLLECTION_COLLECTIBLES_SUCCESS: {
      return {
        ...state,
        fetchingCollectionCollectibles: false,
      };
    }
    case CollectionActionTypes.GET_COLLECTION_COLLECTIBLES_FAILURE:
      return {
        ...state,
        fetchingCollectionCollectibles: false,
        fetchingCollectiblesError: action.error,
      };
    case CollectionActionTypes.SET_ACTIVE_PROFILE_COLLECTION:
      return {
        ...state,
        activeProfileCollectionId: action.payload.activeProfileCollectionId,
      };
    default:
      return state;
  }
};
