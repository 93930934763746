import queryString from 'query-string';

const client_id = 'OrderinboxWeb';

// We can likely get these from the discovery document
const tokenEndpoint = () => 'connect/token';
const userInfoEndpoint = () => 'connect/userinfo';
const revocationEndpoint = () => 'connect/revocation';

const userEndpoint = () => 'v1/user';

export const TOKEN = 'TOKEN';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

const token = (tokenParams) => ({
  type: TOKEN,
  payload: {
    request: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      url: tokenEndpoint(),
      data: queryString.stringify(tokenParams),
    },
  },
});

export const USERINFO = 'USERINFO';
export const USERINFO_SUCCESS = 'USERINFO_SUCCESS';
export const USERINFO_FAILURE = 'USERINFO_FAILURE';

const userInfo = (access_token) => ({
  type: USERINFO,
  payload: {
    request: {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
      url: userInfoEndpoint(),
    },
  },
});

export const REVOCATION = 'REVOCATION';
export const REVOCATION_SUCCESS = 'REVOCATION_SUCCESS';
export const REVOCATION_FAILURE = 'REVOCATION_FAILURE';

const revocation = (revocationParams) => ({
  type: REVOCATION,
  payload: {
    request: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      url: revocationEndpoint(),
      data: queryString.stringify(revocationParams),
    },
  },
});

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

const login = () => ({
  type: LOGIN_SUCCESS,
});

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

const logout = () => ({
  type: LOGOUT_SUCCESS,
});

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

const register = (registerModel) => ({
  type: REGISTER,
  payload: {
    request: {
      method: 'POST',
      url: `${userEndpoint()}/register`,
      data: registerModel,
    },
  },
});

export const GET_USER_BY_PUBLIC_ADDRESS = 'GET_USER_BY_PUBLIC_ADDRESS';
export const GET_USER_BY_PUBLIC_ADDRESS_SUCCESS = 'GET_USER_BY_PUBLIC_ADDRESS_SUCCESS';
export const GET_USER_BY_PUBLIC_ADDRESS_FAILURE = 'GET_USER_BY_PUBLIC_ADDRESS_FAILURE';

const getUserByPublicAddress = (publicAddress) => ({
  type: GET_USER_BY_PUBLIC_ADDRESS,
  payload: {
    request: {
      url: `${userEndpoint()}/${publicAddress}`,
    },
  },
});

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

const getAccounts = () => ({
  type: GET_ACCOUNTS,
  payload: {
    request: {
      url: `${userEndpoint()}/accounts`,
    },
  },
});

export const LOAD_ACCOUNT = 'LOAD_ACCOUNT';
export const LOAD_ACCOUNT_SUCCESS = 'LOAD_ACCOUNT_SUCCESS';
export const LOAD_ACCOUNT_FAILURE = 'LOAD_ACCOUNT_FAILURE';

const loadAccount = (accountId) => ({
  type: LOAD_ACCOUNT,
  payload: {
    request: {
      url: `${userEndpoint()}/accounts/${accountId}`,
    },
  },
});

export const UNLOAD_ACCOUNT = 'UNLOAD_ACCOUNT';

const unloadAccount = () => ({
  type: UNLOAD_ACCOUNT,
});

export const CHANGE_CULTURE = 'CHANGE_CULTURE';
export const CHANGE_CULTURE_SUCCESS = 'CHANGE_CULTURE_SUCCESS';
export const CHANGE_CULTURE_FAILURE = 'CHANGE_CULTURE_FAILURE';

const changeCulture = (culture) => ({
  type: CHANGE_CULTURE,
  payload: {
    request: {
      method: 'PUT',
      url: `${userEndpoint()}/culture`,
      data: {
        culture,
      },
    },
  },
});

export const CHANGE_THEME = 'CHANGE_THEME';
export const CHANGE_THEME_SUCCESS = 'CHANGE_THEME_SUCCESS';
export const CHANGE_THEME_FAILURE = 'CHANGE_THEME_FAILURE';

const changeTheme = (theme) => ({
  type: CHANGE_THEME,
  payload: {
    request: {
      method: 'PUT',
      url: `${userEndpoint()}/theme`,
      data: {
        theme,
      },
    },
  },
});

export const CHANGE_RESTRICTED_CONTENT = 'CHANGE_RESTRICTED_CONTENT';
export const CHANGE_RESTRICTED_CONTENT_SUCCESS = 'CHANGE_RESTRICTED_CONTENT_SUCCESS';
export const CHANGE_RESTRICTED_CONTENT_FAILURE = 'CHANGE_RESTRICTED_CONTENT_FAILURE';

const changeRestrictedContent = (restrictedContent) => ({
  type: CHANGE_RESTRICTED_CONTENT,
  payload: {
    request: {
      method: 'PUT',
      url: `${userEndpoint()}/restrictedContent`,
      data: {
        restrictedContent,
      },
    },
  },
});

export const CHANGE_USERNAME = 'CHANGE_USERNAME';
export const CHANGE_USERNAME_SUCCESS = 'CHANGE_USERNAME_SUCCESS';
export const CHANGE_USERNAME_FAILURE = 'CHANGE_USERNAME_FAILURE';

const changeUsername = (changeUsernameModel) => ({
  type: CHANGE_USERNAME,
  payload: {
    request: {
      method: 'PUT',
      url: `${userEndpoint()}/username`,
      data: changeUsernameModel,
    },
  },
});

export const REQUEST_EMAIL_CHANGE = 'REQUEST_EMAIL_CHANGE';
export const REQUEST_EMAIL_CHANGE_SUCCESS = 'REQUEST_EMAIL_CHANGE_SUCCESS';
export const REQUEST_EMAIL_CHANGE_FAILURE = 'REQUEST_EMAIL_CHANGE_FAILURE';

const requestEmailChange = (requestEmailChangeModel) => ({
  type: REQUEST_EMAIL_CHANGE,
  payload: {
    request: {
      method: 'POST',
      url: `${userEndpoint()}/email`,
      data: requestEmailChangeModel,
    },
  },
});

export const VERIFY_EMAIL_CHANGE = 'VERIFY_EMAIL_CHANGE';
export const VERIFY_EMAIL_CHANGE_SUCCESS = 'VERIFY_EMAIL_CHANGE_SUCCESS';
export const VERIFY_EMAIL_CHANGE_FAILURE = 'VERIFY_EMAIL_CHANGE_FAILURE';

const verifyEmailChange = (verifyEmailChangeModel) => ({
  type: VERIFY_EMAIL_CHANGE,
  payload: {
    request: {
      method: 'PATCH',
      url: `${userEndpoint()}/email`,
      data: verifyEmailChangeModel,
    },
  },
});

export const REQUEST_PHONE_NUMBER_CHANGE = 'REQUEST_PHONE_NUMBER_CHANGE';
export const REQUEST_PHONE_NUMBER_CHANGE_SUCCESS = 'REQUEST_PHONE_NUMBER_CHANGE_SUCCESS';
export const REQUEST_PHONE_NUMBER_CHANGE_FAILURE = 'REQUEST_PHONE_NUMBER_CHANGE_FAILURE';

const requestPhoneNumberChange = (requestPhoneNumberChangeModel) => ({
  type: REQUEST_PHONE_NUMBER_CHANGE,
  payload: {
    request: {
      method: 'POST',
      url: `${userEndpoint()}/phone`,
      data: requestPhoneNumberChangeModel,
    },
  },
});

export const VERIFY_PHONE_NUMBER_CHANGE = 'VERIFY_PHONE_NUMBER_CHANGE';
export const VERIFY_PHONE_NUMBER_CHANGE_SUCCESS = 'VERIFY_PHONE_NUMBER_CHANGE_SUCCESS';
export const VERIFY_PHONE_NUMBER_CHANGE_FAILURE = 'VERIFY_PHONE_NUMBER_CHANGE_FAILURE';

const verifyPhoneNumberChange = (verifyPhoneNumberChangeModel) => ({
  type: VERIFY_PHONE_NUMBER_CHANGE,
  payload: {
    request: {
      method: 'PATCH',
      url: `${userEndpoint()}/phone`,
      data: verifyPhoneNumberChangeModel,
    },
  },
});

export const RESET_EMAIL_CHANGE_DATA = 'RESET_EMAIL_CHANGE_DATA';

const resetEmailChangeData = () => ({
  type: RESET_EMAIL_CHANGE_DATA,
});

export const RESET_PHONE_NUMBER_CHANGE_DATA = 'RESET_PHONE_NUMBER_CHANGE_DATA';

const resetPhoneNumberChangeData = () => ({
  type: RESET_PHONE_NUMBER_CHANGE_DATA,
});

export const UPDATE_USER_ACCOUNT_PROFILE = 'UPDATE_USER_ACCOUNT_PROFILE';

const updateUserAccountProfile = (profile) => ({
  type: UPDATE_USER_ACCOUNT_PROFILE,
  payload: profile,
});

export const GET_NOTIFICATION_PREFERENCES = 'GET_NOTIFICATION_PREFERENCES';
export const GET_NOTIFICATION_PREFERENCES_SUCCESS = 'GET_NOTIFICATION_PREFERENCES_SUCCESS';
export const GET_NOTIFICATION_PREFERENCES_FAILURE = 'GET_NOTIFICATION_PREFERENCES_FAILURE';

const getNotificationPreferences = () => ({
  type: GET_NOTIFICATION_PREFERENCES,
  payload: {
    request: {
      url: `${userEndpoint()}/notificationpreferences`,
    },
  },
});

export const UPDATE_EMAIL_NOTIFICATION_PREFERENCES = 'UPDATE_EMAIL_NOTIFICATION_PREFERENCES';
export const UPDATE_EMAIL_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_EMAIL_NOTIFICATION_PREFERENCES_SUCCESS';
export const UPDATE_EMAIL_NOTIFICATION_PREFERENCES_FAILURE = 'UPDATE_EMAIL_NOTIFICATION_PREFERENCES_FAILURE';

const updateEmailNotificationPreferences = (emailModel) => ({
  type: UPDATE_EMAIL_NOTIFICATION_PREFERENCES,
  payload: {
    request: {
      method: 'PATCH',
      data: emailModel,
      url: `${userEndpoint()}/notificationpreferences/email`,
    },
  },
});

export const UPDATE_SMS_NOTIFICATION_PREFERENCES = 'UPDATE_SMS_NOTIFICATION_PREFERENCES';
export const UPDATE_SMS_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_SMS_NOTIFICATION_PREFERENCES_SUCCESS';
export const UPDATE_SMS_NOTIFICATION_PREFERENCES_FAILURE = 'UPDATE_SMS_NOTIFICATION_PREFERENCES_FAILURE';

const updateSmsNotificationPreferences = (smsModel) => ({
  type: UPDATE_SMS_NOTIFICATION_PREFERENCES,
  payload: {
    request: {
      method: 'PATCH',
      data: smsModel,
      url: `${userEndpoint()}/notificationpreferences/sms`,
    },
  },
});
export const UPDATE_NOTIFICATION_PREFERENCES = 'UPDATE_NOTIFICATION_PREFERENCES';
export const UPDATE_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_NOTIFICATION_PREFERENCES_SUCCESS';
export const UPDATE_NOTIFICATION_PREFERENCES_FAILURE = 'UPDATE_NOTIFICATION_PREFERENCES_FAILURE';

const updateNotificationPreferences = (model) => ({
  type: UPDATE_NOTIFICATION_PREFERENCES,
  payload: {
    request: {
      method: 'PATCH',
      data: model,
      url: `${userEndpoint()}/notificationpreferences`,
    },
  },
});

export const UPDATE_PUSH_NOTIFICATION_PREFERENCES = 'UPDATE_PUSH_NOTIFICATION_PREFERENCES';
export const UPDATE_PUSH_NOTIFICATION_PREFERENCES_SUCCESS = 'UPDATE_PUSH_NOTIFICATION_PREFERENCES_SUCCESS';
export const UPDATE_PUSH_NOTIFICATION_PREFERENCES_FAILURE = 'UPDATE_PUSH_NOTIFICATION_PREFERENCES_FAILURE';

const updatePushNotificationPreferences = (model) => ({
  type: UPDATE_PUSH_NOTIFICATION_PREFERENCES,
  payload: {
    request: {
      method: 'PATCH',
      data: model,
      url: `${userEndpoint()}/notificationpreferences/push`,
    },
  },
});

const actions = {
  token,
  userInfo,
  revocation,

  login,
  logout,
  register,

  getUserByPublicAddress,

  getAccounts,
  loadAccount,
  unloadAccount,
  updateUserAccountProfile,

  changeCulture,
  changeTheme,
  changeRestrictedContent,

  changeUsername,

  requestEmailChange,
  verifyEmailChange,

  requestPhoneNumberChange,
  verifyPhoneNumberChange,

  resetEmailChangeData,
  resetPhoneNumberChangeData,

  getNotificationPreferences,
  updateEmailNotificationPreferences,
  updateSmsNotificationPreferences,
  updateNotificationPreferences,
  updatePushNotificationPreferences,
};

export const UserActions = {
  login: (loginParams) => (dispatch) => {
    return new Promise((resolve, reject) => {
      let scope = 'openid profile email phone api offline_access';

      dispatch(
        actions.token({
          client_id,
          scope,
          ...loginParams,
        }),
      ).then((tokenResult) => {
        console.log(tokenResult)
        if (tokenResult.payload) {
          // We might have to pass the access token to this user info endpoint
          dispatch(actions.userInfo(tokenResult.payload.access_token)).then((userInfoResult) => {
            if (userInfoResult.payload) {
              // Wxtract the expiry from the ticket and use that for quick tests
              const jwt = JSON.parse(atob(tokenResult.payload.access_token.split('.')[1]));

              // convert to milliseconds
              tokenResult.payload.expires_at = jwt.exp * 1000;

              localStorage.setItem('state.auth.user.tokens', JSON.stringify(tokenResult.payload));
              localStorage.setItem('state.auth.user.profile', JSON.stringify(userInfoResult.payload));

              resolve({
                token: tokenResult.payload,
                profile: userInfoResult.payload,
              });
              
              window.location.reload();
              dispatch(actions.login());
            } else {
              reject(userInfoResult.error);
            }
          });
        } else {
          reject(tokenResult.error);
        }
      });
    });
  },

  logout: () => async (dispatch, getState) => {
    const state = getState();
    const tokens = state.auth.user?.tokens || null;

    //const accessToken = tokens?.access_token;
    const refreshToken = tokens?.refresh_token;

    // revoke both the access and refresh tokens
    // enable the following line when using reference tokens
    // if(accessToken) {
    //   dispatch(actions.revocation({token: accessToken, token_type_hint: 'access_token'})),
    // }
    if (refreshToken) {
      dispatch(
        actions.revocation({
          client_id,
          token: refreshToken,
          token_type_hint: 'refresh_token',
        }),
      );
    }

    localStorage.removeItem('state.auth.user.tokens');
    localStorage.removeItem('state.auth.user.profile');

    // Now also disconnect the wallet
    localStorage.removeItem('state.wallet.cachedWallet');

    // Remove anything that has anything to do with the
    // Unstoppable Domains as well
    localStorage.removeItem('openidConfiguration:');
    localStorage.removeItem('username');
    localStorage.removeItem('request');

    await new Promise((resolve) => setTimeout(resolve, 500));
    window.location.href = '/';

    return dispatch(actions.logout());
  },

  refreshUserInfo: () => (dispatch, getState) => {
    const state = getState();
    const tokens = state.auth.user?.tokens || null;
    const accessToken = tokens.access_token;

    return dispatch(actions.userInfo(accessToken)).then((userInfoResult) => {
      if (userInfoResult.payload) {
        localStorage.setItem('state.auth.user.profile', JSON.stringify(userInfoResult.payload));
      } 

      return(userInfoResult);
    });
  },

  refreshTokens: () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const state = getState();
      const tokens = state.auth.user?.tokens || null;

      if (!(tokens && tokens.refresh_token)) {
        reject('No Refresh Token Exists');
        return;
      }

      dispatch(
        actions.token({
          client_id,
          grant_type: 'refresh_token',
          refresh_token: tokens.refresh_token,
        }),
      ).then((tokenResult) => {
        if (tokenResult.payload) {
          // Wxtract the expiry from the ticket and use that for quick tests
          const jwt = JSON.parse(atob(tokenResult.payload.access_token.split('.')[1]));

          // convert to milliseconds
          tokenResult.payload.expires_at = jwt.exp * 1000;

          localStorage.setItem('state.auth.user.tokens', JSON.stringify(tokenResult.payload));

          resolve({
            token: tokenResult.payload,
          });
        } else {
          reject(tokenResult.error);
        }
      });
    });
  },

  register: (registerModel) => (dispatch) =>
    // This could receive the token
    // and call the userinfo endpoint
    // and also save the refresh_token to the local storage
    dispatch(actions.register(registerModel)),

  getUserByPublicAddress: (publicAddress) => (dispatch) => dispatch(actions.getUserByPublicAddress(publicAddress)),

  getAccounts: () => (dispatch) => dispatch(actions.getAccounts()),
  loadAccount: (accountId) => (dispatch) => dispatch(actions.loadAccount(accountId)),
  unloadAccount: () => (dispatch) => dispatch(actions.unloadAccount()),
  updateUserAccountProfile: (profile) => (dispatch) => dispatch(actions.updateUserAccountProfile(profile)),
  resetEmailChangeData: () => (dispatch) => dispatch(actions.resetEmailChangeData()),
  resetPhoneNumberChangeData: () => (dispatch) => dispatch(actions.resetPhoneNumberChangeData()),
  changeCulture: (culture) => (dispatch) => dispatch(actions.changeCulture(culture)),
  changeTheme: (theme) => (dispatch) => dispatch(actions.changeTheme(theme)),
  changeRestrictedContent: (restrictedContent) => (dispatch) =>
    dispatch(actions.changeRestrictedContent(restrictedContent)),
  changeUsername: (changeUsernameModel) => (dispatch) => dispatch(actions.changeUsername(changeUsernameModel)),
  // Email Change
  requestEmailChange: (emailChangeModel) => (dispatch) => dispatch(actions.requestEmailChange(emailChangeModel)),
  verifyEmailChange: (verifyEmailChangeModel) => (dispatch) =>
    dispatch(actions.verifyEmailChange(verifyEmailChangeModel)),
  // Phone number change
  requestPhoneNumberChange: (phoneNumberChangeModel) => (dispatch) =>
    dispatch(actions.requestPhoneNumberChange(phoneNumberChangeModel)),
  verifyPhoneNumberChange: (verifyPhoneNumberChangeModel) => (dispatch) =>
    dispatch(actions.verifyPhoneNumberChange(verifyPhoneNumberChangeModel)),
  // Notification Preferences
  updatePushNotificationPreferences: (model) => (dispatch) =>
    dispatch(actions.updatePushNotificationPreferences(model)),
  updateNotificationPreferences: (model) => (dispatch) => dispatch(actions.updateNotificationPreferences(model)),
  updateSmsNotificationPreferences: (smsModel) => (dispatch) =>
    dispatch(actions.updateSmsNotificationPreferences(smsModel)),
  updateEmailNotificationPreferences: (emailModel) => (dispatch) =>
    dispatch(actions.updateEmailNotificationPreferences(emailModel)),
  getNotificationPreferences: () => (dispatch) => dispatch(actions.getNotificationPreferences()),
};
