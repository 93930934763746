const hashtagsODataEndpoint = (hashtagTag) => `v1/odata/hashtags${hashtagTag ? `/${hashtagTag}` : ''}`;
const trendingHashtagsODataEndpoint = `v1/odata/hashtags/trending`;
const urlQueryPart = (query) => (query ? `?${query}` : '');

export const GET_HASHTAGS = 'GET_HASHTAGS';
export const GET_HASHTAGS_SUCCESS = 'GET_HASHTAGS_SUCCESS';
export const GET_HASHTAGS_FAILURE = 'GET_HASHTAGS_FAILURE';

const getHashtags = (query, group) => ({
  type: GET_HASHTAGS,
  payload: {
    group,
    request: {
      url: hashtagsODataEndpoint() + (group ? `/${group}/` : '') + urlQueryPart(query),
    },
  },
});

export const GET_RECOMMENDED_HASHTAGS = 'GET_RECOMMENDED_HASHTAGS';
export const GET_RECOMMENDED_HASHTAGS_SUCCESS = 'GET_RECOMMENDED_HASHTAGS_SUCCESS';
export const GET_RECOMMENDED_HASHTAGS_FAILURE = 'GET_RECOMMENDED_HASHTAGS_FAILURE';

const getRecommendedHashtags = (query, group) => ({
  type: GET_RECOMMENDED_HASHTAGS,
  payload: {
    group,
    request: {
      url: trendingHashtagsODataEndpoint + urlQueryPart(query),
    },
  },
});

export const GET_HASHTAG = 'GET_HASHTAG';
export const GET_HASHTAG_SUCCESS = 'GET_HASHTAG_SUCCESS';
export const GET_HASHTAG_FAILURE = 'GET_HASHTAG_FAILURE';

const getHashtag = (hashtag, query) => ({
  type: GET_HASHTAG,
  payload: {
    request: {
      url: hashtagsODataEndpoint(hashtag) + urlQueryPart(query),
    },
  },
});

const actions = {
  getHashtags,
  getHashtag,
  getRecommendedHashtags,
};

export const HashtagActions = {
  getHashtags: (query, group, order) => (dispatch) => dispatch(actions.getHashtags(query, group)),
  getRecommendedHashtags: (query) => (dispatch) => dispatch(actions.getRecommendedHashtags(query)),
  getHashtag: (hashtag, query) => (dispatch) => dispatch(actions.getHashtag(hashtag, query)),
};
