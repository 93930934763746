const commentsEndpoint = (commentId) => `v1/comments${  commentId ? `/${  commentId}` : ''}`;
const commentsODataEndpoint = (commentId) => `v1/odata/comments${  commentId ? `/${  commentId}` : ''}`;
const urlQueryPart = query => (query ? `?${query}` : '');

export const CREATE_COMMENT = "CREATE_COMMENT";
export const CREATE_COMMENT_SUCCESS = "CREATE_COMMENT_SUCCESS";
export const CREATE_COMMENT_FAILURE = "CREATE_COMMENT_FAILURE";

const createComment = (comment) => ({
  type: CREATE_COMMENT,
  payload: {
    request: {
      key: comment.key,
      method: 'POST',
      url: commentsEndpoint(),
      data: comment
    }
  }
});

export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const UPDATE_COMMENT_SUCCESS = "UPDATE_COMMENT_SUCCESS";
export const UPDATE_COMMENT_FAILURE = "UPDATE_COMMENT_FAILURE";

const updateComment = (commentId, comment) => ({
  type: UPDATE_COMMENT,
  payload: {
    commentId: commentId,
    request: {
      method: 'PUT',
      url: commentsEndpoint(commentId),
      data: comment
    }
  }
});

export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_SUCCESS = "DELETE_COMMENT_SUCCESS";
export const DELETE_COMMENT_FAILURE = "DELETE_COMMENT_FAILURE";

const deleteComment = (commentId, key) => ({
  type: DELETE_COMMENT,
  payload: {
    commentId: commentId,
    key: key,
    request: {
      method: 'DELETE',
      url: commentsEndpoint(commentId),
    }
  }
});

export const LIKE_COMMENT = "LIKE_COMMENT";
export const LIKE_COMMENT_SUCCESS = "LIKE_COMMENT_SUCCESS";
export const LIKE_COMMENT_FAILURE = "LIKE_COMMENT_FAILURE";

const likeComment = (commentId) => ({
  type: LIKE_COMMENT,
  payload: {
    commentId: commentId,
    request: {
      method: 'POST',
      url: `${commentsEndpoint(commentId)  }/like/`,
    }
  }
});

export const UNLIKE_COMMENT = "UNLIKE_COMMENT";
export const UNLIKE_COMMENT_SUCCESS = "UNLIKE_COMMENT_SUCCESS";
export const UNLIKE_COMMENT_FAILURE = "UNLIKE_COMMENT_FAILURE";

const unlikeComment = (commentId) => ({
  type: UNLIKE_COMMENT,
  payload: {
    commentId: commentId,
    request: {
      method: 'DELETE',
      url: `${commentsEndpoint(commentId)  }/like/`,
    }
  }
});

export const GET_COMMENT_REPLIES = "GET_COMMENT_REPLIES";
export const GET_COMMENT_REPLIES_SUCCESS = "GET_COMMENT_REPLIES_SUCCESS";
export const GET_COMMENT_REPLIES_FAILURE = "GET_COMMENT_REPLIES_FAILURE";

const getCommentReplies = (commentId, query, key=null, flag=false) => ({
  type: GET_COMMENT_REPLIES,
  payload: {
    commentId: commentId,
    key: key,
    flag: flag,
    request: {
      url: `${commentsODataEndpoint(commentId)  }/replies${  urlQueryPart(query)}`
    }
  }
});

export const CLEAR_CACHE_COMMENT = 'CLEAR_CACHE_COMMENT';
const clearCacheComment = (key) => ({
  type: CLEAR_CACHE_COMMENT,
  payload: {
    key
  }
});

const actions = {
  createComment,
  updateComment,
  deleteComment,
  likeComment,
  unlikeComment,

  getCommentReplies,
  clearCacheComment
};

export const CommentActions = {
  createComment: (comment) => (dispatch) => dispatch(actions.createComment(comment)),
  updateComment: (commentId, comment) => (dispatch) => dispatch(actions.updateComment(commentId, comment)),
  deleteComment: (commentId, key) => (dispatch) => dispatch(actions.deleteComment(commentId, key)),
  likeComment: (commentId) => (dispatch) => dispatch(actions.likeComment(commentId)),
  unlikeComment: (commentId) => (dispatch) => dispatch(actions.unlikeComment(commentId)),
  getCommentReplies: (commentId, query, key, flag) => (dispatch) => (dispatch(
    actions.getCommentReplies(commentId, query, key, flag))),
  clearCacheComment: (key) => (dispatch) => dispatch(actions.clearCacheComment(key)),
};
