import { serialize } from 'object-to-formdata';

const accountsEndpoint = (accountId) => `v1/accounts${typeof accountId !== 'undefined' ? `/${accountId}` : ''}`;
const accountsODataEndpoint = (accountId) =>
  `v1/odata/accounts${typeof accountId !== 'undefined' ? `/${accountId}` : ''}`;

const hashtagsODataEndpoint = (tag) => `v1/odata/hashtags${tag ? `(\'${tag}\')` : ''}`;

const meODataEndpoint = () => `v1/odata/me`;

const urlQueryPart = (query) => (query ? `?${query}` : '');

export const GET_ACCOUNTS = 'GET_ACCOUNTS';
export const GET_ACCOUNTS_SUCCESS = 'GET_ACCOUNTS_SUCCESS';
export const GET_ACCOUNTS_FAILURE = 'GET_ACCOUNTS_FAILURE';

const getAccounts = (query, group, category) => ({
  type: GET_ACCOUNTS,
  payload: {
    group,
    category,
    request: {
      url: accountsODataEndpoint() + (group ? `/${group}/` : '') + urlQueryPart(query),
    },
  },
});

export const GET_ACCOUNTS_WITH_POST = 'GET_ACCOUNTS_WITH_POST';
export const GET_ACCOUNTS_WITH_POST_SUCCESS = 'GET_ACCOUNTS_WITH_POST_SUCCESS';
export const GET_ACCOUNTS_WITH_POST_FAILURE = 'GET_ACCOUNTS_WITH_POST_FAILURE';

const getAccountsWithPost = (query, searchKey) => {
  
  var decodedSearchKey = decodeURIComponent(searchKey);

  var endpoint = decodedSearchKey?.startsWith('#')
  ? hashtagsODataEndpoint(decodedSearchKey.substring(1)) + '/accounts'
  : accountsODataEndpoint();

  return {
    type: GET_ACCOUNTS_WITH_POST,
    payload: {
      request: {
        method: 'POST',
        headers: {"content-type": "text/plain"},
        url: endpoint + '/$query',
        data: query,
      },
    },
  };
};

export const CHECK_USERNAME_AVAILABILITY = 'CHECK_USERNAME_AVAILABILITY';
export const CHECK_USERNAME_AVAILABILITY_SUCCESS = 'CHECK_USERNAME_AVAILABILITY_SUCCESS';
export const CHECK_USERNAME_AVAILABILITY_FAILURE = 'CHECK_USERNAME_AVAILABILITY_FAILURE';

const checkUsernameAvailability = (username) => ({
  type: CHECK_USERNAME_AVAILABILITY,
  payload: {
    request: {
      url: `${accountsEndpoint()}/CheckUsername/${username}`,
    },
  },
});

export const GET_ACCOUNT = 'GET_ACCOUNT';
export const GET_ACCOUNT_SUCCESS = 'GET_ACCOUNT_SUCCESS';
export const GET_ACCOUNT_FAILURE = 'GET_ACCOUNT_FAILURE';

const getAccount = (accountId) => ({
  type: GET_ACCOUNT,
  payload: {
    request: {
      url: accountsEndpoint(accountId),
    },
  },
});

export const GET_ACCOUNT_BY_NAME = 'GET_ACCOUNT_BY_NAME';
export const GET_ACCOUNT_BY_NAME_SUCCESS = 'GET_ACCOUNT_BY_NAME_SUCCESS';
export const GET_ACCOUNT_BY_NAME_FAILURE = 'GET_ACCOUNT_BY_NAME_FAILURE';

const getAccountByName = (name) => ({
  type: GET_ACCOUNT_BY_NAME,
  payload: {
    request: {
      url: `${accountsEndpoint()}/${name}`,
    },
  },
});

export const GET_ACCOUNT_BY_MENTION = 'GET_ACCOUNT_BY_MENTION';
export const GET_ACCOUNT_BY_MENTION_SUCCESS = 'GET_ACCOUNT_BY_MENTION_SUCCESS';
export const GET_ACCOUNT_BY_MENTION_FAILURE = 'GET_ACCOUNT_BY_MENTION_FAILURE';

const getAccountByMention = (name) => ({
  type: GET_ACCOUNT_BY_MENTION,
  payload: {
    request: {
      url: `${accountsEndpoint()}/${name}`,
    },
  },
});

// We will likely integrate this as part of the getCollectible
// or implement it in the HTTP headers as part of that
export const RECORD_ACCOUNT_PROFILE_VIEW = 'RECORD_ACCOUNT_PROFILE_VIEW';
export const RECORD_ACCOUNT_PROFILE_VIEW_SUCCESS = 'RECORD_ACCOUNT_PROFILE_VIEW_SUCCESS';
export const RECORD_ACCOUNT_PROFILE_VIEW_FAILURE = 'RECORD_ACCOUNT_PROFILE_VIEW_FAILURE';

const recordAccountProfileView = (accountId) => ({
  type: RECORD_ACCOUNT_PROFILE_VIEW,
  payload: {
    request: {
      method: 'POST',
      url: `${accountsEndpoint(accountId)}/view/`,
    },
  },
});

export const UPDATE_ACCOUNT_PROFILE = 'UPDATE_ACCOUNT_PROFILE';
export const UPDATE_ACCOUNT_PROFILE_SUCCESS = 'UPDATE_ACCOUNT_PROFILE_SUCCESS';
export const UPDATE_ACCOUNT_PROFILE_FAILURE = 'UPDATE_ACCOUNT_PROFILE_FAILURE';

const updateAccountProfile = (profileModel) => {
  const formData = serialize(profileModel, {
    indices: true,
  });

  return {
    type: UPDATE_ACCOUNT_PROFILE,
    payload: {
      request: {
        method: 'PUT',
        url: `${accountsEndpoint()}/profile`,
        data: formData,
      },
    },
  };
};

export const CHANGE_COUNTRY = 'CHANGE_COUNTRY';
export const CHANGE_COUNTRY_SUCCESS = 'CHANGE_COUNTRY_SUCCESS';
export const CHANGE_COUNTRY_FAILURE = 'CHANGE_COUNTRY_FAILURE';

const changeCountry = (countryCode) => ({
  type: CHANGE_COUNTRY,
  payload: {
    request: {
      method: 'PUT',
      url: `${accountsEndpoint()}/country`,
      data: {
        countryCode,
      },
    },
  },
});

export const GET_ACCOUNT_CREATED_COLLECTIONS = 'GET_ACCOUNT_CREATED_COLLECTIONS';
export const GET_ACCOUNT_CREATED_COLLECTIONS_SUCCESS = 'GET_ACCOUNT_CREATED_COLLECTIONS_SUCCESS';
export const GET_ACCOUNT_CREATED_COLLECTIONS_FAILURE = 'GET_ACCOUNT_CREATED_COLLECTIONS_FAILURE';

// We will need to do paging here
const getAccountCreatedCollections = (accountId, query) => ({
  type: GET_ACCOUNT_CREATED_COLLECTIONS,
  payload: {
    accountId: accountId,
    request: {
      url: `${accountsODataEndpoint(accountId)}/collections/${urlQueryPart(query)}`,
    },
  },
});

export const GET_ACCOUNT_CREATED_COLLECTIBLES = 'GET_ACCOUNT_CREATED_COLLECTIBLES';
export const GET_ACCOUNT_CREATED_COLLECTIBLES_SUCCESS = 'GET_ACCOUNT_CREATED_COLLECTIBLES_SUCCESS';
export const GET_ACCOUNT_CREATED_COLLECTIBLES_FAILURE = 'GET_ACCOUNT_CREATED_COLLECTIBLES_FAILURE';

// We will need to do paging here
const getAccountCreatedCollectibles = (accountId, query) => ({
  type: GET_ACCOUNT_CREATED_COLLECTIBLES,
  payload: {
    accountId: accountId,
    request: {
      url: `${accountsODataEndpoint(accountId)}/createdcollectibles/${urlQueryPart(query)}`,
    },
  },
});

const collectiblesODataEndpoint = (collectibleId) => `v1/odata/collectibles${collectibleId ? `/${collectibleId}` : ''}`;

export const GET_ACCOUNT_OWNED_COLLECTIBLES = 'GET_ACCOUNT_OWNED_COLLECTIBLES';
export const GET_ACCOUNT_OWNED_COLLECTIBLES_SUCCESS = 'GET_ACCOUNT_OWNED_COLLECTIBLES_SUCCESS';
export const GET_ACCOUNT_OWNED_COLLECTIBLES_FAILURE = 'GET_ACCOUNT_OWNED_COLLECTIBLES_FAILURE';

// We will need to do paging here
const getAccountOwnedCollectibles = (accountId, query) => ({
  type: GET_ACCOUNT_OWNED_COLLECTIBLES,
  payload: {
    accountId: accountId,
    request: {
      url: `${accountsODataEndpoint(accountId)}/ownedcollectibles/${urlQueryPart(query)}`,
    },
  },
});

export const GET_ACCOUNT_RESERVE_AUCTION_COLLECTIBLES = 'GET_ACCOUNT_RESERVE_AUCTION_COLLECTIBLES';
export const GET_ACCOUNT_RESERVE_AUCTION_COLLECTIBLES_SUCCESS = 'GET_ACCOUNT_RESERVE_AUCTION_COLLECTIBLES_SUCCESS';
export const GET_ACCOUNT_RESERVE_AUCTION_COLLECTIBLES_FAILURE = 'GET_ACCOUNT_RESERVE_AUCTION_COLLECTIBLES_FAILURE';

// We will need to do paging here
const getAccountReserveAuctionCollectibles = (accountId, query) => ({
  type: GET_ACCOUNT_RESERVE_AUCTION_COLLECTIBLES,
  payload: {
    accountId: accountId,
    request: {
      url: `${accountsODataEndpoint(accountId)}/ownedcollectibles/${urlQueryPart(query)}`,
    },
  },
});

export const GET_ACCOUNT_ON_SALE_COLLECTIBLES = 'GET_ACCOUNT_ON_SALE_COLLECTIBLES';
export const GET_ACCOUNT_ON_SALE_COLLECTIBLES_SUCCESS = 'GET_ACCOUNT_ON_SALE_COLLECTIBLES_SUCCESS';
export const GET_ACCOUNT_ON_SALE_COLLECTIBLES_FAILURE = 'GET_ACCOUNT_ON_SALE_COLLECTIBLES_FAILURE';

// We will need to do paging here
const getAccountOnSaleCollectibles = (accountId, query) => ({
  type: GET_ACCOUNT_ON_SALE_COLLECTIBLES,
  payload: {
    accountId: accountId,
    request: {
      url: `${accountsODataEndpoint(accountId)}/ownedcollectibles/${urlQueryPart(query)}`,
    },
  },
});

export const GET_ACCOUNT_FOLLOWERS = 'GET_ACCOUNT_FOLLOWERS';
export const GET_ACCOUNT_FOLLOWERS_SUCCESS = 'GET_ACCOUNT_FOLLOWERS_SUCCESS';
export const GET_ACCOUNT_FOLLOWERS_FAILURE = 'GET_ACCOUNT_FOLLOWERS_FAILURE';

const getAccountFollowers = (accountId, query) => ({
  type: GET_ACCOUNT_FOLLOWERS,
  payload: {
    accountId: accountId,
    request: {
      url: `${accountsODataEndpoint(accountId)}/followers/${urlQueryPart(query)}`,
    },
  },
});

export const GET_ACCOUNT_FOLLOWEES = 'GET_ACCOUNT_FOLLOWEES';
export const GET_ACCOUNT_FOLLOWEES_SUCCESS = 'GET_ACCOUNT_FOLLOWEES_SUCCESS';
export const GET_ACCOUNT_FOLLOWEES_FAILURE = 'GET_ACCOUNT_FOLLOWEES_FAILURE';

const getAccountFollowees = (accountId, query) => ({
  type: GET_ACCOUNT_FOLLOWEES,
  payload: {
    accountId: accountId,
    request: {
      url: `${accountsODataEndpoint(accountId)}/followees/${urlQueryPart(query)}`,
    },
  },
});

export const FOLLOW_ACCOUNT = 'FOLLOW_ACCOUNT';
export const FOLLOW_ACCOUNT_SUCCESS = 'FOLLOW_ACCOUNT_SUCCESS';
export const FOLLOW_ACCOUNT_FAILURE = 'FOLLOW_ACCOUNT_FAILURE';

const followAccount = (followeeAccountId) => ({
  type: FOLLOW_ACCOUNT,
  payload: {
    followeeAccountId,
    request: {
      method: 'POST',
      url: `${accountsEndpoint()}/follow/${followeeAccountId}`,
    },
  },
});

export const UNFOLLOW_ACCOUNT = 'UNFOLLOW_ACCOUNT';
export const UNFOLLOW_ACCOUNT_SUCCESS = 'UNFOLLOW_ACCOUNT_SUCCESS';
export const UNFOLLOW_ACCOUNT_FAILURE = 'UNFOLLOW_ACCOUNT_FAILURE';

const unfollowAccount = (followeeAccountId) => ({
  type: UNFOLLOW_ACCOUNT,
  payload: {
    followeeAccountId,
    request: {
      method: 'DELETE',
      url: `${accountsEndpoint()}/follow/${followeeAccountId}`,
    },
  },
});

export const REMOVE_FOLLOWER = 'REMOVE_FOLLOWER';
export const REMOVE_FOLLOWER_SUCCESS = 'REMOVE_FOLLOWER_SUCCESS';
export const REMOVE_FOLLOWER_FAILURE = 'REMOVE_FOLLOWER_FAILURE';

const removeFollower = (followerAccountId) => ({
  type: REMOVE_FOLLOWER,
  payload: {
    followerAccountId,
    request: {
      method: 'DELETE',
      url: `${accountsEndpoint()}/follower/${followerAccountId}`,
    },
  },
});

export const BLOCK_ACCOUNT = 'BLOCK_ACCOUNT';
export const BLOCK_ACCOUNT_SUCCESS = 'BLOCK_ACCOUNT_SUCCESS';
export const BLOCK_ACCOUNT_FAILURE = 'BLOCK_ACCOUNT_FAILURE';

const blockAccount = (blockedAccountId) => ({
  type: BLOCK_ACCOUNT,
  payload: {
    blockedAccountId,
    request: {
      method: 'POST',
      url: `${accountsEndpoint()}/block/${blockedAccountId}`,
    },
  },
});

export const GET_BLOCKED_ACCOUNTS = 'GET_BLOCKED_ACCOUNTS';
export const GET_BLOCKED_ACCOUNTS_SUCCESS = 'GET_BLOCKED_ACCOUNTS_SUCCESS';
export const GET_BLOCKED_ACCOUNTS_FAILURE = 'GET_BLOCKED_ACCOUNTS_FAILURE';

const getBlockedAccounts = (query) => ({
  type: GET_BLOCKED_ACCOUNTS,
  payload: {
    request: {
      method: 'GET',
      url: `${meODataEndpoint()}/blockedAccounts/${urlQueryPart(query)}`,
    },
  },
});

export const UNBLOCK_ACCOUNT = 'UNBLOCK_ACCOUNT';
export const UNBLOCK_ACCOUNT_SUCCESS = 'UNBLOCK_ACCOUNT_SUCCESS';
export const UNBLOCK_ACCOUNT_FAILURE = 'UNBLOCK_ACCOUNT_FAILURE';

const unblockAccount = (blockedAccountId) => ({
  type: UNBLOCK_ACCOUNT,
  payload: {
    request: {
      method: 'DELETE',
      url: `${accountsEndpoint()}/block/${blockedAccountId}`,
    },
  },
});

export const MUTE_ACCOUNT = 'MUTE_ACCOUNT';
export const MUTE_ACCOUNT_SUCCESS = 'MUTE_ACCOUNT_SUCCESS';
export const MUTE_ACCOUNT_FAILURE = 'MUTE_ACCOUNT_FAILURE';

const muteAccount = (mutedAccountId) => ({
  type: MUTE_ACCOUNT,
  payload: {
    mutedAccountId,
    request: {
      method: 'POST',
      url: `${accountsEndpoint()}/mute/${mutedAccountId}`,
    },
  },
});

export const GET_MUTED_ACCOUNTS = 'GET_MUTED_ACCOUNTS';
export const GET_MUTED_ACCOUNTS_SUCCESS = 'GET_MUTED_ACCOUNTS_SUCCESS';
export const GET_MUTED_ACCOUNTS_FAILURE = 'GET_MUTED_ACCOUNTS_FAILURE';

const getMutedAccounts = (query) => ({
  type: GET_MUTED_ACCOUNTS,
  payload: {
    request: {
      method: 'GET',
      url: `${meODataEndpoint()}/mutedAccounts/${urlQueryPart(query)}`,
    },
  },
});

export const UNMUTE_ACCOUNT = 'UNMUTE_ACCOUNT';
export const UNMUTE_ACCOUNT_SUCCESS = 'UNMUTE_ACCOUNT_SUCCESS';
export const UNMUTE_ACCOUNT_FAILURE = 'UNMUTE_ACCOUNT_FAILURE';

const unmuteAccount = (mutedAccountId) => ({
  type: UNMUTE_ACCOUNT,
  payload: {
    request: {
      method: 'DELETE',
      url: `${accountsEndpoint()}/mute/${mutedAccountId}`,
    },
  },
});

export const IS_FOLLOWING_ACCOUNT = 'IS_FOLLOWING_ACCOUNT';
export const IS_FOLLOWING_ACCOUNT_SUCCESS = 'IS_FOLLOWING_ACCOUNT_SUCCESS';
export const IS_FOLLOWING_ACCOUNT_FAILURE = 'IS_FOLLOWING_ACCOUNT_FAILURE';

const isFollowingAccount = (followeeAccountId) => ({
  type: IS_FOLLOWING_ACCOUNT,
  payload: {
    request: {
      url: `${accountsEndpoint()}/follow/${followeeAccountId}`,
    },
  },
});

export const ENABLE_FOLLOWEE_NOTIFICATIONS = 'ENABLE_FOLLOWEE_NOTIFICATIONS';
export const ENABLE_FOLLOWEE_NOTIFICATIONS_SUCCESS = 'ENABLE_FOLLOWEE_NOTIFICATIONS_SUCCESS';
export const ENABLE_FOLLOWEE_NOTIFICATIONS_FAILURE = 'ENABLE_FOLLOWEE_NOTIFICATIONS_FAILURE';

const enableFolloweeNotifications = (followeeAccountId) => ({
  type: ENABLE_FOLLOWEE_NOTIFICATIONS,
  payload: {
    followeeAccountId: followeeAccountId,
    request: {
      method: 'POST',
      url: `${accountsEndpoint()}/follow/${followeeAccountId}/notifications`,
    },
  },
});

export const DISABLE_FOLLOWEE_NOTIFICATIONS = 'DISABLE_FOLLOWEE_NOTIFICATIONS';
export const DISABLE_FOLLOWEE_NOTIFICATIONS_SUCCESS = 'DISABLE_FOLLOWEE_NOTIFICATIONS_SUCCESS';
export const DISABLE_FOLLOWEE_NOTIFICATIONS_FAILURE = 'DISABLE_FOLLOWEE_NOTIFICATIONS_FAILURE';

const disableFolloweeNotifications = (followeeAccountId) => ({
  type: DISABLE_FOLLOWEE_NOTIFICATIONS,
  payload: {
    followeeAccountId: followeeAccountId,
    request: {
      method: 'DELETE',
      url: `${accountsEndpoint()}/follow/${followeeAccountId}/notifications`,
    },
  },
});

export const IS_FOLLOWEE_NOTIFICATIONS_ENABLED = 'IS_FOLLOWING_ACCOUNT';
export const IS_FOLLOWEE_NOTIFICATIONS_ENABLED_SUCCESS = 'IS_FOLLOWING_ACCOUNT_SUCCESS';
export const IS_FOLLOWEE_NOTIFICATIONS_ENABLED_FAILURE = 'IS_FOLLOWING_ACCOUNT_FAILURE';

const isFolloweeNotificationsEnabled = (followeeAccountId) => ({
  type: IS_FOLLOWEE_NOTIFICATIONS_ENABLED,
  payload: {
    request: {
      url: `${accountsEndpoint()}/follow/${followeeAccountId}/notifications`,
    },
  },
});

export const GET_ACCOUNT_HASHTAGS = 'GET_ACCOUNT_HASHTAGS';
export const GET_ACCOUNT_HASHTAGS_SUCCESS = 'GET_ACCOUNT_HASHTAGS_SUCCESS';
export const GET_ACCOUNT_HASHTAGS_FAILURE = 'GET_ACCOUNT_HASHTAGS_FAILURE';

const getAccountHashtags = (query) => ({
  type: GET_ACCOUNT_HASHTAGS,
  payload: {
    request: {
      url: `${meODataEndpoint()}/hashtags/${urlQueryPart(query)}`,
    },
  },
});

export const FOLLOW_HASHTAG = 'FOLLOW_HASHTAG';
export const FOLLOW_HASHTAG_SUCCESS = 'FOLLOW_HASHTAG_SUCCESS';
export const FOLLOW_HASHTAG_FAILURE = 'FOLLOW_HASHTAG_FAILURE';

const followHashtag = (hashtag) => ({
  type: FOLLOW_HASHTAG,
  payload: {
    tag: hashtag,
    request: {
      method: 'POST',
      url: `${accountsEndpoint()}/follow/hashtag/${hashtag}`,
    },
  },
});

export const UNFOLLOW_HASHTAG = 'UNFOLLOW_HASHTAG';
export const UNFOLLOW_HASHTAG_SUCCESS = 'UNFOLLOW_HASHTAG_SUCCESS';
export const UNFOLLOW_HASHTAG_FAILURE = 'UNFOLLOW_HASHTAG_FAILURE';

const unfollowHashtag = (hashtag) => ({
  type: UNFOLLOW_HASHTAG,
  payload: {
    tag: hashtag,
    request: {
      method: 'DELETE',
      url: `${accountsEndpoint()}/follow/hashtag/${hashtag}`,
    },
  },
});

export const IS_FOLLOWING_HASHTAG = 'IS_FOLLOWING_HASHTAG';
export const IS_FOLLOWING_HASHTAG_SUCCESS = 'IS_FOLLOWING_HASHTAG_SUCCESS';
export const IS_FOLLOWING_HASHTAG_FAILURE = 'IS_FOLLOWING_HASHTAG_FAILURE';

const isFollowingHashtag = (hashtag) => ({
  type: IS_FOLLOWING_HASHTAG,
  payload: {
    request: {
      url: `${accountsEndpoint()}/follow/hashtag/${hashtag}`,
    },
  },
});

export const GET_ACCOUNT_INTERESTS = 'GET_ACCOUNT_INTERESTS';
export const GET_ACCOUNT_INTERESTS_SUCCESS = 'GET_ACCOUNT_INTERESTS_SUCCESS';
export const GET_ACCOUNT_INTERESTS_FAILURE = 'GET_ACCOUNT_INTERESTS_FAILURE';

const getAccountInterests = (query) => ({
  type: GET_ACCOUNT_INTERESTS,
  payload: {
    request: {
      url: `${meODataEndpoint()}/interests/${urlQueryPart(query)}`,
    },
  },
});

export const ADD_ACCOUNT_INTERESTS = 'ADD_ACCOUNT_INTERESTS';
export const ADD_ACCOUNT_INTERESTS_SUCCESS = 'ADD_ACCOUNT_INTERESTS_SUCCESS';
export const ADD_ACCOUNT_INTERESTS_FAILURE = 'ADD_ACCOUNT_INTERESTS_FAILURE';

const addAccountInterests = (interestsToAdd) => ({
  type: ADD_ACCOUNT_INTERESTS,
  payload: {
    request: {
      method: 'POST',
      url: `${accountsEndpoint()}/interests/`,
      data: interestsToAdd,
    },
  },
});

export const REMOVE_ACCOUNT_INTERESTS = 'REMOVE_ACCOUNT_INTERESTS';
export const REMOVE_ACCOUNT_INTERESTS_SUCCESS = 'REMOVE_ACCOUNT_INTERESTS_SUCCESS';
export const REMOVE_ACCOUNT_INTERESTS_FAILURE = 'REMOVE_ACCOUNT_INTERESTS_FAILURE';

const removeInterests = (interestsToRemove) => ({
  type: REMOVE_ACCOUNT_INTERESTS,
  payload: {
    request: {
      method: 'DELETE',
      url: `${accountsEndpoint()}/interests/`,
      data: interestsToRemove,
    },
  },
});

export const INVITE_FRIEND_BY_EMAIL = 'INVITE_FRIEND_BY_EMAIL';
export const INVITE_FRIEND_BY_EMAIL_SUCCESS = 'INVITE_FRIEND_BY_EMAIL_SUCCESS';
export const INVITE_FRIEND_BY_EMAIL_FAILURE = 'INVITE_FRIEND_BY_EMAIL_FAILURE';

const inviteFriendByEmail = (inviteByEmailModel) => ({
  type: INVITE_FRIEND_BY_EMAIL,
  payload: {
    request: {
      method: 'POST',
      url: `${accountsEndpoint()}/invite/email/`,
      data: inviteByEmailModel,
    },
  },
});

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILURE = 'GET_NOTIFICATIONS_FAILURE';

const getNotifications = (skip, take) => ({
  type: GET_NOTIFICATIONS,
  payload: {
    request: {
      url: `${accountsEndpoint()}/notifications`,
      params: {
        skip,
        take,
      },
    },
  },
});

export const MARK_NOTIFICATION_AS_READ = 'MARK_NOTIFICATION_AS_READ';
export const MARK_NOTIFICATION_AS_READ_SUCCESS = 'MARK_NOTIFICATION_AS_READ_SUCCESS';
export const MARK_NOTIFICATION_AS_READ_FAILURE = 'MARK_NOTIFICATION_AS_READ_FAILURE';

const markNotificationAsRead = (notificationId) => ({
  type: MARK_NOTIFICATION_AS_READ,
  payload: {
    request: {
      method: 'PATCH',
      url: `${accountsEndpoint()}/notifications/${notificationId}`,
    },
  },
});

export const MARK_ALL_NOTIFICATIONS_AS_READ = 'MARK_ALL_NOTIFICATIONS_AS_READ';
export const MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS = 'MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE = 'MARK_ALL_NOTIFICATIONS_AS_READ_FAILURE';

const markAllNotificationsAsRead = () => ({
  type: MARK_ALL_NOTIFICATIONS_AS_READ,
  payload: {
    request: {
      method: 'PATCH',
      url: `${accountsEndpoint()}/notifications`,
    },
  },
});

export const MARK_ALL_NOTIFICATIONS_AS_VIEWED = 'MARK_ALL_NOTIFICATIONS_AS_VIEWED';
export const MARK_ALL_NOTIFICATIONS_AS_VIEWED_SUCCESS = 'MARK_ALL_NOTIFICATIONS_AS_VIEWED_SUCCESS';
export const MARK_ALL_NOTIFICATIONS_AS_VIEWED_FAILURE = 'MARK_ALL_NOTIFICATIONS_AS_VIEWED_FAILURE';

const markAllNotificationsAsViewed = () => ({
  type: MARK_ALL_NOTIFICATIONS_AS_VIEWED,
  payload: {
    request: {
      method: 'PUT',
      url: `${accountsEndpoint()}/notifications`,
    },
  },
});

export const GET_FEED_ITEMS = 'GET_FEED_ITEMS';
export const GET_FEED_ITEMS_SUCCESS = 'GET_FEED_ITEMS_SUCCESS';
export const GET_FEED_ITEMS_FAILURE = 'GET_FEED_ITEMS_FAILURE';

const getFeedItems = (skip, take) => ({
  type: GET_FEED_ITEMS,
  payload: {
    request: {
      url: `${accountsEndpoint()}/feeditems`,
      params: {
        skip,
        take,
      },
    },
  },
});

export const GET_HISTORY = 'GET_HISTORY';
export const GET_HISTORY_SUCCESS = 'GET_HISTORY_SUCCESS';
export const GET_HISTORY_FAILURE = 'GET_HISTORY_FAILURE';

const getHistory = (query) => ({
  type: GET_HISTORY,
  payload: {
    request: {
      url: `${meODataEndpoint()}/history${urlQueryPart(query)}`,
    },
  },
});

export const GET_BOOKMARKS = 'GET_BOOKMARKS';
export const GET_BOOKMARKS_SUCCESS = 'GET_BOOKMARKS_SUCCESS';
export const GET_BOOKMARKS_FAILURE = 'GET_BOOKMARKS_FAILURE';

const getBookmarks = (query) => ({
  type: GET_BOOKMARKS,
  payload: {
    request: {
      url: `${meODataEndpoint()}/bookmarks${urlQueryPart(query)}`,
    },
  },
});

export const GET_RECOMMENDED_ACCOUNTS = 'GET_RECOMMENDED_ACCOUNTS';
export const GET_RECOMMENDED_ACCOUNTS_SUCCESS = 'GET_RECOMMENDED_ACCOUNTS_SUCCESS';
export const GET_RECOMMENDED_ACCOUNTS_FAILURE = 'GET_RECOMMENDED_ACCOUNTS_FAILURE';

const getRecommendedAccounts = (query) => ({
  type: GET_RECOMMENDED_ACCOUNTS,
  payload: {
    request: {
      url: accountsODataEndpoint() + urlQueryPart(query),
    },
  },
});

export const SET_ACTIVE_PROFILE_ACCOUNT = 'SET_ACTIVE_PROFILE_ACCOUNT';

const setActiveProfileAccountId = (activeProfileAccountId) => ({
  type: SET_ACTIVE_PROFILE_ACCOUNT,
  payload: {
    activeProfileAccountId: activeProfileAccountId,
  },
});

export const GET_PLACED_BIDS = 'GET_PLACED_BIDS';
export const GET_PLACED_BIDS_SUCCESS = 'GET_PLACED_BIDS_SUCCESS';
export const GET_PLACED_BIDS_FAILURE = 'GET_PLACED_BIDS_FAILURE';

const getPlacedBids = (query) => ({
  type: GET_PLACED_BIDS,
  payload: {
    request: {
      url: `${meODataEndpoint()}/placedbids${urlQueryPart(query)}`,
    },
  },
});

export const GET_REMINDERS = 'GET_REMINDERS';
export const GET_REMINDERS_SUCCESS = 'GET_REMINDERS_SUCCESS';
export const GET_REMINDERS_FAILURE = 'GET_REMINDERS_FAILURE';

const getReminders = () => ({
  type: GET_REMINDERS,
  payload: {
    request: {
      url: `${accountsEndpoint()}/reminders`,
    },
  },
});

export const GET_RECIPIENTS_SUGGESTION = 'GET_RECIPIENTS_SUGGESTION';
export const GET_RECIPIENTS_SUGGESTION_SUCCESS = 'GET_RECIPIENTS_SUGGESTION_SUCCESS';
export const GET_RECIPIENTS_SUGGESTION_FAILURE = 'GET_RECIPIENTS_SUGGESTION_FAILURE';

const getRecipientsSuggestion = (accountId, query) => ({
  type: GET_RECIPIENTS_SUGGESTION,
  payload: {
    accountId: accountId,
    request: {
      url: `${accountsODataEndpoint(accountId)}/followees/${urlQueryPart(query)}`,
    },
  },
});

export const SET_SEARCH_HISTORY = 'SET_SEARCH_HISTORY';
export const SET_SEARCH_HISTORY_SUCCESS = 'SET_SEARCH_HISTORY_SUCCESS';
export const SET_SEARCH_HISTORY_FAILURE = 'SET_SEARCH_HISTORY_FAILURE';

const setSearchHistory = (payload) => ({
  type: SET_SEARCH_HISTORY,
  payload: {
    request: {
      method: 'POST',
      url: `${accountsEndpoint()}/search`,
      data: {
        ...payload
      },
    },
  },
});

export const GET_SEARCH_HISTORY = 'GET_SEARCH_HISTORY';
export const GET_SEARCH_HISTORY_SUCCESS = 'GET_SEARCH_HISTORY_SUCCESS';
export const GET_SEARCH_HISTORY_FAILURE = 'GET_SEARCH_HISTORY_FAILURE';

const getSearchHistory = () => ({
  type: GET_SEARCH_HISTORY,
  payload: {
    request: {
      url: `${accountsEndpoint()}/search/recent`,
    },
  },
});

export const SET_NEW_FEED_ITEM_AVAILABLE = 'SET_NEW_FEED_ITEM_AVAILABLE';
const setNewFeedItemAvailable = () => ({
  type: SET_NEW_FEED_ITEM_AVAILABLE,
});

export const CLEAR_FEED_ITEMS = 'CLEAR_FEED_ITEMS';
const clearFeedItems = () => ({
  type: CLEAR_FEED_ITEMS,
});

const actions = {
  getAccounts,
  getAccountsWithPost,

  getAccount,
  getAccountByName,
  getAccountByMention,
  checkUsernameAvailability,

  recordAccountProfileView,
  updateAccountProfile,
  changeCountry,

  getAccountCreatedCollections,
  getAccountCreatedCollectibles,
  getAccountOwnedCollectibles,
  getAccountReserveAuctionCollectibles,
  getAccountOnSaleCollectibles,

  getAccountFollowers,
  getAccountFollowees,
  followAccount,
  unfollowAccount,
  removeFollower,
  isFollowingAccount,
  muteAccount,
  getMutedAccounts,
  unmuteAccount,
  blockAccount,
  getBlockedAccounts,
  unblockAccount,

  enableFolloweeNotifications,
  disableFolloweeNotifications,
  isFolloweeNotificationsEnabled,

  followHashtag,
  unfollowHashtag,
  isFollowingHashtag,
  getAccountHashtags,

  addAccountInterests,
  removeInterests,
  getAccountInterests,

  inviteFriendByEmail,

  getNotifications,
  markNotificationAsRead,
  markAllNotificationsAsRead,
  markAllNotificationsAsViewed,

  getFeedItems,
  clearFeedItems,
  getHistory,
  getBookmarks,

  getRecommendedAccounts,

  setActiveProfileAccountId,

  getPlacedBids,
  setNewFeedItemAvailable,

  getReminders,
  getRecipientsSuggestion,

  setSearchHistory,
  getSearchHistory,
};

export const AccountActions = {
  // public for all accounts 
  getAccounts: (query, group, category) => (dispatch) => dispatch(actions.getAccounts(query, group, category)),
  getAccountsWithPost: (query, searchKey) => (dispatch) => dispatch(actions.getAccountsWithPost(query, searchKey)),
  getAccount: (accountId) => (dispatch) => dispatch(actions.getAccount(accountId)),
  getAccountByName: (name) => (dispatch) => dispatch(actions.getAccountByName(name)),
  getAccountByMention: (name) => (dispatch) => dispatch(actions.getAccountByMention(name)),
  recordAccountProfileView: (accountId) => (dispatch) => dispatch(actions.recordAccountProfileView(accountId)),

  getAccountCreatedCollections: (accountId, query) => (dispatch) =>
    dispatch(actions.getAccountCreatedCollections(accountId, query)),

  getAccountCreatedCollectibles: (accountId, query) => (dispatch) =>
    dispatch(actions.getAccountCreatedCollectibles(accountId, query)),

  getAccountOwnedCollectibles: (accountId, query) => (dispatch) =>
    dispatch(actions.getAccountOwnedCollectibles(accountId, query)),

  getAccountOnSaleCollectibles: (accountId, query) => (dispatch) =>
    dispatch(actions.getAccountOnSaleCollectibles(accountId, query)),

  getAccountReserveAuctionCollectibles: (accountId, query) => (dispatch) =>
    dispatch(actions.getAccountReserveAuctionCollectibles(accountId, query)),

  // private for the current account
  updateAccountProfile: (profileModel) => (dispatch) => dispatch(actions.updateAccountProfile(profileModel)),
  changeCountry: (countryCode) => (dispatch) => dispatch(actions.changeCountry(countryCode)),
  getAccountFollowers: (accountId, query) => (dispatch) => dispatch(actions.getAccountFollowers(accountId, query)),
  getAccountFollowees: (accountId, query) => (dispatch) => dispatch(actions.getAccountFollowees(accountId, query)),
  followAccount: (followedAccountId) => (dispatch) => dispatch(actions.followAccount(followedAccountId)),
  unfollowAccount: (followedAccountId) => (dispatch) => dispatch(actions.unfollowAccount(followedAccountId)),
  removeFollower: (followerAccountId) => (dispatch) => dispatch(actions.removeFollower(followerAccountId)),
  isFollowingAccount: (followedAccountId) => (dispatch) => dispatch(actions.isFollowingAccount(followedAccountId)),
  muteAccount: (mutedAccountId) => (dispatch) => dispatch(actions.muteAccount(mutedAccountId)),
  getMutedAccounts: (query) => (dispatch) => dispatch(actions.getMutedAccounts(query)),
  unmuteAccount: (mutedAccountId) => (dispatch) => dispatch(actions.unmuteAccount(mutedAccountId)),
  blockAccount: (blockedAccountId) => (dispatch) => dispatch(actions.blockAccount(blockedAccountId)),
  getBlockedAccounts: (query) => (dispatch) => dispatch(actions.getBlockedAccounts(query)),
  unblockAccount: (blockedAccountId) => (dispatch) => dispatch(actions.unblockAccount(blockedAccountId)),
  enableFolloweeNotifications: (followedAccountId) => (dispatch) =>
    dispatch(actions.enableFolloweeNotifications(followedAccountId)),
  disableFolloweeNotifications: (followedAccountId) => (dispatch) =>
    dispatch(actions.disableFolloweeNotifications(followedAccountId)),
  isFolloweeNotificationsEnabled: (followedAccountId) => (dispatch) =>
    dispatch(actions.isFolloweeNotificationsEnabled(followedAccountId)),
  getAccountHashtags: (query) => (dispatch) => dispatch(actions.getAccountHashtags(query)),
  followHashtag: (hashtag) => (dispatch) => dispatch(actions.followHashtag(hashtag)),
  unfollowHashtag: (hashtag) => (dispatch) => dispatch(actions.unfollowHashtag(hashtag)),
  isFollowingHashtag: (hashtag) => (dispatch) => dispatch(actions.isFollowingHashtag(hashtag)),
  getAccountInterests: (query) => (dispatch) => dispatch(actions.getAccountInterests(query)),
  addAccountInterests: (interestsToAdd) => (dispatch) => dispatch(actions.addAccountInterests(interestsToAdd)),
  removeInterests: (interestsToRemove) => (dispatch) => dispatch(actions.removeInterests(interestsToRemove)),
  checkUsernameAvailability: (slug) => (dispatch) => dispatch(actions.checkUsernameAvailability(slug)),
  inviteFriendByEmail: (inviteByEmailModel) => (dispatch) => dispatch(actions.inviteFriendByEmail(inviteByEmailModel)),
  getNotifications:
    (skip = 0, take = 100) =>
    (dispatch) =>
      dispatch(actions.getNotifications(skip, take)),
  markNotificationAsRead: (notificationId) => (dispatch) => dispatch(actions.markNotificationAsRead(notificationId)),
  markAllNotificationsAsRead: () => (dispatch) => dispatch(actions.markAllNotificationsAsRead()),
  markAllNotificationsAsViewed: () => (dispatch) => dispatch(actions.markAllNotificationsAsViewed()),
  getFeedItems:
    (skip = 0, take = 100) =>
    (dispatch) =>
      dispatch(actions.getFeedItems(skip, take)),
  getRecommendedAccounts: (query) => (dispatch) => dispatch(actions.getRecommendedAccounts(query)),

  getHistory: (query) => (dispatch) => dispatch(actions.getHistory(query)),
  getBookmarks: (query) => (dispatch) => dispatch(actions.getBookmarks(query)),
  getPlacedBids: (query) => (dispatch) => dispatch(actions.getPlacedBids(query)),

  setActiveProfileAccountId: (activeProfileAccountId) => (dispatch) =>
    dispatch(actions.setActiveProfileAccountId(activeProfileAccountId)),

  setNewFeedItemAvailable: () => (dispatch) => dispatch(actions.setNewFeedItemAvailable()),
  clearFeedItems: () => (dispatch) => dispatch(actions.clearFeedItems()),
  getReminders: () => (dispatch) => dispatch(actions.getReminders()),
  getRecipientsSuggestion: (accountId, query) => (dispatch) =>
    dispatch(actions.getRecipientsSuggestion(accountId, query)),
  setSearchHistory: (payload) => (dispatch) =>
    dispatch(
      actions.setSearchHistory(payload),
    ),
  getSearchHistory: () => (dispatch) => dispatch(actions.getSearchHistory()),
};
