import { ethereumRequest } from '@Utils';

export const SET_WALLET = "SET_WALLET";

export const SET_WALLET_STATUS = "SET_WALLET_STATUS";
export const SET_WALLET_ERROR = "SET_WALLET_ERROR";

export const SET_CACHED_WALLET_STATE = "SET_CACHED_WALLET_STATE";

export const WALLET_SIGNATURE_REQUEST = "WALLET_SIGNATURE_REQUEST";
export const WALLET_SIGNATURE_SUCCESS = "WALLET_SIGNATURE_SUCCESS";
export const WALLET_SIGNATURE_FAILURE = "WALLET_SIGNATURE_FAILURE";


const walletSignatureRequest = () => ({
  type: WALLET_SIGNATURE_REQUEST,
});

const walletSignatureSuccess = (signature) => ({
  type: WALLET_SIGNATURE_SUCCESS,
  payload: { signature },
});

const walletSignatureFailure = (error) => ({
  type: WALLET_SIGNATURE_FAILURE,
  error,
});

const setWallet = (wallet) => ({
  type: SET_WALLET,
  payload: {
    wallet
  },
});


const setWalletStatus = (walletStatus) => ({
  type: SET_WALLET_STATUS,
  payload: {
    walletStatus,
  },
});

const setWalletError = (error) => ({
  type: SET_WALLET_ERROR,
  error,
});

const setCachedWalletState = (wallet, loading) => ({
  type: SET_CACHED_WALLET_STATE,
  payload: {
    wallet,
    loading,
  },
});

const actions = {
  setWallet,
  setWalletStatus,
  setWalletError,
  setCachedWalletState,
  walletSignatureRequest,
  walletSignatureSuccess,
  walletSignatureFailure,
};

export const WalletActions = {
  setWalletStatus: (walletStatus) => (dispatch) => dispatch(actions.setWalletStatus(walletStatus)),
  setWalletError: (walletError) => (dispatch) => dispatch(actions.setWalletError(walletError)),
  setCachedWalletState: (connector, loading) => (dispatch) => {
    localStorage.setItem('state.wallet.cachedWallet', JSON.stringify(connector));
    return dispatch(actions.setCachedWalletState(connector, loading));
  },
  resetCachedWalletState: () => (dispatch) => {
    localStorage.removeItem('state.wallet.cachedWallet');
    return dispatch(actions.setCachedWalletState(null, false));
  },
  ethereumRequest: (ethereum, method, params) => (dispatch) => new Promise((resolve, reject) => {
    dispatch(actions.walletSignatureRequest());
    ethereumRequest(ethereum, method, params)
      .then((signature) => {
        dispatch(actions.walletSignatureSuccess(signature));
        resolve(signature);
      })
      .catch((err) => {
        dispatch(actions.walletSignatureFailure(err));
        reject(err);
      });
  }),
};
