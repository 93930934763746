export const NEW_NOTIFICATION = "NEW_NOTIFICATION";

const newNotification = (accountId, notification) => ({
  type: NEW_NOTIFICATION,
  payload: {
    accountId,
    notification,
  }
});

export const REMOVED_NOTIFICATION = "REMOVED_NOTIFICATION";

const removedNotification = (accountId, notificationId) => ({
  type: REMOVED_NOTIFICATION,
  payload: {
    accountId,
    notificationId
  }
});

export const SET_UNVIEWED_NOTIFICATION_COUNT = "SET_UNVIEWED_NOTIFICATION_COUNT";

const setUnviewedNotificationCount = (accountId, unviewedNotificationCount) => ({
  type: SET_UNVIEWED_NOTIFICATION_COUNT,
  payload: {
    accountId,
    unviewedNotificationCount
  }
});

export const RESET_NOTIFICATIONS = "RESET_NOTIFICATIONS";

const resetNotifications = () => ({
  type: RESET_NOTIFICATIONS,
});


const actions = {
  resetNotifications,
  newNotification,
  removedNotification,
  setUnviewedNotificationCount,
};

export const NotificationActions = {
  resetNotifications: () => async (dispatch) => {
    dispatch(actions.resetNotifications());
  },
  newNotification: (accountId, notification, unviewedNotificationCount) => async (dispatch) => {
    dispatch(actions.newNotification(accountId, notification));
    dispatch(actions.setUnviewedNotificationCount(accountId, unviewedNotificationCount));
  },
  removeNotification: (accountId, notificationId, unviewedNotificationCount) => async (dispatch) => {
    dispatch(actions.removedNotification(accountId, notificationId));
    dispatch(actions.setUnviewedNotificationCount(accountId, unviewedNotificationCount));
  },
  setUnviewedNotificationCount: (accountId, unviewedNotificationCount) => async (dispatch) => {
    dispatch(actions.setUnviewedNotificationCount(accountId, unviewedNotificationCount));
  },
};
