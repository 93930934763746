import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { debounce } from 'debounce';
import cn from 'classnames';

import { ServiceActions } from '@Actions/ServiceActions';

import Icon from '@Components/shared/Icon';
import Input from '@Components/form/Input';
import { ReactComponent as CollectionIcon } from '@Images/icons/collection.svg?component';
import { useMemo } from 'react';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import { AccountActions } from '@Actions/AccountActions';
import { buildCollectibleLink, convertToPoster } from '@Utils';
import { InlineLoader } from './Spinner';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

const creationsPath = '/search/creations';
const onSalePath = '/search/on-sale';
const liveAuctionsPath = '/search/live-auctions';
const creatorsPath = '/search/creators';
const collectionsPath = '/search/collections';
const hashtagsPath = '/search/hashtags';

const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

const GlobalSearch = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const [searchValue, setSearchValue] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const [showHistory, setShowHistory] = useState(false);
  const [searchHistory, setSearchHistory] = useState();
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(false);

  const searchMap = {
    creations: {
      beName: 'notListedCollectibles',
      icon: <Icon className="mr-1" src="fas fa-icons" type="fontawesome" />,
      displayName: t('public.creations'),
      isCircle: false,
      hasCategory: true,
      setHistoryKey: 'searchedCollectibleId',
      link: (item) => buildCollectibleLink(item),
    },
    onSale: {
      beName: 'fixedPriceCollectibles',
      icon: <Icon className="mr-1" src="fas fa-circle-notch" type="fontawesome" />,
      displayName: t('public.onSale'),
      isCircle: false,
      hasCategory: true,
      setHistoryKey: 'searchedCollectibleId',
      link: (item) => buildCollectibleLink(item),
    },
    liveAuction: {
      beName: 'liveAuctionCollectibles',
      icon: <Icon className="mr-1" src="fas fa-clock" type="fontawesome" />,
      displayName: t('public.liveAuctions'),
      isCircle: false,
      hasCategory: true,
      setHistoryKey: 'searchedCollectibleId',
      link: (item) => buildCollectibleLink(item),
    },
    collections: {
      beName: 'collections',
      icon: <CollectionIcon className="mr-1" width={16} height={16} />,
      displayName: t('public.collections'),
      isCircle: true,
      hasCategory: false,
      setHistoryKey: 'searchedCollectionId',
      link: (item) => `/collection/${item.slug}`,
    },
    creators: {
      beName: 'accounts',
      icon: <Icon className="mr-1" src="fas fa-feather-alt" type="fontawesome" />,
      displayName: t('public.creators'),
      isCircle: true,
      hasCategory: false,
      setHistoryKey: 'searchedAccountId',
      link: (item) => `/${item.slug}`,
    },
    hashtags: {
      beName: 'hashtags',
      icon: <Icon className="mr-1" src="fas fa-hashtag" type="fontawesome" />,
      displayName: t('public.hashtags'),
      isCircle: true,
      setHistoryKey: 'SearchedHashtagId',
      link: (item) => `/hashtags?q=%23/${item.tag}`,
    },
  };

  const _getSearchHistory = () => {
    const { getSearchHistory, account } = props;
    if (account?.id) {
      setLoading(true);
      getSearchHistory()
        .then((res) => {
          if (!res.error) setSearchHistory(res.payload);
        })
        .finally(() => setLoading(false));
    }
  };

  const checkSearchStartWithSpesific = (_value) => {
    if (_value.startsWith('@')) {
      return { path: creatorsPath, value: _value.split('@')[1], character: '@' };
    } else if (_value.startsWith('#')) {
      return { path: hashtagsPath, value: _value.split('#')[1], character: '#' };
    } else {
      return { path: null, value: _value, character: null };
    }
  };

  const getSearchSuggestions = (value) => {
    const { getSearchSuggestion } = props;
    let _value = value?.trim();

    const valueObj = checkSearchStartWithSpesific(_value);

    setLoading(true);
    _value.length > 0 &&
      getSearchSuggestion(valueObj.value, 4)
        .then((res) => {
          if (!res.error) {
            setSearchResults(res.payload);
          }
        })
        .finally(() => setLoading(false));
  };

  const handleTooltipClose = () => {
    setTimeout(() => {
      if (mounted) setShowHistory(false);
    }, 250);
  };

  const handleTooltipOpen = () => {
    setShowHistory(true);
  };

  const onChangeHandler = (e) => {
    const value = e.target.value;
    setSearchValue(value);

    if (!value) setSearchResults({});

    setShowHistory(true);
  };

  const search = (value) => {
    const pathname = history.location.pathname;
    let newPath;
    let _value;

    const valueObj = checkSearchStartWithSpesific(value);
    _value = valueObj.character ? valueObj.character + valueObj.value : valueObj.value;

    if (valueObj.path) {
      newPath = valueObj.path;
    }else if (pathname === onSalePath) {
      newPath = onSalePath;
    } else if (pathname === liveAuctionsPath) {
      newPath = liveAuctionsPath;
    } else if (pathname === creatorsPath) {
      newPath = creatorsPath;
    } else if (pathname === collectionsPath) {
      newPath = collectionsPath;
    } else if (pathname === hashtagsPath) {
      newPath = hashtagsPath;
    } else {
      newPath = `${creationsPath}`;
    }

    return history.push({
      pathname: newPath,
      search: `?q=${encodeURIComponent(_value)}`,
    });
  };

  const onKeyDownHandler = (e) => {
    const { account, setSearchHistory } = props;
    let value = searchValue;

    if (e.key === 'Enter' && value) {
      if (mounted) {
        value = value.trim();

        if (value) {
          search(value);
          account?.id && setSearchHistory({ searchKey: value });
        }

        if (typeof props.closeMobileSearch == 'function') {
          props.closeMobileSearch();
        }
      }
      handleTooltipClose();
    }
  };

  const onClickSearchResult = (item, key, link, event) => {
    event.stopPropagation();
    const { account, setSearchHistory } = props;

    account?.id && setSearchHistory({ searchKey: searchValue, [key]: item.id });
    history.push(link(item));

    if (typeof props.closeMobileSearch == 'function') {
      props.closeMobileSearch();
    }
    setTimeout(() => _getSearchHistory(), 800);
  };

  const historySelectHandler = (value, event) => {
    event.stopPropagation();
    setSearchValue(value);
    const _value = value.trim();

    search(_value);

    if (typeof props.closeMobileSearch == 'function') {
      props.closeMobileSearch();
    }
    handleTooltipClose();
  };

  const clearSearchValue = (e) => {
    e.stopPropagation(); // prevent
    setSearchValue('');

    handleTooltipClose();
  };

  const handleRecentAccountClick = (account) => () => {
    history.push(`/${account.slug}`);
    if (typeof props.closeMobileSearch == 'function') {
      props.closeMobileSearch();
    }
    handleTooltipClose();
  };

  const searchResultItems = useMemo(
    () =>
      !!searchValue &&
      Object.values(searchMap).reduce(
        (acc, { beName, displayName, icon: Icons, isCircle, hasCategory, setHistoryKey, link }) => {
          const results = searchResults[beName]?.map((item) => (
            <div
              className="search-item"
              key={item.id}
              onClick={(e) => onClickSearchResult(item, setHistoryKey, link, e)}>
              <div className={cn('left-side', !isCircle && 'square')}>
                <div>{Icons}</div>
                {beName === 'hashtags' && (
                  <div className="hashtag-icon">
                    {' '}
                    <Icon src="fas fa-hashtag" type="fontawesome" />
                  </div>
                )}
                {beName !== 'hashtags' && (
                  <ProfileAvatar
                    width={40}
                    height={40}
                    imageLink={convertToPoster(item.profileImageUrl || item.coverImageUrl)}
                  />
                )}
                <div className="content">
                  <div className="name">{item.name || item.displayName || item.slug || item.tag || item.title}</div>
                  <div className={hasCategory ? 'category' : 'slug'}>{item.categoryName || item.slug}</div>
                </div>
              </div>
              <div className="right-side">
                {' '}
                {displayName}
                <Icon className="ml-1" src="fas fa-chevron-right" type="fontawesome" />
              </div>
            </div>
          ));
          return [...acc, ...(results || [])];
        },
        [],
      ),

    [searchResults, searchValue],
  );

  useEffect(() => {
    let params = new URLSearchParams(location.search);
    const value = params.get('q');

    setSearchValue(
      value ||
        (history.location.pathname.includes('hashtag') &&
          history.location.pathname.split('/')[2] &&
          `#${history.location.pathname.split('/')[2]}`) ||
        '',
    );
    _getSearchHistory();

    setMounted(true);

    return () => setMounted(true);
  }, [props.account]);

  useEffect(() => {
    let newSearchValue = '';
    if (history.location.pathname.includes('search')) {
      let params = new URLSearchParams(history.location.search);
      newSearchValue = params.get('q') || '';
    }

    if (history.location.pathname.includes('hashtag/')) {
      newSearchValue = `#${history.location.pathname.split('/')[2]}` || '';
    }

    if (newSearchValue !== searchValue) {
      setSearchValue(newSearchValue);
      newSearchValue.length >= 3 && getSearchSuggestions(newSearchValue);
    }
  }, [history.location.pathname, history.location.search]);

  useDebouncedEffect(() => searchValue.length >= 3 && getSearchSuggestions(searchValue), [searchValue], 600);

  return (
    <div className="global-search" onClick={() => setShowHistory(true)}>
      <Input.Text
        className="w-100"
        name="Search"
        placeholder={t('public.searchOix')}
        value={searchValue}
        onKeyDown={onKeyDownHandler}
        onChange={onChangeHandler}
        onFocus={handleTooltipOpen}
        onBlur={handleTooltipClose}
        autoComplete="off"
        prefix={
          !props.hidePrefix && (
            <span className="icon-container">
              <Icon className="settings-icon" type="fontawesome" src="fas fa-search" />
            </span>
          )
        }
        suffix={
          <div
            className={cn('clear-icon-container', showHistory && searchValue.length && 'visible')}
            onClick={(e) => clearSearchValue(e)}>
            <Icon className="settings-icon" type="fontawesome" src="fas fa-times-circle" />
          </div>
        }
      />
      {(showHistory && loading) ||
      ((!!searchResultItems?.length || !!searchHistory?.recentSearches?.length) &&
        (showHistory || props.closeMobileSearch)) ? (
        <div className="history-box">
          <OverlayScrollbarsComponent
            style={!props.isMobile ? { maxHeight: '400px' } : { maxHeight: 'calc(100vh - 72px)' }}
            className="slim-scroll-style">
            {loading && <InlineLoader className="my-4" />}
            {!loading && !!searchResultItems.length && searchResultItems}
            {!loading && !searchResultItems.length && (
              <>
                <div className="recently">{t('public.recentSearches')}</div>
                {!!searchHistory?.recentAccountSearches?.length && (
                  <div className="recently-searched-accounts">
                    {searchHistory.recentAccountSearches.slice(0, 4).map((account) => (
                      <div className="content-wrapper" key={account.id} onClick={handleRecentAccountClick(account)}>
                        <ProfileAvatar width={40} height={40} imageLink={account.profileImageUrl} />
                        <div className="account-info">
                          <div className="account-name">{account.displayName}</div>
                          <div className="account-slug">@{account.slug}</div>
                        </div>
                        <div className="recently-account-icon">
                          <Icon type="fontawesome" src="fas fa-arrow-left" />
                        </div>
                      </div>
                    ))}
                  </div>
                )}
                {searchHistory?.recentSearches
                  .filter((i) => i.toLowerCase().startsWith(searchValue.toLowerCase()))
                  .map((item, index) => (
                    <div
                      className={'history-item' + (item.startsWith('#') ? ' hashtag' : '')}
                      key={index}
                      onClick={(e) => historySelectHandler(item, e)}>
                      <div className="history-item-left-content">
                        <div className="search-icon-container">
                          <Icon type="fontawesome" src="fas fa-search" />
                        </div>
                        {item}
                      </div>
                      <div className="history-item-right-content">
                        <Icon type="fontawesome" src="fas fa-arrow-left" />
                      </div>
                    </div>
                  ))}
              </>
            )}
          </OverlayScrollbarsComponent>
        </div>
      ) : null}
    </div>
  );
};

GlobalSearch.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

const component = connect(
  (state) => {
    return { ...state.service, ...state.profile, ...state.user.account };
  },
  { ...ServiceActions, ...AccountActions },
)(GlobalSearch);

export default component;
