import * as React from 'react';
import { Link } from 'react-router-dom';
import Icon from '@Components/shared/Icon';
import AppVariables from '@AppVariables';
import { buildHelpCenterLink, openInNewTab } from '@Utils';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import Input from '@Components/form/Input';
import Button from '@Components/form/Button';
import withWallet from '@Components/hoc/withWallet';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import i18n from '../../i18n';
import oiLogoImg from '@Images/logo/oix-logo-white.svg';
import { runtimeConfig } from 'config';
import { ServiceActions } from '@Actions/ServiceActions';
import { ToastActions } from '@Actions/ToastActions';
import { ModalActions } from '@Actions/ModalActions';

class AppFooter extends React.Component {
  constructor(props) {
    super(props);

    this.dropdownRef = React.createRef();
    this.state = {
      emailAddress: '',
      isEmailError: false,
      isSendedEmail: false,
    };
  }

  setLanguage = (lang) => {
    i18n.changeLanguage(lang.code);
    console.log(i18n.language);
    this.dropdownRef.current.close();
  };

  subscribe = (e) => {
    e?.preventDefault();
    const { subscribeOixUpdates, toastSuccess, toastError, t } = this.props;
    const { emailAddress } = this.state;

    if (AppVariables.validationRules.email.test(emailAddress)) {
      const payload = { email: emailAddress };
      subscribeOixUpdates(payload)
        .then((res) => {
          toastSuccess(t('public.success'), {});
          this.setState({ isSendedEmail: true });
        })
        .catch((err) => {
          console.log('err', err);
          toastError(t('public.error'), {});
        });
      this.setState({ isEmailError: false });
    } else {
      this.setState({ isEmailError: true });
    }
  };

  render() {
    const { account, openModal, t } = this.props;
    const { emailAddress, isEmailError, isSendedEmail } = this.state;
    const { languages } = AppVariables;

    return (
      <footer id="app-footer" className="text-white text-small">
        <div className="footer-container">
          <hr />
          <div className="community-section">
            <div className="social-media">
              <p> {t('public.footer.sectionHeaderJoinCommunity')} </p>
              <div className="icons">
                <a href={AppVariables.externalUrls.twitter} rel="noreferrer nofollow" target="_blank">
                  <Icon type="fontawesome" src="fab fa-twitter" />
                </a>
                <a href={AppVariables.externalUrls.instagram} rel="noreferrer nofollow" target="_blank">
                  <Icon type="fontawesome" src="fab fa-instagram" />
                </a>
                <a href={AppVariables.externalUrls.discord} rel="noreferrer nofollow" target="_blank">
                  <Icon type="fontawesome" src="fab fa-discord" />
                </a>
                <a href={AppVariables.externalUrls.telegram} rel="noreferrer nofollow" target="_blank">
                  <Icon type="fontawesome" src="fab fa-telegram" />
                </a>
                <a href={AppVariables.externalUrls.youtube} rel="noreferrer nofollow" target="_blank">
                  <Icon type="fontawesome" src="fab fa-youtube" />
                </a>
                <a href={AppVariables.externalUrls.medium} rel="noreferrer nofollow" target="_blank">
                  <Icon type="fontawesome" src="fab fa-medium" />
                </a>
              </div>
            </div>
            <hr />
            <div className="subscribe">
              <p> {t('public.footer.sectionHeaderSubscribe')} </p>
              <form className="sub-input" onSubmit={this.subscribe}>
                {!isSendedEmail && (
                  <>
                    <div className="input-container">
                      <Input.Text
                        className="w-100"
                        name="email"
                        value={emailAddress}
                        onChange={(e) => {
                          this.setState({ emailAddress: e.target.value, isEmailError: false });
                        }}
                        placeholder={t('public.footer.subscribeEmailPlaceholder')}
                        error={isEmailError ? t('public.footer.subscribeInvalidEmailMessage') : null}
                      />
                    </div>
                    <Button onClick={this.subscribe} text={t('public.footer.subscribeButtonLabel')} type="primary" />
                  </>
                )}
              </form>
              {isSendedEmail && (
                <div className="send-email-message"> {t('public.footer.subscribeConfirmSubscriptionMessage')} </div>
              )}
            </div>
          </div>
          <hr />
          <div className="oix-info">
            <div className="oix-text">
              <Link className="logo" to="/">
                <img className="pr-1" src={oiLogoImg} />
              </Link>
              <div>{t('public.footer.textAboutOIX')}</div>
            </div>
            <div className="oix-menus">
              <div className="menus">
                <p> {t('public.footer.headingCommunity')} </p>
                <div>
                  <a href={buildHelpCenterLink('categories', 4472259363227)} rel="noreferrer nofollow" target="_blank">
                    {t('public.footer.linkGettingStarted')}
                  </a>
                  <a href={buildHelpCenterLink()} rel="noreferrer nofollow" target="_blank">
                    {t('public.footer.linkHelpCenter')}
                  </a>
                  <a href="https://blog.oix.app" rel="noreferrer nofollow" target="_blank">
                    {t('public.footer.linkBlog')}
                  </a>
                  <a
                    href="https://blog.oix.app/introducing-oix"
                    rel="noreferrer nofollow"
                    target="_blank">
                    {t('public.footer.linkWhitepaper')}
                  </a>
                </div>
              </div>
              <div className="menus">
                <p> {t('public.footer.headingResources')} </p>
                <div>
                  <Link to="/discover">{t('public.footer.linkDiscover')}</Link>
                  <a href="https://roadmap.oix.app" rel="noreferrer nofollow" target="_blank">
                    {t('public.footer.linkSuggestFeature')}
                  </a>
                  <div
                    className="send-feedback"
                    onClick={() =>
                      openModal('SendFeedbackModal', { show: true, backdrop: true, closeOnOutsideClick: true })
                    }>
                    {t('public.footer.linkSendFeedback')}
                  </div>
                  <a
                    href={'https://roadmap.oix.app/bugs' /*buildHelpCenterLink('requests', 'new?ticket_form_id=4472232196507') */}
                    rel="noreferrer nofollow"
                    target="_blank">
                    {t('public.footer.linkReportBug')}
                  </a>
                </div>
              </div>
              {/* <div className='menus'>
                <p> {t('public.footer.headingCompany')} </p>
                <div>
                  <a href="https://about.oix.app/company" rel="noreferrer nofollow" target="_blank">
                    {t('public.footer.linkAbout')}
                  </a>
                  <a href="https://about.oix.app/careers" rel="noreferrer nofollow" target="_blank">
                    {t('public.footer.linkCareers')}
                  </a>
                  <a href="https://about.oix.app/partners" rel="noreferrer nofollow" target="_blank">
                    {t('public.footer.linkPartners')}
                  </a>
                  <a href="https://about.oix.app/news" rel="noreferrer nofollow" target="_blank">
                    {t('public.footer.linkNews')}
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          <hr />
          <div className="footer-content mx-1">
            <div className="menu">
              <Link className="menu-item" to="/privacy">
                {t('public.privacyPolicy')}
              </Link>
              <Link className="menu-item" to="/terms">
                {t('public.termOfService')}
              </Link>
              <Link className="menu-item" to="/community-guidelines">
                {t('public.communityGuidelines')}
              </Link>
              {!account && (
                <div className="menu-item lang" onClick={() => this.dropdownRef.current.open()}>
                  {
                    <>
                      <Icon type="fontawesome" src="fas fa-language" /> {t('public.footer.dropdownLanguage')}{' '}
                      {languages.find((lang) => lang.code === i18n.language)?.language}{' '}
                      <span id="language-icon">
                        {' '}
                        <Icon type="fontawesome" src="fas fa-angle-down" />{' '}
                      </span>
                    </>
                  }
                </div>
              )}
            </div>
            <hr />
            <div className="social-links">
              <div className="mr-1">© 2023 Orderinbox Inc. {t('public.footer.textCopyright')} </div>
            </div>
          </div>
        </div>

        <ContextDropdown
          ref={this.dropdownRef}
          anchorEl="language-icon"
          anchorOriginV="top"
          transformOriginH="left"
          transformOriginV="bottom">
          {languages.map((lang) => (
            <div key={lang.value} onClick={() => this.setLanguage(lang)}>
              {lang.language}
            </div>
          ))}
        </ContextDropdown>
      </footer>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.user.account,
  ...state.wallet,
  ...state.auth,
});

const mapDispatchToProps = {
  ...ServiceActions,
  ...ToastActions,
  ...ModalActions,
};

export default withWallet(
  connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(withTranslation()(AppFooter)),
);
