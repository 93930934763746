import * as ModalActionTypes from '@Actions/ModalActions';

const initialState = () => ({
  currentModals: [],
  zeroPercentViewed: false
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case ModalActionTypes.MODAL_CLOSE: {
      const { count } = action.payload;
      const newCurrentModals = state.currentModals.slice(0, state.currentModals.length - count);
      return {...state, currentModals: newCurrentModals};
    }
    case ModalActionTypes.MODAL_OPEN: {
      const { modalType, modalProps } = action.payload;
      const newCurrentModals = state.currentModals.concat({ modalType, modalProps });
      return {...state, currentModals: newCurrentModals};
    }
    case ModalActionTypes.MODAL_CLOSE_ALL:
      return {...state, currentModals: []};
    case ModalActionTypes.SET_ZERO_PERCENT_VIEWED:
      return {
        ...state,
        zeroPercentViewed: true
      };      
    default:
      return state;
  }
};
