import * as React from 'react';
import bind from 'bind-decorator';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import Icon from '@Components/shared/Icon';
import { FaSlidersH } from 'react-icons/fa';
import { ModalActions } from '@Actions/ModalActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { AccountActions } from '@Actions/AccountActions';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';

class SearchLayoutE extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      load: false,
      isFilterBarOpen: false,
    };
  }

  scrollbarRef = React.createRef();

  @bind
  onScroll() {
    let max = this.scrollbarRef.current.osInstance().scroll().max.y;
    let current = this.scrollbarRef.current.osInstance().scroll().position.y;
    if (max - current < max / 10) {
      this.setState({ load: true });
    } else {
      this.setState({ load: false });
    }
  }

  convertHashtag() {
    const { location, match } = this.props;
    let params = new URLSearchParams(location.search);
    let value;

    if (match.params.tag) {
      value = '#' + match.params.tag;
    }

    return encodeURIComponent(value || params.get('q') || '');
  }

  @bind
  toggleFilterBar() {
    this.setState(({ isFilterBarOpen }) => ({ isFilterBarOpen: !isFilterBarOpen }));
  }

  render() {
    const { account, children, location, t } = this.props;
    const { load, isFilterBarOpen } = this.state;
    return (
      <div className="scrollbar-div">
        <div id="page-searche">
          <div className={cn('list', !!account && 'has-account')} id="type-box">
            <div className="category-list">
              <NavLink
                exact
                activeClassName="active"
                className="mr-3"
                to={'/search/creations?q=' + this.convertHashtag()}>
                <Icon className="mr-1" src="fas fa-icons" type="fontawesome" />
                {t('public.creations')}
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                className="mr-3"
                to={'/search/on-sale?q=' + this.convertHashtag()}>
                <Icon className="mr-1" src="fas fa-circle-notch" type="fontawesome" />
                {t('public.onSale')}
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                className="mr-3"
                to={'/search/live-auctions?q=' + this.convertHashtag()}>
                <Icon className="mr-1" src="fas fa-clock" type="fontawesome" />
                {t('public.liveAuctions')}
              </NavLink>
              <NavLink activeClassName="active" className="mr-3" to={'/search/creators?q=' + this.convertHashtag()}>
                <Icon className="mr-1" src="fas fa-feather-alt" type="fontawesome" />
                {t('public.creators')}
              </NavLink>
              <NavLink activeClassName="active" className="mr-3" to={'/search/collections?q=' + this.convertHashtag()}>
                <Icon className="mr-1" src="fas fa-feather-alt" type="fontawesome" />
                {t('public.collections')}
              </NavLink>
              <NavLink activeClassName="active" className="mr-3" to={'/search/hashtags?q=' + this.convertHashtag()}>
              <Icon className="mr-1" src="fas fa-hashtag" type="fontawesome" />
                {t('public.hastags')}
              </NavLink>
            </div>
            <div className={cn('filter-button', isFilterBarOpen && 'active')} onClick={this.toggleFilterBar}>
              <FaSlidersH className="mr-1" />
              Filter
            </div>
          </div>
          <hr />
          {React.cloneElement(children, { load: load, isFilterBarOpen: isFilterBarOpen })}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.search,
  ...state.service,
  ...state.user.account,
});

const SearchLayout = connect(mapStateToProps, { ...AccountActions, ...ModalActions, ...SpinnerActions }, null, {
  forwardRef: true,
})(SearchLayoutE);

export default withTranslation(undefined, { withRef: true })(SearchLayout);
