import * as AccountActionTypes from '@Actions/AccountActions';
import * as NotificationActionTypes from '@Actions/NotificationActions';
import * as UserActionTypes from '@Actions/UserActions';

const initialState = () => ({
  loadingAccounts: false,
  accounts: null,
  accountsLoadingError: null,

  loadingAccount: false,
  account: null,
  accountLoadingError: null,

  changingCountry: false,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case UserActionTypes.GET_ACCOUNTS:
      return {
        ...state,
        loadingAccounts: true,
      };
    case UserActionTypes.GET_ACCOUNTS_SUCCESS:
      return {
        ...state,
        loadingAccounts: false,
      };
    case UserActionTypes.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        loadingAccounts: false,
      };
    case UserActionTypes.LOAD_ACCOUNT:
      return {
        ...state,
        loadingCurrentAccount: true,
      };
    case UserActionTypes.LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingCurrentAccount: false,
        loadingAccount: false,
        account: action.payload,
        accountLoadingError: null,
      };
    case UserActionTypes.LOAD_ACCOUNT_FAILURE:
      return {
        ...state,
        loadingCurrentAccount: false,
        loadingAccount: false,
        account: null,
        accountLoadingError: action.error,
      };
    case UserActionTypes.UNLOAD_ACCOUNT:
      return {
        ...state,
        loadingCurrentAccount: false,
        account: null,
        accountLoadingError: null,
      };
    case UserActionTypes.UPDATE_USER_ACCOUNT_PROFILE:
      return {
        ...state,
        account: {
          ...state.account,
          profile: action.payload,
        },
      };
    case UserActionTypes.GET_USER_BY_PUBLIC_ADDRESS:
      return {
        ...state,
        isFetchingUserByPublicAddress:true
      }
    case UserActionTypes.GET_USER_BY_PUBLIC_ADDRESS_SUCCESS:
      return {
        ...state,
        isFetchingUserByPublicAddress:false
      }
    case UserActionTypes.GET_USER_BY_PUBLIC_ADDRESS_FAILURE:
      return {
        ...state,
        isFetchingUserByPublicAddress:false
      }
    case NotificationActionTypes.SET_UNVIEWED_NOTIFICATION_COUNT:
      // If this is an update for user's current account
      if (action.payload.accountId === state.account.id) {
        return {
          ...state,
          account: {
            ...state.account,
            unviewedNotificationCount: action.payload.unviewedNotificationCount,
          },
        };
      }

      return state;

    case AccountActionTypes.MARK_ALL_NOTIFICATIONS_AS_VIEWED_SUCCESS:
      return {
        ...state,
        account: {
          ...state.account,
          unviewedNotificationCount: 0,
        },
      };
    case AccountActionTypes.FOLLOW_ACCOUNT_SUCCESS:
      return {
        account: {
          ...state.account,
          followeesCount: state.account.followeesCount + 1,
        },
      };
    case AccountActionTypes.UNFOLLOW_ACCOUNT_SUCCESS:
      return {
        account: {
          ...state.account,
          followeesCount: state.account.followeesCount - 1,
        },
      };
    case AccountActionTypes.CHANGE_COUNTRY:
      return {
        ...state,
        changingCountry: true,
      };
    case AccountActionTypes.CHANGE_COUNTRY_SUCCESS: {
      const newCountryAccount = { ...state.account };
      newCountryAccount.countryCode = action.meta.previousAction.payload.request.data.countryCode;
      return {
        ...state,
        changingCountry: false,
        account: newCountryAccount,
      };
    }
    case AccountActionTypes.CHANGE_COUNTRY_FAILURE:
      return {
        ...state,
        changingCountry: false,
        changingCountryError: action.error,
      };
    default:
      return state;
  }
};
