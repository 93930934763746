import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

import { apiMiddleware } from '@Middleware/Api';
import { signalRMiddleware, signalRStart } from '@Middleware/SignalR';
import { sentryReduxEnhancer } from './sentryEnhancer';
import createRootReducer from '@Reducers';
import { createCancellationMiddleware } from '@Middleware/CancelMiddleware';

export default function configureStore(history, initialState) {
  // Build middleware. These are functions that can process the actions before they reach the store.
  const windowIfDefined = typeof window === 'undefined' ? null : window;
  // If devTools is installed, connect to it.
  const devToolsExtension = windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__;
  const createStoreWithMiddleware = compose(
    applyMiddleware(thunk, routerMiddleware(history), createCancellationMiddleware, apiMiddleware, signalRMiddleware),
    sentryReduxEnhancer,
    devToolsExtension ? devToolsExtension({ trace: true, traceLimit: 50 }) : (next) => next,
  )(createStore);

  // Combine all reducers and instantiate the app-wide store instance
  const store = createStoreWithMiddleware(createRootReducer(history), initialState);

  // start signalR (only in browser)
  if (windowIfDefined) { 
    signalRStart(store); 
  }

  // Enable Webpack hot module replacement for reducers
  if (module.hot) {
    module.hot.accept('../reducers', () => {
      store.replaceReducer(createRootReducer(history));
    });
  }

  return store;
}
