import * as AccountActionTypes from "@Actions/AccountActions";
import * as CollectibleActionTypes from "@Actions/CollectibleActions";

const initialState = () => ({
  fetchingBids: true,
  placedBids: [],
  bidsError: false,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case AccountActionTypes.GET_PLACED_BIDS:
      return {
        ...state,
        fetchingBids: true,
        bidsError: false
      };
    case AccountActionTypes.GET_PLACED_BIDS_SUCCESS: {
      return {
        ...state,
        fetchingBids: false,
        bidsError: false,
        placedBids: action.payload.value
      };
    }
    case AccountActionTypes.GET_PLACED_BIDS_FAILURE:
      return {
        ...state,
        fetchingBids: false,
        bidsError: action.error,
        placedBids: []
      };
/*    case CollectibleActionTypes.GET_COLLECTIBLE_SUCCESS: {
      const collectible = action.payload;
      const listing = action.payload.mostRecentListing;

      let newPlacedBids = state.placedBids.reduce((placedBids, bid) => {
        if (bid.listing.id === listing?.id) {
          placedBids.push({
            ...bid,
            listing: {
              ...listing,
              collectible
            }
          });          
        }
        else if (bid.listing.collectible.id === collectible.id) {
          placedBids.push({
            ...bid,
            listing: {
              ...bid.listing,
              collectible
            }
          });          
        }
        else {
          placedBids.push(bid);
        }

        return placedBids;
      }, []);

      return {
        ...state,
        placedBids: newPlacedBids
      };
    }    */
    case CollectibleActionTypes.ADD_COLLECTIBLE_TRANSACTION_SUCCESS: {
      const { collectibleId } = action.meta.previousAction.payload;
      const pendingTransactions = action.payload;  

      let newPlacedBids = state.placedBids.reduce((placedBids, bid) => {
        if (bid.listing.collectible.id === collectibleId) {
          placedBids.push({
            ...bid,
            listing: {
              ...bid.listing,
              collectible: {
                ...bid.listing.collectible,
                pendingTransactions
              }
            }
          });          
        }
        else {
          placedBids.push(bid);
        }

        return placedBids;
      }, []);

      return {
        ...state,
        placedBids: newPlacedBids
      };
    }    
    default:
      return state;
  }
};