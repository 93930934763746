import React from 'react';
import { toast } from 'react-toastify';
import EmojiConverter from 'emoji-js';
import BigNumber from 'bignumber.js';
import slugify from 'slugify';
import AppVariables from '@AppVariables';
import baseLoadable from '@loadable/component';
import { FallbackLoader } from '@Components/shared/Spinner';
import gradient from 'random-gradient';
import moment from 'moment';
import i18n from '@i18n';
import { ReactComponent as ShareIcon } from '@Images/icons/share.svg?component';
import Icon from '@Components/shared/Icon';
import oiLogoImg from '@Images/logo/oix-icon-color.svg';

/**
 * Is server prerendering by NodeJs.
 * There can't be any DOM elements such as: window, document, etc.
 */
export function isNode() {
  return typeof process === 'object' && process.versions && !!process.versions.node;
}

export function isBrowser() {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}

/**
 * Get NodeJs process.
 * */
export function getNodeProcess() {
  if (isNode()) {
    return process;
  }
  return null;
}

/**
 * Show error messages on page.
 * @param messages
 */
export function showErrors(...messages) {
  messages.forEach((x) => {
    if (!Array.isArray(x)) {
      toast.error(x);
    } else {
      x.forEach((y) => toast.error(y));
    }
  });
}

/**
 * Show information message on page.
 * @param message
 */
export function showInfo(message) {
  toast.info(message);
}

const getApplicationLoader = () => {
  if (isNode()) {
    return null;
  }
  return document.getElementById('applicationLoader');
};

const getQueryLoader = () => {
  if (isNode()) {
    return null;
  }
  return document.getElementById('queryLoader');
};

/**
 * Show main application loader.
 * */
export function showApplicationLoader() {
  const html = document.querySelector('html');
  const loader = getApplicationLoader();
  if (loader) {
    html.style.overflowY = 'hidden';
    loader.className = '';
  }
}

/**
 * Hide main application loader.
 * */
export function hideApplicationLoader() {
  const html = document.querySelector('html');
  const loader = getApplicationLoader();
  if (loader) {
    html.style.overflowY = null;
    loader.className = 'hidden';
  }
}

/**
 * Show query loader.
 * */
export function showQueryLoader() {
  const loader = getQueryLoader();
  if (loader) {
    loader.className = '';
  }
}

/**
 * Hide query loader.
 * */
export function hideQueryLoader() {
  const loader = getQueryLoader();
  if (loader) {
    loader.className = 'hidden';
  }
}

/**
 * Clone object.
 * @param object input object.
 */
export function clone(object) {
  return JSON.parse(JSON.stringify(object));
}

export function isObjectEmpty(obj) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // eslint-disable-next-line no-prototype-builtins
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

export function emptyForm(form) {
  const inputs = Array.from(form.querySelectorAll('input, select, textarea'));
  inputs.forEach((x) => {
    const inputType = x.getAttribute('type');
    if (inputType === 'checkbox' || inputType === 'radio') {
      x.checked = false;
    } else {
      x.value = '';
    }
  });
}

/**
 * Paginate an array for the client side.
 * @param array input array.
 * @param pageNumber page number.
 * @param limitPerPage entries per page.
 */
export function paginate(array, pageNumber, limitPerPage) {
  const rowOffset = Math.ceil((pageNumber - 1) * limitPerPage);
  return array.slice(rowOffset, rowOffset + limitPerPage);
}

const emoji = new EmojiConverter();

export function getCollectibleSlug(collectible) {
  const title = collectible?.title;
  emoji.text_mode = true;
  const slugifiedName = title
    ? slugify(emoji.replace_unified(title), {
        strict: true,
        lower: true,
      })
    : 'collectible';

  return `${slugifiedName}-`;
}

export function buildCollectibleLink(collectible, feats) {
  const slug = getCollectibleSlug(collectible);
  const collectionId = collectible?.collection?.publicAddress;
  const { tokenId } = collectible;
  const collectibleId = collectible.id;

  const hasPendingMintingTransaction = collectible?.pendingTransactions?.filter((f) => f.kind === 'Mint').length > 0;

  const linkBase = '/collectible/';

  const collectibleShortLink = linkBase + collectibleId;

  let collectibleLink;

  if (collectionId && tokenId) {
    const chainSlug = collectible?.collection?.chain?.slug;
    collectibleLink = `${linkBase}${chainSlug}/${collectionId.toLowerCase()}/${slug}${tokenId}`;
  } else if (hasPendingMintingTransaction || collectible.mintStatus === 'Minting') {
    collectibleLink = collectibleShortLink + '/status';
    if (feats) {
      feats.needsBackground = true;
    }
  } else {
    collectibleLink = collectibleShortLink + '/edit'; // we will switch this to mint status modal!
    if (feats) {
      feats.needsBackground = true;
    }
  }

  return collectibleLink;
}

export function buildCollectibleStatusPushState(collectible, location) {
  const collectibleStatusLink = `${buildCollectibleLink(collectible)}/status`;
  return {
    pathname: collectibleStatusLink,
    state: { ...location.state, background: location.state?.background || location },
  };
}

export function buildCollectionLink(collection, feats) {
  const collectionPublicAddress = collection?.publicAddress;
  const collectionId = collection?.id;

  const hasPendingDeployingTransaction = collection?.pendingTransactions?.filter((f) => f.kind === 'Deploy').length > 0;

  const linkBase = '/collection/';

  let collectionShortLink = linkBase;

  if (collection.status === 'Draft') {
    collectionShortLink = collectionShortLink + collectionId;
  } else {
    collectionShortLink = collectionShortLink + collection.slug;
  }

  let collectionLink;
  if (collectionPublicAddress && collection.status !== 'Draft') {
    if(collection.slug) {
      collectionLink = `${linkBase}${collection.slug.toString()}`;
    } else {
      const chainSlug = collection.chain?.slug;
      collectionLink = `${linkBase}${chainSlug}/${collectionPublicAddress.toLowerCase()}`;
    }
  } else if (hasPendingDeployingTransaction || collection?.deployStatus === 'Deploying') {
    collectionLink = collectionShortLink + '/status';
    if (feats) {
      feats.needsBackground = true;
    }
  } else {
    collectionLink = collectionShortLink + '/edit'; // we will switch this to mint status modal!
    if (feats) {
      feats.needsBackground = true;
    }
  }

  return collectionLink;
}

export function buildCollectionStatusPushState(collection, location) {
  const collectionStatusLink = `${buildCollectionLink(collection)}/status`;
  return {
    pathname: collectionStatusLink,
    state: { ...location.state, background: location.state?.background || location },
  };
}

export const buildCategoryLink = (categoryName, categoryId) => {
  const linkBase = '/discover/creations/popular?category=';

  if (categoryName) {
    return linkBase + categoryName;
  }
};

export const buildCreatorCategoryLink = (categoryName, categoryId) => {
  const linkBase = '/discover/creators/popular?category=';

  if (categoryName) {
    return linkBase + categoryName;
  }
};

export const convertToPoster = (videoUrl) => {
  if (videoUrl?.endsWith('mp4')) {
    let lowercaseUrl = videoUrl.toLowerCase();
    let lastPathSeparatorIndex = lowercaseUrl.lastIndexOf('/');

    // %2F = /
    if (lastPathSeparatorIndex < 0) {
      lastPathSeparatorIndex = lowercaseUrl.lastIndexOf('%2f');

      if (lastPathSeparatorIndex > 0) {
        lastPathSeparatorIndex += 2;
      }
    }

    if (lastPathSeparatorIndex >= 0) {
      return lowercaseUrl.substring(0, lastPathSeparatorIndex + 1) + 'poster.jpg';
    }
  }

  return videoUrl;
};

export const replaceFilename = (url, newFilename) => {
  let lowercaseUrl = url?.toLowerCase() ?? '';
  let lastPathSeparatorIndex = lowercaseUrl.lastIndexOf('/');

  // %2F = /
  if (lastPathSeparatorIndex < 0) {
    lastPathSeparatorIndex = lowercaseUrl.lastIndexOf('%2f');

    if (lastPathSeparatorIndex > 0) {
      lastPathSeparatorIndex += 2;
    }
  }

  if (lastPathSeparatorIndex >= 0) {
    return lowercaseUrl.substring(0, lastPathSeparatorIndex + 1) + newFilename;
  }

  return newFilename;
};

const KNOWN_CHAINS = new Map([
  [1, 'Mainnet'],
  [2, 'Expanse'],
  [3, 'Ropsten'],
  [4, 'Rinkeby'],
  [5, 'Goerli'],
  [8, 'Ubiq'],
  [42, 'Kovan'],
  [100, 'xDai'],
  [56, 'BNB'],
  [97, 'BNBTestnet'],
  [43113, 'Fuji'],
  [43114, 'Avalanche'],
  // This chainId is arbitrary and can be changed,
  // but by convention this is the number used
  // for local chains (ganache, buidler, etc) by default.
  [1337, 'Local'],
  [5777, 'Ganache'],
]);

export const checkChain = (collectible, wallet) => {
  return collectible.collection.chain.id === wallet.chainId;
};

export const getChainInfo = (chainId) => {
  const chain = AppVariables.chains.find((chain) => chain.id === chainId);
  return chain || {};
};

export const getCollectionChainInfo = (collection) => {
  return getChainInfo(collection?.chain?.id);
};

export const getCollectibleChainInfo = (collectible) => {
  return getCollectionChainInfo(collectible?.collection);
};

export const getBlockExplorerTokenInfo = (chainId, contractAddress, tokenId) => {
  let blockExplorer = { link: '', name: '' };

  const chain = getChainInfo(chainId);

  blockExplorer.link =
    getBlockExplorerTokenLink(chain?.chainInfo?.blockExplorerUrls?.[0], contractAddress, tokenId) || '';
  blockExplorer.name = chain?.explorerName || chain?.name || '';

  return blockExplorer;
};

export const getBlockExplorerTransactionInfo = (chainId, statusHash) => {
  let blockExplorer = { link: '', name: '' };

  const chain = getChainInfo(chainId);

  blockExplorer.link = getBlockExplorerTransactionLink(chain?.chainInfo?.blockExplorerUrls?.[0], statusHash) || '';
  blockExplorer.name = chain?.explorerName || chain?.name || '';

  return blockExplorer;
};

export function getChainName(chainId) {
  return KNOWN_CHAINS.get(chainId) || 'Unknown';
}

function getInfuraEndpointSubdomain(chainId) {
  switch (chainId) {
    case 1:
      return 'mainnet';
    case 3:
      return 'ropsten';
    case 4:
      return 'rinkeby';
    case 5:
      return 'goerli';
    case 137:
      return 'polygon-mainnet';
    case 80001:
      return 'polygon-mumbai';
    case 43113:
      return 'avalanche-fuji';
    case 43114:
      return 'avalanche-mainnet';
    case 42:
      return 'kovan';
    default:
      return null;
  }
}

export function getInfuraEndpointWithProtocol(chainId, protocol) {
  // Should we get these from the Azure Key Vault?
  const infuraProjectId = process.env.RAZZLE_INFURA_PROJECT_ID;

  // We might also use the test chains
  const infuraEndpointSubdomain = getInfuraEndpointSubdomain(chainId);
  const infuraUrl = `${protocol}://${infuraEndpointSubdomain}.infura.io/v3/${infuraProjectId}`;

  return infuraEndpointSubdomain ? infuraUrl : 'INVALID_ENDPOINT';
}

export function getInfuraEndpoint(chainId) {
  // Should we get these from the Azure Key Vault?
  const infuraProjectId = process.env.RAZZLE_INFURA_PROJECT_ID;

  return getInfuraEndpointWithProtocol(chainId, 'https');
}

export const zeroBN = new BigNumber(0);
export const oneBN = new BigNumber(1);

export const toWei = (n) => new BigNumber(n).shiftedBy(18);
export const toWeiFixed = (n, d) => new BigNumber(n).shiftedBy(18).toFixed(d);
export const toEther = (n) => new BigNumber(n).shiftedBy(-18);
export const toEtherFixed = (n, d) => new BigNumber(n).shiftedBy(-18).toFixed(d);
export const toBN = (n) => new BigNumber(n);
export const toBNRoundUp = (n, d) => new BigNumber(n).decimalPlaces(d, BigNumber.ROUND_UP);
export const toBNHexString = (value) => '0x' + toBN(value).toString(16);

export const toCustomDecimals = (n, dec) => new BigNumber(n).shiftedBy(dec);
export const toCustomDecimalsFixed = (n, dec, d) => new BigNumber(n).shiftedBy(dec).toFixed(d);
export const toCustomDecimalsFull = (n, dec) => new BigNumber(n).shiftedBy(-dec);
export const toCustomDecimalsFullFixed = (n, dec, d) => new BigNumber(n).shiftedBy(-dec).toFixed(d);
export const toCustomDecimalsWithRounding = (n, dec, d, r) =>
  toBN(n).shiftedBy(-dec).decimalPlaces(d, r).shiftedBy(dec);

export const percentageToBasePoints = (p) => Math.floor(p * 100);
export const basePointsToPercentage = (bp) => Math.floor(bp / 100);
export const royaltyTotalBasePoints = (rc) =>
  rc?.reduce((acc, royalty) => {
    if (royalty?.basePoints) acc += royalty.basePoints;
    return acc;
  }, 0);
export const royaltyTotalBasePointsToPercentage = (rc) => royaltyTotalBasePoints(rc) / 100;

export const numberFormat = (number, format, currency) =>
  Intl.NumberFormat(format, { style: 'currency', currency: currency }).format(number);

export const numberFormatDigits = (number, format, maximumSignificantDigits) =>
  Intl.NumberFormat(format, { maximumSignificantDigits }).format(number);

export const getShortenedPublicAddress = (publicAddress) => {
  if (!publicAddress) return '';
  let splitPublicAddress = String(publicAddress).split('');
  let firstFour = splitPublicAddress.slice(0, 6).join('');
  let lastFour = splitPublicAddress.slice(Math.max(splitPublicAddress.length - 4, 1)).join('');
  let formattedAddress = firstFour + '...' + lastFour;
  return formattedAddress;
};

export const getProfileNameOrShortPublicAddress = (profileName) => {
  const publicAddReg = new RegExp('^0x[a-fA-F0-9]{40}$', 'g');
  let isPublicAddress = publicAddReg.exec(profileName);

  if (isPublicAddress) {
    return getShortenedPublicAddress(profileName);
  } else {
    return profileName;
  }
};

export const getTokenActivityActor = (activity) => {
  switch (activity?.type) {
    case 'Transferred':
      return activity.senderAccount;
    case 'Bid':
      return activity.bid.bidder;
    case 'Listed':
      return activity.listing.seller;
    case 'Unlisted':
      return activity.listing.canceledReason == 'UserRequest' ? activity.listing.seller : activity.actorAccount;
    default:
      return activity?.actorAccount;
  }
};

export const concatCacheKey = (stringArr) => {
  return stringArr
    .filter((s) => !!s)
    .join('-')
    .toLowerCase()
    .trim();
};

export const getCurrenciesFromChain = (chainId) => {
  const chain = getChainInfo(chainId);
  const currencies = [chain?.chainInfo?.nativeCurrency.symbol, ...chain?.currencies].map((currency) => {
    return AppVariables.cryptoCurrencies.find((cryptoCurrency) => cryptoCurrency.Symbol === currency);
  });
  return currencies || [];
};

export const getChainIdFromSlug = (chainSlugParam) => {
  const chain = AppVariables.chains.find((chain) => chain.slug?.toLowerCase() === chainSlugParam?.toLowerCase());
  return chain?.id ?? (chainSlugParam ? -1 : chainSlugParam); // if a param was set, use an invalid chain id as the return value
};

export const getChainSlugFromId = (chainId) => {
  const chain = getChainInfo(chainId);
  return chain?.slug?.toLowerCase();
};

export const getCollectibleOrCollectionIdFromParams = (collectionIdParam, slugParam) => {
  let lastDashIndex = slugParam.lastIndexOf('-');
  let idString = slugParam.substring(lastDashIndex + 1);

  let parsedId = parseInt(idString);
  if (isNaN(parsedId)) {
    // Set not found!
    return null;
  }

  let collectionId = collectionIdParam;
  let collectibleId = null;
  let tokenId = null;

  if (collectionId) {
    // if collection exists, parsedId is tokenid within that collection
    tokenId = parsedId;
  } else {
    // Id refers to our collectionid
    collectibleId = parsedId;
  }

  let collectibleOrCollectionId = tokenId ? collectionId : collectibleId;

  return { collectibleOrCollectionId, tokenId };
};

export const getCollectionPublicAddressOrIdFromParams = (collectionIdParam, slugParam) => {
  let collectionPublicAddressOrId = collectionIdParam ?? slugParam;
  return collectionPublicAddressOrId;
};

export const parseJsonRpcError = (err) => {
  const { t } = i18n;

  try {
    const errObj = { err };
    const errMsg = errObj?.err?.message;
    const errCode = RegExp(/\"code": (.*?)\,/g).exec(errMsg);

    if (!AppVariables.JSON_RPC_ERROR_MAP[errCode[1]]) {
      return err;
    } else {
      if (typeof AppVariables.JSON_RPC_ERROR_MAP[errCode[1]] === 'object') {
        const matchedKey = Object.keys(AppVariables.JSON_RPC_ERROR_MAP[errCode[1]]).find((key) =>
          errMsg.includes(key.toLowerCase()),
        );

        if (matchedKey) {
          return new Error(t(AppVariables.JSON_RPC_ERROR_MAP[errCode[1]][matchedKey]));
        } else {
          return new Error(t(AppVariables.JSON_RPC_ERROR_MAP[errCode[1]]["default"]));
        }
      }
    }

    return new Error(t(AppVariables.JSON_RPC_ERROR_MAP[errCode[1]]));
  } catch (error) {
    return err;
  }
};

export const whichPlatform = (collection) => {
  const ORDERINBOX_LEGACY_ID = '0x0a827bde104590e781bd9c3b09deb26b7d4c958f'; // Original test OIX on rinkeby
  const SUPERRARE_ID = '0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0';
  const FOUNDATION_ID = '0x3b3ee1931dc30c1957379fac9aba94d1c48a5405';
  const collectionAddress = collection?.publicAddress?.toLowerCase();

  if (collectionAddress === SUPERRARE_ID) {
    return 'SUPERRARE';
  } else if (collectionAddress === FOUNDATION_ID) {
    return 'FOUNDATION';
  } else if (collectionAddress === ORDERINBOX_LEGACY_ID) {
    return 'ORDERINBOX_LEGACY';
  } else {
    return 'ORDERINBOX';
  }
};

export const isLegacyToken = (collection) => {
  return whichPlatform(collection) === 'ORDERINBOX_LEGACY';
};

function getEtherscanEndpointSubdomain(chainId) {
  switch (chainId) {
    case 3:
      return 'ropsten';
    case 4:
      return 'rinkeby';
    case 5:
      return 'goerli';
    case 42:
      return 'kovan';
    default:
      return '';
  }
}

function getBlockExplorerInfo(chainId) {
  return AppVariables.chains.find((chain) => chain.id === chainId);
}

// We do not need this since we're getting chain information from AppVariables
export function getEtherscanEndpoint(chainId) {
  const etherscanEndpointSubdomain = getEtherscanEndpointSubdomain(chainId);
  return `https://${etherscanEndpointSubdomain.length > 0 ? etherscanEndpointSubdomain + '.' : ''}etherscan.io`;
}

export function getBlockExplorerTransactionLink(domain, txHash) {
  return domain + '/tx/' + txHash;
}

export function getBlockExplorerTokenLink(domain, contractAddress, tokenId) {
  return domain + '/token/' + contractAddress + '?a=' + tokenId;
}

export function getIPFSLink(ipfsPath) {
  return 'https://ipfs.io/ipfs/' + ipfsPath;
}

export const collectibleStatuses = {
  DRAFT: 'DRAFT',
  MINTING: 'MINTING',
  BURNING: 'BURNING',
  LISTING: 'LISTING',
  UNLISTING: 'UNLISTING',
  UNLISTINGBYADMIN: 'UNLISTINGBYADMIN',
  CHANGINGPRICE: 'CHANGINGPRICE',
  LISTED: 'LISTED',
  FIXEDPRICE: 'FIXEDPRICE',
  RESERVEAUCTION: 'RESERVEAUCTION',
  UNLISTED: 'UNLISTED',
  TRANSFERRING: 'TRANSFERRING',
  EMPTY: 'EMPTY',
  PURCHASING: 'PURCHASING',
  PLACINGBID: 'PLACINGBID',
  FINALIZING: 'FINALIZING',
};

export const collectionStatuses = {
  DRAFT: 'DRAFT',
  DEPLOYING: 'DEPLOYING',
  READY: 'READY',
};

export const hasPendingTransaction = (collectible, accountId) => {
  const { pendingTransactions } = collectible || {};
  const accountPendingTransations = pendingTransactions?.filter((pt) => pt.account.id === accountId);

  return accountPendingTransations?.length > 0;
};

export const getCollectibleStatus = (collectible, accountId) => {
  const { status, pendingTransactions, mostRecentListing } = collectible || {};

  const result = {};

  const accountPendingTransations = pendingTransactions?.filter((pt) => pt.account.id === accountId);

  if (accountPendingTransations?.length) {
    const { kind, status, hash } = accountPendingTransations[0];
    result.status = status;
    result.hash = hash;
    switch (kind) {
      case 'Mint':
        result.kind = collectibleStatuses.MINTING;
        break;
      case 'List':
        result.kind = collectibleStatuses.LISTING;
        break;
      case 'Unlist':
        result.kind = collectibleStatuses.UNLISTING;
        break;
      case 'Burn':
        result.kind = collectibleStatuses.BURNING;
        break;
      case 'ChangePrice':
        result.kind = collectibleStatuses.CHANGINGPRICE;
        break;
      case 'Transfer':
        result.kind = collectibleStatuses.TRANSFERRING;
        break;
      case 'Purchase':
        result.kind = collectibleStatuses.PURCHASING;
        break;
      case 'PlaceBid':
        result.kind = collectibleStatuses.PLACINGBID;
        break;
      case 'Finalize':
        result.kind = collectibleStatuses.FINALIZING;
        break;
    }
    return result;
  } else {
    if (status === 'Draft') {
      return { kind: collectibleStatuses.DRAFT };
    } else if (collectible?.status === 'Published' && mostRecentListing !== null) {
      if (mostRecentListing.status === 'Open' && mostRecentListing.type === 'FixedPrice') {
        return { kind: collectibleStatuses.FIXEDPRICE };
      }
      if (mostRecentListing.status === 'Open' && mostRecentListing.type === 'ReserveAuction') {
        return { kind: collectibleStatuses.RESERVEAUCTION };
      }
    }
  }
  return { kind: collectibleStatuses.UNLISTED };
};

export const getCollectionStatus = (collection, accountId) => {
  const { status, pendingTransactions } = collection || {};

  const result = {};

  const accountPendingTransations = pendingTransactions?.filter((pt) => pt.account.id === accountId);

  if (accountPendingTransations?.length) {
    const { kind, status, hash } = accountPendingTransations[0];
    result.status = status;
    result.hash = hash;
    switch (kind) {
      case 'Deploy':
        result.kind = collectionStatuses.DEPLOYING;
        break;
    }
    return result;
  } else if (status === 'Draft') {
    return { kind: collectionStatuses.DRAFT };
  }

  return { kind: collectionStatuses.READY };
};

export function hasTouch() {
  return 'ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
}

export function getCurrencyPropsFromSymbol(symbol) {
  return AppVariables.cryptoCurrencies.find((ac) => ac.Symbol === symbol);
}

export function isNativeCurrency(symbol) {
  const chainsUsingSymbol = AppVariables.chains.filter((ac) => ac.chainInfo.nativeCurrency.symbol === symbol);
  return chainsUsingSymbol.length > 0;
}

export function padNumberStart(number, width) {
  return String(number).padStart(width, '0');
}

export function openInNewTab(url, focus) {
  const newWindow = window.open(url, '_blank');

  if (focus) {
    newWindow.focus();
  }
}

export function shuffleArray(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}

export const generateGradientBackground = (code = 'default') => {
  return { background: gradient('order' + code.toString() + 'inbox') };
};

export const THUMBNAILS = {
  XS: '_xs',
};

export const getThumbnail = (path, size) => {
  if (!path) return;

  const suffix = '_tn';

  if (path.includes(suffix)) {
    const splittedPath = path.split(suffix);
    const thumbnail = splittedPath[0] + suffix + size + splittedPath[1];
    return thumbnail;
  }

  return path;
};

export const buildNotificationContent = (notification, account) => {
  const content = {
    header: '',
    text: '',
    link: null,
    image: '',
    imageStyle: null,
    isHeaderLink: true,
    indicator: <Icon src="fas fa-circle-check" type="fontawesome" />,
    hideCategory: false,
  };

  const { t } = i18n;

  const bold = (text) => `<strong/>${text}</strong>`;

  const excerpt = (text, length) => {
    if (text?.length > length) return `<div>${text.substring(0, length)}...</div>`;
    return `<div>${text}</div>`;
  };
  if (notification) {
    content.image = notification.sourceAccount?.profile.imageUrl;
    content.header = bold(`${getProfileNameOrShortPublicAddress(notification.sourceAccount?.profile.name)}`);
    switch (notification.type) {
      case 'HAS_NOT_EMAIL':
        content.title = `${bold(t('public.reminder'))}`;
        content.text = `${t('public.updateYourEmailAddress')}`;
        content.image = oiLogoImg;
        content.link = `/settings/notifications`;
        content.indicator = <Icon src="fas fa-info-circle" type="fontawesome" />;
        break;
      case 'HAS_PENDING_CLAIMS':
        content.title = `${bold(t('public.reminder'))}`;
        content.text = `${t('public.finalizeWonAuctions')}`;
        content.image = oiLogoImg;
        content.link = `/bids`;
        content.indicator = <Icon src="fas fa-info-circle" type="fontawesome" />;
        break;
      case 'Activity_Account_Follow':
        if (account?.id === notification.targetAccount.id) content.text = t('public.followedYou');
        else content.text = `${t('public.followed', { name: notification.targetAccount.slug })}`;
        content.link = `/${notification.sourceAccount.slug}`;
        content.indicator = <Icon src="fas fa-user" type="fontawesome" />;
        break;

      case 'Activity_Account_Mention':
        content.text = `${t('public.mentionedComment')}`; // Needs to link to comment
        content.link = `/collectible/${notification.targetComment.postId}`;
        content.linkSearch = '?tab=Comments';
        content.indicator = <Icon src="fas fa-comment" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Like':
        if (
          account?.id === notification.targetCollectible.account.id ||
          account?.id === notification.targetCollectible.owner.id
        )
          content.text = `${t('public.likeYourCollectible', { title: bold(notification.targetCollectible.title) })}`;
        else content.text = `${t('public.liked', { title: bold(notification.targetCollectible.title) })}`;
        content.link = buildCollectibleLink(notification.targetCollectible);
        content.indicator = <Icon src="fas fa-heart" type="fontawesome" />;

        break;

      case 'Activity_Collectible_Mention':
        content.text = `${t('public.mentionedCollectibleComment', {
          title: bold(notification.targetCollectible.title),
        })}`;
        content.link = buildCollectibleLink(notification.targetCollectible); // needs to go to the target comment directly
        content.linkSearch = '?tab=Comments';
        content.indicator = <Icon src="fas fa-comment" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Comment':
        if (
          account?.id === notification.targetCollectible.account.id ||
          account?.id === notification.targetCollectible.owner.id
        )
          content.text = `${t('public.commentedOnYourCollectible', {
            title: bold(notification.targetCollectible?.title),
            comment: excerpt(notification.targetComment?.contentText),
          })}`;
        else
          content.text = `${t('public.commentedOnCollectible', {
            title: bold(notification.targetCollectible?.title),
            comment: excerpt(notification.targetComment?.contentText),
          })}`;
        content.link = buildCollectibleLink(notification.targetCollectible); // needs to go to the target comment directly
        content.linkSearch = '?tab=Comments';
        content.indicator = <Icon src="fas fa-comment" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Share':
        if (
          account?.id === notification.targetCollectible.account.id ||
          account?.id === notification.targetCollectible.owner.id
        )
          content.text = `${t('public.sharedYourCollectible', { title: bold(notification.targetCollectible.title) })}`;
        else content.text = `${t('public.sharedCollectible', { title: bold(notification.targetCollectible.title) })}`;
        content.link = buildCollectibleLink(notification.targetCollectible);
        content.indicator = <ShareIcon />;
        break;

      case 'Activity_Collectible_Mint':
        if (account?.id === notification.targetCollectible.account.id) {
          content.header = bold(notification.targetCollectible.title);
          content.text = `${t('public.succesfullyMinted')}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
        } else
          content.text = `${t('public.createdCollectible', { title: bold(notification.targetCollectible.title) })}`;
        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        content.indicator = <Icon src="fas fa-feather-pointed" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Transfer':
        if (account?.id === notification.tokenActivity.senderAccount.id) {
          content.header = bold(t('public.congratulations'));
          content.text = `${t('public.transferredNotificationForSender', {
            title: bold(notification.targetCollectible.title),
          })}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
          content.hideCategory = true;
        } else if (account?.id === notification.tokenActivity.recipientAccount.id) {
          content.header = bold(t('public.congratulations'));
          content.text = `${t('public.transferredNotificationForReceiver', {
            title: bold(notification.targetCollectible.title),
            name: bold(getProfileNameOrShortPublicAddress(notification.tokenActivity.recipientAccount.profile.name)),
          })}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
          content.hideCategory = true;
        } else
          content.text = `${t('public.transferredNotificationForCreator', {
            title: bold(notification.targetCollectible.title),
            name: bold(getProfileNameOrShortPublicAddress(notification.tokenActivity.recipientAccount.profile.name)),
          })}`;
        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        content.indicator = <Icon src="fas fa-exchange-alt" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Burned':
        if (account?.id === notification.targetCollectible.account.id) {
          content.header = bold(`${t('public.burned')}`);
          content.text = `${t('public.successfullyBurnedText')}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
          content.link = null;
          content.linkSearch = null;
          content.indicator = <Icon src="fas fa-fire" type="fontawesome" />;
        }
        break;

      case 'Activity_Collectible_List':
        if (account?.id === notification.tokenActivity.listing.seller.id) {
          content.header = bold(notification.targetCollectible.title);
          content.text = `${t(
            notification.tokenActivity.listing.type === 'FixedPrice'
              ? 'public.nowListedItemForSale'
              : 'public.nowListedItemForAuction',
          )}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
        } else {
          if (notification.targetCollectible.account.id === account?.id) {
            content.text = `${t(
              notification.tokenActivity.listing.type === 'FixedPrice'
                ? 'public.listedSaleNotificationForCreator'
                : 'public.listedAuctionNotificationForCreator',
              { title: bold(notification.targetCollectible.title) },
            )}`;
          } else {
            content.text = `${t(
              notification.tokenActivity.listing.type === 'FixedPrice'
                ? 'public.listedItemForSale'
                : 'public.listedItemForAuction',
              { title: bold(notification.targetCollectible.title) },
            )}`;
          }
        }
        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        content.indicator = <Icon src="fas fa-bars" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Unlist':
        if (account?.id === notification.tokenActivity.listing.seller.id) {
          content.header = bold(notification.targetCollectible.title);
          content.text = `${t('public.succesfullyUnlistNotification')}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
        } else {
          if (notification.targetCollectible.account.id === account?.id) {
            content.text = `${t(
              notification.tokenActivity.listing.type === 'FixedPrice'
                ? 'public.unlistedSaleNotificationForCreator'
                : 'public.unlistedAuctionNotificationForCreator',
              { title: bold(notification.targetCollectible.title) },
            )}`;
          } else {
            content.text = `${t(
              notification.tokenActivity.listing.type === 'FixedPrice'
                ? 'public.unlistedItemFromSale'
                : 'public.unlistedItemFromAuction',
              { title: bold(notification.targetCollectible.title) },
            )}`;
          }
        }
        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        content.indicator = <Icon src="fas fa-bars" type="fontawesome" />;
        break;

      case 'Activity_Collectible_PriceChange':
        const newPrice = `${toCustomDecimalsFullFixed(
          notification.tokenActivity.listing.price,
          notification.tokenActivity.listing.priceAssetType.decimals,
          2,
        )} ${notification.tokenActivity.listing.priceAssetType.symbol}`;

        if (account?.id === notification.tokenActivity.listing.seller.id) {
          content.header = bold(notification.targetCollectible.title);
          content.text = `${t(
            notification.tokenActivity.listing.type === 'ReserveAuction'
              ? 'public.reservePriceChangeSuccess'
              : 'public.priceChangeSuccess',
            { price: newPrice },
          )}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
        } else {
          if (notification.targetCollectible.account.id === account?.id) {
            content.text = `${t(
              notification.tokenActivity.listing.type === 'ReserveAuction'
                ? 'public.changedReservePriceNotificationForCreator'
                : 'public.changedPriceNotificationForCreator',
              { price: newPrice, title: bold(notification.targetCollectible.title) },
            )}`;
          } else {
            content.text = `${t(
              notification.tokenActivity.listing.type === 'ReserveAuction'
                ? 'public.changedReservePriceFor'
                : 'public.changedPriceFor',
              { price: newPrice, title: bold(notification.targetCollectible.title) },
            )}`;
          }
        }

        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        content.indicator = <Icon src="fas fa-bars" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Purchase':
        if (account?.id === notification.tokenActivity.actorAccount.id) {
          content.header = bold(t('public.congratulations'));
          content.text = `${t('public.successfullyPurchased', { title: bold(notification.targetCollectible.title) })}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
          content.hideCategory = true;
        } else {
          if (account?.id === notification.tokenActivity.listing.seller.id) {
            content.header = bold(t('public.congratulations'));
            content.text = `${t('public.successfullySoldNotificationForOwner', {
              title: bold(notification.targetCollectible.title),
            })}`;
            content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
            content.imageStyle = 'square';
            content.isHeaderLink = false;
            content.hideCategory = true;
          } else if (account?.id === notification.targetCollectible.account.id)
            content.text = `${t('public.purchasedYourCollectible', {
              title: bold(notification.targetCollectible.title),
            })}`;
          else
            content.text = `${t('public.purchasedCollectible', { title: bold(notification.targetCollectible.title) })}`;
        }

        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        content.indicator = <Icon src="fas fa-circle-notch" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Bid':
        const bidAmount = `${toCustomDecimalsFullFixed(
          notification.tokenActivity.bid.amount,
          notification.tokenActivity.listing.priceAssetType.decimals,
          2,
        )} ${notification.tokenActivity.listing.priceAssetType.symbol}`;
        if (account?.id === notification.tokenActivity.actorAccount.id) {
          content.header = bold(t('public.congratulations'));
          content.text = `${t('public.successfullyBid', {
            amount: bidAmount,
            title: bold(notification.targetCollectible.title),
          })}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.indicator = <Icon src="fas fa-clock" type="fontawesome" />;
          content.imageStyle = 'square';
          content.isHeaderLink = false;
          content.hideCategory = true;
        } else if (notification.accountWasOutbid) {
          content.text =
            `${t('public.outbidInAuction', { amount: bidAmount, title: bold(notification.targetCollectible.title) })}` +
            `${
              moment().isBefore(moment(notification.tokenActivity.listing.dateAuctionEnding))
                ? t('public.tapToBidAgain')
                : ''
            }`;
          content.indicator = <Icon src="fas fa-arrow-up" type="fontawesome" />;
        } else {
          content.indicator = <Icon src="fas fa-clock" type="fontawesome" />;

          if (account?.id === notification.tokenActivity.listing.seller.id) {
            content.header = bold(t('public.congratulations'));
            content.text = `${t('public.succesfullyBidNotificationForSeller', {
              amount: bidAmount,
              title: bold(notification.targetCollectible.title),
            })}`;
            content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
            content.imageStyle = 'square';
            content.isHeaderLink = false;
            content.hideCategory = true;
          } else if (account?.id === notification.targetCollectible.account.id)
            content.text = `${t('public.bidOnYourCollectible', {
              amount: bidAmount,
              title: bold(notification.targetCollectible.title),
            })}`;
          else
            content.text = `${t('public.bidOnCollectible', {
              amount: bidAmount,
              title: bold(notification.targetCollectible.title),
            })}`;
        }

        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        break;

      case 'Activity_Collectible_Win':
        if (account?.id === notification.tokenActivity.actorAccount.id) {
          content.header = bold(t('public.congratulations'));
          content.text = `${t('public.youWonAuctionFor', { title: bold(notification.targetCollectible.title) })}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
          content.hideCategory = true;
        } else {
          if (account?.id === notification.tokenActivity.listing.seller.id) {
            content.header = bold(t('public.congratulations'));
            content.text = `${t('public.completedAuctionForSeller', {
              title: bold(notification.targetCollectible.title),
            })}`;
            content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
            content.imageStyle = 'square';
            content.isHeaderLink = false;
            content.hideCategory = true;
          } else if (account?.id === notification.targetCollectible.account.id)
            content.text = `${t('public.wonAuctionNotificationForCreator', {
              title: bold(notification.targetCollectible.title),
            })}`;
          else content.text = `${t('public.wonAuctionFor', { title: bold(notification.targetCollectible.title) })}`;
        }

        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        content.indicator = <Icon src="fas fa-star" type="fontawesome" />;
        break;

      case 'Activity_Collectible_Finalize':
        if (
          account?.id === notification.tokenActivity.actorAccount.id &&
          account?.id === notification.tokenActivity.listing.highestBid.bidder.id
        ) {
          // if this finalizing account is the winner of the auction
          content.header = bold(t('public.congratulations'));
          content.text = `${t('public.youAreNewOwnerOf', { title: bold(notification.targetCollectible.title) })}`;
          content.image = convertToPoster(notification.targetCollectible.coverImageUrl);
          content.imageStyle = 'square';
          content.isHeaderLink = false;
          content.hideCategory = true;
        } else {
          if (account?.id === notification.tokenActivity.listing.highestBid.bidder.id) {
            content.text = `${t('public.finalizedBuySeller', {
              title: bold(notification.targetCollectible.title),
            })}`;
          } else {
            content.text = `${t('public.finalizedAuction', {
              title: bold(notification.targetCollectible.title),
              buyer: bold(notification.targetCollectible?.owner?.slug),
            })}`;
          }
        }
        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Activity';
        content.indicator = <Icon src="fas fa-circle-check" type="fontawesome" />;
        break;

      ////////////////////////////
      // COMMENTS

      case 'Activity_Comment_Like':
        content.text = `${t('public.likedYourComment', { comment: excerpt(notification.targetComment?.contentText) })}`;
        content.link = `/collectible/${notification.targetComment.postId}`;
        content.linkSearch = '?tab=Comments';
        content.indicator = <Icon src="fas fa-heart" type="fontawesome" />;
        break;

      case 'Activity_Comment_Reply':
        content.text = `${t('public.repliedYourComment', {
          comment: excerpt(notification.targetComment?.contentText),
        })}`; // We are adding this as the comment replies weren't getting back with a target comment any more
        content.link = buildCollectibleLink(notification.targetCollectible);
        content.linkSearch = '?tab=Comments';
        content.indicator = <Icon src="fas fa-comment" type="fontawesome" />;
        break;

      default:
        content.text = `${notification.id} - ${notification.sourceAccount?.slug} - ${notification.type}`;
        content.link = '#';
        break;
    }
  }

  return content;
};

export const loadable = (func) => {
  return baseLoadable(func, { fallback: <FallbackLoader /> });
};

function isUnwrappedRpcResult(response) {
  return typeof response === 'object' && response !== null && 'jsonrpc' in response;
}

function rpcResult(response) {
  // Some providers don’t wrap the response
  if (isUnwrappedRpcResult(response)) {
    if (response.error) {
      throw new Error(response.error);
    }
    return response.result || null;
  }

  return response || null;
}

export async function ethereumRequest(ethereum, method, params) {
  // If ethereum.request() exists, the provider is probably EIP-1193 compliant.
  if (ethereum.request) {
    return ethereum.request({ method, params }).then(rpcResult);
  }

  // This is specific to some older versions of MetaMask combined with Web3.js.
  if (ethereum.sendAsync && ethereum.selectedAddress) {
    return new Promise((resolve, reject) => {
      ethereum.sendAsync(
        {
          method,
          params,
          from: ethereum.selectedAddress,
          jsonrpc: '2.0',
          id: 0,
        },
        (err, result) => {
          if (err) {
            reject(err);
          } else {
            resolve(result);
          }
        },
      );
    }).then(rpcResult);
  }

  // If none of the previous two exist, we assume the provider is pre EIP-1193,
  // using .send() rather than .request().
  if (ethereum.send) {
    return ethereum.send(method, params).then(rpcResult);
  }

  throw new Error('The Ethereum provider doesn’t seem to provide a request method.');
}

export function dataLayerPageView(url, title) {
  window?.dataLayer?.push({
    event: 'pageview',
    page: {
      url,
      title,
    },
  });
}

export function dataLayerEvent(category, action, label, value) {
  window?.dataLayer?.push({
    event: 'event',
    eventProps: {
      category,
      action,
      label,
      value,
    },
  });
}

export const mapAttributes = (attributes) => {
  const parsedAttributes = JSON.parse(attributes);

  if (Array.isArray(parsedAttributes) && parsedAttributes.length == 0) {
    return JSON.stringify([{ trait_type: '', value: '' }]);
  }

  if (Array.isArray(parsedAttributes[0])) {
    let attributeObj = parsedAttributes.map(([trait_type, value]) => ({
      trait_type,
      value,
    }));

    return JSON.stringify(attributeObj);
  }

  return JSON.stringify(parsedAttributes);
};

export const isAuthenticated = (user) => {
  return user && user.tokens && Date.now() < user.tokens.expires_at;
};

export const hasRole = (user, role) => {
  const profileRole = user?.profile?.role;

  return (
    (Array.isArray(profileRole) && !!profileRole?.find((r) => r?.toLowerCase() === role?.toLowerCase())) ||
    (!Array.isArray(profileRole) && profileRole?.toLowerCase() == role?.toLowerCase())
  );
};

export const isAdministrator = (user) => {
  return hasRole(user, 'Administrator');
};

export const isModerator = (user) => {
  return hasRole(user, 'Moderator');
};

export const isAdminOrModerator = (user) => {
  return isAdministrator(user) || isModerator(user);
};

export const getMomentRelativeTime = (locale = 'en') => {
  switch (locale) {
    case 'en':
      return {
        s: '1s',
        ss: '%ds',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        w: '1w',
        ww: '%dw',
        M: '1mo',
        MM: '%dmo',
        y: '1y',
        yy: '%dy',
      };
    case 'fr':
      return {
        s: '1d',
        ss: '%dd',
        m: '1m',
        mm: '%dm',
        h: '1he',
        hh: '%dhe',
        d: '1j',
        dd: '%dj',
        w: '1l',
        ww: '%dl',
        M: '1lu',
        MM: '%dlu',
        y: '1a',
        yy: '%da',
      };
    case 'tr':
      return {
        s: '1s',
        ss: '%ds',
        m: '1d',
        mm: '%dd',
        h: '1sa',
        hh: '%dsa',
        d: '1g',
        dd: '%dg',
        w: '1h',
        ww: '%dh',
        M: '1ay',
        MM: '%day',
        y: '1y',
        yy: '%dy',
      };
    case 'es':
      return {
        s: '1s',
        ss: '%ds',
        m: '1dm',
        mm: '%dm',
        h: '1ho',
        hh: '%dho',
        d: '1d',
        dd: '%dd',
        w: '1s',
        ww: '%ds',
        M: '1lu',
        MM: '%dlu',
        y: '1a',
        yy: '%da',
      };
    case 'pt-br':
      return {
        s: '1s',
        ss: '%ds',
        m: '1dm',
        mm: '%dm',
        h: '1ho',
        hh: '%dho',
        d: '1d',
        dd: '%dd',
        w: '1s',
        ww: '%ds',
        M: '1lu',
        MM: '%dlu',
        y: '1m',
        yy: '%dm',
      };
    case 'ru':
      return {
        s: '1в',
        ss: '%dв',
        m: '1dм',
        mm: '%dм',
        h: '1ч',
        hh: '%dч',
        d: '1д',
        dd: '%dд',
        w: '1н',
        ww: '%dн',
        M: '1л',
        MM: '%dл',
        y: '1г',
        yy: '%dг',
      };
    case 'zh-cn':
      return {
        s: '1第',
        ss: '%d第',
        m: '1d分',
        mm: '%d分',
        h: '1小',
        hh: '%d小',
        d: '1天',
        dd: '%d天',
        w: '1星',
        ww: '%d星',
        M: '1月',
        MM: '%d月',
        y: '1年',
        yy: '%d年',
      };
    default:
      return {
        s: '1s',
        ss: '%ds',
        m: '1m',
        mm: '%dm',
        h: '1h',
        hh: '%dh',
        d: '1d',
        dd: '%dd',
        w: '1w',
        ww: '%dw',
        M: '1mo',
        MM: '%dmo',
        y: '1y',
        yy: '%dy',
      };
  }
};

export const buildHelpCenterLink = (contentCategory, id) => {
  let ln = i18n.language;

  if (ln === 'en') {
    ln = 'en-us';
  }

  if (!contentCategory && !id) {
    return `https://support.oix.app/hc/${ln}`;
  }

  return `https://support.oix.app/hc/${ln}/${contentCategory}/${id}`;
};

export const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === 'undefined') {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};

export const isEmpty = (value) => {
  return (
    // null or undefined
    value == null ||
    // has length and it's zero
    (value.hasOwnProperty('length') && value.length === 0) ||
    // is an Object and has no keys
    (value.constructor === Object && Object.keys(value).length === 0)
  );
};

export const setBodyPosition = () => {
  const modalOpenClassName = 'modal-open';

  const openModal = () => {
    const scrollY = window.scrollY;
    const windowInnerWidth = window.innerWidth;
    const body = document.body;
    const bodyClientWidth = body.clientWidth;
    const bodyPaddingRight = windowInnerWidth - bodyClientWidth;
    body.style.top = `-${scrollY}px`;

    if (bodyPaddingRight > 0) {
      body.style.paddingRight = `${bodyPaddingRight}px`;
    }

    body.classList.add(modalOpenClassName);
  };

  const closeModal = () => {
    const body = document.body;
    body.classList.remove(modalOpenClassName);
    const scrollY = body.style.top;
    //    console.log(scrollY);
    body.style.top = '';
    body.style.paddingRight = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  };

  return { openModal, closeModal };
};
