import * as React from 'react';
import { connect } from 'react-redux';
import bind from 'bind-decorator';
import PropTypes from 'prop-types';
import ReactSelect, { components } from 'react-select';
import Icon from '@Components/shared/Icon';
import { Checkbox, Radio } from '@material-ui/core';
import { ServiceActions } from '@Actions/ServiceActions';
import ProfileAvatar from '@Components/profile/ProfileAvatar';

import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import { EditorState, convertToRaw, CompositeDecorator, convertFromRaw } from 'draft-js';

import loadable from '@loadable/component';

const MentionPlugin = loadable.lib(
  () => import(/* webpackChunkName: "draft-js-plugins" */ '@draft-js-plugins/mention'),
  { ssr: false, fallback: <></> },
);
const HashtagPlugin = loadable.lib(
  () => import(/* webpackChunkName: "draft-js-plugins" */ '@draft-js-plugins/hashtag'),
  { ssr: false, fallback: <></> },
);
const EmojiPlugin = loadable.lib(() => import(/* webpackChunkName: "draft-js-plugins" */ '@draft-js-plugins/emoji'), {
  ssr: false,
  fallback: <></>,
});
const LinkifyPlugin = loadable.lib(
  () => import(/* webpackChunkName: "draft-js-plugins" */ '@draft-js-plugins/linkify'),
  { ssr: false, fallback: <></> },
);

export const Editor = loadable(() => import(/* webpackChunkName: "draft-js-plugins" */ '@draft-js-plugins/editor'), {
  ssr: false,
  fallback: <></>,
});

class Input extends React.Component {
  inputEl;

  constructor(props) {
    // this.props => { id, type, placeholder, name, onChange, validate, onValidate, onKeyDown, onClick, onBlur, width, readOnly, value }
    /*
     * validate takes an array of validationRule objects
     * validationRule => { rule : RegExp | Fn, message: String }
     */
    super(props);

    this.state = {
      readOnly: !!this.props.readOnly,
    };
  }

  get value() {
    return this.inputEl.value;
  }

  @bind
  setReadOnly(isReadOnly) {
    this.setState({ readOnly: isReadOnly });
  }

  @bind
  onChangeHandler(e) {
    if (e.persist) e.persist();
    if (this.props.onChange && typeof this.props.onChange == 'function') this.props.onChange(e);
  }

  @bind
  onFocusHandler(e) {
    this.props.inputComponent.setState({ focused: true });
    if (this.props.onFocus && typeof this.props.onFocus == 'function') this.props.onFocus(e);
  }

  @bind
  onBlurHandler(e) {
    this.props.inputComponent.setState({ focused: false });
    if (this.props.onBlur && typeof this.props.onBlur == 'function') this.props.onBlur(e);
  }

  @bind
  getStyle() {
    const style = {};
    if (this.props.width !== undefined) style.width = this.props.width;

    return style;
  }

  render() {
    return (
      <input
        id={this.props.id}
        type={this.props.type}
        placeholder={this.props.placeholder}
        name={this.props.name}
        readOnly={this.props.readOnly}
        onClick={this.props.onClick}
        onFocus={this.onFocusHandler}
        ref={(x) => (this.inputEl = x)}
        style={this.getStyle()}
        data-isfilled={!!this.state.value}
        autoComplete={this.props.autoComplete}
        onMouseDown={this.props.onMouseDown}
        onTouchEnd={this.props.onTouchEnd}
        onKeyDown={this.props.onKeyDown}
        onBlur={this.onBlurHandler}
        onChange={this.onChangeHandler}
        value={this.props.value}
      />
    );
  }
}

class Text extends React.Component {
  rawInput;

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      focused: false,
      isLoading: false,
    };
  }

  get value() {
    return this.rawInput.value;
  }

  setReadOnly(isReadOnly) {
    this.rawInput.setReadOnly(isReadOnly);
  }

  startLoading() {
    this.setState({
      isLoading: true,
    });
    this.setReadOnly(true);
  }

  endLoading() {
    this.setState({
      isLoading: false,
    });
    this.setReadOnly(false);
  }

  @bind
  getClassName() {
    let className = 'input input-text';
    if (this.state.focused) className += ' input-focused';
    if (this.props.readOnly) className += ' read-only';
    if (this.props.className) className += ' ' + this.props.className;
    if (this.props.buttons) className += ' input-has-buttons input-buttons-align-' + this.props.buttons.alignment;

    return className;
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() });
  }

  render() {
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        {this.props.label ? <label htmlFor={this.state.id}>{this.props.label}</label> : null}

        <div className="input-container">
          <div className="input-wrap">
            {this.props.info ? (
              <div className="info mr-1">
                <div className="left-area">
                  {this.props.info.icon ? (
                    <Icon
                      type="fontawesome"
                      style={{ color: this.props.info.icon.color || '#909097' }}
                      src={this.props.info.icon.src}
                    />
                  ) : this.props.info.component ? (
                    this.props.info.component
                  ) : null}
                  {this.props.info.text}
                </div>
                <div className="right-area">{this.props.info.extra} </div>
              </div>
            ) : null}
            {this.props.prefix ? <div className="mr-1 input-prefix">{this.props.prefix}</div> : null}
            <Input
              id={this.state.id}
              type="text"
              placeholder={this.props.placeholder}
              name={this.props.name}
              readOnly={this.props.readOnly}
              onKeyDown={this.props.onKeyDown}
              width={this.props.width}
              label={this.props.label}
              autoComplete={this.props.autoComplete}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              value={this.props.value}
              onMouseDown={this.props.onMouseDown}
              onTouchEnd={this.props.onTouchEnd}
              ref={(x) => (this.rawInput = x)}
              inputComponent={this}
            />
            {this.props.icon ? (
              <Icon
                type="fontawesome"
                style={{ color: this.props.icon.color }}
                src={this.props.icon.src}
                onClick={this.toggleShowPassword}
              />
            ) : null}
            {this.state.isLoading ? (
              <div className="loading-container">
                <div className="loader"></div>
              </div>
            ) : null}
            {this.props.suffix ? <div className="ml-1 input-suffix">{this.props.suffix}</div> : null}
          </div>
          {this.props.buttons && this.props.buttons.factory && typeof this.props.buttons.factory == 'function'
            ? this.props.buttons.factory()
            : null}
        </div>
        <>{this.props.error && <div className="input-validation-error">{this.props.error}</div>}</>
      </div>
    );
  }
}
Text.propTypes = {
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  showLimit: PropTypes.bool,

  width: PropTypes.number,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  icon: PropTypes.object,
  buttons: PropTypes.object,

  name: PropTypes.string,
  error: PropTypes.string,
};

class TextArea extends React.Component {
  inputEl;

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      focused: false,
      isLoading: false,
    };
  }

  get value() {
    return this.inputEl.value;
  }

  startLoading() {
    this.setState({
      isLoading: true,
    });
    this.setReadOnly(true);
  }

  endLoading() {
    this.setState({
      isLoading: false,
    });
    this.setReadOnly(false);
  }

  @bind
  getClassName() {
    let className = 'input input-text';
    if (this.state.focused) className += ' input-focused';
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() });
  }

  render() {
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        {this.props.label ? <label htmlFor={this.state.id}>{this.props.label}</label> : null}
        <div className="input-container">
          <div className="input-wrap">
            <textarea
              id={this.props.id}
              type={this.props.type}
              placeholder={this.props.placeholder}
              name={this.props.name}
              readOnly={this.props.readOnly}
              onKeyDown={this.props.onKeyDown}
              onClick={this.props.onClick}
              data-isfilled={!!this.state.value}
              autoComplete={this.props.autoComplete}
              rows={this.props.rows}
              maxLength={this.props.maxLength}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              value={this.props.value}
              ref={(x) => (this.inputEl = x)}
            />
            {this.props.showLimit ? (
              <div className="characterLimit">
                <span style={{ color: '#00B7FF' }}>{this.props.value.length}</span>
                <span>/{this.props.maxLength}</span>
              </div>
            ) : null}
          </div>
        </div>
        <>{<div className="input-validation-error">{this.props.error}</div>}</>
      </div>
    );
  }
}
TextArea.propTypes = {
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  showLimit: PropTypes.bool,

  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,

  name: PropTypes.string,
  error: PropTypes.string,
};

class Password extends React.Component {
  rawInput;

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      focused: false,
      showPassword: false,
    };
  }

  get value() {
    return this.rawInput.value;
  }

  @bind
  toggleShowPassword() {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  }

  @bind
  getClassName() {
    let className = 'input input-password';
    if (this.state.focused) className += ' input-focused';
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() });
  }

  render() {
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        {this.props.label ? <label htmlFor={this.state.id}>{this.props.label}</label> : null}
        <div className="input-container">
          <div className="input-wrap">
            <Input
              id={this.state.id}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder={this.props.placeholder}
              name={this.props.name}
              readOnly={this.props.readOnly}
              onKeyDown={this.props.onKeyDown}
              label={this.props.label}
              width={this.props.width}
              autoComplete={this.props.autoComplete}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              value={this.props.value}
              ref={(x) => (this.rawInput = x)}
              inputComponent={this}
            />
            <Icon
              type="fontawesome"
              src={this.state.showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'}
              onClick={this.toggleShowPassword}
            />
          </div>
        </div>
        {this.props.buttons && this.props.buttons.factory && typeof this.props.buttons.factory == 'function'
          ? this.props.buttons.factory()
          : null}

        <>{<div className="input-validation-error">{this.props.error}</div>}</>
      </div>
    );
  }
}
Password.propTypes = {
  width: PropTypes.number,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  buttons: PropTypes.object,

  name: PropTypes.string,
  error: PropTypes.string,
};

class Number extends React.Component {
  rawInput;

  constructor(props) {
    super(props);

    this.state = {
      id: 'ob_inp_' + Math.random(),
      errors: [],
    };
  }

  get value() {
    return this.rawInput.value;
  }

  setReadOnly(isReadOnly) {
    this.rawInput.setReadOnly(isReadOnly);
  }

  @bind
  getClassName() {
    let className = 'input input-number';
    if (this.state.focused) className += ' input-focused';
    if (this.props.buttons) className += ' input-has-buttons input-buttons-align-' + this.props.buttons.alignment;
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() });
  }

  render() {
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        {this.props.label ? <label htmlFor={this.state.id}>{this.props.label}</label> : null}
        <div className="input-container">
          <div className="input-wrap">
            <Input
              id={this.state.id}
              type="number"
              placeholder={this.props.placeholder}
              name={this.props.name}
              readOnly={this.props.readOnly}
              onKeyDown={this.props.onKeyDown}
              width={this.props.width}
              label={this.props.label}
              autoComplete={this.props.autoComplete}
              onChange={this.props.onChange}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              value={this.props.value}
              ref={(x) => (this.rawInput = x)}
              inputComponent={this}
            />
            {this.props.icon ? (
              <Icon
                type="fontawesome"
                style={{ color: this.props.icon.color }}
                src={this.props.icon.src}
                onClick={this.toggleShowPassword}
              />
            ) : null}
          </div>
        </div>
        <>{<div className="input-validation-error">{this.props.error}</div>}</>
      </div>
    );
  }
}
Number.propTypes = {
  width: PropTypes.number,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  icon: PropTypes.object,

  name: PropTypes.string,
  error: PropTypes.string,
};
class Select extends React.Component {
  rawInput;

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      errors: [],
      focused: false,
      isExpanded: false,
      value: this.props.value || null,
    };
  }

  menuRenderer = (props) => {
    return (
      <OverlayScrollbarsComponent
        style={{ maxHeight: '300px', overflow: 'auto' }}
        options={{
          scrollbars: {
            visibility: 'visible',
            autoHide: 'never',
          },
          nativeScrollbarsOverlaid: {
            showNativeScrollbars: false,
            initialize: true,
          },
        }}>
        {props.children}
      </OverlayScrollbarsComponent>
    );
  };

  colourStyles = {
    multiValue: (styles) => ({
      ...styles,
      borderRadius: '10px',
      backgroundColor: '#20212e',
    }),
    multiValueLabel: (styles) => ({
      ...styles,
      borderRight: '1px solid #2e3146',
      color: '#ffffff',
    }),
    multiValueRemove: (styles) => ({
      ...styles,
      borderRadius: '0 10px 10px 0',
      color: '#ffffff',
      ':hover': {
        backgroundColor: '#00B7FF',
        color: '#ffffff',
      },
    }),
  };

  get value() {
    return this.rawInput.getValue();
    //        return this.state.value;
  }

  get isValid() {
    return this.state.errors.length == 0;
  }

  @bind
  expand() {
    this.setState({ isExpanded: true });
  }

  @bind
  collapse() {
    this.setState({ isExpanded: false });
  }

  @bind
  clickHandler() {
    if (this.state.isExpanded == false) {
      this.expand();
    } else if (this.state.isExpanded == true) {
      this.collapse();
    }
  }

  @bind
  onChangeHandler(value, event) {
    this.setState({ value });

    if (typeof this.props.onChange == 'function') this.props.onChange(value, event);

    // Set formik field value
    // TODO: Handle formik value for multi select
    if (typeof this.props.setFieldValue == 'function') this.props.setFieldValue(this.props.name, value);
  }

  @bind
  onFocusHandler() {
    this.setState({ focused: true });
    if (typeof this.props.onFocus == 'function') this.props.onFocus(this.rawInput);
  }

  @bind
  onBlurHandler() {
    this.setState({ focused: false });
    if (typeof this.props.onBlur == 'function') this.props.onBlur(this.rawInput);

    // Set formik field touched to true
    if (typeof this.props.setFieldTouched == 'function') this.props.setFieldTouched(this.props.name, true);
  }

  @bind
  getClassName() {
    let className = 'input input-select';
    if (this.state.focused) className += ' input-focused';
    if (this.props.className) className += ' ' + this.props.className;
    if (this.props.type) className += ' input-' + this.props.type;
    return className;
  }

  @bind
  setValue(value) {
    this.rawInput.select.setValue(value);
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() }, () => {
      if (this.props.onDidMount && typeof this.props.onDidMount === 'function') this.props.onDidMount(this);
    });
  }

  render() {
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        {this.props.label ? <label htmlFor={this.state.id}>{this.props.label}</label> : null}
        <div className="input-container">
          <div className={`input-wrap ${this.props.isDisabled ? " input-wrap-disabled" :  ""}`}>
            <ReactSelect
              id={this.state.id}
              ref={(x) => (this.rawInput = x)}
              className="w-100 react-select"
              classNamePrefix="react-select"
              options={this.props.items}
              getOptionLabel={(option) =>
                this.props.customOptionLabel ? this.props.customOptionLabel(option) : option[this.props.displayExpr]
              }
              getOptionValue={(option) => option[this.props.valueExpr]}
              onChange={this.onChangeHandler}
              onFocus={this.onFocusHandler}
              onBlur={this.onBlurHandler}
              placeholder={this.props.placeholder}
              isClearable={this.props.isClearable}
              menuIsOpen={this.props.menuIsOpen}
              value={this.props.value}
              defaultValue={this.props.defaultValue}
              isSearchable={false}
              autoComplete={this.props.autoComplete}
              isMulti={this.props.isMulti}
              isDisabled={this.props.isDisabled}
              styles={this.colourStyles}
              components={{ MenuList: this.menuRenderer }}
              menuPlacement={this.props.menuPlacement || 'auto'}
            />
          </div>
        </div>
        {<div className="input-validation-error">{this.props.error}</div>}
      </div>
    );
  }
}
Select.propTypes = {
  items: PropTypes.array.isRequired,
  displayExpr: PropTypes.string.isRequired,
  valueExpr: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  autoComplete: PropTypes.bool,

  // Properties required for Formik & Yup implementation
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  error: PropTypes.string,
};

class Switch extends React.Component {
  rawInput;

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      errors: [],
      focused: false,
      value: !!this.props.value,
    };
  }

  get value() {
    return this.state.value;
  }

  @bind
  onClickHandler(e) {
    if (typeof this.props.onClick == 'function') this.props.onClick(e, e.target.checked);
  }

  @bind
  onChangeHandler(e) {
    if (!this.props.readOnly) this.setState({ value: e.target.checked });
    if (typeof this.props.onChange == 'function') this.props.onChange(e, e.target.checked);
    if (typeof this.props.setFieldValue == 'function') this.props.setFieldValue(this.props.name, e.target.checked);
  }

  @bind
  onFocusHandler(e) {
    this.setState({ focused: true });
    if (typeof this.props.onFocus == 'function') this.props.onFocus(e);
  }

  @bind
  onBlurHandler(e) {
    this.setState({ focused: false });
    if (typeof this.props.onBlur == 'function') this.props.onBlur(e);
    if (typeof this.props.setFieldTouched == 'function') this.props.setFieldTouched(this.props.name, true);
  }

  @bind
  getClassName() {
    let className = 'input input-switch';
    if (this.state.focused) className += ' input-focused';
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  @bind
  switch(value) {
    this.setState({
      value,
    });
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() }, () => {
      if (this.props.onDidMount && typeof this.props.onDidMount === 'function') this.props.onDidMount(this);
    });
  }

  render() {
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        <div className="input-container">
          <div className="input-wrap">
            <input
              ref={(x) => (this.rawInput = x)}
              name={this.props.name}
              className="react-switch-checkbox"
              id={this.state.id}
              type="checkbox"
              onClick={this.onClickHandler}
              onFocus={this.onFocusHandler}
              onBlur={this.onBlurHandler}
              onChange={this.onChangeHandler}
              checked={this.state.value}
              readOnly={this.props.readOnly}
            />
            <label
              style={{ background: this.state.value && '#00B7FF' }}
              className="react-switch-label"
              htmlFor={this.state.id}>
              <span className={`react-switch-button`} />
            </label>
          </div>
        </div>
      </div>
    );
  }
}
Switch.propTypes = {
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,

  name: PropTypes.string,
  error: PropTypes.string,
};

class CheckBox extends React.Component {
  rawInput;

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      errors: [],
      focused: false,
      value: !!this.props.value,
    };
  }

  get value() {
    return this.state.value;
  }

  @bind
  onClickHandler(e) {
    if (typeof this.props.onClick == 'function') this.props.onClick(e, e.target.checked);
    if (typeof this.props.setFieldValue == 'function') this.props.setFieldValue(this.props.name, e.target.checked);
  }

  @bind
  onChangeHandler(e) {
    if (!this.props.readOnly) this.setState({ value: e.target.checked });
    if (typeof this.props.onChange == 'function') this.props.onChange(e, e.target.checked);
  }

  @bind
  getClassName() {
    let className = 'input input-checkbox';
    if (this.props.type) {
      className += ' ' + this.props.type;
    }
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  @bind
  check(value) {
    this.setState({
      value,
    });
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() }, () => {
      if (this.props.onDidMount && typeof this.props.onDidMount === 'function') this.props.onDidMount(this);
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.value !== this.props.value) {
      this.setState({ value: this.props.value });
    }
  }


  render() {
    const { icon, checkedIcon } = this.props;
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        <div className="input-container">
          <div className="input-wrap">
            <Checkbox
              ref={(x) => (this.rawInput = x)}
              name={this.props.name}
              id={this.state.id}
              checked={this.state.value}
              onChange={this.onChangeHandler}
              onClick={this.onClickHandler}
              disabled={this.props.disabled}
              {...(icon && { icon })}
              {...(checkedIcon && { checkedIcon })}
            />
          </div>
        </div>
      </div>
    );
  }
}
CheckBox.propTypes = {
  onClick: PropTypes.func,
  onChange: PropTypes.func,

  name: PropTypes.string,
};

class RadioButton extends React.Component {
  rawInput;

  constructor(props) {
    super(props);

    this.state = {};
  }

  @bind
  getClassName() {
    let className = 'input input-radio';
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() }, () => {
      if (this.props.onDidMount && typeof this.props.onDidMount === 'function') this.props.onDidMount(this);
    });
  }

  render() {
    const { checkedIcon, icon } = this.props;
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        <Radio
          ref={(x) => (this.rawInput = x)}
          checked={this.props.selectedValue === this.props.value}
          onChange={this.props.onChange}
          value={this.props.value}
          name={this.props.name}
          size={this.props.size}
          {...(checkedIcon && { checkedIcon })}
          {...(icon && { icon })}
        />
      </div>
    );
  }
}

class FileInput extends React.Component {
  inputEl;

  constructor(props) {
    super(props);
    this.state = {
      focused: false,
    };
  }

  get value() {
    return this.inputEl.value;
  }

  @bind
  onChangeHandler(e) {
    if (e.persist) e.persist();

    if (this.props.onChange && typeof this.props.onChange == 'function') this.props.onChange(e);
  }

  @bind
  onFocusHandler(e) {
    this.setState({ focused: true });
    if (this.props.onFocus && typeof this.props.onFocus == 'function') this.props.onFocus(e);
  }

  @bind
  onBlurHandler(e) {
    this.setState({ focused: false });
    if (this.props.onBlur && typeof this.props.onBlur == 'function') this.props.onBlur(e);
  }

  @bind
  getClassName() {
    let className = 'input';
    if (this.state.focused) className += ' input-focused';
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        <div className="input-container">
          <div className="input-wrap">
            <input
              id={this.props.id}
              type="file"
              name={this.props.name}
              onClick={this.props.onClick}
              onFocus={this.onFocusHandler}
              ref={(x) => (this.inputEl = x)}
              onBlur={this.onBlurHandler}
              onChange={this.onChangeHandler}
              value={this.props.value}
              accept={this.props.accept}
              multiple={this.props.multiple}
              disabled={this.props.disabled}
            />
          </div>
        </div>
        <>{<div className="input-validation-error">{this.props.error}</div>}</>
      </div>
    );
  }
}
FileInput.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  value: PropTypes.string,
  name: PropTypes.string,
  accept: PropTypes.string,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  setFieldValue: PropTypes.func,
  setFieldTouched: PropTypes.func,
  error: PropTypes.string,
};

class MoneyInput extends React.Component {
  rawInput;

  constructor(props) {
    super(props);

    this.state = {
      id: null,
      focused: false,
      isLoading: false,
      value: '',
    };
  }

  get value() {
    return this.rawInput.value;
  }

  setValue(value) {
    this.setState({
      value: value,
    });
  }

  setReadOnly(isReadOnly) {
    this.rawInput.setReadOnly(isReadOnly);
  }

  startLoading() {
    this.setState({
      isLoading: true,
    });
    this.setReadOnly(true);
  }

  endLoading() {
    this.setState({
      isLoading: false,
    });
    this.setReadOnly(false);
  }

  @bind
  getClassName() {
    let className = 'input input-money';
    if (this.state.focused) className += ' input-focused';
    if (this.props.className) className += ' ' + this.props.className;

    return className;
  }

  @bind
  onChangeHandler(e) {
    if (e.target.value === '') this.setState({ value: '' });
    else if ((e.nativeEvent.data >= '0' && e.nativeEvent.data <= '9') || e.nativeEvent.data === '.')
      this.setState({
        value:
          e.nativeEvent.data === '.' && String(this.state.value).indexOf('.') === -1 && this.props.decimalPrecision > 0
            ? e.target.value
            : parseFloat(parseFloat(e.target.value).toFixed(this.props.decimalPrecision)),
      });
    if (typeof this.props.onChange == 'function') this.props.onChange(e, e.target.value);
  }

  componentDidMount() {
    this.setState({ id: 'ob_inp_' + Math.random() });
  }

  render() {
    if (!this.state.id) return <div></div>;

    return (
      <div className={this.getClassName()}>
        {this.props.label ? <label htmlFor={this.state.id}>{this.props.label}</label> : null}

        <div className="input-container">
          <div className="input-wrap">
            {this.props.prefix ? <div className="mr-1 input-prefix">{this.props.prefix}</div> : null}
            <Input
              id={this.state.id}
              type="text"
              placeholder={this.props.placeholder}
              name={this.props.name}
              readOnly={this.props.readOnly}
              onKeyDown={this.props.onKeyDown}
              width={this.props.width}
              label={this.props.label}
              autoComplete={this.props.autoComplete}
              onChange={this.onChangeHandler}
              onFocus={this.props.onFocus}
              onBlur={this.props.onBlur}
              value={this.props.value}
              onMouseDown={this.props.onMouseDown}
              onTouchEnd={this.props.onTouchEnd}
              ref={(x) => (this.rawInput = x)}
              inputComponent={this}
            />
            {this.state.isLoading ? (
              <div className="loading-container">
                <div className="loader"></div>
              </div>
            ) : null}
            {this.props.suffix ? <div className="ml-1 input-suffix">{this.props.suffix}</div> : null}
          </div>
          <>{<div className="input-validation-error">{this.props.error}</div>}</>
        </div>
      </div>
    );
  }
}
MoneyInput.propTypes = {
  rows: PropTypes.number,
  maxLength: PropTypes.number,
  showLimit: PropTypes.bool,

  width: PropTypes.number,
  label: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  onKeyDown: PropTypes.func,
  autoComplete: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  icon: PropTypes.object,

  name: PropTypes.string,
  error: PropTypes.string,
};

class AnnotationEditor extends React.Component {
  collectibleSuggestions = [
    {
      name: 'ACollectible1',
    },
    {
      name: 'Collectible12',
    },
    {
      name: 'BCollectible13',
    },
    {
      name: 'Collectible14',
    },
    {
      name: 'Collectible15',
    },
  ];

  constructor(props) {
    super(props);
    this.editorRef;

    this.state = {
      id: null,
      focused: false,
      isClientReady: false,
      editorState: this.props.value ? this.convertToEditorState(this.formatText()) : EditorState.createEmpty(),

      accountMentionPlugin: null,
      accountSuggestions: [],
      accountOpen: false,

      hashtagMentionPlugin: null,
      hashtagSuggestions: [],
      hashtagOpen: false,

      collectibleMentionPlugin: null,
      collectibleSuggestions: this.collectibleSuggestions,
      collectibleOpen: false,

      emojiPlugin: null,
      linkifyPlugin: null,
      isDisplayingSuggestions: false,
    };
  }

  componentDidMount() {
    this.setState({
      isClientReady: true,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.props.stopFocus && prevState.editorRef !== this.state.editorRef) {
      this.state.editorRef.editor.focus({ preventScroll: true });
    }
  }

  @bind
  mentionPluginLoaded(plugin) {
    if (plugin) {
      let createMentionPlugin = plugin.default;
      let defaultSuggestionsFilter = plugin.defaultSuggestionsFilter;

      let accountMentionPlugin = createMentionPlugin({
        mentionTrigger: '@',
        mentionPrefix: '@',
        entityMutability: 'SEGMENTED',
        supportWhitespace: false,
      });
      let hashtagMentionPlugin = createMentionPlugin({
        mentionTrigger: '#',
        entityMutability: 'SEGMENTED',
        supportWhitespace: false,
      });
      let collectibleMentionPlugin = createMentionPlugin({
        mentionTrigger: '!',
        entityMutability: 'SEGMENTED',
        supportWhitespace: this.props.supportWhitespace,
      });

      this.setState({
        accountMentionPlugin,
        hashtagMentionPlugin,
        collectibleMentionPlugin,
        defaultSuggestionsFilter,
      });
    }
  }

  @bind
  emojiPluginLoaded(plugin) {
    if (plugin) {
      let createEmojiPlugin = plugin.default;
      let emojiPlugin = createEmojiPlugin({
        useNativeArt: false,
        selectButtonContent: <Icon type="fontawesome" src="fas fa-smile" />,
      });

      this.setState({
        emojiPlugin,
      });
    }
  }

  @bind
  hashtagPluginLoaded(plugin) {
    if (plugin) {
      let createHashtagPlugin = plugin.default;
      let hashtagPlugin = createHashtagPlugin();

      this.setState({
        hashtagPlugin,
      });
    }
  }

  @bind
  linkifyPluginLoaded(plugin) {
    if (plugin) {
      let createLinkifyPlugin = plugin.default;
      let linkifyPlugin = createLinkifyPlugin();

      this.setState({
        linkifyPlugin,
      });
    }
  }

  mentionComponent(props) {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line no-unused-vars
      isFocused, // eslint-disable-line no-unused-vars
      ...parentProps
    } = props;
    return (
      <div {...parentProps}>
        <div className="mention-entry-container">
          <div className="mr-2">
            <ProfileAvatar height={36} width={36} imageLink={mention.avatar} alt="" />
          </div>
          <div>
            <div className="name">{mention.displayName}</div>
            <div className="slug">@{mention.name}</div>
            {/* {mention.actorFollower || mention.actorFollowee ? (
              <div className="actor-follow-state">
                {mention.actorFollower && mention.actorFollowee ? (
                  <span>You follow each other</span>
                ) : mention.actorFollower ? (
                  <span>Follows you</span>
                ) : mention.actorFollowee ? (
                  <span>Following</span>
                ) : null}
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }

  hashtagComponent(props) {
    const {
      mention,
      theme,
      searchValue, // eslint-disable-line no-unused-vars
      isFocused, // eslint-disable-line no-unused-vars
      ...parentProps
    } = props;
    return (
      <div {...parentProps}>
        <div className="hashtag-entry-container">
          <div className="name">{mention.name}</div>
        </div>
      </div>
    );
  }

  formatText() {
    // Return formatted this.props.value

    let valueRawContent = {
      blocks: [],
      entityMap: {},
    };

    valueRawContent.blocks.push({
      key: '0',
      type: 'unstyled',
      depth: 0,
      text: this.props.value.text,
      entityRanges: [],
      inlineStyleRanges: [],
      data: {},
    });

    let numEntities = 0;

    const sortedAnnotations = this.props.value.annotations.sort((a, b) => a.startIndex - b.startIndex);

    // Format mentions
    sortedAnnotations.forEach((a) => {
      let entity = null;

      switch (a.type.toLowerCase()) {
        case 'accountmention':
          entity = {
            type: 'mention',
            mutability: 'SEGMENTED',
            data: {
              mention: {
                name: a.slug,
                displayName: a.name,
                link: '/' + a.slug,
                avatar: a.profileImageUrl,
              },
            },
          };

          break;

        case 'hashtag':
          entity = {
            type: '#mention',
            mutability: 'SEGMENTED',
            data: {
              mention: {
                name: '#' + a.hashtag,
              },
            },
          };
          break;

        default:
          break;
      }

      // if object is empty
      if (entity) {
        valueRawContent.entityMap[numEntities] = entity;

        valueRawContent.blocks[0].entityRanges.push({
          offset: a.startIndex,
          length: a.endIndex - a.startIndex + 1,
          key: numEntities,
        });

        numEntities++;
      }
    });

    return valueRawContent;
  }

  convertToEditorState = (editorContent) => {
    let content;
    try {
      content = convertFromRaw(JSON.parse(editorContent));
    } catch (e) {
      content = convertFromRaw(editorContent);
    }
    const editorState = EditorState.createWithContent(content);
    return editorState;
  };

  get value() {
    let content = this.state.editorState.getCurrentContent();
    const rawContent = convertToRaw(content);

    let value = '';
    rawContent.blocks.forEach((b, i) => {
      value += b.text + (i < rawContent.blocks.length - 1 ? '\n' : '');
    });

    // Append all blocks together, inserting a new line in between.
    return value;
  }

  resetValue() {
    this.setState({
      editorState: EditorState.createEmpty(),
    });
  }

  @bind
  getClassName() {
    let className = 'input';
    if (this.state.focused) className += ' input-focused';
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  _handleBeforeInput = () => {
    const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length;

  	if (currentContentLength > this.props.contentLimit - 1) {
    	return 'handled';
    }
  }

  _handlePastedText = (pastedText) => {
  	const currentContent = this.state.editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText('').length

  	if (currentContentLength + pastedText.length > this.props.contentLimit) {
    	return 'handled';
    }
  }

  onChange = (editorState) => {
    if (typeof this.props.setFieldValue == 'function') {
      let content = this.state.editorState.getCurrentContent();
      const rawContent = convertToRaw(content);

      let value = '';
      rawContent.blocks.forEach((b, i) => {
        value += b.text + (i < rawContent.blocks.length - 1 ? '\n' : '');
      });

      // Append all blocks together, inserting a new line in between.
      this.props.setFieldValue(this.props.name, value);
    }

    this.setState({
      editorState,
    });
  };

  accountSuggestionsFilter = (searchValue, suggestions) => {
    var value = searchValue.toLowerCase();
    var filteredSuggestions = suggestions.filter(function (suggestion) {
      return (
        !value ||
        suggestion.name.toLowerCase().indexOf(value) > -1 ||
        suggestion.displayName.toLowerCase().indexOf(value) > -1
      );
    });
    var length = filteredSuggestions.length < 5 ? filteredSuggestions.length : 5;
    return filteredSuggestions.slice(0, length);
  };

  onAccountOpenChange = (_open) => {
    this.setState((prevState) => ({
      accountOpen: _open,
      isDisplayingSuggestions: prevState.isDisplayingSuggestions && _open,
    }));
  };

  onAccountSearchChange = ({ value }) => {
    this.props.getAccountSuggestions(value, 0, 10).then((result) => {
      if (!result.error) {
        let suggestions = result.payload.map((s) => {
          return {
            name: s.slug,
            displayName: s.profile.name,
            avatar: s.profile.imageUrl,
            actorFollower: s.actorFollower,
            actorFollowee: s.actorFollowee,
          };
        });
        this.setState({
          accountSuggestions: this.accountSuggestionsFilter(value, suggestions),
          isDisplayingSuggestions: this.accountSuggestionsFilter(value, suggestions).length > 0,
        });
      }
    });
  };

  onHashtagOpenChange = (_open) => {
    this.setState((prevState) => ({
      hashtagOpen: _open,
      isDisplayingSuggestions: prevState.isDisplayingSuggestions && _open,
    }));
  };

  onHashtagSearchChange = ({ value }) => {
    this.props.getHashtagSuggestions(value, 0, 10).then((result) => {
      if (!result.error) {
        let suggestions = result.payload.map((s) => {
          return {
            name: '#' + s.tag,
            annotationsCount: s.annotationsCount, // This is how many times the hashtag was used
          };
        });

        const hashtagSuggestions = this.state.defaultSuggestionsFilter
          ? this.state.defaultSuggestionsFilter(value, suggestions)
          : [];

        this.setState({
          hashtagSuggestions: hashtagSuggestions,
          isDisplayingSuggestions: hashtagSuggestions.length > 0,
        });
      }
    });
  };

  onCollectibleOpenChange = (_open) => {
    this.setState({ collectibleOpen: _open });
  };

  onCollectibleSearchChange = ({ value }) => {
    this.setState({
      collectibleSuggestions: this.state.defaultSuggestionsFilter
        ? this.state.defaultSuggestionsFilter(value, this.collectibleSuggestions)
        : [],
    });
  };

  @bind
  onFocusHandler(e) {
    this.setState({ focused: true });
    if (this.props.onFocus && typeof this.props.onFocus == 'function') this.props.onFocus(e);
  }

  @bind
  onBlurHandler(e) {
    this.setState({ focused: false });
    if (typeof this.props.setFieldTouched == 'function') this.props.setFieldTouched(this.props.name, true);
    if (this.props.onBlur && typeof this.props.onBlur == 'function') this.props.onBlur(e);
  }

  onAddMention = () => {
    this.setState({
      hashtagSuggestions: [],
      accountSuggestions: [],
    });
  };

  render() {
    let plugins = [
      this.state.accountMentionPlugin,
      this.state.hashtagMentionPlugin,
      this.state.collectibleMentionPlugin,
      this.state.emojiPlugin,
      this.state.linkifyPlugin,
    ];

    let isReady =
      this.state.isClientReady &&
      plugins.every(function (p) {
        return !!p;
      });

    const AccountSuggestions = this.state.accountMentionPlugin?.MentionSuggestions;
    const HashtagSuggestions = this.state.hashtagMentionPlugin?.MentionSuggestions;
    const CollectibleSuggestions = this.state.collectibleMentionPlugin?.MentionSuggestions;
    const EmojiSuggestions = this.state.emojiPlugin?.EmojiSuggestions;
    const EmojiSelect = this.state.emojiPlugin?.EmojiSelect;
    const _editorState = this.state.editorState;
    const contentLength = _editorState.getCurrentContent().getPlainText().length;

    return (
      <div className={this.getClassName()}>
        {this.props.label ? <label htmlFor={this.state.id}>{this.props.label}</label> : null}

        <div className="input-container">
          <div className="input-wrap">
            {isReady ? (
              <div className="editor" style={this.props.style}>
                {
                  this.props.contentLimit && (
                    <div className={
                      "editor-content-length" + (this.props.contentLimit === contentLength ? " limit": "")
                      }
                    > 
                      {contentLength} / {this.props.contentLimit} 
                    </div>
                  )
                }
                <Editor
                  editorState={this.state.editorState}
                  onChange={this.onChange}
                  plugins={plugins}
                  ref={(x) => {
                    if (!this.state.editorRef) {
                      this.setState({
                        editorRef: x,
                      });
                    }
                  }}
                  onFocus={this.onFocusHandler}
                  onBlur={this.onBlurHandler}
                  name={this.props.name}
                  stripPastedStyles={true}
                  placeholder={this.props.placeholder}
                  handleBeforeInput={this.props.contentLimit && this._handleBeforeInput}
                  handlePastedText={this.props.contentLimit && this._handlePastedText}
                />
                <EmojiSuggestions />
                <AccountSuggestions
                  open={this.state.accountOpen}
                  onOpenChange={this.onAccountOpenChange}
                  onSearchChange={this.onAccountSearchChange}
                  suggestions={this.state.accountSuggestions}
                  entryComponent={this.mentionComponent}
                  onAddMention={this.onAddMention}
                />
                <HashtagSuggestions
                  open={this.state.hashtagOpen}
                  onOpenChange={this.onHashtagOpenChange}
                  onSearchChange={this.onHashtagSearchChange}
                  suggestions={this.state.hashtagSuggestions}
                  entryComponent={this.hashtagComponent}
                />
                {/* <CollectibleSuggestions
                  open={this.state.collectibleOpen}
                  onOpenChange={this.onCollectibleOpenChange}
                  onSearchChange={this.onCollectibleSearchChange}
                  suggestions={this.state.collectibleSuggestions}
                /> */}
                <div className={'options'}>
                  <EmojiSelect />
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <>{<div className="input-validation-error">{this.props.error}</div>}</>
        <MentionPlugin ref={this.mentionPluginLoaded} />
        <EmojiPlugin ref={this.emojiPluginLoaded} />
        <HashtagPlugin ref={this.hashtagPluginLoaded} />
        <LinkifyPlugin ref={this.linkifyPluginLoaded} />
      </div>
    );
  }
}

class AnnotationDisplay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      isClientReady: false,
      editorContent: null,
    };
  }

  componentDidMount() {
    this.setState({
      isClientReady: true,
      editorContent: this.props.editorContent,
    });
  }

  setValue(value) {
    this.setState({
      editorContent: value,
    });
  }

  mention(props) {
    const { mention } = props.contentState.getEntity(props.entityKey).getData();
    const type = props.contentState.getEntity(props.entityKey).getType();
    return (
      <a
        rel="nofollow noreferrer"
        href={mention.link}
        target="_blank"
        className={type === 'mention' ? 'mentionUser' : type === '#mention' ? 'mentionHashtag' : 'mentionCollectible'}>
        {props.children}
      </a>
    );
  }

  findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      return (
        entityKey !== null &&
        (contentState.getEntity(entityKey).getType() === 'mention' ||
          contentState.getEntity(entityKey).getType() === '#mention' ||
          contentState.getEntity(entityKey).getType() === '!mention')
      );
    }, callback);
  }

  decorator = new CompositeDecorator([
    {
      strategy: this.findLinkEntities,
      component: this.mention,
    },
  ]);

  convertToEditorState = (editorContent) => {
    let content;
    try {
      content = convertFromRaw(JSON.parse(editorContent));
    } catch (e) {
      content = convertFromRaw(editorContent);
    }

    const editorState = EditorState.createWithContent(content, this.decorator);
    return editorState;
  };

  @bind
  getClassName() {
    let className = 'input';
    if (this.props.className) className += ' ' + this.props.className;
    return className;
  }

  render() {
    return (
      <div className={this.getClassName()}>
        {this.props.label ? <label htmlFor={this.state.id}>{this.props.label}</label> : null}

        <div className="input-container ">
          <div className="input-wrap ">
            {this.state.isClientReady ? (
              <div className="editor" onClick={this.focus}>
                <Editor
                  readOnly
                  editorState={this.convertToEditorState(this.state.editorContent)}
                  style={this.props.style}
                  onChange={() => {}}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default {
  Text: Text,
  Raw: Input,
  TextArea: TextArea,
  Password: Password,
  Number: Number,
  Select: Select,
  Switch: Switch,
  CheckBox: CheckBox,
  FileInput: FileInput,
  RadioButton: RadioButton,
  Money: MoneyInput,
  AnnotationEditor: connect(null, ServiceActions, null, { forwardRef: true })(AnnotationEditor),
  AnnotationDisplay: AnnotationDisplay,
};
