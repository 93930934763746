import * as React from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { InlineLoader } from '@Components/shared/Spinner';

class AppScroller extends React.Component {
  constructor(props) {
    super(props);
  }

  // We might want to think about adjusting the scrollThreshold for mobile devices ( using total height of the viewport ).
  render() {
    const { dataLength, next, hasMore, children, height, disableLoader, scrollableTarget } = this.props;
    return (
      <InfiniteScroll
        dataLength={dataLength || 0}
        hasMore={hasMore}
        loader={!disableLoader && <InlineLoader height={30} />}
        next={next}
        {...(height && { height })}
        {...(scrollableTarget && { scrollableTarget })}
        scrollThreshold="100px">
        {children}
      </InfiniteScroll>
    );
  }
}

export default AppScroller;
