import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { ModalActions } from '@Actions/ModalActions';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { HashtagActions } from '@Actions/HashtagActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { CollectionActions } from '@Actions/CollectionActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { AccountActions } from '@Actions/AccountActions';
import { SearchActions } from '@Actions/SearchActions';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import AccountCard from '@Components/shared/AccountCard';
import { AnimatedPlaceholderRect } from '@Components/placeholder/PlaceholderComponents';
import { InlineLoader } from '@Components/shared/Spinner';
import AppScroller from '@Components/shared/AppScroller';
import { withTranslation } from 'react-i18next';
import CollectionCard from '@Components/shared/CollectionCard';
import { SearchFilterWrapper } from '@Components/searchFilters/FilterWrapper';
import cn from 'classnames';

class SearchCollectionsE extends React.Component {
  TOP = 32;
  constructor(props) {
    super(props);
    this.state = {
      shouldLoadMore: true,
      resultCount: 0,
      shouldFetch: true,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { load } = this.props;
    if (prevProps.load !== load) {
      if (load) this.loadMore();
    }
    if (decodeURIComponent(prevProps.location.search) !== decodeURIComponent(location.search)) {
      this.init();
    }
  }

  async init() {
    const { clearSearchCollections } = this.props;
    await clearSearchCollections();
    await this.createRequest();
  }

  createRequest() {
    const { location, match, collectionsData, getCollectionsWithPost } = this.props;
    const query = new URLSearchParams(location.search);
    let value = match.params.tag ? '#' + match.params.tag : query.get('q') || '';
    value = encodeURIComponent(value);

    const filterArr = [];
    let filterQuery = '';

    const chains = query.get('chains');

    if (chains) {
      let chainsQuery = chains.split(',').map((chainId) => `chain/id eq ${chainId}`).join(' or ');
      chainsQuery = `(${chainsQuery})`;
      filterArr.push(chainsQuery);
    }

    if (filterArr.length) {
      filterQuery = `&$filter=${filterArr.join(' and ')}`
    }

    const limitQuery = '&$skip=' + collectionsData?.length + '&$top=' + this.TOP;
    const searchKeyQuery = value.length > 0 ? '&searchKey=' + value : '';

    const concattedQuery = '$count=true' + limitQuery + searchKeyQuery + filterQuery;

    getCollectionsWithPost(concattedQuery, value).then((res) => {
      if (!res.error) {
        this.setState({ resultCount: res.payload['@odata.count'], shouldFetch: res.payload.value.length >= this.TOP });
      }
    });
  }

  loadMore() {
    this.createRequest();
  }

  render() {
    const { location, collectionsData, fetchingCollections, isFilterBarOpen, t } = this.props;
    const { resultCount, shouldFetch } = this.state;
    let params = new URLSearchParams(location.search);

    return (
      <>
        <Helmet>
          <title> {t('public.searchCreators')} </title>
        </Helmet>
        <SearchFilterWrapper
          isFilterBarOpen={isFilterBarOpen}
          showBlockchain={true}
        />
        <div className={cn('result-info', isFilterBarOpen && 'filter-open')}>
          <div className="result">
            &quot;<span className="text">{params.get('q')}</span>&quot; {t('public.searchResult')}
          </div>
          <div className="about mt-1"> {t('public.aboutResult', { count: resultCount })} </div>
        </div>
        {collectionsData?.length == 0 && fetchingCollections ? (
          <DiscoverCardGroup key={'discover-group-2'} hideCarousel hideInfo className="mt-3">
            {new Array(this.TOP).fill(0).map((i) => {
              return (
                <div key={'account' + i} className="w-100 placeholder-card">
                  <div style={{ position: 'relative' }}>
                    <div style={{ display: 'block', paddingBottom: '100%' }} />
                    <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                  </div>
                </div>
              );
            })}
          </DiscoverCardGroup>
        ) : (
          <AppScroller
            dataLength={collectionsData?.length || 0}
            next={() => this.loadMore()}
            hasMore={shouldFetch && collectionsData?.length >= this.TOP}
            loader={<InlineLoader height={30} />}>
            <DiscoverCardGroup key={'discover-group-'} hideCarousel hideInfo className="mt-3">
              {collectionsData.map((collection) => {
                return <CollectionCard key={collection.id} collection={collection} />;
              })}
            </DiscoverCardGroup>
          </AppScroller>
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...CollectionActions,
  ...SearchActions,
  ...ModalActions,
  ...SpinnerActions,
  ...CollectibleActions,
  ...ServiceActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.search,
  ...state.service,
  ...state.user.account,
});

const SearchCollections = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation()(SearchCollectionsE),
);

export default SearchCollections;
