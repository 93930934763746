import * as React from 'react';
import { push } from 'connected-react-router';
import Popover from '@material-ui/core/Popover';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import { UserActions } from '@Actions/UserActions';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import { ServiceActions } from '@Actions/ServiceActions';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import DisplayPublicAddress from '@Components/shared/DisplayPublicAddress';
import { FaExchangeAlt, FaCcVisa, FaCcMastercard, FaCcAmex, FaCcApplePay } from 'react-icons/fa';
import { toEtherFixed, numberFormat, numberFormatDigits, getChainInfo, toCustomDecimalsFullFixed } from '@Utils';
import { Link } from 'react-router-dom';
import { getProfileNameOrShortPublicAddress, openInNewTab } from '@Utils';
import withRouterAndRef from '@Components/hoc/withRouterAndRef';
import AppVariables from '@AppVariables';
import { toBN } from '@Utils';
import { withTranslation } from 'react-i18next';
import { runtimeConfig } from '../../config';

class Dropdown extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  @bind
  open() {
    this.setState({ open: true });
  }

  @bind
  close() {
    this.setState({ open: false });
  }

  @bind
  logOut() {
    this.props.logout();
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { open } = this.state;
    const { account, tokenPricesInUsd, history, location, openModal, wallet, t } = this.props;

    const currentChainInfo = getChainInfo(wallet.chainId);
    const currentChainCurrency = currentChainInfo.chainInfo.nativeCurrency;

    const currencyProps = AppVariables.cryptoCurrencies.find((ac) => ac.Symbol === currentChainCurrency.symbol);

    const walletBalanceInUsd = toBN(tokenPricesInUsd?.[currencyProps?.Gecko]?.usd).times(
      toEtherFixed(wallet.balance, 2),
    );

    return (
      <div className="dropdown">
        <Popover
          id={open ? 'simple-popover' : undefined}
          open={open}
          onClose={this.close}
          classes={{ root: 'profile-dropdown' }}
          className="popover-class"
          anchorEl={document.getElementById('app-header')}
          anchorReference="anchorEl"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transitionDuration={0}
          container={document.getElementById('app-header')}>
          <div className="dropdown-content">
            <div className="user-info">
              <ProfileAvatar onClick={() => this.close()} width={60} height={60} profileData={account} />
              <div className="user-profile">
                <div className="user-name">
                  {getProfileNameOrShortPublicAddress(account?.profile?.name || account?.profile?.slug)}
                </div>
                <div className="slug">@{getProfileNameOrShortPublicAddress(account?.profile?.slug)}</div>
                <Link to={'/' + account?.profile?.slug.toLowerCase()} onClick={() => this.close()}>
                  <div className="to-profile"> {t('public.seeYourProfile')} </div>
                </Link>
              </div>
            </div>
            <hr className="horizontal" />
            <div className="public-address-wrapper">
              <DisplayPublicAddress publicAddress={account.publicAddress} />
            </div>
            <div className="wallet-info">
              <div className="balance info">
                {/* <div className="icon">
                  <FaEthereum />
                </div> */}
                <div className="text">
                  <div className="eth">
                    {numberFormatDigits(
                      toCustomDecimalsFullFixed(wallet.balance, currentChainCurrency.decimals, 2),
                      'en-US',
                      15,
                    )}
                    &nbsp;{currentChainCurrency.symbol}&nbsp;|&nbsp;
                    {numberFormat(walletBalanceInUsd, 'en-US', 'USD')} USD
                  </div>
                  <div className="balance"> {t('public.balance')} </div>
                </div>
              </div>
            </div>
            <hr className="horizontal" />
            <div
              className="convert d-flex align-center justify-space-between p-3 link"
              onClick={() => {
                history.push({
                  pathname: '/convert',
                  state: { background: location },
                });
                this.close();
              }}>
              <div className="convert-text">{t('public.convert')}</div>
              <div className="icon">
                <FaExchangeAlt className="icon" size={18} />
              </div>
            </div>
            {/*<hr className="horizontal" />
            <div
              className="add-fund d-flex align-center justify-space-between p-3"
              onClick={() => {
                this.props.initWyre('ethereum');
                this.close();
              }}>
              <div className="convert-text">{t('public.addFunds')}</div>
              <div className="icon">
                <FaCcVisa className="card-icon" size={20} />
                <FaCcMastercard className="card-icon" size={20} />
                <FaCcAmex className="card-icon" size={20} />
                <FaCcApplePay className="card-icon" size={20} />
              </div>
            </div>*/}
            <hr className="horizontal" />
            <div className="account">
              <div
                onClick={() => {
                  openModal('SendFeedbackModal', { show: true, backdrop: true, closeOnOutsideClick: true });
                  this.close();
                }}
                className="feedback link">
                <div className="title">{t('public.sendFeedback')}</div>
                <div className="subtitle">{t('public.helpUsImprove')}</div>
              </div>
              <hr className="horizontal" />
              <Link to="/settings/privacy" className="settings link" onClick={() => this.close()}>
                {t('public.privacySafety')}
              </Link>
              <Link to="/settings/notifications" className="settings link" onClick={() => this.close()}>
                {t('public.notificationSettings')}
              </Link>
              <Link to="/support" className="settings link" onClick={() => this.close()}>
                <div>{t('public.helpSupport')}</div>
              </Link>
            </div>
            <hr className="horizontal" />
            <div className="log-out">
              {/* We cannot use Link below as the 'to' property is required and it navigates twice, on click and 
                             * on route, which messes up the push state of the browser.
                             Only use an optional link through the a tag or a button. */}
              <a onClick={this.logOut} className="logout link">
                {t('public.disconnect')}
              </a>
            </div>
          </div>
        </Popover>
      </div>
    );
  }
}

const dropdownComponent = connect(
  (state) => {
    return {
      ...state.auth,
      ...state.user,
      ...state.user.account,
      ...state.service,
    };
  },
  {
    ...UserActions,
    ...AccountActions,
    push,
    ...ModalActions,
    ...ServiceActions,
  },
  null,
  { forwardRef: true },
)(withTranslation(undefined, { withRef: true })(Dropdown));

export default withRouterAndRef(dropdownComponent);
