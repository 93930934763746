const collectiblesEndpoint = (collectibleId) => `v1/collectibles${collectibleId ? `/${collectibleId}` : ''}`;
const collectiblesODataEndpoint = (collectibleId) => `v1/odata/collectibles${collectibleId ? `/${collectibleId}` : ''}`;

const hashtagsODataEndpoint = (tag) => `v1/odata/hashtags${tag ? `(\'${tag}\')` : ''}`;

const urlQueryPart = (query) => (query ? `?${query}` : '');

export const GET_COLLECTIBLES = 'GET_COLLECTIBLES';
export const GET_COLLECTIBLES_SUCCESS = 'GET_COLLECTIBLES_SUCCESS';
export const GET_COLLECTIBLES_FAILURE = 'GET_COLLECTIBLES_FAILURE';

const getCollectibles = (query, group, category) => {
  return {
    type: GET_COLLECTIBLES,
    payload: {
      group,
      category,
      request: {
        url: collectiblesODataEndpoint() + (group ? `/${group}(listingStatus=null)/` : '') + urlQueryPart(query),
      },
    },
  };
};

export const GET_COLLECTIBLES_WITH_POST = 'GET_COLLECTIBLES_WITH_POST';
export const GET_COLLECTIBLES_WITH_POST_SUCCESS = 'GET_COLLECTIBLES_WITH_POST_SUCCESS';
export const GET_COLLECTIBLES_WITH_POST_FAILURE = 'GET_COLLECTIBLES_WITH_POST_FAILURE';

const getCollectiblesWithPost = (query, searchKey) => {
  var decodedSearchKey = decodeURIComponent(searchKey);

  var endpoint = decodedSearchKey?.startsWith('#')
  ? hashtagsODataEndpoint(decodedSearchKey.substring(1)) + '/collectibles'
  : collectiblesODataEndpoint();

  return {
    type: GET_COLLECTIBLES_WITH_POST,
    payload: {
      request: {
        method: 'POST',
        headers: {"content-type": "text/plain"},
        url: endpoint + '/$query',
        data: query
      },
    },
  };
};

export const GET_LIVE_AUCTION_ITEMS = 'GET_LIVE_AUCTION_ITEMS';
export const GET_LIVE_AUCTION_ITEMS_SUCCESS = 'GET_LIVE_AUCTION_ITEMS_SUCCESS';
export const GET_LIVE_AUCTION_ITEMS_FAILURE = 'GET_LIVE_AUCTION_ITEMS_FAILURE';

const getLiveAuctionItems = (query, group, category) => ({
  type: GET_LIVE_AUCTION_ITEMS,
  payload: {
    group,
    category,
    request: {
      url: collectiblesODataEndpoint() + (group ? `/${group}(listingStatus=Orderinbox.Business.Dto.CollectibleListingStatus'LiveAuctions')/` : '') + urlQueryPart(query),
    },
  },
});

export const GET_ON_SALE_ITEMS = 'GET_ON_SALE_ITEMS';
export const GET_ON_SALE_ITEMS_SUCCESS = 'GET_ON_SALE_ITEMS_SUCCESS';
export const GET_ON_SALE_ITEMS_FAILURE = 'GET_ON_SALE_ITEMS_FAILURE';

const getOnSaleItems = (query, group, category) => ({
  type: GET_ON_SALE_ITEMS,
  payload: {
    group,
    category,
    request: {
      url: collectiblesODataEndpoint() + (group ? `/${group}(listingStatus=Orderinbox.Business.Dto.CollectibleListingStatus'OnSale')/` : '') + urlQueryPart(query),
    },
  },
});

export const GET_TRENDING_ITEMS = 'GET_TRENDING_ITEMS';
export const GET_TRENDING_ITEMS_SUCCESS = 'GET_TRENDING_ITEMS_SUCCESS';
export const GET_TRENDING_ITEMS_FAILURE = 'GET_TRENDING_ITEMS_FAILURE';

const getTrendingItems = (query, group, category) => ({
  type: GET_TRENDING_ITEMS,
  payload: {
    group,
    category,
    request: {
      url: collectiblesODataEndpoint() + (group ? `/${group}(listingStatus=null)/` : '') + urlQueryPart(query),
    },
  },
});

export const GET_COLLECTIBLE = 'GET_COLLECTIBLE';
export const GET_COLLECTIBLE_SUCCESS = 'GET_COLLECTIBLE_SUCCESS';
export const GET_COLLECTIBLE_FAILURE = 'GET_COLLECTIBLE_FAILURE';

const getCollectible = (collectibleOrCollectionId, tokenId, chainId = null) => ({
  type: GET_COLLECTIBLE,
  payload: {
    chainId,
    collectibleOrCollectionId,
    tokenId,
    request: {
      url: collectiblesEndpoint() + (chainId ? `/${chainId}` : '') + (collectibleOrCollectionId ? `/${collectibleOrCollectionId}` : '') + (tokenId ? `/${tokenId}` : ''),
    },
  },
});

export const CREATE_COLLECTIBLE = 'CREATE_COLLECTIBLE';
export const CREATE_COLLECTIBLE_SUCCESS = 'CREATE_COLLECTIBLE_SUCCESS';
export const CREATE_COLLECTIBLE_FAILURE = 'CREATE_COLLECTIBLE_FAILURE';

const createCollectible = (collectible) => ({
  type: CREATE_COLLECTIBLE,
  payload: {
    request: {
      method: 'POST',
      url: collectiblesEndpoint(),
      data: collectible,
    },
  },
});

export const GET_UNLOCKABLE_CONTENT = 'GET_UNLOCKABLE_CONTENT';
export const GET_UNLOCKABLE_CONTENT_SUCCESS = 'GET_UNLOCKABLE_CONTENT_SUCCESS';
export const GET_UNLOCKABLE_CONTENT_FAILURE = 'GET_UNLOCKABLE_CONTENT_FAILURE';

const getUnlockableContent = (collectibleId) => ({
  type: GET_UNLOCKABLE_CONTENT,
  payload: {
    request: {
      url: `${collectiblesEndpoint(collectibleId)}/unlockablecontent/`,
    },
  },
});

// We will likely integrate this as part of the getCollectible
// or implement it in the HTTP headers as part of that
export const RECORD_COLLECTIBLE_VIEW = 'RECORD_COLLECTIBLE_VIEW';
export const RECORD_COLLECTIBLE_VIEW_SUCCESS = 'RECORD_COLLECTIBLE_VIEW_SUCCESS';
export const RECORD_COLLECTIBLE_VIEW_FAILURE = 'RECORD_COLLECTIBLE_VIEW_FAILURE';

const recordCollectibleView = (collectibleId) => ({
  type: RECORD_COLLECTIBLE_VIEW,
  payload: {
    request: {
      method: 'POST',
      url: `${collectiblesEndpoint(collectibleId)}/view/`,
    },
  },
});

export const LIKE_COLLECTIBLE = 'LIKE_COLLECTIBLE';
export const LIKE_COLLECTIBLE_SUCCESS = 'LIKE_COLLECTIBLE_SUCCESS';
export const LIKE_COLLECTIBLE_FAILURE = 'LIKE_COLLECTIBLE_FAILURE';

const likeCollectible = (collectibleId, cacheKey = null) => ({
  type: LIKE_COLLECTIBLE,
  payload: {
    collectibleId: collectibleId,
    cacheKey: cacheKey,
    request: {
      method: 'POST',
      url: `${collectiblesEndpoint(collectibleId)}/like/`,
    },
  },
});

export const UNLIKE_COLLECTIBLE = 'UNLIKE_COLLECTIBLE';
export const UNLIKE_COLLECTIBLE_SUCCESS = 'UNLIKE_COLLECTIBLE_SUCCESS';
export const UNLIKE_COLLECTIBLE_FAILURE = 'UNLIKE_COLLECTIBLE_FAILURE';

const unlikeCollectible = (collectibleId, cacheKey = null) => ({
  type: UNLIKE_COLLECTIBLE,
  payload: {
    collectibleId: collectibleId,
    cacheKey: cacheKey,
    request: {
      method: 'DELETE',
      url: `${collectiblesEndpoint(collectibleId)}/like/`,
    },
  },
});

export const SHARE_COLLECTIBLE = 'SHARE_COLLECTIBLE';
export const SHARE_COLLECTIBLE_SUCCESS = 'SHARE_COLLECTIBLE_SUCCESS';
export const SHARE_COLLECTIBLE_FAILURE = 'SHARE_COLLECTIBLE_FAILURE';

const shareCollectible = (collectibleId, cacheKey = null) => ({
  type: SHARE_COLLECTIBLE,
  payload: {
    collectibleId: collectibleId,
    cacheKey: cacheKey,
    request: {
      method: 'POST',
      url: `${collectiblesEndpoint(collectibleId)}/share/`,
    },
  },
});

export const UNSHARE_COLLECTIBLE = 'UNSHARE_COLLECTIBLE';
export const UNSHARE_COLLECTIBLE_SUCCESS = 'UNSHARE_COLLECTIBLE_SUCCESS';
export const UNSHARE_COLLECTIBLE_FAILURE = 'UNSHARE_COLLECTIBLE_FAILURE';

const unshareCollectible = (collectibleId, cacheKey = null) => ({
  type: UNSHARE_COLLECTIBLE,
  payload: {
    collectibleId: collectibleId,
    cacheKey: cacheKey,
    request: {
      method: 'DELETE',
      url: `${collectiblesEndpoint(collectibleId)}/share/`,
    },
  },
});

export const BOOKMARK_COLLECTIBLE = 'BOOKMARK_COLLECTIBLE';
export const BOOKMARK_COLLECTIBLE_SUCCESS = 'BOOKMARK_COLLECTIBLE_SUCCESS';
export const BOOKMARK_COLLECTIBLE_FAILURE = 'BOOKMARK_COLLECTIBLE_FAILURE';

const bookmarkCollectible = (collectibleId, cacheKey) => ({
  type: BOOKMARK_COLLECTIBLE,
  payload: {
    collectibleId: collectibleId,
    cacheKey: cacheKey,
    request: {
      method: 'POST',
      url: `${collectiblesEndpoint(collectibleId)}/bookmark/`,
    },
  },
});

export const UNBOOKMARK_COLLECTIBLE = 'UNBOOKMARK_COLLECTIBLE';
export const UNBOOKMARK_COLLECTIBLE_SUCCESS = 'UNBOOKMARK_COLLECTIBLE_SUCCESS';
export const UNBOOKMARK_COLLECTIBLE_FAILURE = 'UNBOOKMARK_COLLECTIBLE_FAILURE';

const unbookmarkCollectible = (collectibleId, cacheKey) => ({
  type: UNBOOKMARK_COLLECTIBLE,
  payload: {
    collectibleId: collectibleId,
    cacheKey: cacheKey,
    request: {
      method: 'DELETE',
      url: `${collectiblesEndpoint(collectibleId)}/bookmark/`,
    },
  },
});

export const GET_COLLECTIBLE_COMMENTS = 'GET_COLLECTIBLE_COMMENTS';
export const GET_COLLECTIBLE_COMMENTS_SUCCESS = 'GET_COLLECTIBLE_COMMENTS_SUCCESS';
export const GET_COLLECTIBLE_COMMENTS_FAILURE = 'GET_COLLECTIBLE_COMMENTS_FAILURE';

const getCollectibleComments = (collectibleId, query, key = false, flag = false) => ({
  type: GET_COLLECTIBLE_COMMENTS,
  payload: {
    key: key,
    flag: flag,
    request: {
      url: `${collectiblesODataEndpoint(collectibleId)}/comments${urlQueryPart(query)}`,
    },
  },
});

export const GET_COLLECTIBLE_ACTIVITIES = 'GET_COLLECTIBLE_ACTIVITIES';
export const GET_COLLECTIBLE_ACTIVITIES_SUCCESS = 'GET_COLLECTIBLE_ACTIVITIES_SUCCESS';
export const GET_COLLECTIBLE_ACTIVITIES_FAILURE = 'GET_COLLECTIBLE_ACTIVITIES_FAILURE';

const getCollectibleActivities = (collectibleId, query) => ({
  type: GET_COLLECTIBLE_ACTIVITIES,
  payload: {
    request: {
      url: `${collectiblesODataEndpoint(collectibleId)}/activities${urlQueryPart(query)}`,
    },
  },
});

export const UPDATE_COLLECTIBLE = 'UPDATE_COLLECTIBLE';
export const UPDATE_COLLECTIBLE_SUCCESS = 'UPDATE_COLLECTIBLE_SUCCESS';
export const UPDATE_COLLECTIBLE_FAILURE = 'UPDATE_COLLECTIBLE_FAILURE';

const updateCollectible = (collectibleId, collectible) => ({
  type: UPDATE_COLLECTIBLE,
  payload: {
    collectibleId: collectibleId,
    request: {
      method: 'PUT',
      url: collectiblesEndpoint(collectibleId),
      data: collectible,
    },
  },
});

export const DELETE_COLLECTIBLE = 'DELETE_COLLECTIBLE';
export const DELETE_COLLECTIBLE_SUCCESS = 'DELETE_COLLECTIBLE_SUCCESS';
export const DELETE_COLLECTIBLE_FAILURE = 'DELETE_COLLECTIBLE_FAILURE';

const deleteCollectible = (collectibleId, accountId) => ({
  type: DELETE_COLLECTIBLE,
  payload: {
    collectibleId,
    accountId,
    request: {
      method: 'DELETE',
      url: collectiblesEndpoint(collectibleId),
    },
  },
});

export const SET_COLLECTIBLE_MINTING = 'SET_COLLECTIBLE_MINTING';
export const SET_COLLECTIBLE_MINTING_SUCCESS = 'SET_COLLECTIBLE_MINTING_SUCCESS';
export const SET_COLLECTIBLE_MINTING_FAILURE = 'SET_COLLECTIBLE_MINTING_FAILURE';

const setCollectibleMinting = (collectibleId, collectionId, mintTxHash) => ({
  type: SET_COLLECTIBLE_MINTING,
  payload: {
    request: {
      method: 'PUT',
      url: `${collectiblesEndpoint(collectibleId)}/mint/${collectionId}/${mintTxHash}`,
    },
  },
});

export const ADD_COLLECTIBLE_TRANSACTION = 'ADD_COLLECTIBLE_TRANSACTION';
export const ADD_COLLECTIBLE_TRANSACTION_SUCCESS = 'ADD_COLLECTIBLE_TRANSACTION_SUCCESS';
export const ADD_COLLECTIBLE_TRANSACTION_FAILURE = 'ADD_COLLECTIBLE_TRANSACTION_FAILURE';

const addCollectibleTransaction = (collectibleId, txHash, latestBlockNumber, kind) => ({
  type: ADD_COLLECTIBLE_TRANSACTION,
  payload: {
    collectibleId: collectibleId,
    //    cacheKey: cacheKey,
    request: {
      method: 'POST',
      url: `${collectiblesEndpoint(collectibleId)}/tx`,
      data: {
        txHash,
        latestBlockNumber,
        kind,
      },
    },
  },
});

export const CLEAR_COLLECTIBLE = 'CLEAR_COLLECTIBLE';
const clearCollectible = () => ({
  type: CLEAR_COLLECTIBLE,
});

export const CLEAR_COMMENTS = 'CLEAR_COMMENTS';
const clearComments = () => ({
  type: CLEAR_COMMENTS,
});

export const CLEAR_ACTIVITIES = 'CLEAR_ACTIVITIES';
const clearActivities = () => ({
  type: CLEAR_ACTIVITIES,
});

export const UNRESTRICT_CONTENT = 'UNRESTRICT_CONTENT';
const unrestrictContent = (collectibleId, cacheKey = null) => ({
  type: UNRESTRICT_CONTENT,
  payload: {
    collectibleId: collectibleId,
    cacheKey: cacheKey,
  },
});

export const UPDATE_COLLECTIBLE_STATE = 'UPDATE_COLLECTIBLE_STATE';
const updateCollectibleState = (updatedCollectibleSummary) => ({
  type: UPDATE_COLLECTIBLE_STATE,
  payload: {
    updatedCollectibleSummary: updatedCollectibleSummary,
  },
});

const actions = {
  createCollectible,
  getCollectibles,
  getCollectiblesWithPost,
  getLiveAuctionItems,
  getOnSaleItems,
  getTrendingItems,
  getCollectible,
  updateCollectible,
  deleteCollectible,
  getUnlockableContent,
  recordCollectibleView,
  likeCollectible,
  unlikeCollectible,
  shareCollectible,
  unshareCollectible,
  bookmarkCollectible,
  unbookmarkCollectible,
  getCollectibleComments,
  getCollectibleActivities,
  clearCollectible,
  clearComments,
  clearActivities,
  setCollectibleMinting,
  addCollectibleTransaction,
  unrestrictContent,
  updateCollectibleState,
};

export const CollectibleActions = {
  createCollectible: (collectible) => (dispatch) => 
    dispatch(actions.createCollectible(collectible)),
  getCollectibles: (query, group, category) => (dispatch) => 
    dispatch(actions.getCollectibles(query, group, category)),
  getCollectiblesWithPost: (query, searchKey) => (dispatch) => 
    dispatch(actions.getCollectiblesWithPost(query, searchKey)),
  getLiveAuctionItems: (query, group, category, order) => (dispatch) => 
    dispatch(actions.getLiveAuctionItems(query, group, category, order)),
  getOnSaleItems: (query, group, category, order) => (dispatch) => 
    dispatch(actions.getOnSaleItems(query, group, category, order)),
  getTrendingItems: (query, group, category) => (dispatch) => 
    dispatch(actions.getTrendingItems(query, group, category)),
  getCollectible: (collectibleOrCollectionId, tokenId, chainId) => (dispatch) =>
    dispatch(actions.getCollectible(collectibleOrCollectionId, tokenId, chainId)),
  updateCollectible: (collectibleId, model) => (dispatch) => 
    dispatch(actions.updateCollectible(collectibleId, model)),
  deleteCollectible: (collectibleId, accountId) => (dispatch) =>
    dispatch(actions.deleteCollectible(collectibleId, accountId)),
  getUnlockableContent: (collectibleId) => (dispatch) => 
    dispatch(actions.getUnlockableContent(collectibleId)),
  clearCollectible: () => (dispatch) => 
    dispatch(actions.clearCollectible()),
  clearComments: () => (dispatch) => 
    dispatch(actions.clearComments()),
  recordCollectibleView: (collectibleId) => (dispatch) =>
    dispatch(actions.recordCollectibleView(collectibleId)),
  likeCollectible: (collectibleId, cacheKey) => (dispatch) =>
    dispatch(actions.likeCollectible(collectibleId, cacheKey)),
  unlikeCollectible: (collectibleId, cacheKey) => (dispatch) =>
    dispatch(actions.unlikeCollectible(collectibleId, cacheKey)),
  shareCollectible: (collectibleId, cacheKey) => (dispatch) =>
    dispatch(actions.shareCollectible(collectibleId, cacheKey)),
  unshareCollectible: (collectibleId, cacheKey) => (dispatch) =>
    dispatch(actions.unshareCollectible(collectibleId, cacheKey)),
  bookmarkCollectible: (collectibleId, cacheKey) => (dispatch) =>
    dispatch(actions.bookmarkCollectible(collectibleId, cacheKey)),
  unbookmarkCollectible: (collectibleId, cacheKey) => (dispatch) =>
    dispatch(actions.unbookmarkCollectible(collectibleId, cacheKey)),
  getCollectibleComments: (collectibleId, query, key, flag) => (dispatch) =>
    dispatch(actions.getCollectibleComments(collectibleId, query, key, flag)),
  getCollectibleActivities: (collectibleId, query) => (dispatch) =>
    dispatch(actions.getCollectibleActivities(collectibleId, query)),
  setCollectibleMinting: (collectibleId, collectionId, mintTxHash) => (dispatch) =>
    dispatch(actions.setCollectibleMinting(collectibleId, collectionId, mintTxHash)),
  addCollectibleTransaction: (collectibleId, txHash, latestBlockNumber, kind) => (dispatch) =>
    dispatch(actions.addCollectibleTransaction(collectibleId, txHash, latestBlockNumber, kind)),
  clearActivities: () => (dispatch) => dispatch(actions.clearActivities()),
  unrestrictContent: (collectibleId, cacheKey) => (dispatch) =>
    dispatch(actions.unrestrictContent(collectibleId, cacheKey)),
  updateCollectibleState: (updatedCollectibleSummary) => (dispatch) =>
    dispatch(actions.updateCollectibleState(updatedCollectibleSummary)),
};
