import * as React from "react";
import Icon from "@Components/shared/Icon";
import GlobalSearch from "@Components/shared/GlobalSearch";

class MobileSearch extends React.Component {

    render() {
        return <div className="mobile-search-screen">
            <div className="header">
                <div className="back-icon">
                    <div className="back-button" onClick={() => this.props.closeMobileSearch()}>
                        <Icon type="fontawesome" src="fas fa-arrow-left" />
                    </div>
                </div>
                <div className="input">
                    <GlobalSearch isMobile hidePrefix width={"100%"} closeMobileSearch={this.props.closeMobileSearch}/>
                </div>
            </div>
        </div>
    }
}

export default MobileSearch;