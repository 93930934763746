import React from 'react';
import { connect } from 'react-redux';
import logo from '@Images/logo/oix-icon-color.svg';

class Spinner extends React.Component {
  render() {
    return this.props.isSpinnerEnabled ? (
      <>
        <div className="app-spinner">
          <img width="48" height="48" src={logo} />
        </div>
        <div className="app-loader" style={{ top: '50px', background: '0' }}>
          <div className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
        </div>
      </>
    ) : null;
  }
}

class Loader extends React.Component {
  render() {
    return this.props.isLoaderEnabled ? (
      <div className="app-loader">
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    ) : null;
  }
}

export class InlineLoader extends React.Component {
  getClassName() {
    let cname = 'inline-loader';
    if (this.props.className) {
      cname += ' ' + this.props.className;
    }
    return cname;
  }

  render() {
    return (
      <div className={this.getClassName()} style={{ width: this.props.width, height: this.props.height }}>
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    );
  }
}

export const FallbackLoader = () => {
  return (
    <div className='fallback-loader'>
      <InlineLoader />
    </div>
  );
};

export const AppSpinner = connect((state) => {
  return { ...state.spinner };
})(Spinner);
export const AppLoader = connect((state) => {
  return { ...state.spinner };
})(Loader);
