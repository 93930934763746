import React from 'react';

import clsx from 'clsx';

import OiInfoIcon from './OiInfoIcon';

const OiLabel = ({ className, isShowInfoIcon, infoText, ...rest }) => (
  <div className={clsx('oi-label', className)}>
    <h3 {...rest} className="oi-label__text" />
    <OiInfoIcon className="oi-label__info-icon" infoText={infoText} isHidden={!isShowInfoIcon} />
  </div>
);

export default OiLabel;
