import * as React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

export default class Button extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { children, className, fullSize, small, submit, text, type, variant, ...rest } = this.props;

    const componentClassName = clsx(
      'button',
      `button-${type}`,
      `button-${type}--${variant}`,
      className,
      fullSize && 'full-size',
      small && 'button-small',
    );

    return (
      <button className={componentClassName} type={submit ? 'submit' : 'button'} {...rest}>
        {children ?? text}
      </button>
    );
  }
}

Button.propTypes = {
  children: PropTypes.node,
  type: PropTypes.oneOf([
    'default',
    'primary',
    'secondary',
    'transparent',
    'text',
    'link',
    'tab',
    'golden',
    'green',
    'pink',
    'danger',
    'unclickable',
    'white',
    'square',
    'oi',
    'follow'
  ]).isRequired,
  text: PropTypes.node,
  className: PropTypes.string,
};

Button.defaultProps = {
  type: 'default',
};
