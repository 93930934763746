import * as React from 'react';
import Popover from '@material-ui/core/Popover';
import bind from 'bind-decorator';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';

class ContextDropdown extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };
  }

  @bind
  open() {
    this.setState({ open: true });
  }

  @bind
  close() {
    this.setState({ open: false });
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const className = this.props.className;
    return (
      <Popover
        disableScrollLock={true}
        id={this.state.open ? 'simple-popover' : undefined}
        open={this.state.open}
        onClose={this.close}
        classes={{ root: cn('context-dropdown-popover', className) }}
        anchorEl={
          typeof window !== 'undefined'
            ? this.props.refAnchorEl
              ? this.props.refAnchorEl
              : document.getElementById(this.props.anchorEl)
            : ''
        }
        anchorReference="anchorEl"
        anchorOrigin={{
          vertical: this.props.anchorOriginV ? this.props.anchorOriginV : 'bottom',
          horizontal: this.props.origin ? this.props.origin : 'right',
        }}
        transformOrigin={{
          vertical: this.props.transformOriginV ? this.props.transformOriginV : 'bottom',
          horizontal: this.props.transformOriginH ? this.props.transformOriginH : 'right',
        }}
        transitionDuration={100}>
        <div className="context-dropdown">{this.props.children}</div>
      </Popover>
    );
  }
}

export default withTranslation(undefined, { withRef: true })(ContextDropdown);
