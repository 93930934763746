import React, { useRef } from 'react';

import clsx from 'clsx';
import { FaSearch } from 'react-icons/fa';

const OiInput = ({ className, suffix, options: { rightComponent = {}, leftComponent = {} } = {}, error, ...rest }) => {
  /**
   * refs
   */
  const inputRef = useRef();

  /**
   * render
   */
  return (
    <div className={clsx('oi-input', error && 'error', className)} onClick={() => inputRef.current.focus()}>
      {leftComponent === 'searchIcon' ? <FaSearch className="oi-input__search-icon" /> : null}
      <input {...rest} ref={inputRef} className="oi-input__input" />
      {rightComponent === 'searchIcon' ? <FaSearch className="oi-input__search-icon" /> : null}
      {rightComponent === 'percentage' ? <span className="oi-input__percentage">%</span> : null}
      {suffix}
    </div>
  );
};

export default OiInput;
