
export const MODAL_OPEN = "MODAL_OPEN";

const openModal = (modalType, modalProps) => ({
  type: MODAL_OPEN,
  payload: { modalType, modalProps }
});

export const MODAL_CLOSE = "MODAL_CLOSE";

const closeModal = (count = 1) => ({
  type: MODAL_CLOSE,
  payload: { count }
});

export const MODAL_CLOSE_ALL = "MODAL_CLOSE_ALL";

const closeAllModals = () => ({
  type: MODAL_CLOSE_ALL
});

export const SET_ZERO_PERCENT_VIEWED = "SET_ZERO_PERCENT_VIEWED";

const setZeroPercentViewed = () => ({
  type: SET_ZERO_PERCENT_VIEWED
});

const actions = {
  openModal,
  closeModal,
  closeAllModals,
  setZeroPercentViewed
};

export const ModalActions = {
  closeModal: (count) => (dispatch) => dispatch(actions.closeModal(count)),
  openModal: (modalType, modalProps) => (dispatch) => dispatch(actions.openModal(modalType, modalProps)),
  closeAllModals: () => (dispatch) => dispatch(actions.closeAllModals()),
  setZeroPercentViewed: () => (dispatch) => {
    localStorage.setItem('state.modal.zeroPercentViewed', true);
    return dispatch(actions.setZeroPercentViewed());
  },
}