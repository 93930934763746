import * as UploadActionTypes from '@Actions/UploadActions';

const initialState = () => ({
  progresses: [],
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case UploadActionTypes.UPLOAD_PROGRESS: {
      const elementsIndex = state.progresses.findIndex((element) => element.fileMarkId === action.payload.fileMarkId);
      const newProgresses = state.progresses;

      if (elementsIndex === -1) {
        action.payload.percentage = (action.payload.percentage * 100).toFixed(0);
        action.payload.state = 'uploading';
        newProgresses.push(action.payload);
      } else {
        newProgresses[elementsIndex] = {
          ...newProgresses[elementsIndex],
          percentage: (action.payload.percentage * 100).toFixed(0),
          message: '',
          state: 'uploading',
        };
      }
      return {
        ...state,
        progresses: newProgresses,
      };
    }
    case UploadActionTypes.UPLOAD_START:
    case UploadActionTypes.UPLOAD_CANCEL:
    case UploadActionTypes.CLEAR_PROGRESS: {
      let result;
      if (action.payload.fileMarkId) {
        result = state.progresses.filter((element) => element.fileMarkId !== action.payload.fileMarkId);
      } else {
        result = [];
      }

      return {
        ...state,
        progresses: result,
      };
    }
    case UploadActionTypes.UPLOAD_FILE_SUCCESS: {
      const elementsIndex = state.progresses.findIndex((element) => element.fileMarkId === action.payload.fileMarkId);
      const resultProgresses = state.progresses;

      resultProgresses[elementsIndex] = { ...resultProgresses[elementsIndex], state: 'success' };

      return {
        ...state,
        progresses: resultProgresses,
      };
    }
    case UploadActionTypes.UPLOAD_FILE_FAILURE: {
      const elementsIndex = state.progresses.findIndex((element) => element.fileMarkId === action.error.fileMarkId);
      const resultProgresses = state.progresses;

      resultProgresses[elementsIndex] = {
        ...resultProgresses[elementsIndex],
        state: 'failure',
        message: action.error.message,
      };

      return {
        ...state,
        progresses: resultProgresses,
      };
    }
    default:
      return state;
  }
};
