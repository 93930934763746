import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import Icon from '@Components/shared/Icon';
import { UserActions } from '@Actions/UserActions';
import { ModalActions } from '@Actions/ModalActions';
import { Formik } from 'formik';
import AppVariables from '@AppVariables';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';

class ChangePhoneNumberModal extends React.Component {
  constructor(props) {
    super(props);
  }

  inputs = {
    NewPhoneNumber: null,
    Code: null,
  };

  @bind
  submitNewPhoneNumber(values, action) {
    this.props.enableLoader();
    this.props.requestPhoneNumberChange(values).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.openModal('PhoneNumberChangeModal2', {
          show: true,
          backdrop: true,
          newPhoneNumber: values.newPhoneNumber,
          enableTwoFactor: this.props.enableTwoFactor,
          smsAuthSuccess: this.props.smsAuthSuccess,
          closeOnOutsideClick: true,
        });
      } else {
        this.props.disableLoader(); // ?
        this.props.changingPhoneNumberError?.response.data['_generic'].map((error) => {
          this.props.toastError(error, {});
        });
      }
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, show, t } = this.props;
    let newPhoneNumberForm;
    return (
      <Modal
        title={t('modals.changePhoneNumberModalTitle')}
        subtext={''}
        backdrop={backdrop}
        show={show}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}>
        <div className="sms-authentication-modal mx-2 my-2">
          <div className="sms-auth-container mt-2 mx-1 mb-2">
            <div className="text">{t('modals.changeAccountPhoneNumber')}</div>
            <div className="sub-text mt-2">{t('modals.changeAccountPhoneNumberSubtext')}</div>
            <div className="content my-4">
              <div className="icon-container">
                <Icon src="fas fa-mobile-alt" type="fontawesome" />
              </div>
              <div className="instruction-container">
                <div className="mb-3">{t('modals.changeAccountPhoneNumberStep1')}</div>
                <Formik
                  initialValues={{ newPhoneNumber: '' }}
                  onSubmit={this.submitNewPhoneNumber}
                  validationSchema={AppVariables.validationSchemas.faNewPhoneNumberSchema}>
                  {(formikProps) => {
                    newPhoneNumberForm = formikProps.submitForm;
                    return (
                      <form className="form" onSubmit={formikProps.handleSubmit}>
                        <Input.Text
                          className="w-100 my-3"
                          label={t('modals.changeAccountPhoneNumberInputLabel')}
                          placeholder=""
                          name="newPhoneNumber"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.newPhoneNumber}
                          error={t(formikProps.errors.newPhoneNumber)}
                        />
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
            <div className="mt-2 mb-3">{t('modals.changeAccountPhoneNumberNote')}</div>
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div> </div>
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.continueButton')}
              type="primary"
              fullSize
              onClick={() => newPhoneNumberForm()}
              disabled={this.props.changingPhoneNumber}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

class VerifyPhoneNumberModal extends React.Component {
  constructor(props) {
    super(props);
  }

  inputs = {
    NewPhoneNumber: null,
    Code: null,
  };

  @bind
  resend() {
    const { t } = this.props;

    this.props.enableLoader();
    const NewPhoneNumberModel = {
      NewPhoneNumber: this.props.newPhoneNumber,
    };
    this.props.requestPhoneNumberChange(NewPhoneNumberModel).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.toastSuccess(t('modals.messageSentToast'));
      } else {
        this.props.changingPhoneNumberError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  @bind
  submitVerificationCode(values, action) {
    this.props.enableLoader();
    values.newPhoneNumber = this.props.newPhoneNumber;
    values.enableTwoFactor = this.props.enableTwoFactor;
    this.props.verifyPhoneNumberChange(values).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        if (this.props.enableTwoFactor) {
          this.props.smsAuthSuccess();
          this.props.openModal('PhoneNumberChangeModal3', { show: true, backdrop: true, closeOnOutsideClick: true });
        } else {
          this.props.openModal('PhoneNumberChangeModal4', { show: true, backdrop: true, closeOnOutsideClick: true });
        }
      } else {
        this.props.verifyingPhoneNumberError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, show, t } = this.props;
    let submitVerificationCodeForm;
    return (
      <Modal
        title={t('modals.verifyPhoneNumberModalTitle')}
        subtext={''}
        backdrop={backdrop}
        show={show}
        closeOnOutsideClick={closeOnOutsideClick}>
        <div className="sms-authentication-modal mx-2 my-2">
          <div className="sms-auth-container mt-2 mx-1 mb-2">
            <div className="content my-4">
              <div className="icon-container">
                <Icon src="fas fa-mobile-alt" type="fontawesome" />
              </div>
              <div className="instruction-container">
                <div className="mb-3">{t('modals.verifyPhoneNumberStep2')}</div>
                <Formik
                  initialValues={{ code: '' }}
                  onSubmit={this.submitVerificationCode}
                  validationSchema={AppVariables.validationSchemas.faVerificationCodeSchema}>
                  {(formikProps) => {
                    submitVerificationCodeForm = formikProps.submitForm;
                    return (
                      <form className="form" onSubmit={formikProps.handleSubmit}>
                        <Input.Text
                          className="w-100 my-3"
                          label={t('modals.verifyPhoneNumberInputLabel')}
                          placeholder={t('modals.verifyPhoneNumberInputPlaceholder')}
                          name="code"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.code}
                          error={t(formikProps.errors.code)}
                        />
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.resendButton')}
              type="primary"
              fullSize
              onClick={this.resend}
              disabled={this.props.changingPhoneNumber}
            />
          </div>
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.activateButton')}
              type="primary"
              fullSize
              onClick={() => submitVerificationCodeForm()}
              disabled={this.props.verifyingPhoneNumber}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
class SmsAuthEnabledModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, show, t } = this.props;
    return (
      <Modal
        title={t('modals.smsAuthEnableTitle')}
        subtext={''}
        backdrop={backdrop}
        show={show}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}>
        <div className="sms-authentication-modal mx-2 my-2">
          <div className="sms-auth-container mt-2 mx-1 mb-2">
            <div className="text mt-2">{t('modals.smsAuthEnableWhatNext')}</div>
            <div className="sub-text mt-3 mb-2" style={{ lineHeight: '1.2rem' }}>
              {t('modals.secureCodeSendPhoneText')}
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div></div>
          <div style={{ width: '40%' }}>
            <Button text={t('modals.doneButton')} type="primary" fullSize onClick={() => this.props.closeAllModals()} />
          </div>
        </div>
      </Modal>
    );
  }
}

class PhoneNumberChangedModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { backdrop, closeOnOutsideClick, show, t } = this.props;
    return (
      <Modal
        title={t('modals.phoneNumberChangedTitle')}
        subtext={''}
        backdrop={backdrop}
        show={show}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={this.resetOnCloseModal}>
        <div className="sms-authentication-modal mx-2 my-2">
          <div className="sms-auth-container mt-2 mx-1 mb-2">
            <div className="text mt-2">{t('modals.whatNext')}</div>
            <div className="sub-text mt-3 mb-2" style={{ lineHeight: '1.2rem' }}>
              {t('modals.secureCodeSendPhoneText')}
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div></div>
          <div style={{ width: '40%' }}>
            <Button text={t('modals.doneButton')} type="primary" fullSize onClick={() => this.props.closeAllModals()} />
          </div>
        </div>
      </Modal>
    );
  }
}

export const PhoneNumberChangeModal = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.user.settings, ...state.auth };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(ChangePhoneNumberModal));
export const PhoneNumberChangeModal2 = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.user.settings, ...state.auth };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(VerifyPhoneNumberModal));
export const PhoneNumberChangeModal3 = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.user.settings, ...state.auth };
  },
  { ...UserActions, ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(SmsAuthEnabledModal));
export const PhoneNumberChangeModal4 = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.user.settings, ...state.auth };
  },
  { ...UserActions, ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(PhoneNumberChangedModal));
