import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import Icon from '@Components/shared/Icon';
import { AccountActions } from '@Actions/AccountActions';
import { CollectionActions } from '@Actions/CollectionActions';
import { ModalActions } from '@Actions/ModalActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { Formik } from 'formik';
import { withRouter } from 'react-router';
import AppVariables from '@AppVariables';
import { UserActions } from '../../actions/UserActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { WalletActions } from '@Actions/WalletActions';
import { ToastActions } from '@Actions/ToastActions';
import Placeholder from '@Images/placeholder-images/placeholder-avatar.png';
import OixSpinner from '@Components/shared/OixSpinner';
import withWallet from '@Components/hoc/withWallet';
import { buildCollectionLink, generateGradientBackground } from '@Utils';
import { withTranslation } from 'react-i18next';
import oiLogo from '@Images/logo/oix-icon-white.svg';
import OiLabel from '@Components/form/OiLabel';

class EditCollectionModal extends React.Component {
  inputProfileFileRef;
  inputCoverFileRef;
  featuredHorizontalFileRef;
  featuredVerticalFileRef;
  formikRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      checkingSlug: false,
      slugAvailable: true,
      initializeChecking: false,
      hasHFeatureImage: false,
      hasVFeatureImage: false,
    };
  }

  componentDidMount() {
    const {
      allDetailedCollections,
      activeProfileCollectionId,
      t,
    } = this.props;
    const collection = allDetailedCollections[activeProfileCollectionId];
    collection.verticalFeatureImageUrl && this.setState({ hasVFeatureImage: true });
    collection.horizontalFeatureImageUrl && this.setState({ hasHFeatureImage: true });
  }

  @bind
  checkSlug(slug, oldSlug) {
    const { t } = this.props;
    this.formikRef.current.setFieldValue('slug', slug);
    if (slug === oldSlug) {
      return this.setState({ checkingSlug: false, initializeChecking: false, slugAvailable: true });
    }
    this.setState({ checkingSlug: true, initializeChecking: true });
    setTimeout(() => {
      this.props
        .checkSlugAvailability(slug)
        .then((res) => {
          this.setState({ checkingSlug: false });
          if (res.error) {
            this.setState({ slugAvailable: false });
            this.formikRef.current.setFieldError('slug', t('modals.notAvailable'));
          } else {
            this.setState({ slugAvailable: true });
            this.formikRef.current.setFieldError('slug', null);
          }
        })
        .catch(() => {
          this.setState({ checkingSlug: false });
        });
    }, 1000);
  }

  @bind
  submitEditProfile(values) {
    const {
      t,
      activeProfileCollectionId,
      history,
      getCollection,
      disableLoader,
      closeAllModals,
      toastError,
      enableLoader,
      updateCollectionProfile,
    } = this.props;
    const { hasHFeatureImage, hasVFeatureImage } = this.state;
    values = this.addPrefixWebsite(values);
    
    if (hasHFeatureImage && !values.horizontalFeatureImageUrl) {
      values.deleteHorizontalFeatureImage = true;
    }

    if (hasVFeatureImage && !values.verticalFeatureImageUrl) {
      values.deleteVerticalFeatureImage = true;
    }

    enableLoader();
    updateCollectionProfile(activeProfileCollectionId, values).then((result) => {
      if (!result.error) {
        getCollection(activeProfileCollectionId).then((getResult) => {
          const newCollectionLink = buildCollectionLink(getResult.payload);
          history.replace(newCollectionLink);
          // window?.location.reload();
          disableLoader();
          closeAllModals();
        });
      } else if (result.error) {
        disableLoader();
        closeAllModals();
        toastError(t('modals.collectionNotUpdateProfileInfoToast'), {});
      }
    });
  }

  profileInputChange(e) {
    const { t } = this.props;
    const file = e.target.files[0];

    if (file) {
      if (file.type === 'image/gif') {
        const url = URL.createObjectURL(file);
        this.getProfilePicture(file, url);
      } else {
        this.props.openModal('EditProfilePictureModal', {
          backdrop: true,
          closeOnOutsideClick: true,
          getPicture: (blob, url) => this.getProfilePicture(blob, url),
          file: file,
          title: t('public.Edit Collection Picture'),
        });
      }

      this.inputProfileFileRef.value = '';
    }
  }

  
  resetFields(setFieldValue, fields) {
    fields.forEach((field) => {
      setFieldValue(field, null);
    });
  }

  setVerticalPicture(blob, url, setFieldValue) {
    setFieldValue('verticalFeatureImageUrl', url);
    setFieldValue('verticalFeatureImage', blob);
  }

  setHorizontalPicture(blob, url, setFieldValue) {
    setFieldValue('horizontalFeatureImageUrl', url);
    setFieldValue('horizontalFeatureImage', blob);
  }

  handleFileChange({ e, setFieldValue, setPictureCallback, fileRef, imageDimensions }) {
    const file = e.target.files[0];
    console.log(e, setFieldValue, setPictureCallback)
    if (file) {
      if (file.type === 'image/gif') {
        const url = URL.createObjectURL(file);
        setPictureCallback(file, url, setFieldValue);
      } else {
        this.props.openModal('EditProfilePictureModal', {
          backdrop: true,
          getPicture: (blob, url) => setPictureCallback(blob, url, setFieldValue),
          file: file,
          title: this.props.t('modals.collectionCoverEditImage'),
          ...imageDimensions,
        });
      }

      fileRef.value = '';
    }
  }

  coverInputChange(e) {
    const { t } = this.props;
    const file = e.target.files[0];

    if (file) {
      if (file.type === 'image/gif') {
        const url = URL.createObjectURL(file);
        this.getCoverPicture(file, url);
      } else {
        this.props.openModal('EditProfilePictureModal', {
          backdrop: true,
          getPicture: (blob, url) => this.getCoverPicture(blob, url),
          file: file,
          title: t('public.Edit Collection Cover Picture'),
          borderRadius: 4,
          heightRatio: 3.57,
          width: 1830,
        });
      }

      this.inputCoverFileRef.value = '';
    }
  }

  @bind
  getProfilePicture(blob, url) {
    this.formikRef.current.setFieldValue('profileImageUrl', url);
    this.formikRef.current.setFieldValue('profileImage', blob);
  }

  @bind
  getCoverPicture(blob, url) {
    this.formikRef.current.setFieldValue('coverImageUrl', url);
    this.formikRef.current.setFieldValue('coverImage', blob);
  }

  addPrefixWebsite = (values) => {
    if (values['website']?.length && !values['website'].startsWith('https://')) {
      values['website'] = 'https://' + values['website'];
    }

    return values;
  };

  checkWebsiteLink = (link) => {
    if (link?.length && link.startsWith('https://')) {
      link = link.replace('https://', '');
    }

    return link;
  };

  render() {
    const {
      backdrop,
      closeOnOutsideClick,
      onOutsideClick,
      fullscreen,
      allDetailedCollections,
      activeProfileCollectionId,
      t,
    } = this.props;
    let collection = allDetailedCollections[activeProfileCollectionId];
    let submitEditProfile;

    return (
      <>
        <Modal
          backdrop={backdrop}
          closeOnOutsideClick={closeOnOutsideClick}
          fullscreen={fullscreen}
          title={t('modals.editCollection')}
          onOutsideClick={onOutsideClick}>
          <Formik
            initialValues={{
              Name: collection?.name || '',
              Symbol: collection?.symbol || '',
              coverImageUrl: collection?.coverImageUrl || null,
              coverImage: collection?.coverImageUrl || null,
              profileImageUrl: collection?.profileImageUrl || null,
              profileImage: collection?.profileImageUrl || null,
              about: collection?.about?.text ? collection.about : { text: '', annotations: [] },
              slug: collection?.slug || undefined,
              website: collection?.website || undefined,
              linkedin: collection?.linkedin || undefined,
              instagram: collection?.instagram || undefined,
              youTube: collection?.youTube || undefined,
              facebook: collection?.facebook || undefined,
              twitter: collection?.twitter || undefined,
              twitch: collection?.twitch || undefined,
              tikTok: collection?.tikTok || undefined,
              snapchat: collection?.snapchat || undefined,
              discord: collection?.discord || undefined,
              horizontalFeatureImage: collection?.horizontalFeatureImage || null,
              horizontalFeatureImageUrl: collection?.horizontalFeatureImageUrl || null,
              verticalFeatureImage: collection?.verticalFeatureImage || null,
              verticalFeatureImageUrl: collection?.verticalFeatureImageUrl || null,
            }}
            innerRef={this.formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={AppVariables.validationSchemas.editCollectionSchema}
            onSubmit={this.submitEditProfile}>
            {(formikProps) => {
              submitEditProfile = formikProps.submitForm;
              return (
                <form
                  className="form edit-collection-modal d-flex flex-wrap mx-2 my-2"
                  onSubmit={formikProps.handleSubmit}>
                  <div className="all-content">
                    <div className="images-container">
                      <div className="cover-img-container mb-3 mr-2" style={generateGradientBackground(collection?.id)}>
                        {formikProps.values.coverImageUrl && (
                          <img src={formikProps.values.coverImageUrl || ''} width={'100%'} height={'100%'} />
                        )}
                        <div
                          className="shadow"
                          onClick={() => {
                            this.inputCoverFileRef.click();
                          }}>
                          <Icon src="fas fa-camera" type="fontawesome" />
                        </div>
                      </div>
                      <div className="img-container mb-3 mt-2 mr-2">
                        <ProfileAvatar
                          alt=""
                          height={118}
                          imageLink={formikProps.values.profileImageUrl || Placeholder}
                          width={118}
                        />
                        <div
                          className="shadow"
                          onClick={() => {
                            this.inputProfileFileRef.click();
                          }}>
                          <Icon src="fas fa-camera" type="fontawesome" />
                        </div>
                      </div>
                      <input
                        ref={(x) => (this.inputCoverFileRef = x)}
                        accept="image/png, image/jpeg, image/gif"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={(e) => {
                          this.coverInputChange(e);
                        }}
                      />
                      <input
                        ref={(x) => (this.inputProfileFileRef = x)}
                        accept="image/png, image/jpeg, image/gif"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={(e) => {
                          this.profileInputChange(e);
                        }}
                      />
                    </div>
                    <div className="image-error">{t(formikProps.errors.profileImageUrl)}</div>
                    <div className="image-error">{t(formikProps.errors.coverImageUrl)}</div>
                    <div className="image-error">{t(formikProps.errors.horizontalFeatureImage)}</div>
                    <div className="image-error">{t(formikProps.errors.verticalFeatureImageUrl)}</div>
                    <div className="w-100 d-flex align-center">
                      <Input.Text
                        className="mb-3 w-100"
                        error={t(formikProps.errors.Name)}
                        label={t('modals.displayName')}
                        name="Name"
                        placeholder={t('modals.displayName')}
                        value={formikProps.values.Name}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        readOnly
                      />
                    </div>
                    <div className="w-100 d-flex align-center">
                      <Input.Text
                        className="mb-3 w-100"
                        error={t(formikProps.errors.Symbol)}
                        label={t('modals.symbol')}
                        name="Symbol"
                        placeholder={t('modals.symbol')}
                        value={formikProps.values.Symbol}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        readOnly
                      />
                    </div>
                    <Input.AnnotationEditor
                      stopFocus
                      className="w-100 mb-3"
                      error={t(formikProps.errors.about)}
                      label={t('modals.description')}
                      name="about"
                      setFieldTouched={formikProps.setFieldTouched}
                      setFieldValue={formikProps.setFieldValue}
                      supportWhitespace={true}
                      value={formikProps.values.about}
                      onChange={formikProps.handleChange}
                      placeholder="All humans are excited about their #spaceship clubs"
                    />
                    <Input.Text
                      className="mb-3 w-100"
                      label={t('modals.shortUrl')}
                      name="slug"
                      placeholder={t('modals.slug')}
                      error={t(formikProps.errors.slug)}
                      info={{
                        component: <img className="oilogo" src={oiLogo} />,
                        text: 'OIX',
                        extra: '/collection/',
                      }}
                      suffix={
                        this.state.initializeChecking ? (
                          this.state.checkingSlug ? (
                            <OixSpinner backgroundColor={'#2E3146'} size={16} thick={0.7} />
                          ) : this.state.slugAvailable ? (
                            <Icon src="fas fa-check-circle" style={{ color: '#00DCAF' }} type="fontawesome" />
                          ) : (
                            <Icon src="fas fa-exclamation-circle" style={{ color: '#FF0000' }} type="fontawesome" />
                          )
                        ) : null
                      }
                      value={formikProps.values.slug}
                      onBlur={formikProps.handleBlur}
                      onChange={(e) => this.checkSlug(e.target.value, collection?.slug)}
                    />
                        <OiLabel>{t('public.featureImages')}</OiLabel>

                        <div className="oi-collection-create__featureImages">
                        <div className="oi-collection-create__featureImage oi-collection-create__featureImage--horizontal mb-3 mr-2">
                          {formikProps.values.horizontalFeatureImageUrl && (
                            <img src={formikProps.values.horizontalFeatureImageUrl || ''} width={'100%'} height={'100%'} />
                          )}
                          <div className="oi-collection-create__featureImageShadow">
                            <div className="oi-collection-create__featuredImageActions">
                              <div
                                className="oi-collection-create__iconContainer"
                                onClick={() => {
                                  this.featuredHorizontalFileRef.click();
                                }}>
                                <Icon src="fas fa-camera" type="fontawesome" />
                              </div>
                              {formikProps.values.horizontalFeatureImageUrl && (
                                <div
                                  className="oi-collection-create__iconContainer"
                                  onClick={() =>
                                    this.resetFields(formikProps.setFieldValue, ['horizontalFeatureImageUrl', 'horizontalFeatureImage'])
                                  }>
                                  <Icon src="fas fa-trash" type="fontawesome" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="oi-collection-create__featureImage oi-collection-create__featureImage--vertical mb-3 mr-2">
                          {formikProps.values.verticalFeatureImageUrl && (
                            <img src={formikProps.values.verticalFeatureImageUrl || ''} width={'100%'} height={'100%'} />
                          )}
                          <div className="oi-collection-create__featureImageShadow">
                            <div className="oi-collection-create__featuredImageActions oi-collection-create__featuredImageActions--vertical">
                              <div
                                className="oi-collection-create__iconContainer"
                                onClick={() => {
                                  this.featuredVerticalFileRef.click();
                                }}>
                                <Icon src="fas fa-camera" type="fontawesome" />
                              </div>
                              {formikProps.values.verticalFeatureImageUrl && (
                                <div
                                  className="oi-collection-create__iconContainer"
                                  onClick={() =>
                                    this.resetFields(formikProps.setFieldValue, ['verticalFeatureImageUrl', 'verticalFeatureImage'])
                                  }>
                                  <Icon src="fas fa-trash" type="fontawesome" />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <input
                        ref={x => this.featuredHorizontalFileRef = x}
                        accept="image/png, image/jpeg, image/gif"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={(e) => {
                          this.handleFileChange({
                            e,
                            setFieldValue: formikProps.setFieldValue,
                            fileRef: this.featuredHorizontalFileRef,
                            setPictureCallback: this.setHorizontalPicture,
                            imageDimensions: { borderRadius: 4, heightRatio: 1.77, width: 160 },
                          });
                        }}
                      />
                      <input
                        ref={x => this.featuredVerticalFileRef = x}
                        accept="image/png, image/jpeg, image/gif"
                        style={{ display: 'none' }}
                        type="file"
                        onChange={(e) => {
                          this.handleFileChange({
                            e,
                            setFieldValue: formikProps.setFieldValue,
                            fileRef: this.featuredVerticalFileRef,
                            setPictureCallback: this.setVerticalPicture,
                            imageDimensions: { borderRadius: 4, heightRatio: 0.56, width: 160 },
                          });
                        }}
                      />
                    <hr className="mt-3 mb-2" />
                    <div className="w-100 social-text-container mt-3">
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.website)}
                          info={{
                            icon: { src: 'fas fa-globe' },
                            text: 'Website',
                            extra: 'https://',
                          }}
                          name="website"
                          placeholder="Web Site"
                          value={this.checkWebsiteLink(formikProps.values.website)}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.discord)}
                          info={{
                            icon: { src: 'fab fa-discord' },
                            text: 'Discord',
                          }}
                          name="discord"
                          placeholder="Include #Code"
                          value={formikProps.values.discord}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.youTube)}
                          info={{
                            icon: { src: 'fab fa-youtube' },
                            text: 'Youtube',
                          }}
                          name="youTube"
                          placeholder="Channel URL"
                          value={formikProps.values.youTube}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.instagram)}
                          info={{
                            icon: { src: 'fab fa-instagram' },
                            text: 'Instagram',
                          }}
                          name="instagram"
                          placeholder="Username"
                          value={formikProps.values.instagram}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.twitter)}
                          info={{
                            icon: { src: 'fab fa-twitter' },
                            text: 'Twitter',
                          }}
                          name="twitter"
                          placeholder="Username"
                          value={formikProps.values.twitter}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.facebook)}
                          info={{
                            icon: { src: 'fab fa-facebook' },
                            text: 'Facebook',
                            extra: 'facebook.com/',
                          }}
                          name="facebook"
                          placeholder="Username"
                          value={formikProps.values.facebook}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <Input.Text
                        className="mb-3 w-100 pr-1"
                        error={t(formikProps.errors.twitch)}
                        info={{
                          icon: { src: 'fab fa-twitch' },
                          text: 'Twitch',
                          extra: 'twitch.tv/',
                        }}
                        name="twitch"
                        placeholder="Username"
                        value={formikProps.values.twitch}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                      <Input.Text
                        className="mb-3 w-100 pr-1"
                        error={t(formikProps.errors.tikTok)}
                        info={{
                          icon: { src: 'fab fa-tiktok' },
                          text: 'TikTok',
                          extra: 'tiktok.com/',
                        }}
                        name="tikTok"
                        placeholder="Username"
                        value={formikProps.values.tikTok}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div className="footer mx-2">
            <Button
              fullSize
              disabled={!this.state.slugAvailable || this.state.checkingSlug}
              text={t('modals.saveChangesButton')}
              type="primary"
              onClick={() => submitEditProfile()}
            />
          </div>
        </Modal>
      </>
    );
  }
}
const component = connect(
  (state) => {
    return {
      ...state.profile,
      ...state.service,
      ...state.onboarding,
      ...state.wallet,
      ...state.user.account,
      ...state.auth,
      ...state.cache,
    };
  },
  {
    ...ServiceActions,
    ...AccountActions,
    ...UserActions,
    ...ModalActions,
    ...ToastActions,
    ...SpinnerActions,
    ...WalletActions,
    ...CollectionActions,
  },
  null,
  { forwardRef: true },
)(EditCollectionModal);
export default withRouter(withWallet(withTranslation()(component)));
