import * as UserActionTypes from "@Actions/UserActions";

const initialState = () => ({
  fetchingNotificationPreferences: false,
  notificationPreferencesData: null,
  fetchingNotificationPreferencesError: null,

  updatingEmailNotificationPreferences: false,
  emailNotificationPreferencesUpdated: null,
  updatingEmailNotificationPreferencesError: null,

  updatingPushNotificationPreferences: false,
  pushNotificationPreferencesUpdated: null,
  updatingPushNotificationPreferencesError: null,
});

export default (state = initialState(), action) => {

  switch (action.type) {
    case UserActionTypes.UPDATE_SMS_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        updatingPushNotificationPreferences: true
      }
    case UserActionTypes.UPDATE_SMS_NOTIFICATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        updatingPushNotificationPreferences: false,
        pushNotificationPreferencesUpdated: action.payload
      }
    case UserActionTypes.UPDATE_SMS_NOTIFICATION_PREFERENCES_FAILURE:
      return {
        ...state,
        updatingPushNotificationPreferences: false,
        updatingPushNotificationPreferencesError: action.error
      }
    case UserActionTypes.UPDATE_EMAIL_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        updatingEmailNotificationPreferences: true
      }
    case UserActionTypes.UPDATE_EMAIL_NOTIFICATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        updatingEmailNotificationPreferences: false,
        emailNotificationPreferencesUpdated: action.payload
      }
    case UserActionTypes.UPDATE_EMAIL_NOTIFICATION_PREFERENCES_FAILURE:
      return {
        ...state,
        updatingEmailNotificationPreferences: false,
        updatingEmailNotificationPreferencesError: action.error
      }
    case UserActionTypes.GET_NOTIFICATION_PREFERENCES:
      return {
        ...state,
        fetchingNotificationPreferences: true
      }
    case UserActionTypes.GET_NOTIFICATION_PREFERENCES_SUCCESS:
      return {
        ...state,
        fetchingNotificationPreferences: false,
        notificationPreferencesData: action.payload
      }
    case UserActionTypes.GET_NOTIFICATION_PREFERENCES_FAILURE:
      return {
        ...state,
        fetchingNotificationPreferences: false,
        fetchingNotificationPreferencesError: action.error
      }
    default:
      return state;
  }
};