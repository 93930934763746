import * as React from "react";
import Modal from "@Components/modal/Modal";
import Button from "@Components/form/Button";
import Input from "@Components/form/Input";
import ProfileAvatar from "@Components/profile/ProfileAvatar";
import Icon from "@Components/shared/Icon";
import { withTranslation } from "react-i18next";


class InviteSocialAccountModal extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            tab: 1
        }
    }
   
    render() {
        const { backdrop, onOutsideClick, accountName, t } = this.props;
        return (
            <Modal
                title={t('modals.inviteSocialModalTitle', { accountName })}
                subtext={t('modals.inviteSocialModalSubtitle')}
                backdrop={backdrop}
                onOutsideClick={onOutsideClick}
            >
                <div className="invite-social-account-modal mx-2 ">
                    <div className="social-account-container mx-1 ">
                        <Input.Text
                            className="w-100 my-2"
                            name={t('modals.inputLabelSearch')}
                            placeholder={t('modals.inputPlaceholderInviteFriend')}
                            ref={x => this.SearchRef = x}
                            onChange={() => { this.search() }}
                            icon={{
                                src: "fas fa-search",
                                color: "#707070"
                            }}
                        />
                        <hr />
                        <div className="tab-container my-3">
                            <div className="d-flex">
                                <div onClick={() => this.setState({ tab: 1 })} style={{ color: this.state.tab == 1 ? "#00B7FF" : "white" }} className="mr-3">{t('modals.allContacts')}</div>
                                <div onClick={() => this.setState({ tab: 2 })} style={{ color: this.state.tab == 2 ? "#00B7FF" : "white" }}>{t('modals.invitedContacts')}</div>
                            </div>
                            <div className="text-small">254 contacts</div>
                        </div>
                        <div className="friend-list-container">
                            <hr />
                            <div className="collectible-item">
                                <div className="cell-1">
                                    <div className="img-container">
                                        <ProfileAvatar width={60} height={60} />
                                    </div>
                                    <div className="ml-2">
                                        <div className="title">dsadasd</div>
                                        <div className="subtext">dsadadas</div>
                                    </div>
                                </div>
                                <div className="cell-2">
                                    <div className="icon-container">
                                        < Icon onClick={() => {}} style={{ color: "grey" }} className="mr-1" type="fontawesome" src="far fa-circle" />
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <div className="collectible-item">
                                <div className="cell-1">
                                    <div className="img-container">
                                        <ProfileAvatar width={60} height={60} />
                                    </div>
                                    <div className="ml-2">
                                        <div className="title">dasdasdas</div>
                                        <div className="subtext">saddadasdasda</div>
                                    </div>
                                </div>
                                <div className="cell-2">
                                    <div className="icon-container">
                                        < Icon onClick={() => {}} style={{ color: "#00B7FF" }} className="mr-1" type="fontawesome" src="far fa-check-circle" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer mx-2 mt-1">
                    <div> <a> {t('modals.switchGoogleAccount')} </a> </div>
                    <Button className="w-30" text="Send Invatations" type="primary" onClick={() => { this.props.onChangeAddedCollectibles(this.state.addedCollectibles); this.props.closeModal(); }} />
                </div >
            </Modal >

        );
    }
}

export default withTranslation()(InviteSocialAccountModal);