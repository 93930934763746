import AppVariables from '@AppVariables';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import Input from '@Components/form/Input';
import ChainIcon from '@Components/shared/ChainIcon';
import { runtimeConfig } from 'config';
import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { FaChevronDown, FaCheckSquare } from 'react-icons/fa';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';

const checkboxIcon = (
  <div style={{ width: '20px', height: '20px', border: '1px solid #ffffff80', borderRadius: '5px' }} />
);
const checkboxCheckedIcon = (
  <div>
    <FaCheckSquare />
  </div>
);

export const BlockchainFilter = ({ isMobile }) => {
  const acceptedChains = JSON.parse(runtimeConfig.acceptedChains);
  const chains = AppVariables.chains.filter((chain) => acceptedChains.includes(chain.id));

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const blochainDropdownRef = useRef();
  const [selectedChain, setSelectedChain] = useState({});
  const [isOpenBlockchain, setIsOpenBlockchain] = useState(false);

  const openDropdown = () => {
    isMobile && setIsOpenBlockchain((prevState) => !prevState);
    !isMobile && blochainDropdownRef.current?.open();
  };

  const handleSelectChain = (chainId) => (e) => {
    setSelectedChain((prevState) => ({ ...prevState, [chainId]: e.target.checked }));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedChainKeys = [];
    Object.entries(selectedChain).forEach(([key, value]) => value && selectedChainKeys.push(key));

    if (selectedChainKeys.length) {
      params.set('chains', selectedChainKeys.join(','));
      const totalParams = params.toString().length ? '?' + params.toString() : '';
      history.push(location.pathname + totalParams);
    } else {
      params.delete('chains');
      const totalParams = params.toString().length ? '?' + params.toString() : '';
      history.push(location.pathname + totalParams)
    }
  }, [selectedChain]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const chainFilter = params.get('chains');

    if (chainFilter) {
      const decodedBlockchainStr = decodeURIComponent(chainFilter);
      const decodedBlockchainIds = decodedBlockchainStr.split(',');

      const _selectedBlockchain = decodedBlockchainIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      setSelectedChain(_selectedBlockchain);
    }
  }, []);

  return (
    <div className={cn('blockchain-filter-container', isMobile && 'mobile')} id="blockchain-filter">
      <div className="blockchain-filter" onClick={openDropdown}>
        <div>{t('public.searchFilterBlockchainText')}</div>
        <FaChevronDown className="ml-1" />
      </div>
      {isMobile && isOpenBlockchain && (
        <div className="chain-list">
          {chains.map((chain) => (
            <div key={chain.id} className="chain">
              <div className={cn('chain-icon-container', chain.icon)}>
                <ChainIcon icon={chain.icon} />
              </div>
              <div className="chain-name"> {chain.name} </div>
              <Input.CheckBox
                checkedIcon={checkboxCheckedIcon}
                icon={checkboxIcon}
                value={selectedChain[chain.id]}
                onChange={handleSelectChain(chain.id)}
              />
            </div>
          ))}
        </div>
      )}
      {!isMobile && (
        <ContextDropdown
          ref={blochainDropdownRef}
          anchorEl="blockchain-filter"
          className="blockchain-filter-popover"
          origin="left"
          transformOriginH="left"
          transformOriginV="top">
          {chains.map((chain) => (
            <div key={chain.id} className="chain">
              <div className={cn('chain-icon-container', chain.icon)}>
                <ChainIcon icon={chain.icon} />
              </div>
              <div className="chain-name"> {chain.name} </div>
              <Input.CheckBox
                checkedIcon={checkboxCheckedIcon}
                icon={checkboxIcon}
                value={selectedChain[chain.id]}
                onChange={handleSelectChain(chain.id)}
              />
            </div>
          ))}
        </ContextDropdown>
      )}
    </div>
  );
};
