import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import { UserActions } from '@Actions/UserActions';
import { ModalActions } from '@Actions/ModalActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { withTranslation } from 'react-i18next';

class UnblockWarningModal extends React.Component {
  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    return (
      <Modal
        title={'Unblock @' + this.props.profileAccount.slug + '?'}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}>
        <div className="mx-2 ">
          <div className="mx-1 ">
            <div  className="info-container my-3 text-align-left">
              <div>
                {t('modals.unblockWarningText')}
                <Link to="">{t('modals.learnMoreLink')}</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div> </div>
          <Button
            text={t('modals.unblockButton')}
            fullSize
            type="primary"
            onClick={() => {
              this.props.callback();
            }}
          />
        </div>
      </Modal>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.auth, ...state.user, ...state.account };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions },
  null,
  { forwardRef: true },
)(withTranslation()(UnblockWarningModal));
export default withRouter(component);
