import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import { UserActions } from '@Actions/UserActions';
import { Formik } from 'formik';
import AppVariables from '@AppVariables';
import { ModalActions } from '@Actions/ModalActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
class AccountDeleteModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  submitRequestAccountDeleteForm(values, action) {
    this.props.enableLoader();
    this.props.requestDeletePersonalData(values).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.closeModal();
        // Log out the user
        this.props.logout();
      } else {
        this.props.disableLoader();
        this.props.requestingAccountDeleteError?.response.data['_generic'].map((error) => {
          this.props.toastError(error, {});
        });
      }
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    let requestAccountDeleteForm;
    return (
      <Modal
        title={t('modals.deleteTitle')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="account-delete-modal mx-2 my-2">
          <div className="account-delete-container mt-2 mx-1 mb-2">
            <div className="title mb-2">{t('modals.enterPassword')}</div>
            <div className="text">{t('modals.confirmPassword')}</div>
            <div className="mb-1"> {t('modals.noteIrreversible')}</div>
            <div className="mb-1">
              <Trans i18nKey="modals.havingProblemContact">
                If you are having problems, please contact <Link to="#">player support</Link> for help deleting your
                account. 
              </Trans> 
            </div>
            <Formik
              initialValues={{ password: '' }}
              onSubmit={this.submitRequestAccountDeleteForm}
              validationSchema={AppVariables.validationSchemas.deleteAccountSchema}
            >
              {(formikProps) => {
                requestAccountDeleteForm = formikProps.submitForm;
                return (
                  <form className="form mt-1" onSubmit={formikProps.handleSubmit}>
                    <Input.Password
                      label="Password"
                      placeholder=""
                      className="w-100"
                      name="password"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.password}
                      error={formikProps.touched.password && t(formikProps.errors.password)}
                    />
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="footer mx-2 mt-1 ">
          <div></div>
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.confirmDeleteButton')}
              type="primary"
              fullSize
              onClick={() => requestAccountDeleteForm()}
              disabled={this.props.requestingAccountDelete}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.auth, ...state.user, ...state.account };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(AccountDeleteModal);
export default withRouter(withTranslation()(component));
