import React from 'react';

import PropTypes from 'prop-types';

const OiModalBackdrop = (props) => <div className="oi-modal-backdrop" {...props} />;

OiModalBackdrop.propTypes = {
  props: PropTypes.object,
};

export default OiModalBackdrop;
