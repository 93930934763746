import axios from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';

import { isNode } from "@Utils";

import { runtimeConfig } from '../config';
import { UserActions } from "@Actions/UserActions";
import { ModalActions } from "@Actions/ModalActions";

const oixApiClient =
{
  client: axios.create({ // all axios can be used, shown in axios documentation
    baseURL: runtimeConfig.apiPath,
    responseType: 'json',
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    validateStatus(status) {
      return status >= 200 && status < 300;
    },
    //    withCredentials: true // this is a fix for Firefox to develop on localhost... grrr
  }),
  options: {
    // We would like to return the rejected promise on error but the components can be unmounting
    // before the catch is handled, causing other errors.
    // If not handled, we can get unhandled exceptions as well.
    // Right now, it will still succeed in case of an error
    // This will force us to switch to the utilizing reducers properly, so keeping it.
    //    returnRejectedPromiseOnError: true,
    errorSuffix: '_FAILURE',
    interceptors: {
      request: [{
        success: function ({ getState, dispatch, getSourceAction }, request) {

          // let sourceAction = getSourceAction(request);

          return new Promise((resolve, reject) => {

              // console.log(request); //contains information about request object

              const state = getState();
              const tokens = state.auth.user?.tokens;
              const profile = state.auth.user?.profile;

              const authenticated = tokens && profile && Date.now() < tokens.expires_at;

              // if current time > token.expires_at... refresh the token

              if (isNode()) {
                // Make SSR requests 'authorized' from the NodeServices to the web server.
              }
              else if (authenticated) {
                const accessToken = tokens.access_token;
                const activeUserAccount = profile.default_account_id;

                request.headers.Authorization = `Bearer ${accessToken}`;

                if (activeUserAccount !== 0) {
                  request.headers['X-ORDERINBOX-ACCOUNT-ID'] = `${activeUserAccount}`;
                }
              }

              resolve(request);
          })
        },
        error: function ({ getState, dispatch, getSourceAction }, err) {
          console.log(err); //contains information about error object
          //                // We cannot extract the response from this directly as the middleware treats this return value
          //                // as an axios fatal error if there isn't a response object included.
          return err;
        }
      }],
      response: [{
        success: function ({ getState, dispatch, getSourceAction }, response) {
          //                console.log(response); //contains information about response object

          //                    console.log(response);

          return Promise.resolve(response);
        },
        error: function ({ getState, dispatch, getSourceAction }, err) {

          let sourceAction = getSourceAction(err.config);

          if (err.response?.status === 401 && !sourceAction._retry) {
          
            return new Promise((resolve, reject) => {

              const state = getState();
              const tokens = state.auth.user?.tokens || null;
              
              if (!tokens) {
                ModalActions.openModal('ConnectWalletModal', {
                  closeOnOutsideClick: true,
                  onCloseButtonClick: () => {
                    ModalActions.closeModal()(dispatch, getState);
                  },
                })(dispatch, getState);

                // We should return the end result of the modal as a promise and return this reject accordingly
                // Right now we are just showing the connect modal and rejecting the promise regardless. 
                // So the user has a chance to stay where they are but at least shown the connect modal
                // to indicate that the action they are looking to do needs authentication
                reject(err);
         
              }     
              else if(Date.now() > tokens.expires_at) { // token exists, but expired

                // then refresh
                UserActions.refreshTokens()(dispatch, getState).then((tokenResult) => {
//                                console.log(tokenResult);
  
                  // Now re-send the original action as a retry 
                  dispatch({
                    ...sourceAction,
                    _retry: true
                  }).then(sourceRes => {
                    if (sourceRes.error) {
                      console.log(sourceRes.error);
                      reject(sourceRes.error);
                    }

                    if (sourceRes.payload) {
                      resolve(sourceRes.payload);
                    }
  
                  }).catch(err => {
                    console.log(err);
  
                    reject(err);
                  });
  
                }).catch(error => {
                  console.log(error);
  
                  // We need to log the user out.
                  UserActions.logout()(dispatch, getState).then(() => {
                    reject(error);
                  });
                });                
              }
              else {
                reject(err);
              }

            });

          }

          //                // We cannot extract the response from this directly as the middleware treats this return value
          //                // as an axios fatal error if there isn't a response object included.
          return Promise.reject(err);
        }
      }]
    }
  },
};

const pinataApiClient = {
  client: axios.create({ // all axios can be used, shown in axios documentation
    baseURL: 'https://api.pinata.cloud/',
    responseType: 'json',
    //        maxContentLength: Infinity,
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    validateStatus(status) {
      return status >= 200 && status < 300;
    },
  })
};

const CoinGeckoApiClient = {
  client: axios.create({ // all axios can be used, shown in axios documentation
    baseURL: 'https://api.coingecko.com/api/v3/',
    responseType: 'json',
    validateStatus(status) {
      return status >= 200 && status < 300;
    },
  })
};

const selfClient = {
  client: axios.create({ 
    responseType: 'json',
    validateStatus(status) {
      return status >= 200 && status < 300;
    },
  })
};

const multiClients = {
  default: oixApiClient,
  pinata: pinataApiClient,
  coinGecko: CoinGeckoApiClient,
  selfClient
};

const multiClientGlobalOptions =
{
  // We would like to return the rejected promise on error but the components can be unmounting
  // before the catch is handled, causing other errors.
  // If not handled, we can get unhandled exceptions as well.
  // Right now, it will still succeed in case of an error
  // This will force us to switch to the utilizing reducers properly, so keeping it.
  //    returnRejectedPromiseOnError: true,
  errorSuffix: '_FAILURE',
  interceptors: {
    request: [{
      success: function ({}, request) {
        return Promise.resolve(request);
      }
    }],
    response: [{
      success: function ({}, response) {
        return Promise.resolve(response.data);
      }
    }]
  }
}

export const apiMiddleware = multiClientMiddleware(multiClients, multiClientGlobalOptions);
