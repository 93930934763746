import * as ServiceActionTypes from "@Actions/ServiceActions";

const initialState = () => ({
  sendingFeedback: false,
  feedbackSent: false,
  sendFeedbackError: null
});

export default (state = initialState(), action) => {

  switch (action.type) {
    case ServiceActionTypes.SEND_FEEDBACK:
      return {
        ...state,
        sendingFeedback: true,
      };
    case ServiceActionTypes.SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackSent: true,
        sendingFeedback: false,
      };
    case ServiceActionTypes.SEND_FEEDBACK_FAILURE:
      return {
        ...state,
        sendFeedbackError: action.error,
        sendingFeedback: false,
      };
    default:
      return state;
  }
};