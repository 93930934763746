import * as React from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ModalActions } from '@Actions/ModalActions';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { HashtagActions } from '@Actions/HashtagActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { AccountActions } from '@Actions/AccountActions';
import { SearchActions } from '@Actions/SearchActions';
import AppRoute from '@Components/shared/AppRoute';
import SearchCreators from './SearchCreators';
import SearchCollections from './SearchCollections';
import SearchAll from './SearchAll';
import SearchLayout from './SearchLayout';
import withRouterAndRef from '@Components/hoc/withRouterAndRef';
import SearchHashtags from './SearchHashtags';


const SearchLiveAuctions = withRouterAndRef((props) => (
  <SearchAll liveAuctions {...props}/>
));

const SearchOnSale = withRouterAndRef((props) => (
  <SearchAll onSale {...props}/>
));


class SearchPage extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>Search</title>
        </Helmet>

        <Switch>
          <AppRoute
            exact
            component={SearchAll}
            layout={SearchLayout}
            layoutProps={{ ...this.props }}
            path={['/search/creations', '/hashtag/:tag']}
          />
          <AppRoute
            exact
            component={SearchLiveAuctions}
            layout={SearchLayout}
            layoutProps={{ ...this.props }}
            path={['/search/live-auctions']}
          />
          <AppRoute
            exact
            component={SearchOnSale}
            layout={SearchLayout}
            layoutProps={{ ...this.props }}
            path={['/search/on-sale']}
          />
          <AppRoute
            exact
            component={SearchCreators}
            layout={SearchLayout}
            layoutProps={{ ...this.props }}
            path={'/search/creators'}
          />
          <AppRoute
            exact
            component={SearchCollections}
            layout={SearchLayout}
            layoutProps={{ ...this.props }}
            path={'/search/collections'}
          />
           <AppRoute
            exact
            component={SearchHashtags}
            layout={SearchLayout}
            layoutProps={{ ...this.props }}
            path={'/search/hashtags'}
          />
          {/* <Redirect to="/discover" /> */}
        </Switch>
      </>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...SearchActions,
  ...ModalActions,
  ...SpinnerActions,
  ...CollectibleActions,
  ...ServiceActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.search,
  ...state.service,
  ...state.user.account,
});

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchPage);
