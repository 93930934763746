import * as AccountActionTypes from "@Actions/AccountActions";

const initialState = () => ({
  addingAccountInterests: false,
  addAccountInterests: null,
  addingAccountInterestsError: null,

  removingAccountInterests: false,
  removeAccountInterests: null,
  removingAccountInterestsError: null    
});

export default (state = initialState(), action) => {

  switch (action.type) {
    case AccountActionTypes.ADD_ACCOUNT_INTERESTS:
      return {
        ...state,
        addingAccountInterests: true,
      };
    case AccountActionTypes.ADD_ACCOUNT_INTERESTS_SUCCESS:
      return {
        ...state,
        addAccountInterests: action.payload,
        addingAccountInterests: false,
      };
    case AccountActionTypes.ADD_ACCOUNT_INTERESTS_FAILURE:
      return {
        ...state,
        addingAccountInterestsError: action.error,
        addingAccountInterests: false,
      };
    case AccountActionTypes.REMOVE_ACCOUNT_INTERESTS:
      return {
        ...state,
        removingAccountInterests: true,
      };
    case AccountActionTypes.REMOVE_ACCOUNT_INTERESTS_SUCCESS:
      return {
        ...state,
        removeAccountInterests: action.payload,
        removingAccountInterests: false,
      };
    case AccountActionTypes.REMOVE_ACCOUNT_INTERESTS_FAILURE:
      return {
        ...state,
        removingAccountInterestsError: action.error,
        removingAccountInterests: false,
      };
    default:
      return state;
  }
};