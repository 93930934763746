import React from 'react';

import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

const OiModalCloseButton = ({ onClick }) => (
  <div className="oi-modal-close-button" onClick={onClick}>
    <FaTimes />
  </div>
);

OiModalCloseButton.propTypes = {
  onClick: PropTypes.func,
};

export default OiModalCloseButton;
