// import public path init
import './init.client';

// Import polyfills.
import 'core-js/stable';
import 'regenerator-runtime/runtime';

import 'custom-event-polyfill';

// Import global styles.
import '@Styles/loaders/queryLoader.scss';

// Other imports.
import React from 'react';
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import configureStore from '@Store/configureStore';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { hasTouch, showApplicationLoader, hideApplicationLoader } from '@Utils';
import App from './App';
import { loadableReady } from '@loadable/component';
import { SENTRY_IGNORED_ERRORS } from '@Lib/constants';

// import i18n
import { useSSR } from 'react-i18next';
import './i18n';
import { Suspense } from 'react';

function DOMInteractive(callback) {
  if (document.readyState === 'interactive') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('readystatechange', (event) => {
      if (event.target.readyState === 'interactive') {
        callback();
      }
    });
  }
}

function DOMComplete(callback) {
  if (document.readyState === 'complete') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('readystatechange', (event) => {
      if (event.target.readyState === 'complete') {
        callback();
      }
    });
  }
}

function setupEvents() {
  DOMInteractive(() => {
    console.log('DOMInteractive');
    showApplicationLoader();
    //hideApplicationLoader();

    document.body.style.overflow = 'hidden';
  });

  DOMComplete(() => {
    console.log('DOMComplete');

    if (!hasTouch()) document.body.className += ' hasHover';

    hideApplicationLoader();
    document.body.style.overflow = null;
  });
}

function getBaseUrl() {
  var elements = document.getElementsByTagName('base');
  if (elements.length === 0) {
    return null;
  }
  return elements[0].getAttribute('href');
}

function getAuthState() {
  try {
    const tokens = JSON.parse(localStorage.getItem('state.auth.user.tokens')) || undefined;
    const profile = JSON.parse(localStorage.getItem('state.auth.user.profile')) || undefined;

    return {
      user: { tokens, profile },
    };
  } catch (err) {
    return undefined;
  }
}
function getModalState() {
  try {
    const zeroPercentViewed = localStorage.getItem('state.modal.zeroPercentViewed') || undefined;

    return {
      zeroPercentViewed,
    };
  } catch (err) {
    return undefined;
  }
}

function getWalletState() {
  try {
    const cachedWallet = JSON.parse(localStorage.getItem('state.wallet.cachedWallet')) || undefined;

    return {
      cachedWallet,
      cachedWalletExists: !!cachedWallet,
    };
  } catch (err) {
    return undefined;
  }
}

function setAuthState(state) {
  // Check if the state.auth.tokens and profile have changed?
  try {
    localStorage.setItem('state.auth.user.tokens', JSON.stringify((state.auth.user || {}).tokens));
    localStorage.setItem('state.auth.user.profile', JSON.stringify((state.auth.user || {}).profile));
    localStorage.setItem('state.wallet.cachedWallet', JSON.stringify((state.wallet || {}).cachedWallet));
  } catch (err) {
    return undefined;
  }
}

// Create browser history to use in the Redux store.
const baseUrl = getBaseUrl();
const history = createBrowserHistory({ basename: baseUrl });

Sentry.init({
  beforeSend: (event) => {
    // do not define locally, it's defined in the pipeline
    if (!process.env.RAZZLE_BUILD_NUMBER) {
      // we only do this in local
      console.log('Sentry event captured:', event);
      return null;
    }
    return event;
  },

  dsn: process.env.RAZZLE_PUBLIC_SENTRY_DSN,

  enabled: process.env.NODE_ENV !== 'development',
  environment: process.env.NODE_ENV, // isProdOrStaging
  ignoreErrors: SENTRY_IGNORED_ERRORS,

  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],

  // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/
  normalizeDepth: 10, // we can increase this if needed

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const initialState = () => ({
  ...window.__PRELOADED_STATE__,
  auth: {
    ...window.__PRELOADED_STATE__.auth,
    ...getAuthState(),
  },
  wallet: {
    ...window.__PRELOADED_STATE__.wallet,
    ...getWalletState(),
  },
  modal: {
    ...window.__PRELOADED_STATE__.modal,
    ...getModalState(),
  },
});

const store = configureStore(history, initialState());

delete window.__PRELOADED_STATE__;

// store.subscribe(() => {
//     setAuthState(store.getState());
// });

const BaseApp = () => {
  useSSR(window.initialI18nStore, window.initialLanguage);
  return (
    <Suspense>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </Suspense>
  );
};

function renderApp() {
  // This code starts up the React app when it runs in a browser.
  // It sets up the routing configuration and injects the app into a DOM element.

  // If we have HMR enabled, we will disable the hydration
  // as it is causing mismatches between the server and the client
  // even when we have the server side HMR enabled by Razzle
  // Ideally this quick fix should be temporary as this will mismatch between
  // the development and production environments :(
//  const renderMethod = !!module.hot ? ReactDOM.render : ReactDOM.hydrateRoot; // if module is hot, use ReactDOM's render, else hydrate

  loadableReady(() => {
    const container = document.getElementById('root');

    const baseApp = <BaseApp />;

    if(!!module.hot) {
      const root = ReactDOM.createRoot(container);
      root.render(baseApp);
    }
    else {
      ReactDOM.hydrateRoot(container, baseApp)
    }

  });
}

// Setup the application and render it.
setupEvents();
renderApp();

// Allow Hot Module Replacement.
if (module.hot) {
  module.hot.accept('./App', () => {
    renderApp();
  });
}
