import React, { useState, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import OiInfoTooltip from '@Components/shared/OiInfoTooltip';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import ChainIcon from '@Components/shared/ChainIcon';
import { useHistory, useLocation } from 'react-router-dom';
import {
  generateGradientBackground,
  collectionStatuses,
  getCollectionStatus,
  buildCollectionLink,
  getCollectionChainInfo,
} from '@Utils';
import Icon from '@Components/shared/Icon';
import Placeholder from '@Images/collection-placeholder.svg';
import { withTranslation, Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CollectionIcon } from '@Images/icons/collection.svg?component';
import { useMountedState } from 'react-use';
import AppVariables from '@AppVariables';
import { captureMessage } from '@sentry/react';
import useInterval from '@Hooks/use-interval';
import OixSpinner from '@Components/shared/OixSpinner';
import { CollectionActions } from '@Actions/CollectionActions';

const CARD_IMAGE_CHECK_INTERVAL = 3000;

const CollectionCard = ({ collection }) => {
  const { account, profileImageUrl, name, slug, id, coverImageUrl, publicAddress } = collection;

  const { processingStatus } = AppVariables;
  const isProfileImageProcessing =
    collection &&
    collection.profileImageProcessingStatus !== processingStatus.notStarted &&
    collection.profileImageProcessingStatus !== processingStatus.completed;

  const isCoverImageProcessing =
    collection &&
    collection.coverImageProcessingStatus !== processingStatus.notStarted &&
    collection.coverImageProcessingStatus !== processingStatus.completed;

  const [delay, setDelay] = useState(
    isProfileImageProcessing || isCoverImageProcessing ? CARD_IMAGE_CHECK_INTERVAL : null,
  );

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const isMounted = useMountedState();
  const { t } = useTranslation();

  useEffect(() => {
    if (isProfileImageProcessing || isCoverImageProcessing) {
      setDelay(CARD_IMAGE_CHECK_INTERVAL);
    }
  }, [(isProfileImageProcessing || isCoverImageProcessing) && !delay]);

  useInterval(() => {
    console.log('interval');
    dispatch(CollectionActions.getCollection(collection.id))
      .then((result) => {
        if (result.error) {
          captureMessage(result.error, 'error');
          if (isMounted) setDelay(null);
          return;
        }

        if (!result.payload?.id) {
          throw new Error('No such collection was found');
        }

        const collectionScoped = result.payload;

        const isPreviewStillProcessing =
          collectionScoped.profileImageProcessingStatus !== processingStatus.notStarted &&
          collectionScoped.profileImageProcessingStatus !== processingStatus.completed;

        const isCoverStillProcessing =
          collectionScoped.coverImageProcessingStatus !== processingStatus.notStarted &&
          collectionScoped.coverImageProcessingStatus !== processingStatus.completed;

        if (!(isPreviewStillProcessing || isCoverStillProcessing)) {
          if (isMounted) {
            setDelay(null);
          }
        }
      })
      .catch((error) => {
        // we should not need any error handling here
        captureMessage(error, 'error');
        if (isMounted) {
          setDelay(null);
        }
      });
  }, delay);

  const handleCollectionRedirect = () => {
    let linkFeatures = {};
    let collectionLink = buildCollectionLink(collection, linkFeatures);

    history.push({
      pathname: collectionLink,
      state: {
        fromOix: true,
        background: linkFeatures.needsBackground ? location : null,
        unrestrictContent: collection.unrestrictContent,
      },
    });
  };

  const getStatus = (kind) => {
    switch (kind) {
      case collectionStatuses.DRAFT:
        return (
          <div className={'draft'}>
            <Icon className="mr-1" src={'fas fa-circle'} type="fontawesome" /> {t('public.draft')}
          </div>
        );
      case collectionStatuses.DEPLOYING:
        return (
          <div className={'deploying'}>
            <Icon className="mr-1" src={'fas fa-circle'} type="fontawesome" /> {t('public.deploying')}
          </div>
        );
    }
  };

  const goToLink = (link) => () => {
    history.push(link)
  }


  const { icon, variant, name: chainName } = getCollectionChainInfo(collection);
  const kind = getCollectionStatus(collection, account?.id)?.kind;
  let linkFeatures = {};
  let collectionLink = buildCollectionLink(collection, linkFeatures);
  let collectionLinkObj = {
    pathname: collectionLink,
    state: {
      fromOix: true,
      background: linkFeatures.needsBackground ? location : null,
      unrestrictContent: collection.unrestrictContent,
    },
  };
 
  return (
    <Link to={collectionLinkObj} className="collection-card">
      <div className="height-decider"></div>
      <div className="status">{getStatus(kind, collection)}</div>

      <div className="collection-card__cover-image-container" style={generateGradientBackground(id)}>
        {coverImageUrl && (
          <img width={'100%'} height={'100%'} className="collection-card__cover-image" src={coverImageUrl} />
        )}
      </div>

      <div className="collection-card__avatar-wrapper">
        {!isProfileImageProcessing ? (
          <ProfileAvatar
            imageLink={profileImageUrl ?? Placeholder}
            width={76}
            height={76}
          />
        ) : (
          <>
            <OixSpinner backgroundColor={'#2E3146'} size={74} thick={0.9} />
            <div className="processing-icon">
              <CollectionIcon width={26} height={26} />
            </div>
          </>
        )}
      </div>
      <div className="collection-card__description">
        <div className={`collection-card__name ${!name ? 'draft-name' : ''}`}>{name || t('public.title')}</div>
        <div className="collection-card__creator">
          {account?.slug && (
            <div className="collection-card__creator--slug"
              onClick={(e) => {
                e.preventDefault();
                history.push(`/${account?.slug}`);
              }}>
              {account?.profile?.name || account?.slug}
            </div>
          )}
        </div>
        <OiInfoTooltip title={chainName} placement="top">
          <div className={`collection-card__chain-icon collection-card__chain-icon--${variant}`}>
            <ChainIcon icon={icon} />
          </div>
        </OiInfoTooltip>
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  tempPlaceholder: state.user?.account?.account?.profile?.imageUrl,
});

const actions = {};

export default connect(mapStateToProps, actions)(withTranslation()(CollectionCard));
