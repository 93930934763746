import { Route } from 'react-router';
import * as React from 'react';

import '@Styles/styles.scss';

const AppRoute = ({ component: Component, layout: Layout, path: Path, layoutProps: LayoutProps, ...rest }) => {
  // Can do app-wide redirect or other other work based on authentication
  const layoutRef = React.createRef();
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout ref={layoutRef} {...LayoutProps}>
          <Component {...props} layoutRef={layoutRef} />
        </Layout>
      )}
    />
  );
};

export default AppRoute;
