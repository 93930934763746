import React, { useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';

import { FaInfoCircle } from 'react-icons/fa';
import withWindowDimensions from '@Components/hoc/withWindowDimensions';
import { ClickAwayListener } from '@material-ui/core';

const OiInfoTooltip = React.forwardRef(
  ({ placement, title, children, isMobileSized }, ref) => {
    /**
     * state
     */
    const [open, setOpen] = useState(false);

    /**
     * helpers
     */
    const openTooltip = () => setOpen(true);
    const closeTooltip = () => setOpen(false);

    const renderTooltip = (mobile = false) => {
      return (
        <Tooltip
          ref={ref}
          arrow
          classes={{ popper: 'oi-info-tooltip' }}
          disableFocusListener={mobile}
          disableHoverListener={mobile}
          disableTouchListener={mobile}
          open={open}
          placement={placement ? placement : 'bottom'}
          title={title || ''}
          onClose={() => closeTooltip()}
          onOpen={() => openTooltip()}>
          {children || (
            <div className="oi-info-tooltip__icon">
              <FaInfoCircle />
            </div>
          )}
        </Tooltip>
      );
    };

    return isMobileSized ? (
      <ClickAwayListener onClickAway={closeTooltip}>
        <div className="oi-info-tooltip__mobile-wrapper" onClick={() => openTooltip()}>{renderTooltip(true)}</div>
      </ClickAwayListener>
    ) : (
      renderTooltip()
    );
  });

OiInfoTooltip.displayName = 'OiInfoTooltip'; 

export default withWindowDimensions(OiInfoTooltip);
