import { CANCEL_ACTION_REQUESTS, CANCEL_ALL_ACTION_REQUESTS } from '@Actions/ServiceActions';
import axios from 'axios';

export const CANCEL_DATA = { cancelled: true };

const cancelRequest = (token) => {
  token.cancel(CANCEL_DATA);
  token.onCancelCallback && token.onCancelCallback();
};

export function createCancellationMiddleware() {
  let tokensMap = {};

  return (next) => (action) => {
    const { actionType, payload, type } = action;

    if (payload && payload.request && type) {
      const source = axios.CancelToken.source();
      source.onCancelCallback = payload.request.onCancel;

      const cancelableAction = {
        ...action,
        payload: {
          ...payload,
          request: {
            ...payload.request,
            cancelToken: source.token,
          },
        },
      };

      const actionTokens = tokensMap[type] || [];
      actionTokens.push(source);
      tokensMap[type] = actionTokens;

      return next(cancelableAction);
    }

    if (type === CANCEL_ACTION_REQUESTS) {
      const actionTypes = Array.isArray(actionType) ? actionType : [actionType];

      actionTypes.forEach((actionType) => {
        const actionTokens = tokensMap[actionType];
        if (actionTokens) {
          actionTokens.forEach(cancelRequest);
          tokensMap[actionType] = [];
        }
      });
    } else if (type === CANCEL_ALL_ACTION_REQUESTS) {
      Object.values(tokensMap).forEach((actionTokens) => actionTokens.forEach(cancelRequest));
      tokensMap = {};
    }

    return next(action);
  };
}
