import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Form from '@Components/form/Form';
import Input from '@Components/form/Input';
import AppVariables from '@AppVariables';
import Icon from '@Components/shared/Icon';
import { Formik } from 'formik';
import { ServiceActions } from '@Actions/ServiceActions';
import { ToastActions } from '@Actions/ToastActions';
import * as html2canvas from 'html2canvas';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

class SendFeedbackModal extends React.Component {
  modal;
  fileUpload = React.createRef();
  dropAreaRef = React.createRef();
  switchRef = React.createRef();

  inputs = {
    Message: null,
  };

  constructor(props) {
    super(props);

    this.captureScreenshotAndShow();

    this.state = {
      file: null,
      url: null,
      error: '',
      switch: false,
      waitingStatus: true,
    };
  }

  componentWillUnmount() {
    /* This prevents "Can't perform a React state update on an unmounted... 
            but it indicates a memory leak" error. with returning null, 
            it will no longer hold any data in the memory.
            https://stackoverflow.com/a/61055910 */
    this.setState = (state, callback) => {
      return;
    };
  }

  @bind
  captureScreenshotAndShow() {
    html2canvas
      .default(document.body, {
        useCORS: true,
        x: document.body.scrollLeft,
        y: document.body.scrollTop,
        width: document.body.clientWidth,
        height: document.body.clientHeight,
        useCORS: true,
      })
      .then((canvas) => {
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob);
          this.setState({
            file: blob,
            url: url,
            waitingStatus: false,
          });
        }, 'image/jpeg');
      });
  }

  @bind
  sendFeedback(values, action) {
    const { t } = this.props;

    this.props.sendFeedback(values.message, values.file).then((result) => {
      if (result.error) {
        this.props.sendFeedbackError?.response.data['Screenshot'].map((error) => {
          this.props.toastError(error, {});
        });
      } else {
        this.hide();
        this.props.toastSuccess(t('modals.feedbackSuccessToast'), {});
      }
    });
  }

  @bind
  show() {
    this.modal.show();
  }

  @bind
  hide() {
    this.modal.props.closeModal();
  }

  @bind
  switchHandlar(value) {
    this.setState(
      {
        switch: value,
      },
      () => {
        this.switchRef.current.switch(this.state.switch);
      },
    );
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    return (
      <Modal
        ref={(x) => (this.modal = x)}
        title={t('modals.sendFeedbackTitle')}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}>
        <Formik
          initialValues={{ message: '', file: null }}
          onSubmit={this.sendFeedback}
          validationSchema={AppVariables.validationSchemas.sendFeedbackSchema}>
          {(formikProps) => (
            <div className="send-feedback-modal">
              <form className="send-feedback-form" onSubmit={formikProps.handleSubmit}>
                <div className="mt-4">
                  <Input.TextArea
                    className="mb-3 w-100"
                    name="message"
                    placeholder={t('modals.feedbackMessagePlaceholder')}
                    value={formikProps.values.message}
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    error={t(formikProps.errors.message)}
                    rows={7}
                    maxLength={500}
                    showLimit
                  />
                </div>
                <div className="switch-container">
                  <p className="text"> {t('modals.includeScreenshot')} </p>
                  <Input.Switch
                    ref={this.switchRef}
                    onClick={(e, value) => {
                      this.switchHandlar(value);
                      formikProps.setFieldValue('file', this.state.file);
                    }}
                    readOnly={true}
                  />
                </div>
                {this.state.switch ? (
                  this.state.waitingStatus ? (
                    <div className="my-2">{t('modals.pleaseWait')}</div>
                  ) : (
                    <div className="input-div">
                      <img className="feedback-image" src={this.state.url} />
                    </div>
                  )
                ) : null}

                <div className="information mt-3">
                  <p>
                  <Trans i18nKey="modals.feedbackPolicy">
                    We will use the information you give us to help address technical issues and to improve our
                    services, subject to our <Link to='/privacy' onClick={this.hide}>Privacy Policy</Link> and <Link to='/terms' onClick={this.hide}>Terms of Service</Link>.
                  </Trans>
                  </p>
                </div>
                <div className="button-area mt-4 mb-2">
                  <Button
                    className="w-100"
                    text={t("modals.sendButton")}
                    type="primary"
                    submit={true}
                    disabled={!(formikProps.dirty && formikProps.isValid)}
                  />
                </div>
              </form>
            </div>
          )}
        </Formik>
      </Modal>
    );
  }
}
const component = connect(
  (state) => {
    return { ...state.feedback };
  },
  { ...ServiceActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(SendFeedbackModal));
export default component;
