import * as React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Input from '@Components/form/Input';
import Icon from '@Components/shared/Icon';
import { UserActions } from '@Actions/UserActions';
import { ModalActions } from '@Actions/ModalActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';

class PublishViaModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    return (
      <Modal
        title={t('modals.publishVia')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="publish-via-modal mx-2 my-2">
          <div className="publish-via-container mt-2 mx-1 mb-2">
            <div className="switch-row">
              <div>
                <Icon type="fontawesome" src="fab fa-facebook" />
                Facebook
              </div>
              <Input.Switch
                name="facebook"
                setFieldValue={this.props.formikProps.setFieldValue}
                setFieldTouched={this.props.formikProps.setFieldTouched}
                value={this.props.formikProps.values.facebook}
              />
            </div>
            <hr />
            <div className="switch-row">
              <div>
                <Icon type="fontawesome" src="fab fa-twitter" />
                Twitter
              </div>
              <Input.Switch
                name="twitter"
                setFieldValue={this.props.formikProps.setFieldValue}
                setFieldTouched={this.props.formikProps.setFieldTouched}
                value={this.props.formikProps.values.twitter}
              />
            </div>
            <hr />
            <div className="switch-row">
              <div>
                <Icon type="fontawesome" src="fab fa-linkedin" />
                LinkedIn
              </div>
              <Input.Switch
                name="linkedin"
                setFieldValue={this.props.formikProps.setFieldValue}
                setFieldTouched={this.props.formikProps.setFieldTouched}
                value={this.props.formikProps.values.linkedin}
              />
            </div>
            <hr />
            <div className="switch-row">
              <div>
                <Icon type="fontawesome" src="fab fa-pinterest" />
                Pinterest
              </div>
              <Input.Switch
                name="pinterest"
                setFieldValue={this.props.formikProps.setFieldValue}
                setFieldTouched={this.props.formikProps.setFieldTouched}
                value={this.props.formikProps.values.pinterest}
              />
            </div>
            <hr />
            <div className="switch-row">
              <div>
                <Icon type="fontawesome" src="fab fa-instagram" />
                Instagram
              </div>
              <Input.Switch
                name="instagram"
                setFieldValue={this.props.formikProps.setFieldValue}
                setFieldTouched={this.props.formikProps.setFieldTouched}
                value={this.props.formikProps.values.instagram}
              />
            </div>
            <hr />
            <div className="switch-row">
              <div>
                <Icon type="fontawesome" src="fab fa-tumblr" />
                Tumblr
              </div>
              <Input.Switch
                name="tumblr"
                setFieldValue={this.props.formikProps.setFieldValue}
                setFieldTouched={this.props.formikProps.setFieldTouched}
                value={this.props.formikProps.values.tumblr}
              />
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1 "></div>
      </Modal>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.auth, ...state.user, ...state.account };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(PublishViaModal));
export default withRouter(component);
