import * as AccountActionTypes from '@Actions/AccountActions';
import * as CollectibleActionTypes from '@Actions/CollectibleActions';
import * as HashtagActionTypes from '@Actions/HashtagActions';
import {
  getProfileNameOrShortPublicAddress
} from '@Utils';
import i18n from '@i18n';

const initialState = () => ({
  fetchingFeedItems: false,
  feedItems: [],
  fetchingFeedItemsError: null,
  newFeedItemAvailable: false,

  whoToFollow: [],
  trendingNow: [],
  liveAuctions: [],
  followHashtags: [],
});

const buildFeedItemContent = (feedItem) => {
  const { t } = i18n;
  let content = {
    sources: [],
    otherSourcesCount: 0,
    showHeadline: false,
    text: '',
    link: null,
  };

  if (feedItem) {

    let otherSourcesCount = feedItem.totalSourceCount - feedItem.sourceAccounts?.length;
    let sliceSources;

    if(feedItem.sourceAccounts?.length === 2 && otherSourcesCount === 0) {
      sliceSources = 2;
    }
    else if(feedItem.sourceAccounts?.length > 1 && otherSourcesCount > 0) {
      otherSourcesCount += (feedItem.sourceAccounts?.length - 1);
    }

    let sources = feedItem.sourceAccounts.slice(0, sliceSources).map(a => ({
      text: getProfileNameOrShortPublicAddress(a.profile.name || a.slug),
      link: `/${a.slug}`
    }));

    content.headline = {
      sources,
      otherSourcesCount: otherSourcesCount,
      text: null,
      icon: null,
    };

    switch (feedItem.type) {
      case 'Activity_Collectible_Mint':
        content.headline = null; // We just show the collectible as it is, so no headline
        break;
      case 'Activity_Collectible_Like':
        content.headline.text = t('public.feedCardHeaderLikedText');
        content.headline.icon = 'fa-heart like';
        break;
      case 'Activity_Collectible_Comment':
      case 'Activity_Comment_Reply':
        content.headline.text = t('public.feedCardHeaderCommentedText'); // Build the comment content here...
        content.headline.icon = 'fa-comment comment';
        break;
      case 'Activity_Collectible_Share':
        content.headline.text = t('public.feedCardHeaderSharedText');
        content.headline.icon = 'share';
        break;
      case 'Activity_Collectible_List':
        content.headline.text = t('public.feedCardHeaderListedText');
        content.headline.icon = 'fa-bars list';
        break;
      case 'Activity_Collectible_Purchase':
        content.headline.text = t('public.feedCardHeaderPurchasedText');
        content.headline.icon = 'swap';
        break;
      case 'Activity_Collectible_Bid':
        content.headline.text = t('public.feedCardHeaderBidText');
        content.headline.icon = 'swap';
        break;
      case 'Activity_Collectible_Win':
        content.headline.text = t('public.feedCardHeaderWonText');
        content.headline.icon = 'fa-star win';
        break;
      case 'Activity_Collectible_Hashtag_Mention':
        content.headline.icon = 'fa-hashtag';
        content.headline.sources = 
        [{ 
          text: feedItem.targetHashtag.tag, 
          link: '/hashtag/' + feedItem.targetHashtag.tag 
        }]
        break;
      default:
        content = null;
        break;
    }
  }

  return content;
};

const connectFeedItemToCache = (feedItems) => {
  let feedItemArr = JSON.parse(JSON.stringify(feedItems));

  let newFeedItems = feedItemArr.map((fi) => {
    fi.content = buildFeedItemContent(fi);
    fi.sourceAccountIds = fi.sourceAccounts?.map(a => a.id);
    fi.targetAccountId = fi.targetAccount?.id;
    fi.targetCollectibleId = fi.targetCollectible?.id;
    fi.targetCollectibleAccountId = fi.targetCollectible?.account.id;
    fi.targetCommentAccountId = fi.targetComment?.account.id;
    fi.targetCommentParentAccountId = fi.targetCommentParent?.account.id;

    delete fi.sourceAccounts;
    delete fi.targetAccount;
    delete fi.targetCollectible;
    delete fi.targetComment;
    delete fi.targetCommentParent;

    return fi;
  });

  return newFeedItems;
};

export default (state = initialState(), action) => {
  switch (action.type) {
    case AccountActionTypes.GET_FEED_ITEMS:
      return {
        ...state,
        fetchingFeedItems: true,
      };
    case AccountActionTypes.MUTE_ACCOUNT_SUCCESS: {
      const { mutedAccountId } = action.meta.previousAction.payload;

      let newFeedItems = state.feedItems.reduce((payload, feedItem) => {
        const filteredSourceAccountIds = feedItem.sourceAccountIds.filter(id => id !== mutedAccountId);

        if(filteredSourceAccountIds.length > 0 &&
          feedItem.targetAccountId !== mutedAccountId &&
          feedItem.targetCollectibleAccountId !== mutedAccountId &&
          feedItem.targetCommentAccountId !== mutedAccountId &&
          feedItem.targetCommentParentAccountId !== mutedAccountId) {
            payload.push({
              ...feedItem,
              sourceAccountIds: filteredSourceAccountIds
            });
        }

        return payload;
      }, []);

      return {
        ...state,
        feedItems: newFeedItems,
      };
    }
    case AccountActionTypes.BLOCK_ACCOUNT_SUCCESS: {
      const { blockedAccountId } = action.meta.previousAction.payload;

      let newFeedItems = state.feedItems.reduce((payload, feedItem) => {
        const filteredSourceAccountIds = feedItem.sourceAccountIds.filter(id => id !== blockedAccountId);

        if(filteredSourceAccountIds.length > 0 &&
          feedItem.targetAccountId !== blockedAccountId &&
          feedItem.targetCollectibleAccountId !== blockedAccountId &&
          feedItem.targetCommentAccountId !== blockedAccountId &&
          feedItem.targetCommentParentAccountId !== blockedAccountId) {
            payload.push({
              ...feedItem,
              sourceAccountIds: filteredSourceAccountIds
            });
        }

        return payload;
      }, []);


      return {
        ...state,
        feedItems: newFeedItems,
      };
    }
    case AccountActionTypes.GET_FEED_ITEMS_SUCCESS: {
      const newFeedItems = connectFeedItemToCache(action.payload);

      return {
        ...state,
        fetchingFeedItems: false,
        // It is only added to the back of the list
        feedItems: [...state.feedItems, ...newFeedItems],
      };
    }
    case AccountActionTypes.GET_FEED_ITEMS_FAILURE:
      return {
        ...state,
        fetchingFeedItems: false,
        fetchingFeedItemsError: action.error,
      };
    case AccountActionTypes.GET_RECOMMENDED_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        whoToFollow: [...state.whoToFollow, ...action.payload.value.map((value) => value.id)],
      };
    }
    case CollectibleActionTypes.GET_TRENDING_ITEMS_SUCCESS: {
      return {
        ...state,
        trendingNow: [...state.trendingNow, ...action.payload.value.map((value) => value.id)],
      };
    }
    case CollectibleActionTypes.GET_LIVE_AUCTION_ITEMS_SUCCESS: {
      return {
        ...state,
        liveAuctions: [...state.liveAuctions, ...action.payload.value.map((value) => value.id)],
      };
    }
    case AccountActionTypes.GET_RECOMMENDED_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        whoToFollow: [...state.whoToFollow, ...action.payload.value.map((value) => value.id)],
      };
    }
    case CollectibleActionTypes.GET_TRENDING_ITEMS_SUCCESS: {
      return {
        ...state,
        trendingNow: [...state.trendingNow, ...action.payload.value.map((value) => value.id)],
      };
    }
    case CollectibleActionTypes.GET_LIVE_AUCTION_ITEMS_SUCCESS: {
      return {
        ...state,
        liveAuctions: [...state.liveAuctions, ...action.payload.value.map((value) => value.id)],
      };
    }
    case HashtagActionTypes.GET_RECOMMENDED_HASHTAGS_SUCCESS: {
      return {
        ...state,
        followHashtags: [...state.followHashtags, ...action.payload.value.map((value) => value.tag)],
      };
    }
    case AccountActionTypes.SET_NEW_FEED_ITEM_AVAILABLE: {
      return {
        ...state,
        newFeedItemAvailable: true,
      };
    }
    case AccountActionTypes.CLEAR_FEED_ITEMS: {
      return {
        ...state,
        newFeedItemAvailable: false,
        feedItems: [],
      };
    }
    default:
      return state;
  }
};
