import * as React from 'react';
import { withRouter } from 'react-router-dom';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Icon from '@Components/shared/Icon';
import { ToastActions } from '@Actions/ToastActions';
import { ModalActions } from '@Actions/ModalActions';
import { AccountActions } from '@Actions/AccountActions';
import { ServiceActions } from '@Actions/ServiceActions';
import AppVariables from '@AppVariables';
import oiLogoImg from '@Images/logo/oix-community-color.svg';
import Button from '@Components/form/Button';
import { withTranslation } from 'react-i18next';

class ReportModalE extends React.Component {
  searchRef;
  constructor(props) {
    super(props);

    this.state = {
      selectedValue: '',
      searchInput: '',
    };
  }

  @bind
  selectType(value) {
    this.setState({ selectedValue: value });
  }

  @bind
  searchHandler(e) {
    this.setState({ searchInput: this.searchRef.state.value.toLowerCase() });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    return (
      <Modal
        title={t('modals.reportTitle')}
        subtext={t('modals.reportSubtitle')}
        backdrop={backdrop}
        onOutsideClick={onOutsideClick}
        closeOnOutsideClick={closeOnOutsideClick}>
        <div className="report-modal mx-2">
          {/* <Search
            name="title"
            className="w-100 my-3"
            placeholder="Search"
            onChange={() => {
              this.searchHandler();
            }}
            ref={(x) => (this.searchRef = x)}
          /> */}
          {AppVariables.reportTypes
            .filter((t) => t.Name.toLowerCase().includes(this.state.searchInput))
            .map((type, index) => {
              return (
                <div className="row-element" key={type + index}>
                  <div className="line-content" key={index} onClick={() => this.selectType(type.Value)}>
                    {t(`public.reportsType.${type.Value}`)}{' '}
                    {this.state.selectedValue == type.Value ? (
                      <Icon
                        style={{ color: '#00B7FF' }}
                        className="mr-1"
                        type="fontawesome"
                        src="fas fa-check-circle"
                      />
                    ) : (
                      <Icon className="mr-1" type="fontawesome" src="far fa-circle" />
                    )}
                  </div>
                  {index !== AppVariables.reportTypes.length - 1 && <hr />}
                </div>
              );
            })}
        </div>
        <div className="footer mx-2 mt-1">
          <div> </div>
          <Button
            className="w-30"
            text="Next"
            type="primary"
            fullSize
            disabled={!this.state.selectedValue.length}
            onClick={() => {
              this.props.openModal('ConfirmReportModal', {
                onOutsideClick: true,
                closeOnOutsideClick: true,
                backdrop: true,
                selectedValue: this.state.selectedValue,
                targetAccountId: this.props.targetAccountId,
                targetPostId: this.props.targetPostId,
                targetCollectionId: this.props.targetCollectionId
              });
            }}
          />
        </div>
      </Modal>
    );
  }
}
class ConfirmReportE extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      confirm: false,
    };
  }

  @bind
  confirm() {
    this.setState((prevState) => ({ confirm: !prevState.confirm }));
  }

  @bind
  sendReport() {
    if (this.props.targetAccountId) {
      this.props
        .reportAccount({
          targetAccountId: this.props.targetAccountId,
          kind: this.props.selectedValue,
          type: 'Account',
        })
        .then((res) => {
          if (!res.error) {
            this.props.toastInfo('Reported');
            this.props.closeAllModals();
          }
        });
    } else if (this.props.targetPostId) {
      this.props
        .reportPost({
          targetPostId: this.props.targetPostId,
          kind: this.props.selectedValue,
          type: 'Post',
        })
        .then((res) => {
          if (!res.error) {
            this.props.toastInfo('Reported');
            this.props.closeAllModals();
          }
        });
    } else if (this.props.targetCollectionId) {
      this.props
        .reportCollection({
          targetCollectionId: this.props.targetCollectionId,
          kind: this.props.selectedValue,
          type: 'Post',
        })
        .then((res) => {
          if (!res.error) {
            this.props.toastInfo('Reported');
            this.props.closeAllModals();
          }
        });
    }
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    return (
      <Modal
        title={t('modals.reportConfirmationTitle')}
        backdrop={backdrop}
        onOutsideClick={onOutsideClick}
        closeOnOutsideClick={closeOnOutsideClick}>
        <div className="confirm-report-modal mx-2">
          <div className="brand-area">
            <img src={oiLogoImg} className="pr-1" />
          </div>
          <div className="text-align-left my-2">{t('modals.aganistCommunityGuidelines')}</div>
          <Button
            className="button my-1"
            text={t('modals.seeCommunityGuidelines')}
            type="transparent"
            fullSize="true"
            onClick={() => {
              this.props.closeAllModals();
              this.props.history.push('/community-guidelines');
            }}
          />
          <div className="text-align-left my-2">{t('modals.communityGuidelinesText')}</div>
          <div className="line-content" onClick={() => this.confirm()}>
            <div>{t('modals.believeAganistGuidelines')}</div>
            {this.state.confirm ? (
              <Icon style={{ color: '#00B7FF' }} className="mr-1" type="fontawesome" src="fas fa-check-circle" />
            ) : (
              <Icon className="mr-1" type="fontawesome" src="far fa-circle" />
            )}
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div> </div>
          <Button
            className="w-30"
            text={t('modals.reportButton')}
            type="primary"
            fullSize
            disabled={!this.state.confirm}
            onClick={() => {
              this.sendReport();
            }}
          />
        </div>
      </Modal>
    );
  }
}

export const ReportModal = connect(
  (state) => {
    return {};
  },
  { ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(ReportModalE));
export const ConfirmReportModal = connect(
  (state) => {
    return {};
  },
  { ...ModalActions, ...ToastActions, ...AccountActions, ...ServiceActions },
  null,
  { forwardRef: true },
)(withRouter(withTranslation()(ConfirmReportE)));
