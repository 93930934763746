import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import Icon from '@Components/shared/Icon';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import { Formik } from 'formik';
import AppVariables from '@AppVariables';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';

class GeneralInformationModal extends React.Component {
  formikProps;
  constructor(props) {
    super(props);
    this.state = {
      addAccountExpand: false,
    };
  }

  componentDidMount() {}

  @bind
  submitGeneralInformationForm(values, action) {
    this.props.openModal('SelectPayoutMethodModal', { show: true, backdrop: true });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    let generalInformationSubmitForm;
    return (
      <Modal
        title={t('modals.generalInformationModalTitle')}
        subtext={t('modals.generalInformationModalSubtitle')}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="general-information-modal mx-2">
          <div className="general-information-container px-1 my-2">
            <Formik
              initialValues={{
                type: '',
                businessName: '',
                firstName: '',
                lastName: '',
                email: '',
                dateofBirth: '',
                phoneNumber: '',
                govermentId: '',
                country: '',
                line1: '',
                line2: '',
                city: '',
                province: '',
                zip: '',
              }}
              onSubmit={this.submitGeneralInformationForm}
              validationSchema={AppVariables.validationSchemas.generalInformationSchema}
            >
              {(formikProps) => {
                this.formikProps = formikProps;
                generalInformationSubmitForm = formikProps.submitForm;
                return (
                  <form className="form mb-5 mt-3 pb-5" onSubmit={formikProps.handleSubmit}>
                    <div className="inputs-row">
                      <div
                        className="input-field"
                        style={{ width: formikProps.values.type.twoLetterISOCode !== 'US' ? '100%' : '48%' }}
                      >
                        {/* TODO: Render Business name if only Business selected */}
                        <Input.Select
                          className="w-100 "
                          label="Type"
                          name="type"
                          items={this.props.CountriesData ? this.props.CountriesData : []}
                          placeholder=""
                          valueExpr={'twoLetterISOCode'}
                          displayExpr={'name'}
                          error={formikProps.touched.country && t(formikProps.errors.country)}
                          setFieldValue={formikProps.setFieldValue}
                          setFieldTouched={formikProps.setFieldTouched}
                        />
                      </div>
                      {formikProps.values.type.twoLetterISOCode == 'US' ? (
                        <div className="input-field ml-4">
                          <Input.Text label={t('modals.inputLabelBusinessName')} className="w-100" name="businessName" placeholder="" />
                        </div>
                      ) : null}
                    </div>
                    <div className="inputs-row">
                      <div className="input-field mr-4">
                        <Input.Text label={t('modals.inputLabelFirstName')} className="w-100" name="firstName" placeholder="" />
                      </div>
                      <div className="input-field">
                        <Input.Text label={t('modals.inputLabelLastName')} className="w-100" name="lastName" placeholder="" />
                      </div>
                    </div>
                    <div className="inputs-row">
                      <div className="input-field mr-4">
                        <Input.Text label={t('modals.inputLabelEmail')} className="w-100" name="email" placeholder="" />
                      </div>
                      <div className="input-field">
                        <Input.Text label={t('modals.inputLabelDateOfBirth')} className="w-100" name="dateofBirth" placeholder="" />
                      </div>
                    </div>
                    <div className="inputs-row">
                      <div className="input-field mr-4">
                        <Input.Text label={t('modals.inputLabelPhoneNumber')} className="w-100" name="phoneNumber" placeholder="" />
                      </div>
                      <div className="input-field">
                        <Input.Text label={t('modals.inputLabelGovernmentId')} className="w-100" name="govermentId" placeholder="" />
                      </div>
                    </div>
                    <hr className="my-1" />
                    <div className="address-container pt-1">
                      <Icon src="fas fa-map-marker-alt" type="fontawesome" />
                      <Input.Select
                        className="w-100 "
                        label={t('modals.inputLabelCountry')}
                        name="country"
                        items={this.props.CountriesData ? this.props.CountriesData : []}
                        placeholder=""
                        valueExpr={'twoLetterISOCode'}
                        displayExpr={'name'}
                        error={formikProps.touched.country && t(formikProps.errors.country)}
                        setFieldValue={formikProps.setFieldValue}
                        setFieldTouched={formikProps.setFieldTouched}
                      />
                    </div>
                    {this.formikProps.values.country ? (
                      <div>
                        <Input.Text
                          className="w-100 py-1"
                          label={t('modals.inputLabelAdress1')}
                          name="line1"
                          placeholder=""
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.line1}
                          error={formikProps.touched.line1 && t(formikProps.errors.line1)}
                        />
                        <Input.Text
                          className="w-100 py-1"
                          label={t('modals.inputLabelAdress2')}
                          name="line2"
                          placeholder=""
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.line2}
                          error={formikProps.touched.line2 && t(formikProps.errors.line2)}
                        />
                        <Input.Text
                          className="w-100 py-1"
                          label={t('modals.inputLabelCity')}
                          name="city"
                          placeholder=""
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.city}
                          error={formikProps.touched.city && t(formikProps.errors.city)}
                        />
                        <div className="d-flex">
                          <Input.Text
                            className="w-100 py-1 mr-3"
                            label={formikProps.values.country.twoLetterISOCode == 'US' ? t('modals.inputLabelZip') : t('modals.inputLabelPostal')}
                            name="zip"
                            placeholder=""
                            onChange={formikProps.handleChange}
                            onBlur={formikProps.handleBlur}
                            value={formikProps.values.address_zip}
                            error={formikProps.touched.address_zip && t(formikProps.errors.address_zip)}
                          />
                          {this.formikProps.values.country.addressRequiresSubdivision ? (
                            <Input.Select
                              className="w-100 py-1 ml-3"
                              label={t('modals.inputLabelProvince')}
                              name="province"
                              items={this.formikProps.values.country.subdivisions}
                              placeholder=""
                              valueExpr={'twoLetterISOCode'}
                              displayExpr={'name'}
                              error={formikProps.touched.province && t(formikProps.errors.province)}
                              setFieldValue={formikProps.setFieldValue}
                              setFieldTouched={formikProps.setFieldTouched}
                            />
                          ) : (
                            <div className="w-100"></div>
                          )}
                        </div>
                        <hr className="my-2" />
                      </div>
                    ) : null}
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="footer mx-2 mt-1 ">
          <div style={{ width: '40%' }}>
            <div style={{ color: '#00DCAF', fontSize: '.7rem' }}>
              <Icon className="mr-1" type="fontawesome" src="fas fa-lock" />
              <span>{t('modals.oiSecurePayment')}</span>
            </div>
          </div>
          <div style={{ width: '40%', display: 'flex' }}>
            <Button
              text={t('modals.nextButton')}
              type="primary"
              fullSize
              onClick={() => {
                generalInformationSubmitForm();
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.user.account, ...state.service, ...state.account, ...state.auth };
  },
  { ...AccountActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(GeneralInformationModal);
export default withTranslation()(component);
