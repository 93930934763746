import { serialize } from 'object-to-formdata';

const collectionsEndpoint = (collectionId) =>
  `v1/collections${typeof collectionId !== 'undefined' ? `/${collectionId}` : ''}`;
const collectionsODataEndpoint = (collectionId) =>
  `v1/odata/collections${typeof collectionId !== 'undefined' ? `/${collectionId}` : ''}`;
const hashtagsODataEndpoint = (tag) => `v1/odata/hashtags${tag ? `(\'${tag}\')` : ''}`;

const urlQueryPart = (query) => (query ? `?${query}` : '');

export const GET_COLLECTIONS = 'GET_COLLECTIONS';
export const GET_COLLECTIONS_SUCCESS = 'GET_COLLECTIONS_SUCCESS';
export const GET_COLLECTIONS_FAILURE = 'GET_COLLECTIONS_FAILURE';

const getCollections = (query, group, category) => ({
  type: GET_COLLECTIONS,
  payload: {
    group,
    category,
    request: {
      url: collectionsODataEndpoint() + (group ? `/${group}/` : '') + urlQueryPart(query),
    },
  },
});

export const GET_COLLECTIONS_WITH_POST = 'GET_COLLECTIONS_WITH_POST';
export const GET_COLLECTIONS_WITH_POST_SUCCESS = 'GET_COLLECTIONS_WITH_POST_SUCCESS';
export const GET_COLLECTIONS_WITH_POST_FAILURE = 'GET_COLLECTIONS_WITH_POST_FAILURE';

const getCollectionsWithPost = (query, searchKey) => {
  var decodedSearchKey = decodeURIComponent(searchKey);

  var endpoint = decodedSearchKey?.startsWith('#')
  ? hashtagsODataEndpoint(decodedSearchKey.substring(1)) + '/collections'
  : collectionsODataEndpoint();

  return {
    type: GET_COLLECTIONS_WITH_POST,
    payload: {
      request: {
        method: 'POST',
        headers: {"content-type": "text/plain"},
        url: endpoint + '/$query',
        data: query,
      },
    },
  };
};

export const GET_COLLECTION = 'GET_COLLECTION';
export const GET_COLLECTION_SUCCESS = 'GET_COLLECTION_SUCCESS';
export const GET_COLLECTION_FAILURE = 'GET_COLLECTION_FAILURE';

const getCollection = (collectionIdOrPublicAddress, chainId = null) => ({
  type: GET_COLLECTION,
  payload: {
    chainId,
    collectionIdOrPublicAddress,
    request: {
      url: collectionsEndpoint() + (chainId ? `/${chainId}` : '') + (collectionIdOrPublicAddress ? `/${collectionIdOrPublicAddress}` : ''),
    },
  },
});

export const GET_COLLECTION_BY_NAME = 'GET_COLLECTION_BY_NAME';
export const GET_COLLECTION_BY_NAME_SUCCESS = 'GET_COLLECTION_BY_NAME_SUCCESS';
export const GET_COLLECTION_BY_NAME_FAILURE = 'GET_COLLECTION_BY_NAME_FAILURE';

const getCollectionByName = (name) => ({
  type: GET_COLLECTION_BY_NAME,
  payload: {
    request: {
      url: collectionsEndpoint(name),
    },
  },
});

export const CREATE_COLLECTION = 'CREATE_COLLECTION';
export const CREATE_COLLECTION_SUCCESS = 'CREATE_COLLECTION_SUCCESS';
export const CREATE_COLLECTION_FAILURE = 'CREATE_COLLECTION_FAILURE';

const createCollection = (collection) => ({
  type: CREATE_COLLECTION,
  payload: {
    request: {
      method: 'POST',
      url: collectionsEndpoint(),
      data: collection,
    },
  },
});

export const UPDATE_COLLECTION = 'UPDATE_COLLECTION';
export const UPDATE_COLLECTION_SUCCESS = 'UPDATE_COLLECTION_SUCCESS';
export const UPDATE_COLLECTION_FAILURE = 'UPDATE_COLLECTION_FAILURE';

const updateCollection = (collectionId, collection) => {
  const formData = serialize(collection, {
    indices: true,
  });
  
  return {
    type: UPDATE_COLLECTION,  
    payload: {
      collectibleId: collectionId,
      request: {
        method: 'PUT',
        url: collectionsEndpoint(collectionId),
        data: formData,
      },
    },
  }
};

export const DELETE_COLLECTION = 'DELETE_COLLECTION';
export const DELETE_COLLECTION_SUCCESS = 'DELETE_COLLECTION_SUCCESS';
export const DELETE_COLLECTION_FAILURE = 'DELETE_COLLECTION_FAILURE';

const deleteCollection = (collectionId, accountId) => ({
  type: DELETE_COLLECTION,
  payload: {
    collectionId,
    accountId,
    request: {
      method: 'DELETE',
      url: collectionsEndpoint(collectionId),
    },
  },
});

export const GET_COLLECTION_STATS = 'GET_COLLECTION_STATS';
export const GET_COLLECTION_STATS_SUCCESS = 'GET_COLLECTION_STATS_SUCCESS';
export const GET_COLLECTION_STATS_FAILURE = 'GET_COLLECTION_STATS_FAILURE';

const getCollectionStats = (collectionId) => ({
  type: GET_COLLECTION_STATS,
  payload: {
    collectionId,
    request: {
      method: 'GET',
      url: `${collectionsEndpoint(collectionId)}/stats`,
    },
  },
});

export const SET_COLLECTION_DEPLOYING = 'SET_COLLECTION_DEPLOYING';
export const SET_COLLECTION_DEPLOYING_SUCCESS = 'SET_COLLECTION_DEPLOYING_SUCCESS';
export const SET_COLLECTION_DEPLOYING_FAILURE = 'SET_COLLECTION_DEPLOYING_FAILURE';

const setCollectionDeploying = (collectionId, chainId, deployTxHash) => ({
  type: SET_COLLECTION_DEPLOYING,
  payload: {
    request: {
      method: 'PUT',
      url: `${collectionsEndpoint(collectionId)}/deploy/${chainId}/${deployTxHash}`,
    },
  },
});

export const ADD_COLLECTION_TRANSACTION = 'ADD_COLLECTION_TRANSACTION';
export const ADD_COLLECTION_TRANSACTION_SUCCESS = 'ADD_COLLECTION_TRANSACTION_SUCCESS';
export const ADD_COLLECTION_TRANSACTION_FAILURE = 'ADD_COLLECTION_TRANSACTION_FAILURE';

const addCollectionTransaction = (collectionId, txHash, latestBlockNumber, kind) => ({
  type: ADD_COLLECTION_TRANSACTION,
  payload: {
    collectionId: collectionId,
    request: {
      method: 'POST',
      url: `${collectionsEndpoint(collectionId)}/tx`,
      data: {
        txHash,
        latestBlockNumber,
        kind,
      },
    },
  },
});

export const CHECK_SLUG_AVAILABILITY = 'CHECK_SLUG_AVAILABILITY';
export const CHECK_SLUG_AVAILABILITY_SUCCESS = 'CHECK_SLUG_AVAILABILITY_SUCCESS';
export const CHECK_SLUG_AVAILABILITY_FAILURE = 'CHECK_SLUG_AVAILABILITY_FAILURE';

const checkSlugAvailability = (slug) => ({
  type: CHECK_SLUG_AVAILABILITY,
  payload: {
    request: {
      url: `${collectionsEndpoint()}/CheckSlug/${slug}`,
    },
  },
});

// We will likely integrate this as part of the getCollectible
// or implement it in the HTTP headers as part of that
export const RECORD_COLLECTION_VIEW = 'RECORD_COLLECTION_VIEW';
export const RECORD_COLLECTION_VIEW_SUCCESS = 'RECORD_COLLECTION_VIEW_SUCCESS';
export const RECORD_COLLECTION_VIEW_FAILURE = 'RECORD_COLLECTION_VIEW_FAILURE';

const recordCollectionView = (collectionId) => ({
  type: RECORD_COLLECTION_VIEW,
  payload: {
    request: {
      method: 'POST',
      url: `${collectionsEndpoint(collectionId)}/view/`,
    },
  },
});

export const UPDATE_COLLECTION_PROFILE = 'UPDATE_COLLECTION_PROFILE';
export const UPDATE_COLLECTION_PROFILE_SUCCESS = 'UPDATE_COLLECTION_PROFILE_SUCCESS';
export const UPDATE_COLLECTION_PROFILE_FAILURE = 'UPDATE_COLLECTION_PROFILE_FAILURE';

const updateCollectionProfile = (collectionId, profileModel) => {
  const formData = serialize(profileModel, {
    indices: true,
  });

  return {
    type: UPDATE_COLLECTION_PROFILE,
    payload: {
      request: {
        method: 'PUT',
        url: `${collectionsEndpoint(collectionId)}/profile`,
        data: formData,
      },
    },
  };
};

export const GET_COLLECTION_COLLECTIBLES = 'GET_COLLECTION_COLLECTIBLES';
export const GET_COLLECTION_COLLECTIBLES_SUCCESS = 'GET_COLLECTION_COLLECTIBLES_SUCCESS';
export const GET_COLLECTION_COLLECTIBLES_FAILURE = 'GET_COLLECTION_COLLECTIBLES_FAILURE';

// We will need to do paging here
const getCollectionCollectibles = (collectionId, query) => ({
  type: GET_COLLECTION_COLLECTIBLES,
  payload: {
    collectionId: collectionId,
    request: {
      url: `${collectionsODataEndpoint(collectionId)}/collectibles/${urlQueryPart(query)}`,
    },
  },
});

export const GET_COLLECTION_RESERVE_AUCTION_COLLECTIONS = 'GET_COLLECTION_RESERVE_AUCTION_COLLECTIONS';
export const GET_COLLECTION_RESERVE_AUCTION_COLLECTIONS_SUCCESS = 'GET_COLLECTION_RESERVE_AUCTION_COLLECTIONS_SUCCESS';
export const GET_COLLECTION_RESERVE_AUCTION_COLLECTIONS_FAILURE = 'GET_COLLECTION_RESERVE_AUCTION_COLLECTIONS_FAILURE';

// We will need to do paging here
const getCollectionReserveAuctionCollectibles = (collectionId, query) => ({
  type: GET_COLLECTION_RESERVE_AUCTION_COLLECTIONS,
  payload: {
    collectionId: collectionId,
    request: {
      url: `${collectionsODataEndpoint(collectionId)}/collectibles/${urlQueryPart(query)}`,
    },
  },
});

export const GET_COLLECTION_ON_SALE_COLLECTIONS = 'GET_COLLECTION_ON_SALE_COLLECTIONS';
export const GET_COLLECTION_ON_SALE_COLLECTIONS_SUCCESS = 'GET_COLLECTION_ON_SALE_COLLECTIONS_SUCCESS';
export const GET_COLLECTION_ON_SALE_COLLECTIONS_FAILURE = 'GET_COLLECTION_ON_SALE_COLLECTIONS_FAILURE';

// We will need to do paging here
const getCollectionOnSaleCollectibles = (collectionId, query) => ({
  type: GET_COLLECTION_ON_SALE_COLLECTIONS,
  payload: {
    collectionId: collectionId,
    request: {
      url: `${collectionsODataEndpoint(collectionId)}/collectibles/${urlQueryPart(query)}`,
    },
  },
});

export const FEATURE_COLLECTION = 'FEATURE_COLLECTION';
export const FEATURE_COLLECTION_SUCCESS = 'FEATURE_COLLECTION_SUCCESS';
export const FEATURE_COLLECTION_FAILURE = 'FEATURE_COLLECTION_FAILURE';

const featureCollection = (collectionId) => ({
  type: FEATURE_COLLECTION,
  payload: {
    request: {
      method: 'POST',
      url: `${collectionsEndpoint(collectionId)}/featured/`,
    },
  },
});

export const UNFEATURE_COLLECTION = 'UNFEATURE_COLLECTION';
export const UNFEATURE_COLLECTION_SUCCESS = 'UNFEATURE_COLLECTION_SUCCESS';
export const UNFEATURE_COLLECTION_FAILURE = 'UNFEATURE_COLLECTION_FAILURE';

const unfeatureCollection = (collectionId) => ({
  type: UNFEATURE_COLLECTION,
  payload: {
    request: {
      method: 'DELETE',
      url: `${collectionsEndpoint(collectionId)}/featured/`,
    },
  },
});


export const GET_RECOMMENDED_COLLECTIONS = 'GET_RECOMMENDED_COLLECTIONS';
export const GET_RECOMMENDED_COLLECTIONS_SUCCESS = 'GET_RECOMMENDED_COLLECTIONS_SUCCESS';
export const GET_RECOMMENDED_COLLECTIONS_FAILURE = 'GET_RECOMMENDED_COLLECTIONS_FAILURE';

const getRecommendedCollections = (query) => ({
  type: GET_RECOMMENDED_COLLECTIONS,
  payload: {
    request: {
      url: collectionsODataEndpoint() + urlQueryPart(query),
    },
  },
});

export const SET_ACTIVE_PROFILE_COLLECTION = 'SET_ACTIVE_PROFILE_COLLECTION';

const setActiveProfileCollectionId = (activeProfileCollectionId) => ({
  type: SET_ACTIVE_PROFILE_COLLECTION,
  payload: {
    activeProfileCollectionId: activeProfileCollectionId,
  },
});

const actions = {
  getCollections,
  getCollectionsWithPost,

  getCollection,
  getCollectionByName,
  createCollection,
  updateCollection,
  deleteCollection,
  getCollectionStats,

  recordCollectionView,
  updateCollectionProfile,
  setCollectionDeploying,
  addCollectionTransaction,

  checkSlugAvailability,

  getCollectionCollectibles,
  getCollectionReserveAuctionCollectibles,
  getCollectionOnSaleCollectibles,

  getRecommendedCollections,

  setActiveProfileCollectionId,

  featureCollection,
  unfeatureCollection
};

export const CollectionActions = {
  // public for all collections 
  getCollections: (query, group, category) => (dispatch) => 
    dispatch(actions.getCollections(query, group, category)),
  getCollectionsWithPost: (query, searchKey) => (dispatch) =>
    dispatch(actions.getCollectionsWithPost(query, searchKey)),

  getCollection: (collectionIdOrPublicAddress, chainId) => (dispatch) => 
    dispatch(actions.getCollection(collectionIdOrPublicAddress, chainId)),
  getCollectionByName: (name) => (dispatch) => dispatch(actions.getCollectionByName(name)),
  
  createCollection: (model) => (dispatch) => dispatch(actions.createCollection(model)),
  updateCollection: (collectionId, model) => (dispatch) => 
    dispatch(actions.updateCollection(collectionId, model)),
  deleteCollection: (collectionId, accountId) => (dispatch) =>
    dispatch(actions.deleteCollection(collectionId, accountId)),

  getCollectionStats: (collectionId) => (dispatch) => dispatch(actions.getCollectionStats(collectionId)),

  recordCollectionView: (collectionId) => (dispatch) => dispatch(actions.recordCollectionView(collectionId)),

  getCollectionCollectibles: (collectionId, query) => (dispatch) =>
    dispatch(actions.getCollectionCollectibles(collectionId, query)),

  getCollectionOnSaleCollectibles: (collectionId, query) => (dispatch) =>
    dispatch(actions.getCollectionOnSaleCollectibles(collectionId, query)),

  getCollectionReserveAuctionCollectibles: (collectionId, query) => (dispatch) =>
    dispatch(actions.getCollectionReserveAuctionCollectibles(collectionId, query)),

  // private for the current collection
  updateCollectionProfile: (collectionId, profileModel) => (dispatch) =>
    dispatch(actions.updateCollectionProfile(collectionId, profileModel)),

  checkSlugAvailability: (slug) => (dispatch) => dispatch(actions.checkSlugAvailability(slug)),

  getRecommendedCollections: (query) => (dispatch) => dispatch(actions.getRecommendedCollections(query)),

  setActiveProfileCollectionId: (activeProfileCollectionId) => (dispatch) =>
    dispatch(actions.setActiveProfileCollectionId(activeProfileCollectionId)),

  setCollectionDeploying: (collectionId, chainId, deployTxHash) => (dispatch) =>
    dispatch(actions.setCollectionDeploying(collectionId, chainId, deployTxHash)),

  addCollectionTransaction: (collectionId, txHash, latestBlockNumber, kind) => (dispatch) =>
    dispatch(actions.addCollectionTransaction(collectionId, txHash, latestBlockNumber, kind)),

  featureCollection: (collectionId) => (dispatch) => dispatch(actions.featureCollection(collectionId)),

  unfeatureCollection: (collectionId) => (dispatch) => dispatch(actions.unfeatureCollection(collectionId)),    
};
