import * as React from 'react';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import { UserActions } from '@Actions/UserActions';
import { ModalActions } from '@Actions/ModalActions';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class WarningModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, show, t } = this.props;
    return (
      <Modal
        title={<div style={{ color: '#ff0000' }}>{this.props.title}</div>}
        subtext={''}
        show={show}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="disable-2fa-modal mx-2 my-2">
          {this.props.description ? (
            <div className="disable-2fa-modal-container mt-2 mx-1 mb-2">
              <div className="text my-1">
                {t('modals.warning')}<span className="sub-text ml-1">{this.props.description}</span>
              </div>
            </div>
          ) : null}
        </div>
        <div className="footer mx-2 mt-1">
          <div style={{ width: '40%' }}>
            <Button text="Cancel" type="primary" fullSize onClick={() => this.props.closeModal()} />
          </div>
          <div style={{ width: '40%' }}>
            <Button
              style={{ backgroundColor: '#ff0000' }}
              text={this.props.buttonText}
              type="primary"
              fullSize
              onClick={this.props.callback}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
WarningModal.propTypes = {
  title: PropTypes.any,
  description: PropTypes.any,
  buttonText: PropTypes.string,
  callback: PropTypes.func,
};

const component = connect(
  (state) => {
    return { ...state.user, ...state.auth };
  },
  { ...UserActions, ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(WarningModal));
export default component;
