import * as AccountActionTypes from '@Actions/AccountActions';
import * as UserActionTypes from '@Actions/UserActions';

const initialState = () => ({
  loadingProfileAccount: false,
  profileAccountError: null,

  loadingFollowState: false,
  followStateError: null,

  updatingProfile: false,
  updatedProfile: null,
  updateProfileError: null,

  fetchingAccountCollectibles: false,
  fetchingCollectiblesError: false,

  fetchingAccountCreationCollectibles: false,
  fetchingCreationCollectiblesError: false,

  fetchingHistoryCollectibles: false,
  historyCollectibles: [],
  fetchingHistoryCollectiblesErr: false,

  fetchingBookmarkCollectibles: false,
  bookmarkCollectibles: [],
  fetchingBookmarkCollectiblesErr: false,

  fetchingAccountFollowers: false,
  fetchingAccountFollowersError: null,

  fetchingAccountFollowees: false,
  fetchingAccountFolloweesError: null,

  loadingMentionAccount: false,
  mentionProfile: null,
  loadingMentionAccountError: null
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case AccountActionTypes.GET_ACCOUNT_BY_NAME:
      return {
        ...state,
        loadingProfileAccount: true,
      };
    case AccountActionTypes.GET_ACCOUNT_BY_NAME_SUCCESS:
      return {
        ...state,
        loadingProfileAccount: false,
      };
    case AccountActionTypes.GET_ACCOUNT_BY_NAME_FAILURE:
      return {
        ...state,
        profileAccountError: action.error,
        loadingProfileAccount: false,
      };
    case AccountActionTypes.GET_ACCOUNT_BY_MENTION:
      return {
        ...state,
        loadingMentionAccount: true,
      };
    case AccountActionTypes.GET_ACCOUNT_BY_MENTION_SUCCESS:
      return {
        ...state,
        loadingMentionAccount: false,
        mentionProfile: action.payload,
      };
    case AccountActionTypes.GET_ACCOUNT_BY_MENTION_FAILURE:
      return {
        ...state,
        loadingMentionAccount: false,
        loadingMentionAccountError: action.error,
      };
    case AccountActionTypes.UPDATE_ACCOUNT_PROFILE:
      return {
        ...state,
        updatingProfile: true,
      };
    case AccountActionTypes.UPDATE_ACCOUNT_PROFILE_SUCCESS:
      return {
        ...state
      };
    case AccountActionTypes.UPDATE_ACCOUNT_PROFILE_FAILURE:
      return {
        ...state,
        updateProfileError: action.error,
        updatingProfile: false,
      };
    case AccountActionTypes.FOLLOW_ACCOUNT:
    case AccountActionTypes.UNFOLLOW_ACCOUNT:
    case AccountActionTypes.IS_FOLLOWING_ACCOUNT:
      return {
        ...state,
        loadingFollowState: true,
      };
    case AccountActionTypes.IS_FOLLOWING_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingFollowState: false,
      };
    case AccountActionTypes.FOLLOW_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingFollowState: false,
      };
    case AccountActionTypes.UNFOLLOW_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingFollowState: false,
      };
    case AccountActionTypes.MUTE_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case AccountActionTypes.UNMUTE_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case AccountActionTypes.BLOCK_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case AccountActionTypes.UNBLOCK_ACCOUNT_SUCCESS:
      return {
        ...state,
      };
    case AccountActionTypes.FOLLOW_ACCOUNT_FAILURE:
    case AccountActionTypes.UNFOLLOW_ACCOUNT_FAILURE:
    case AccountActionTypes.GET_ACCOUNT_OWNED_COLLECTIBLES:
      return {
        ...state,
        fetchingAccountCollectibles: true,
      };
    case AccountActionTypes.GET_ACCOUNT_OWNED_COLLECTIBLES_SUCCESS: {
      return {
        ...state,
        fetchingAccountCollectibles: false,
      };
    }
    case AccountActionTypes.GET_ACCOUNT_OWNED_COLLECTIBLES_FAILURE:
      return {
        ...state,
        fetchingAccountCollectibles: false,
        fetchingCollectiblesError: action.error,
      };
    case AccountActionTypes.GET_ACCOUNT_CREATED_COLLECTIBLES:
      return {
        ...state,
        fetchingAccountCreationCollectibles: true,
      };
    case AccountActionTypes.GET_ACCOUNT_CREATED_COLLECTIBLES_SUCCESS: {
      return {
        ...state,
        fetchingAccountCreationCollectibles: false,
      };
    }
    case AccountActionTypes.GET_ACCOUNT_CREATED_COLLECTIBLES_FAILURE:
      return {
        ...state,
        fetchingAccountCreationCollectibles: false,
        fetchingCreationCollectiblesError: action.error,
      };
    case AccountActionTypes.GET_HISTORY:
      return {
        ...state,
        fetchingHistoryCollectibles: true,
      };
    case AccountActionTypes.GET_HISTORY_SUCCESS: {
      const historyCollectiblesArr =
        state.historyCollectibles.length > 0
          ? [...state.historyCollectibles, ...action.payload.value]
          : action.payload.value;

      return {
        ...state,
        fetchingHistoryCollectibles: false,
        historyCollectibles: historyCollectiblesArr,
      };
    }
    case AccountActionTypes.GET_HISTORY_FAILURE:
      return {
        ...state,
        fetchingHistoryCollectibles: false,
        fetchingHistoryCollectiblesErr: action.error,
      };

    case AccountActionTypes.GET_BOOKMARKS:
      return {
        ...state,
        fetchingBookmarkCollectibles: true,
      };
    case AccountActionTypes.GET_BOOKMARKS_SUCCESS: {  
      const bookmarkCollectiblesArr =
        state.bookmarkCollectibles.length > 0
          ? [...state.bookmarkCollectibles, ...action.payload.value]
          : action.payload.value;
          
      return {
        ...state,
        fetchingBookmarkCollectibles: false,
        bookmarkCollectibles: bookmarkCollectiblesArr,
      };
    }
    case AccountActionTypes.GET_BOOKMARKS_FAILURE:
      return {
        ...state,
        fetchingBookmarkCollectibles: false,
        fetchingBookmarkCollectiblesErr: action.error,
      };
    case AccountActionTypes.GET_ACCOUNT_FOLLOWEES:
      return {
        ...state,
        fetchingAccountFollowees: true,
      };
    case AccountActionTypes.GET_ACCOUNT_FOLLOWEES_SUCCESS: {
      return {
        ...state,
        fetchingAccountFollowees: false,
      };
    }
    case AccountActionTypes.GET_ACCOUNT_FOLLOWEES_FAILURE:
      return {
        fetchingAccountFollowees: false,
        fetchingAccountFolloweesError: action.error,
      };
    case AccountActionTypes.GET_ACCOUNT_FOLLOWERS:
      return {
        ...state,
        fetchingAccountFollowers: true,
      };
    case AccountActionTypes.GET_ACCOUNT_FOLLOWERS_SUCCESS: {
      return {
        ...state,
        fetchingAccountFollowers: false,
      };
    }
    case AccountActionTypes.GET_ACCOUNT_FOLLOWERS_FAILURE:
      return {
        fetchingAccountFollowers: false,
        fetchingAccountFollowersError: action.error,
      };
    case AccountActionTypes.ENABLE_FOLLOWEE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        profileAccount: {
          ...state.profileAccount,
          actorFolloweeNotificationsEnabled: true,
        },
      };
    case AccountActionTypes.DISABLE_FOLLOWEE_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        profileAccount: {
          ...state.profileAccount,
          actorFolloweeNotificationsEnabled: false,
        },
      };
    case AccountActionTypes.SET_ACTIVE_PROFILE_ACCOUNT:
      return {
        ...state,
        activeProfileAccountId: action.payload.activeProfileAccountId
      }
    default:
      return state;
  }
};
