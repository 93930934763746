import * as Yup from 'yup';

import ArtImage from '@Images/category-images/category-art@2x.png';
import ComicsImage from '@Images/category-images/category-comics@2x.png';
// import DefiImage from '@Images/category-images/category-defi@2x.png';
// import DomainImage from '@Images/category-images/category-domain@2x.png';
import GameImage from '@Images/category-images/category-game@2x.png';
import MetaverseImage from '@Images/category-images/category-metaverse@2x.png';
import MovieImage from '@Images/category-images/category-movie@2x.png';
import MusicImage from '@Images/category-images/category-music@2x.png';
import PhotographyImage from '@Images/category-images/category-photography@2x.png';
import SportsImage from '@Images/category-images/category-sports@2x.png';

import EthCircle from '@Images/currency/circle/ETH.svg';
import WethCircle from '@Images/currency/circle/WETH.svg';
import MaticCircle from '@Images/currency/circle/MATIC.svg';
import WmaticCircle from '@Images/currency/circle/WMATIC.svg';
import AvaxCircle from '@Images/currency/circle/AVAX.svg';
import WavaxCircle from '@Images/currency/circle/WAVAX.svg';
import BnbCircle from '@Images/currency/circle/BNB.svg';
import WbnbCircle from '@Images/currency/circle/WBNB.svg';
import CroCircle from '@Images/currency/circle/CRO.svg';
import WcroCircle from '@Images/currency/circle/WCRO.svg';
import DaiCircle from '@Images/currency/circle/DAI.svg';
import UsdcCircle from '@Images/currency/circle/USDC.svg';
import BusdCircle from '@Images/currency/circle/BUSD.svg';

export const URL_REGEX =
  'https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,}';
const URL_REGEX_WITHOUT_HTTP =
  /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#()?&//=]*)/g;
const PROFILE_FILE_SIZE = 2097152;
const SECONDS_IN_HOUR = 60 * 60;
export const SECONDS_IN_MS = 1000;
export const SECONDS_IN_MINUTE = 60 * SECONDS_IN_MS;
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';
const BPS = 100;
const CURRENCY = {
  ETH: 'ETH',
  WETH: 'WETH',
  DAI: 'DAI',
  USDC: 'USDC',
  USDT: 'USDT',
  MATIC: 'MATIC',
  WMATIC: 'WMATIC',
  BNB: 'BNB',
  WBNB: 'WBNB',
  BUSD: 'BUSD',
  AVAX: 'AVAX',
  WAVAX: 'WAVAX',
  CRO: 'CRO',
  WCRO: 'WCRO',
};

export const SENSITIVE_CONTENT = 'This item has sensitive content';
export const UNLOCKABLE_CONTENT = 'This item has unlockable content';

const AppVariables = {
  supportedWallets: [
    { id: 1, name: 'MetaMask', value: '', image: 'metamask.svg', redirect: 'https://metamask.app.link/' },
    { id: 2, name: 'MetaMask', value: 'walletconnect', image: 'metamask.svg' },
    { id: 3, name: 'Web3', value: '', image: 'web3.svg' },
    //    { id: 4, name: 'Portis', value: 'portis', image: 'portis.svg' },
    //    { id: 5, name: 'Torus', value: 'torus', image: 'torus.svg' },
    { id: 6, name: 'WalletConnect', value: 'walletconnect', image: 'walletconnect.svg' },
    { id: 7, name: 'Coinbase', value: 'walletlink', image: 'coinbase.png' },
    //    { id: 8, name: 'Fortmatic', value: 'fortmatic', image: 'fortmatic.svg' },
    { id: 9, name: 'Unstoppable', value: 'uauth', image: 'unstoppable.svg' },
  ],
  JSON_RPC_ERROR_MESSAGE: 'Internal JSON-RPC error.',
  JSON_RPC_ERROR_MAP: {
    4001: 'public.errorMapping.1000',
    4100: 'public.errorMapping.1001',
    4200: 'public.errorMapping.1002',
    4900: 'public.errorMapping.1003',
    4901: 'public.errorMapping.1004',
    '-32700': 'public.errorMapping.1005',
    '-32600': 'public.errorMapping.1006',
    '-32601': 'public.errorMapping.1007',
    '-32602': 'public.errorMapping.1008',
    '-32603': {
      'transaction underpriced': 'public.errorMapping.1010',
      default: 'public.errorMapping.1009',
    },
    '-32000': 'public.errorMapping.1011',
    '-32001': 'public.errorMapping.1012',
    '-32002': 'public.errorMapping.1013',
    '-32003': 'public.errorMapping.1014',
    '-32004': 'public.errorMapping.1015',
    '-32005': 'public.errorMapping.1016',
  },
  chains: [
    // Variant: Ethereum, Polygon, Avalanche, Binance, Default: Green
    // id parameter refers to the decimal of the chain id
    {
      id: 1,
      name: 'Ethereum',
      explorerName: 'Etherscan',
      icon: 'ethereum',
      variant: 'ethereum',
      slug: 'ethereum',
      chainInfo: {
        chainId: '0x1',
        chainName: 'Ethereum Mainnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`https://rpc.ankr.com/eth`],
        blockExplorerUrls: ['https://etherscan.io'],
      },
      wallets: {
        portis: true,
      },
      supportsDonations: true,
      supportsGroupBuying: true,
      currencies: [CURRENCY.WETH, CURRENCY.DAI, CURRENCY.USDC],
    },
    {
      id: 3,
      name: 'Ropsten Testnet',
      explorerName: 'Etherscan',
      icon: 'ethereum',
      variant: 'ethereum',
      slug: 'ropsten',
      chainInfo: {
        chainId: '0x3',
        chainName: 'Ropsten Testnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`https://rpc.ankr.com/eth_ropsten`],
        blockExplorerUrls: ['https://ropsten.etherscan.io'],
      },
      wallets: {
        portis: true,
      },
      testnet: true,
      supportsDonations: true,
      nonProfitChainId: 1,
      currencies: [CURRENCY.WETH, CURRENCY.DAI, CURRENCY.USDC],
    },
    {
      id: 4,
      name: 'Rinkeby Testnet',
      explorerName: 'Etherscan',
      icon: 'ethereum',
      variant: 'ethereum',
      slug: 'rinkeby',
      chainInfo: {
        chainId: '0x4',
        chainName: 'Rinkeby Testnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`https://rpc.ankr.com/eth_rinkeby`],
        blockExplorerUrls: ['https://rinkeby.etherscan.io'],
      },
      wallets: {
        portis: true,
      },
      testnet: true,
      supportsDonations: true,
      nonProfitChainId: 1,
      currencies: [CURRENCY.WETH, CURRENCY.DAI, CURRENCY.USDC],
    },
    {
      id: 5,
      name: 'Goerli Testnet',
      explorerName: 'Etherscan',
      icon: 'ethereum',
      variant: 'ethereum',
      slug: 'goerli',
      chainInfo: {
        chainId: '0x5', // 5
        chainName: 'Goerli Testnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: [`https://rpc.ankr.com/eth_goerli`],
        blockExplorerUrls: ['https://goerli.etherscan.io'],
      },
      wallets: {
        portis: true,
      },
      testnet: true,
      supportsDonations: true,
      nonProfitChainId: 1,
      supportsGroupBuying: true,
      currencies: [CURRENCY.WETH, CURRENCY.DAI, CURRENCY.USDC],
    },
    {
      id: 137,
      name: 'Polygon',
      explorerName: 'Polygonscan',
      icon: 'polygon',
      variant: 'polygon',
      slug: 'polygon',
      chainInfo: {
        chainId: '0x89', // 137
        chainName: 'Polygon Mainnet',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: [`https://polygon-rpc.com`],
        blockExplorerUrls: ['https://polygonscan.com'],
      },
      wallets: {
        portis: true,
      },
      supportsGroupBuying: true,
      currencies: [CURRENCY.WMATIC, CURRENCY.DAI, CURRENCY.USDC],
      gasPriceMultiplier: 1.25, // This is here so that we can offset the gas price so we move closer to the aggressive
    },
    {
      id: 80001,
      name: 'Polygon Testnet',
      explorerName: 'Polygonscan',
      icon: 'polygon',
      variant: 'polygon',
      slug: 'polygon_mumbai',
      chainInfo: {
        chainId: '0x13881', // 80001
        chainName: 'Polygon Testnet',
        nativeCurrency: {
          name: 'MATIC',
          symbol: 'MATIC',
          decimals: 18,
        },
        rpcUrls: [`https://matic-mumbai.chainstacklabs.com`],
        blockExplorerUrls: ['https://mumbai.polygonscan.com'],
      },
      wallets: {
        portis: true,
      },
      testnet: true,
      supportsGroupBuying: true,
      currencies: [CURRENCY.WMATIC, CURRENCY.DAI],
      gasPriceMultiplier: 1.25, // This is here so that we can offset the gas price so we move closer to the aggressive
    },
    {
      id: 56,
      name: 'BNB Smart Chain',
      explorerName: 'BscScan',
      icon: 'binance',
      variant: 'binance',
      slug: 'binance',
      chainInfo: {
        chainId: '0x38', // 56
        chainName: 'BNB Smart Chain',
        nativeCurrency: {
          name: 'BNB',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://bsc-dataseed1.binance.org'],
        blockExplorerUrls: ['https://bscscan.com'],
      },
      currencies: [CURRENCY.WBNB, CURRENCY.BUSD, CURRENCY.DAI],
    },
    {
      id: 97,
      name: 'BNB Smart Chain Testnet',
      explorerName: 'BscScan',
      icon: 'binance',
      variant: 'binance',
      slug: 'binance_testnet',
      chainInfo: {
        chainId: '0x61', // 97
        chainName: 'BNB Smart Chain Testnet',
        nativeCurrency: {
          name: 'Test BNB',
          symbol: 'BNB',
          decimals: 18,
        },
        rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545'],
        blockExplorerUrls: ['https://testnet.bscscan.com'],
      },
      wallets: {
        portis: false,
      },
      currencies: [CURRENCY.WBNB, CURRENCY.BUSD],
    },
    {
      id: 42161,
      name: 'Arbitrum',
      icon: 'default',
      variant: 'default',
      slug: 'arbitrum',
      chainInfo: {
        chainId: '0xa4b1', // 42161
        chainName: 'Arbitrum Mainnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://arb1.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://arbiscan.io'],
      },
    },
    {
      id: 421611,
      name: 'Arbitrum Testnet',
      icon: 'default',
      variant: 'default',
      slug: 'arbitrum_testnet',
      chainInfo: {
        chainId: '0x66eeb', // 421611
        chainName: 'Arbitrum Testnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://rinkeby.arbitrum.io/rpc'],
        blockExplorerUrls: ['https://testnet.arbiscan.io'],
      },
      testnet: true,
    },
    {
      id: 43113,
      name: 'Avalanche Fuji Testnet',
      explorerName: 'Snowtrace',
      icon: 'avalanche',
      variant: 'avalanche',
      slug: 'avalanche_fuji',
      chainInfo: {
        chainId: '0xa869', // 43113
        chainName: 'Avalanche Testnet',
        nativeCurrency: {
          name: 'Avalanche',
          symbol: 'AVAX',
          decimals: 18,
        },
        rpcUrls: ['https://api.avax-test.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://testnet.snowtrace.io'],
      },
      currencies: [CURRENCY.WAVAX, CURRENCY.USDC],
      nonProfitChainId: 43114,
      supportsDonations: true,
      testnet: true,
    },
    {
      id: 43114,
      name: 'Avalanche',
      explorerName: 'Snowtrace',
      icon: 'avalanche',
      variant: 'avalanche',
      slug: 'avalanche',
      chainInfo: {
        chainId: '0xa86a', // 43114
        chainName: 'Avalanche Mainnet',
        nativeCurrency: {
          name: 'Avalanche',
          symbol: 'AVAX',
          decimals: 18,
        },
        rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
        blockExplorerUrls: ['https://snowtrace.io'],
      },
      supportsDonations: true,
      currencies: [CURRENCY.WAVAX, CURRENCY.USDC, CURRENCY.DAI],
    },
    {
      id: 25,
      name: 'Cronos Mainnet',
      icon: 'default',
      variant: 'default',
      slug: 'cronos',
      chainInfo: {
        chainId: '0x19', // 25
        chainName: 'Cronos Mainnet',
        nativeCurrency: {
          name: 'Cronos',
          symbol: 'CRO',
          decimals: 18,
        },
        rpcUrls: ['https://evm.cronos.org/'],
        blockExplorerUrls: ['https://cronoscan.com'],
      },
      currencies: [CURRENCY.WCRO],
    },
    {
      id: 338,
      name: 'Cronos Testnet',
      icon: 'default',
      variant: 'default',
      slug: 'cronos_testnet',
      chainInfo: {
        chainId: '0x152', // 338
        chainName: 'Cronos Testnet',
        nativeCurrency: {
          name: 'Test Cronos',
          symbol: 'CRO',
          decimals: 18,
        },
        rpcUrls: ['https://evm-t3.cronos.org/'],
        blockExplorerUrls: ['https://testnet.cronoscan.com'],
      },
      currencies: [CURRENCY.WCRO],
      testnet: true,
    },

    {
      id: 1337,
      name: 'Local',
      icon: 'default',
      variant: 'default',
      slug: 'local',
      chainInfo: {
        chainId: '0x539', // 1337
        chainName: 'Local Testnet',
        nativeCurrency: {
          name: 'Ether',
          symbol: 'ETH',
          decimals: 18,
        },
        rpcUrls: ['https://127.0.0.1:7545'],
        blockExplorerUrls: ['https://ganache'],
      },
      wallets: {
        portis: true,
      },
      testnet: true,
      currencies: [CURRENCY.WETH, CURRENCY.DAI, CURRENCY.USDC],
    },
  ],
  collectibleCategories: [
    { Id: 1, Name: 'Art', IconSrc: 'fas fa-image', ReactIconSrc: 'FaImage', Img: ArtImage },
    { Id: 2, Name: 'Photography', IconSrc: 'fas fa-camera', ReactIconSrc: 'FaCamera', Img: PhotographyImage },
    { Id: 3, Name: 'Game', IconSrc: 'fas fa-gamepad', ReactIconSrc: 'FaGamepad', Img: GameImage },
    { Id: 4, Name: 'Music', IconSrc: 'fas fa-music', ReactIconSrc: 'FaMusic', Img: MusicImage },
    {
      Id: 5,
      Name: 'Metaverse',
      IconSrc: 'fas fa-greater-than-equal',
      ReactIconSrc: 'FaGreaterThanEqual',
      Img: MetaverseImage,
    },
    // { Id: 6, Name: 'Domains', IconSrc: 'fas fa-globe', ReactIconSrc: 'FaGlobe', Img: DomainImage },
    // { Id: 7, Name: 'DeFi', IconSrc: 'fas fa-coins', ReactIconSrc: 'FaCoins', Img: DefiImage },
    { Id: 8, Name: 'Movies', IconSrc: 'fas fa-film', ReactIconSrc: 'FaFilm', Img: MovieImage },
    { Id: 9, Name: 'Sports', IconSrc: 'fas fa-basketball-ball', ReactIconSrc: 'FaBasketballBall', Img: SportsImage },
    { Id: 10, Name: 'Comics', IconSrc: 'fas fa-comments', ReactIconSrc: 'FaComments', Img: ComicsImage },
  ],
  mimeTypes: [
    { extension: '.jpe', mimeType: 'image/jpeg' },
    { extension: '.jpg', mimeType: 'image/jpeg' },
    { extension: '.jpeg', mimeType: 'image/jpeg' },
    { extension: '.png', mimeType: 'image/png' },
    { extension: '.gif', mimeType: 'image/gif' },
    { extension: '.glb', mimeType: 'model/gltf-binary' },
    { extension: '.cbr', mimeType: 'application/vnd.comicbook-rar' },
    { extension: '.cbz', mimeType: 'application/vnd.comicbook+zip' },
    { extension: '.mp4', mimeType: 'video/mp4' },
    { extension: '.mov', mimeType: 'video/quicktime' },
    { extension: '.mp3', mimeType: 'audio/mpeg' },
    { extension: '.ttf', mimeType: 'application/font-sfnt' },
    { extension: '.otf', mimeType: 'application/font-sfnt' },
    { extension: '.sbs', mimeType: 'application/octet-stream' },
    { extension: '.sbsas', mimeType: 'application/octet-stream' },
    { extension: '.mobi', mimeType: 'application/x-mobipocket-ebook' },
    { extension: '.epub', mimeType: 'application/epub+zip' },
    { extension: '.azw', mimeType: 'application/vnd.amazon.ebook' },
    { extension: '.hpub', mimeType: 'application/prs.hpub+zip' },
    { extension: '.folio', mimeType: 'application/vnd.adobe.folio+zip' },
    { extension: '.pdf', mimeType: 'application/pdf' },
    { extension: '.psd', mimeType: 'application/octet-stream' },
    { extension: '.ai', mimeType: 'application/postscript' },
    { extension: '.eps', mimeType: 'application/postscript' },
    { extension: '.zip', mimeType: 'application/zip' },
  ],
  royaltiesOptions: [
    { label: 'None', royalties: 0 },
    { label: '5%', royalties: 5 * BPS },
    { label: '10%', royalties: 10 * BPS },
    { label: '15%', royalties: 15 * BPS },
    { label: '20%', royalties: 20 * BPS },
    { label: '25%', royalties: 25 * BPS },
    { label: '30%', royalties: 30 * BPS },
  ],
  receiverKinds: {
    Regular: 1,
    Donation: 2,
    AddressOnly: 3,
  },
  reserveAuctionDurations: [
    // 3600 seconds in 1 hour
    { label: '1 hour', seconds: 1 * SECONDS_IN_HOUR },
    { label: '3 hours', seconds: 3 * SECONDS_IN_HOUR },
    { label: '6 hours', seconds: 6 * SECONDS_IN_HOUR },
    { label: '12 hours', seconds: 12 * SECONDS_IN_HOUR },
    { label: '24 hours', seconds: 24 * SECONDS_IN_HOUR },
    { label: '36 hours', seconds: 36 * SECONDS_IN_HOUR },
    { label: '48 hours', seconds: 48 * SECONDS_IN_HOUR },
    { label: '72 hours', seconds: 72 * SECONDS_IN_HOUR },
  ],
  reportTypes: [
    {
      Id: 1,
      Name: 'Intellectual property or copyrights',
      Value: 'Intellectual_Property_Copyrights',
    },
    {
      Id: 2,
      Name: 'False news or Information',
      Value: 'False_News_Information',
    },
    { Id: 3, Name: 'Fraud or scam', Value: 'Fraud_scam' },
    { Id: 4, Name: 'Violence', Value: 'Violence' },
    { Id: 5, Name: 'Unauthorized sales', Value: 'Unauthorized_Sales' },
    { Id: 6, Name: 'Terrorism', Value: 'Terrorism' },
    { Id: 7, Name: 'Hate speech', Value: 'Hate_Speech' },
  ],
  cryptoCurrencies: [
    {
      Id: 1,
      Name: 'Ether',
      Symbol: CURRENCY.ETH,
      Class: 'ETH',
      Gecko: 'ethereum',
      Decimals: 18,
      Icon: 'ETH.svg',
      CircleIcon: EthCircle,
    },
    {
      Id: 2,
      Name: 'Wrapped Ether',
      Symbol: CURRENCY.WETH,
      Class: 'ERC20',
      Gecko: 'weth',
      Decimals: 18,
      Icon: 'WETH.svg',
      CircleIcon: WethCircle,
    },
    {
      Id: 3,
      Name: 'USD Coin',
      Symbol: CURRENCY.USDC,
      Class: 'ERC20',
      Gecko: 'usd-coin',
      Decimals: 6,
      Icon: 'USDC.svg',
      CircleIcon: UsdcCircle,
    },
    {
      Id: 4,
      Name: 'DAI Stablecoin',
      Symbol: CURRENCY.DAI,
      Class: 'ERC20',
      Gecko: 'dai',
      Decimals: 18,
      Icon: 'DAI.svg',
      CircleIcon: DaiCircle,
    },
    {
      Id: 5,
      Name: 'Matic',
      Symbol: CURRENCY.MATIC,
      Class: 'ETH',
      Gecko: 'matic-network',
      Decimals: 18,
      Icon: 'MATIC.svg',
      CircleIcon: MaticCircle,
    },
    {
      Id: 6,
      Name: 'Wrapped Matic',
      Symbol: CURRENCY.WMATIC,
      Class: 'ERC20',
      Gecko: 'wmatic',
      Decimals: 18,
      Icon: 'WMATIC.svg',
      CircleIcon: WmaticCircle,
    },
    {
      Id: 7,
      Name: 'Binance Coin',
      Symbol: CURRENCY.BNB,
      Class: 'ETH',
      Gecko: 'binancecoin',
      Decimals: 18,
      Icon: 'BNB.svg',
      CircleIcon: BnbCircle,
    },
    {
      Id: 8,
      Name: 'Wrapped BNB',
      Symbol: CURRENCY.WBNB,
      Class: 'ERC20',
      Gecko: 'wbnb',
      Decimals: 18,
      Icon: 'WBNB.svg',
      CircleIcon: WbnbCircle,
    },
    {
      Id: 9,
      Name: 'Avalanche',
      Symbol: CURRENCY.AVAX,
      Class: 'ETH',
      Gecko: 'avalanche-2',
      Decimals: 18,
      Icon: 'AVAX.svg',
      CircleIcon: AvaxCircle,
    },
    {
      Id: 10,
      Name: 'Wrapped AVAX',
      Symbol: CURRENCY.WAVAX,
      Class: 'ERC20',
      Gecko: 'wrapped-avax',
      Decimals: 18,
      Icon: 'WAVAX.svg',
      CircleIcon: WavaxCircle,
    },
    {
      Id: 11,
      Name: 'Cronos',
      Symbol: CURRENCY.CRO,
      Class: 'ETH',
      Gecko: 'crypto-com-chain',
      Decimals: 18,
      Icon: 'CRO.svg',
      CircleIcon: CroCircle,
    },
    {
      Id: 12,
      Name: 'Wrapped CRO',
      Symbol: CURRENCY.WCRO,
      Class: 'ERC20',
      Gecko: 'wrapped-cro',
      Decimals: 18,
      Icon: 'WCRO.svg',
      CircleIcon: WcroCircle,
    },
    {
      Id: 13,
      Name: 'Binance USD Coin',
      Symbol: CURRENCY.BUSD,
      Class: 'ERC20',
      Gecko: 'binance-usd',
      Decimals: 18,
      Icon: 'BUSD.svg',
      CircleIcon: BusdCircle,
    },
  ],
  validationRules: {
    requiredText: /.+/,
    password_1: /^.{8,24}$/,
    password_2: (val) => /[a-z]/.test(val) && /[A-Z]/.test(val) && /[^A-Za-z0-9\s]/.test(val),
    // eslint-disable-next-line no-useless-escape
    email: /[a-zA-Z0-9\.\-_]+@[a-zA-Z0-9_]+\.[a-zA-Z0-9]+/,
    username_1: /^.{2,15}$/,
    username_2: (val) => !/[^abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890_]/.test(val),
  },
  validationSchemas: {
    commentSchema: Yup.object().shape({
      // content: Yup.string().required('public.inputValid.required'),
      parentCommentId: Yup.number().nullable(),
    }),
    passwordChangeSchema: Yup.object().shape({
      oldPassword: Yup.string().required('public.inputValid.required'),
      newPassword: Yup.string()
        .min(8, 'public.inputValid.passwordMin8Characters')
        .max(24, 'public.inputValid.passwordMax24Characters')
        .matches(/^[^\s]+$/, 'public.inputValid.passwordNotContainSpace')
        .matches(/[a-z]/, 'public.inputValid.passwordNeedLowercase')
        .matches(/[A-Z]/, 'public.inputValid.passwordNeedUppercase')
        .matches(/[0-9]/, 'public.inputValid.passwordNeedDigit')
        .matches(/[^A-Za-z0-9\s]/, 'public.inputValid.passwordNeedSymbol')
        .required('public.inputValid.required'),
      confirmPassword: Yup.string()
        .min(8, 'public.inputValid.passwordMin8Characters')
        .max(24, 'public.inputValid.passwordMax24Characters')
        .matches(/^[^\s]+$/, 'public.inputValid.passwordNotContainSpace')
        .matches(/[a-z]/, 'public.inputValid.passwordNeedLowercase')
        .matches(/[A-Z]/, 'public.inputValid.passwordNeedUppercase')
        .matches(/[0-9]/, 'public.inputValid.passwordNeedDigit')
        .matches(/[^A-Za-z0-9\s]/, 'public.inputValid.passwordNeedSymbol')
        .required('public.inputValid.required'),
    }),
    faVerificationCodeSchema: Yup.object().shape({
      code: Yup.string()
        .required('public.inputValid.required')
        .min(6, 'public.inputValid.verificationCodeMin6')
        .max(7, 'public.inputValid.verificationCodeMax7'),
    }),
    faNewPhoneNumberSchema: Yup.object().shape({
      newPhoneNumber: Yup.string().required('public.inputValid.required'),
    }),
    sendFeedbackSchema: Yup.object().shape({
      message: Yup.string().required('public.inputValid.required'),
      file: Yup.mixed(),
    }),
    developerSettingsSchema: Yup.object().shape({
      pingEndpoint: Yup.string().required('public.inputValid.required'),
    }),
    editProfileSchema: Yup.object().shape({
      username: Yup.string()
        .min(3, 'public.inputValid.usernameMin3Characters')
        .max(15, 'public.inputValid.usernameMax15Characters')
        .matches(/^(?!.*[_]{3})\w{3,15}$/, 'public.inputValid.usernameRules')
        .required('public.inputValid.required'),

      Name: Yup.string(),
      // eslint-disable-next-line react/forbid-prop-types
      category: Yup.object().required('public.inputValid.required').nullable(),
      about: Yup.string()
        .trim()
        .required('public.inputValid.required')
        .max(300, 'public.inputValid.editProfileAboutMax'),
      website: Yup.string().matches(URL_REGEX_WITHOUT_HTTP, 'public.inputValid.urlNotValid').nullable(),
      url: Yup.mixed().nullable(),
      profileImage: Yup.mixed()
        .test('fileSize', 'public.inputValid.fileIsTooLarge', (value) => {
          if (!value) return true;
          return value.size < PROFILE_FILE_SIZE;
        })
        .nullable(),
      instagram: Yup.string().nullable(),
      linkedin: Yup.string().nullable(),
      youTube: Yup.string().matches(URL_REGEX, 'public.inputValid.urlNotValid').nullable(),
      twitter: Yup.string().nullable(),
      pinterest: Yup.string().nullable(),
      facebook: Yup.string().nullable(),
      tikTok: Yup.string().nullable(),
      discord: Yup.string()
        // copied from https://stackoverflow.com/a/51507374/1837427
        .matches(/^((.+?)#\d{4})/, 'public.inputValid.discordUrlNotValid')
        .nullable(),
    }),
    editCollectionSchema: Yup.object().shape({
      slug: Yup.string()
        .min(3, 'public.inputValid.slugMin3Characters')
        .max(15, 'public.inputValid.slugMax15Characters')
        .matches(/^(?!.*[_]{3})\w{3,15}$/, 'public.inputValid.slugRules')
        .required('public.inputValid.required'),

      Name: Yup.string(),
      // eslint-disable-next-line react/forbid-prop-types
      about: Yup.string()
        .required('public.inputValid.required')
        .max(300, 'public.inputValid.editCollectionAboutMax300'),
      website: Yup.string().matches(URL_REGEX_WITHOUT_HTTP, 'public.inputValid.urlNotValid').nullable(),
      coverImageUrl: Yup.mixed().nullable(),
      profileImage: Yup.mixed().nullable(),
      url: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size >= PROFILE_FILE_SIZE,
        )
        .nullable(),
      coverUrl: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size >= PROFILE_FILE_SIZE,
        )
        .nullable(),
      instagram: Yup.string().nullable(),
      linkedin: Yup.string().nullable(),
      youTube: Yup.string().matches(URL_REGEX, 'public.inputValid.urlNotValid').nullable(),
      twitter: Yup.string().nullable(),
      pinterest: Yup.string().nullable(),
      facebook: Yup.string().nullable(),
      tikTok: Yup.string().nullable(),
      discord: Yup.string()
        // copied from https://stackoverflow.com/a/51507374/1837427
        .matches(/^((.+?)#\d{4})/, 'public.inputValid.discordUrlNotValid')
        .nullable(),
      verticalFeatureImageUrl: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
      verticalFeatureImage: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
      horizontalFeatureImage: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
      horizontalFeatureImageUrl: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
    }),
    editProfileImageSchema: Yup.object().shape({
      image: Yup.mixed(),
    }),
    changeUsernameSchema: Yup.object().shape({
      password: Yup.string().required('public.inputValid.required'),
      newUsername: Yup.string()
        .min(3, 'public.inputValid.usernameMin3Characters')
        .max(15, 'public.inputValid.usernameMax15Characters')
        .matches(/^(?!.*[_]{3})\w{3,15}$/, 'public.inputValid.usernameRules')
        .required('public.inputValid.required'),
      confirmNewUsername: Yup.string().required('public.inputValid.required'),
      acceptTerms: Yup.bool().oneOf([true], 'public.inputValid.mustAccceptTerm'),
    }),
    changeEmailSchema: Yup.object().shape({
      newEmail: Yup.string().required('public.inputValid.required'),
    }),
    verificationCodeSchema: Yup.object().shape({
      code: Yup.string()
        .required('public.inputValid.required')
        .min(6, 'public.inputValid.verificationCodeMin6')
        .max(7, 'public.inputValid.verificationCodeMax7'),
    }),
    deleteAccountSchema: Yup.object().shape({
      password: Yup.string().required('public.inputValid.required'),
    }),
    idVerificationSchema: Yup.object().shape({
      firstName: Yup.string().required('public.inputValid.required'),
      lastName: Yup.string(),
      email: Yup.string().required('public.inputValid.required'),
      dateofBirth: Yup.string().required('public.inputValid.required'),
      verificationType: Yup.mixed(),
      driverLicenseCountry: Yup.mixed(),
    }),
    generalInformationSchema: Yup.object().shape({
      type: Yup.mixed(),
      businessName: Yup.string(),
      firstName: Yup.string(),
      lastName: Yup.string(),
      email: Yup.string(),
      dateofBirth: Yup.string(),
      phoneNumber: Yup.string(),
      govermentId: Yup.string(),
      country: Yup.mixed(),
      line1: Yup.string(),
      line2: Yup.string(),
      city: Yup.string(),
      province: Yup.mixed(),
      zip: Yup.string(),
    }),
    shareFriendEmailSchema: Yup.object().shape({
      email: Yup.string()
        // eslint-disable-next-line no-useless-escape
        .matches(/[a-zA-Z0-9\.\-_]+@[\w]+\.[a-zA-Z0-9]+/, 'public.inputValid.urlNotValid')
        .required('public.inputValid.required'),
      name: Yup.string().required('public.inputValid.required'),
    }),
    createCollectibleSchema: Yup.object().shape({
      title: Yup.string().required('public.inputValid.required'),
      description: Yup.string(),
      // eslint-disable-next-line react/forbid-prop-types
      category: Yup.object(),
      hasSensitiveContent: Yup.bool(),
      cover: Yup.mixed(),
    }),
    listCollectibleSchema: Yup.object().shape({
      // TODO: Add more to check this is greater than 0 and less than a certain value
      price: Yup.string().required('public.inputValid.required'),
      currency: Yup.object(),
      duration: Yup.object(),
    }),
    unlistCollectibleByAdminSchema: Yup.object().shape({
      reason: Yup.string().required('public.inputValid.required'),
    }),
    transferCollectibleSchema: Yup.object().shape({
      transferTo: Yup.string()
        .matches(/^0x[a-fA-F0-9]{40}$/, 'public.inputValid.notEthAddress')
        .required('public.inputValid.required'),
    }),
    changePriceSchema: Yup.object().shape({
      // TODO: Add more to check this is greater than 0 and less than a certain value
      price: Yup.string().required('public.inputValid.required'),
      currency: Yup.object(),
    }),
    placeBidSchema: Yup.object().shape({
      // TODO: Add more to check this is greater than 0 and less than a certain value
      bidAmount: Yup.string().required('public.inputValid.required'),
    }),
    createOrEditCollectibleSchema: Yup.object().shape({
      title: Yup.string().trim().required('public.inputValid.createOrEditCollectibleRequiredTitle'),
      description: Yup.string().max(999, 'public.inputValid.createEditCollectibleDescriptionMax'),
      royalties: Yup.object(),
      category: Yup.object(),
      hasSensitiveContent: Yup.bool().required('public.inputValid.required'),
    }),
    createOrEditCollectionSchema: Yup.object().shape({
      name: Yup.string().max(50, 'public.inputValid.nameMax50Characters').required('public.inputValid.required'),
      description: Yup.string().max(299, 'public.inputValid.createEditCollectionDescriptionMax'),
      symbol: Yup.string()
        .min(3, 'public.inputValid.symbolNeedMin3')
        .max(8, 'public.inputValid.symbolNeedMax8')
        .matches(/^[a-zA-Z]{3,8}$/, 'public.inputValid.symbolMustCotainLetter')
        .required('public.inputValid.required'),
      slug: Yup.string()
        .min(3, 'public.inputValid.slugMin3Characters')
        .max(15, 'public.inputValid.slugMax15Characters')
        .matches(/^(?!.*[_]{3})\w{3,15}$/, 'public.inputValid.slugRules')
        .required('public.inputValid.required'),
      coverImageUrl: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
      coverImage: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
      verticalFeatureImageUrl: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
      verticalFeatureImage: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
      horizontalFeatureImage: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
      horizontalFeatureImageUrl: Yup.mixed()
        .test(
          'fileSize',
          'public.inputValid.fileIsTooLarge',
          (value) => !value?.size || value?.size < PROFILE_FILE_SIZE,
        )
        .nullable(),
    }),
  },
  processingStatus: {
    notStarted: 'NotStarted',
    completed: 'Completed',
  },
  externalUrls: {
    blog: 'https://blog.oix.app',
    facebook: 'https://www.facebook.com/oixofficial',
    twitter: 'https://www.twitter.com/oixofficial',
    discord: 'https://discord.gg/oixofficial',
    instagram: 'https://www.instagram.com/oixofficial',
    youtube: 'https://www.youtube.com/@oixofficial',
    medium: 'https://medium.com/oixofficial',
    telegram: 'https://t.me/oixofficial',
  },
  preloadEssentialTimeout: 5000,
  preloadTimeout: 12000,
  languages: [
    { language: 'English', value: 1, code: 'en' },
    { language: 'Español', value: 2, code: 'es' },
    { language: 'Français', value: 3, code: 'fr' },
    { language: 'Português', value: 4, code: 'pt-br' },
    { language: 'Русский', value: 5, code: 'ru' },
    { language: 'Türkçe', value: 6, code: 'tr' },
    { language: '中文', value: 7, code: 'zh-cn' },
  ],
};

export default Object.freeze(AppVariables);
