import React from 'react';
import copy from 'copy-to-clipboard';

import Icon from '@Components/shared/Icon';
import { getShortenedPublicAddress } from '@Utils';
import OiInfoTooltip from './OiInfoTooltip';
import { withTranslation } from 'react-i18next';

class DisplayPublicAddress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      icon: 'copy',
    };
  }

  getPublicAddressFormat = () => {
    const { publicAddress } = this.props;
    return getShortenedPublicAddress(publicAddress);
  };

  copyToClipboard = () => {
    this.setState({ icon: 'check-circle' }, () => {
      const { publicAddress } = this.props;
      copy(publicAddress);
      setTimeout(() => {
        this.setState({ icon: 'copy' });
      }, 2000);
    });
  };

  render() {
    const { t } = this.props;
    const formattedAddress = this.getPublicAddressFormat();

    return !formattedAddress ? null : (
      <OiInfoTooltip placement={'top'} title={t('public.copyUpperCase')}>
        <div
          className="display-public-address"
          id="copy-tooltip"
          onClick={this.copyToClipboard}
          >
          <div className="address"> {formattedAddress} </div>
          <div className={'icon ' + this.state.icon}>
            <Icon src={'far fa-' + this.state.icon} type="fontawesome" />{' '}
          </div>
        </div>
      </OiInfoTooltip>
    );
  }
}

export default withTranslation()(DisplayPublicAddress);
