import React from 'react';
import { useTranslation } from 'react-i18next';

import OiModal from '../shared/OiModal';
import { BlockchainFilter } from './BlockchainFilter';
import CategoryFilter from './CategoryFilter';
import { CurrencyFilter } from './CurrencyFilter';
import { PriceFilter } from './PriceFilter';

export const OiSearchFilterModal = ({
  onHide,
  showBlockchain,
  showCategory,
  showCurrency,
  showPrice,
  isAccountCategory,
}) => {
  const { t } = useTranslation();

  /**
   * render
   */
  return (
    <OiModal className="oi-search-filter-modal" footer={false} title={t('public.searchFilterFiltersText')} onHide={onHide}>
      {showCategory && <CategoryFilter isMobile isAccountCategory={isAccountCategory} />}
      {showBlockchain && <BlockchainFilter isMobile />}
      {showCurrency && <CurrencyFilter isMobile />}
      {showPrice && <PriceFilter isMobile />}
    </OiModal>
  );
};
