import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import AccountReducer from '@Reducers/Account';
import CollectionReducer from '@Reducers/Collection';
import AuthReducer from '@Reducers/Auth';
import CollectibleReducer from '@Reducers/Collectible';
import DiscoverReducer from '@Reducers/Discover';
import FeedReducer from '@Reducers/Feed';
import FeedbackReducer from '@Reducers/Feedback';
import ModalReducer from '@Reducers/Modal';
import NotificationsReducer from '@Reducers/Notifications';
import OnboardingReducer from '@Reducers/Onboarding';
import ProfileReducer from '@Reducers/Profile';
import SearchReducer from '@Reducers/Search';
import ServiceReducer from '@Reducers/Service';
import SpinnerReducer from '@Reducers/Spinner';
import BidsReducer from '@Reducers/Bids';
import UploadReducer from '@Reducers/Upload';
import UserReducer from '@Reducers/User';
import UserNotificationsReducer from '@Reducers/User.Notifications.js';
import UserSettingsReducer from '@Reducers/User.Settings.js';
import WalletReducer from '@Reducers/Wallet';
import CacheReducer from '@Reducers/Cache';

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
const createRootReducer = (history) => {
  const allReducers = combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    account: AccountReducer,
    collection: CollectionReducer,
    collectible: CollectibleReducer,
    profile: ProfileReducer,
    feedback: FeedbackReducer,
    onboarding: OnboardingReducer,
    service: ServiceReducer,
    search: SearchReducer,
    bids: BidsReducer,
    modal: ModalReducer,
    notifications: NotificationsReducer,
    feed: FeedReducer,
    spinner: SpinnerReducer,
    discover: DiscoverReducer,
    user: combineReducers({
      account: UserReducer,
      settings: UserSettingsReducer,
      notifications: UserNotificationsReducer,
    }),
    upload: UploadReducer,
    wallet: WalletReducer,
    cache: CacheReducer,
  });

  const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
      console.log('asssssssssssssssssssssssssssssssssssssssssssssssssssssssssss');
      state = undefined;
    }

    return allReducers(state, action);
  };

  return rootReducer;
};

export default createRootReducer;
