import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { captureMessage } from '@sentry/react';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { toCustomDecimalsFullFixed, getCollectibleChainInfo, getThumbnail, THUMBNAILS } from '@Utils';
import { AnimatedPlaceholderRect } from '@Components/placeholder/PlaceholderComponents';
import { ReactComponent as SoundWave } from '@Images/icons/sound-wave.svg?component';
import { FaDiceD6 } from 'react-icons/fa';
import Icon from '@Components/shared/Icon';
import VideoPlayer from '@Components/shared/VideoPlayer';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import StackableCard from '@Components/shared/StackableCard';
import OiInfoTooltip from '@Components/shared/OiInfoTooltip';
import {
  buildCollectibleLink,
  convertToPoster,
  getCollectibleStatus,
  collectibleStatuses,
  buildCategoryLink,
} from '@Utils';
import AppVariables, { SENSITIVE_CONTENT, UNLOCKABLE_CONTENT } from '@AppVariables';
import moment from 'moment';
import Countdown from 'react-countdown';
import { useLocation, useHistory } from 'react-router';
import useInterval from '@Hooks/use-interval';
import { useMountedState } from 'react-use';
import ChainIcon from '@Components/shared/ChainIcon';
import { useTranslation } from 'react-i18next';
import { runtimeConfig } from '../../config';

const CARD_IMAGE_CHECK_INTERVAL = 3000;

const CollectibleCard = ({ user, account, collectible, getCollectibles, getCollectible }) => {
  const { processingStatus } = AppVariables;

  const isPreviewProcessing =
    collectible &&
    collectible.previewProcessingStatus !== processingStatus.notStarted &&
    collectible.previewProcessingStatus !== processingStatus.completed;

  const isCoverProcessing =
    collectible &&
    collectible.coverProcessingStatus !== processingStatus.notStarted &&
    collectible.coverProcessingStatus !== processingStatus.completed;

  /**
   * state
   */
  const [delay, setDelay] = useState(isPreviewProcessing || isCoverProcessing ? CARD_IMAGE_CHECK_INTERVAL : null);

  /**
   * hooks
   */
  const history = useHistory();
  const location = useLocation();
  const isMounted = useMountedState();
  const { t } = useTranslation();

  /**
   * variables
   */

  useInterval(() => {
    // We are using the odata version as the collectible cards are used
    // with getCollectibles calls and they pull the data from
    // allCollectibles, which is filled by the actions using getCollectibles
    const query = '$filter=id eq ' + collectible.id;
    getCollectibles(query)
      .then((result) => {
        if (result.error) {
          captureMessage(result.error, 'error');
          if (isMounted) setDelay(null);
          return;
        }

        if (!result.payload?.value?.length) {
          throw new Error('No such collectible was found');
        }

        const collectibleScoped = result.payload.value[0];

        const isPreviewStillProcessing =
          collectibleScoped.previewProcessingStatus !== processingStatus.notStarted &&
          collectibleScoped.previewProcessingStatus !== processingStatus.completed;

        const isCoverStillProcessing =
          collectibleScoped.coverProcessingStatus !== processingStatus.notStarted &&
          collectibleScoped.coverProcessingStatus !== processingStatus.completed;

        if (!(isPreviewStillProcessing || isCoverStillProcessing)) {
          if (isMounted) {
            setDelay(null);
          }
        }
      })
      .catch((error) => {
        // we should not need any error handling here
        captureMessage(error, 'error');
        if (isMounted) {
          setDelay(null);
        }
      });
  }, delay);

  /**
   * helpers
   */

  const getAuctionDate = (collectible) => {
    if (collectible.mostRecentListing.dateAuctionEnding === null) {
      return (
        <div>
          <Icon className="mr-1" src={'fas fa-pause'} type="fontawesome" />
          <Countdown
            daysInHours
            autoStart={false}
            date={moment()
              .add(collectible.mostRecentListing.durationTicks / 10000)
              .toDate()}
            onComplete={() => {
              getCollectible(collectible.id);
            }}
          />
        </div>
      );
    } else if (moment().isBefore(collectible.mostRecentListing.dateAuctionEnding)) {
      return (
        <div>
          <Icon className="mr-1" src={'fas fa-clock'} type="fontawesome" />
          <Countdown
            daysInHours
            autoStart={true}
            date={moment(collectible.mostRecentListing.dateAuctionEnding).toDate()}
            onComplete={() => {
              getCollectible(collectible.id);
            }}
          />
        </div>
      );
    }

    return <></>;
  };

  const getStatus = (kind, collectible) => {
    switch (kind) {
      case collectibleStatuses.DRAFT:
        return (
          <div className={'draft'}>
            <Icon className="mr-1" src={'fas fa-circle'} type="fontawesome" /> {t('public.draft')}
          </div>
        );
      case collectibleStatuses.MINTING:
      case collectibleStatuses.BURNING:
      case collectibleStatuses.LISTING:
      case collectibleStatuses.UNLISTING:
      case collectibleStatuses.UNLISTINGBYADMIN:
      case collectibleStatuses.CHANGINGPRICE:
      case collectibleStatuses.TRANSFERRING:
      case collectibleStatuses.PURCHASING:
      case collectibleStatuses.PLACINGBID:
      case collectibleStatuses.FINALIZING:
        return (
          <div className={'minted'}>
            <Icon className="mr-1" src={'fas fa-circle'} type="fontawesome" /> {t('public.processing')}
          </div>
        );
      case collectibleStatuses.RESERVEAUCTION:
        return getAuctionDate(collectible);
    }
  };

  if (!collectible)
    return (
      <div className="w-100">
        <div style={{ position: 'relative' }}>
          <div style={{ display: 'block', paddingBottom: 'calc(100% + 80px)' }} />
          <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
        </div>
      </div>
    );

  const kind = getCollectibleStatus(collectible, account?.id)?.kind;

  const category = AppVariables.collectibleCategories.find((category) => category.Id === collectible.categoryId);

  const categoryIcon = category?.IconSrc || 'fas fa-circle';
  const categoryName = category?.Name || '';

  const restrictContent =
    collectible.hasSensitiveContent && !(collectible.unrestrictContent || user?.profile?.allowRestrictedContent);

  const blurClassName = restrictContent ? 'blur-15' : '';

  const { icon, variant, name: chainName } = getCollectibleChainInfo(collectible);
  let linkFeatures = {};
  let collectibleLink = buildCollectibleLink(collectible, linkFeatures);
  let collectibleLinkObj = {
    pathname: collectibleLink,
    state: {
      fromOix: true,
      background: linkFeatures.needsBackground ? location : null,
      unrestrictContent: collectible.unrestrictContent,
    },
  };

  const isAudio = collectible.contentType?.startsWith('audio');
  const isModel = collectible.contentType?.startsWith('model');

  /**
   * render
   */

  return (
    <Link to={collectibleLinkObj} key={collectible.id} className="collectible-card2">
      <div className="height-decider"></div>
      <div className="status">{getStatus(kind, collectible)}</div>
      <div className="collectible-top-container">
        {collectible.coverImageUrl?.endsWith('mp4') ? (
          // Do this based on content type?
          <VideoPlayer
            autoPlay={true}
            className={'collectible-video ' + blurClassName}
            loop={true}
            muted={true}
            playsInline={true}
            poster={convertToPoster(collectible.coverImageUrl)}
            source={collectible.coverImageUrl}
          />
        ) : collectible.coverImageUrl ? (
          <img className={'collectible-image ' + blurClassName} src={collectible.coverImageUrl} />
        ) : (isAudio || isModel) &&
          collectible.previewProcessingStatus === AppVariables.processingStatus.completed &&
          collectible.coverProcessingStatus !== AppVariables.processingStatus.completed ? (
          <div className="collectible-cover-image">
            {' '}
            <div className="collectible-cover-image-circle">
              {' '}
              {isAudio ? <SoundWave /> : <FaDiceD6 className="cubes" width={15} />} 
            </div>{' '}
          </div>
        ) : (
          <div className="collectible-cover-image-processing">
            <div className="collectible-cover-image-circle"></div>
            <div className="collectible-cover-image-inner-circle">
              <Icon src="fas fa-icons" type="fontawesome" />
            </div>
          </div>
        )}
        <div className="collectible-icons">
          {collectible.hasSensitiveContent && (
            <OiInfoTooltip placement="left" title={SENSITIVE_CONTENT}>
              <div className="collectible-icon mb-1">
                <Icon src="fas fa-eye-slash" type="fontawesome" />
              </div>
            </OiInfoTooltip>
          )}
          {collectible.hasUnlockableContent && (
            <OiInfoTooltip placement="left" title={UNLOCKABLE_CONTENT}>
              <div className="collectible-icon mb-1">
                <Icon src="fas fa-lock" type="fontawesome" />
              </div>
            </OiInfoTooltip>
          )}
          <OiInfoTooltip placement="left" title={categoryName}>
            <div className="collectible-icon">
              <Icon src={categoryIcon || 'fas fa-circle'} type="fontawesome" />
            </div>
          </OiInfoTooltip>
          {icon && (
            <OiInfoTooltip placement="left" title={chainName}>
              <div className={`mt-1 collectible-icon collectible-icon--borderless collectible-icon--${variant}`}>
                <ChainIcon icon={icon} />
              </div>
            </OiInfoTooltip>
          )}
        </div>
        <div className="creator-images">
          <StackableCard>
            {collectible?.collection && (
              <OiInfoTooltip placement="left" title={`${t('public.collection')} ${collectible?.collection?.name}`}>
                <div
                  onClick={(e) => {
                    e.preventDefault();
                    history.push(`/collection/${collectible?.collection?.slug || collectible?.collection?.id}`);
                  }}>
                  <ProfileAvatar
                    height={25}
                    imageLink={getThumbnail(collectible?.collection.profileImageUrl, THUMBNAILS.XS)}
                    width={25}
                  />
                </div>
              </OiInfoTooltip>
            )}
            <OiInfoTooltip
              placement="left"
              title={`${t('public.creator')} ${collectible?.account.profile.name || collectible?.account.slug}`}>
              <div>
                <ProfileAvatar height={25} profileData={collectible?.account} width={25} />
              </div>
            </OiInfoTooltip>
            {collectible?.owner && (
              <OiInfoTooltip
                placement="left"
                title={`${t('public.owner')} ${collectible?.owner.profile.name || collectible?.owner.slug}`}>
                <div>
                  <ProfileAvatar height={25} profileData={collectible?.owner} width={25} />
                </div>
              </OiInfoTooltip>
            )}
          </StackableCard>
        </div>
        <div className="price-info">
          {kind === collectibleStatuses.FIXEDPRICE ? (
            <div className="price mr-1">
              {toCustomDecimalsFullFixed(
                collectible.mostRecentListing?.price,
                collectible.mostRecentListing?.priceAssetType.decimals,
                2,
              )}
              &nbsp;
              {collectible.mostRecentListing?.priceAssetType.symbol}
            </div>
          ) : kind === collectibleStatuses.RESERVEAUCTION &&
            (collectible.mostRecentListing.dateAuctionEnding === null ||
              moment(collectible.mostRecentListing.dateAuctionEnding).isAfter(moment())) ? (
            <div className="price mr-1">
              {toCustomDecimalsFullFixed(
                collectible.mostRecentListing.highestBid?.amount || collectible.mostRecentListing?.price,
                collectible.mostRecentListing?.priceAssetType.decimals,
                2,
              )}
              &nbsp;
              {collectible.mostRecentListing?.priceAssetType.symbol}
            </div>
          ) : null}
          <div className="edition">1/1</div>
        </div>
      </div>
      <div className="collectible-detail bg-color-2 px-2">
        <div className="collectible-detail-user-info">
          {collectible?.title ? (
            <div className="collectible-title">{collectible?.title}</div>
          ) : (
            <div className="collectible-title collectible-dark-title">{'Title'}</div>
          )}
        </div>
        <div
          className="collectible-category"
          onClick={(e) => {            
            e.preventDefault();
            history.push(buildCategoryLink(collectible.categoryName));
          }}>
          {collectible ? t(`public.${collectible.categoryName}`) : ''}
        </div>
      </div>
    </Link>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  account: state.user.account.account,
});

const actions = {
  getCollectibles: CollectibleActions.getCollectibles,
  getCollectible: CollectibleActions.getCollectible,
};

export default withRouter(connect(mapStateToProps, actions)(CollectibleCard));
