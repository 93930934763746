import * as React from 'react';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import { ModalActions } from '@Actions/ModalActions';
import Button from '@Components/form/Button';
import AvatarEditor from 'react-avatar-editor';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { useTranslation } from 'react-i18next';
import { useState, useRef } from 'react';
import { useEffect } from 'react';

const AVATAR_EDITOR_SIZE = 450;
const AVATAR_EDITOR_PADDING_SIZE = 100;
const DEFAULT_CANVAS_WIDTH = 1200;
function EditProfilePictureModal(props) {
  const avatarEditorWrapRef = useRef(null);
  let editor = '';
  const {
    heightRatio: heightRatioProp,
    borderRadius: borderRadiusProp,
    avatarSize: avatarSizeProp,
    width,
    file,
    title,
  } = props;
  const heightRatio = heightRatioProp || 1;

  /**
   * hooks
   */
  const { t } = useTranslation();
  const [scaleValue, setScaleValue] = useState(1);
  const [avatarEditorSize, setAvatarEditorSize] = useState(avatarSizeProp);
  const [canvasWidth, setCanvasWidth] = useState(width || DEFAULT_CANVAS_WIDTH);
  const borderRadius = borderRadiusProp || canvasWidth;
  const canvasHeight = canvasWidth / heightRatio;

  const setImageSize = (file) => {
    let img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      const defaultWidth = width || DEFAULT_CANVAS_WIDTH;
      const currentWidth = defaultWidth > img.width ? img.width : DEFAULT_CANVAS_WIDTH;
      setCanvasWidth(currentWidth);
    };
  };

  useEffect(() => {
    setImageSize(file);
  }, [file])

  useEffect(() => {
    const handleResize = () => {
      let offset = avatarEditorWrapRef.current.offsetWidth - AVATAR_EDITOR_PADDING_SIZE;
      offset = offset > AVATAR_EDITOR_SIZE ? AVATAR_EDITOR_SIZE : offset;
      setAvatarEditorSize(offset);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [avatarEditorWrapRef]);

  /**
   * functions
   */
  const setEditorRef = (ed) => {
    editor = ed;
  };

  const submitEditProfilePicture = () => {
    props.enableLoader();

    if (editor) {
      const canvasScaled = editor.getImageScaledToCanvas();
      canvasScaled.toBlob((blob) => {
        props.getPicture(blob, URL.createObjectURL(blob));
      }, file.type, 0.8);
    }

    props.disableLoader();
    props.closeModal();
  };

  const onScaleChange = (scaleValueEvent) => {
    const scaleVal = parseFloat(scaleValueEvent.target.value);
    setScaleValue(scaleVal);
  };

  /**
   * render
   */
  return (
    <Modal backdrop closeOnOutsideClick={false} title={title} onOutsideClick>
      <div className="edit-profile-picture-modal my-3 ">
        <div ref={avatarEditorWrapRef} className="avatar-editor-wrapper">
          <AvatarEditor
            ref={setEditorRef}
            border={50}
            borderRadius={borderRadius}
            color={[12, 14, 25, 0.8]}
            height={canvasHeight}
            image={file}
            rotate={0}
            scale={scaleValue}
            style={{ maxWidth: `${avatarEditorSize}px`, maxHeight: `${avatarEditorSize / heightRatio}px` }}
            width={canvasWidth}
          />
        </div>
        <br />
        <br />
        <input
          className="slider mt-2"
          max="3"
          min="1"
          step="0.01"
          type="range"
          value={scaleValue}
          onChange={(e) => {
            onScaleChange(e);
          }}
        />
      </div>
      <div className="footer">
        <div />
        <div>
          <Button text={t('modals.applyButton')} type="primary" onClick={() => submitEditProfilePicture()} />
        </div>
      </div>
    </Modal>
  );
}

const component = connect(
  (state) => {
    return { ...state.profile };
  },
  { ...ModalActions, ...SpinnerActions },
  null,
  { forwardRef: true },
)(EditProfilePictureModal);
export default component;
