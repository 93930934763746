import * as React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import { connect } from 'react-redux';
import Button from '@Components/form/Button';
import bind from 'bind-decorator';
import { getProfileNameOrShortPublicAddress } from '@Utils';
import millify from 'millify';
import { withTranslation } from 'react-i18next';

class AccountCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      accountData: null,
      mouseOverFollowButton: false,
    };
  }

  componentDidMount() {
    this.setState({ accountData: this.props.accountData });
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.accountData) !== JSON.stringify(this.props.accountData)) {
      this.setState({ accountData: this.props.accountData });
    }
  }

  followAccount() {
    const { user } = this.props;
    const authenticated = user && user.profile && user.tokens && Date.now() < user.tokens.expires_at;

    if (!authenticated) {
      this.openConnectWalletModal();
    } else if (this.props.account?.id !== this.state.accountData.id) {
      this.props.followAccount(this.state.accountData.id).then((res) => {
        if (res.payload) {
          this.updateAccountData(res.payload);
        }
      });
    }
  }

  unfollowAccount() {
    const { user } = this.props;
    const authenticated = user && user.profile && user.tokens && Date.now() < user.tokens.expires_at;

    if (!authenticated) {
      this.openConnectWalletModal();
    } else if (this.props.account?.id !== this.state.accountData.id) {
      this.props.openModal('VerifyUnfollowModal', {
        backdrop: true,
        closeOnOutsideClick: true,
        followerData: this.state.accountData,
        updateAccountData: this.updateAccountData,
      });
    }
  }

  @bind
  openConnectWalletModal() {
    this.props.openModal('ConnectWalletModal', {
      onOutsideClick: true,
      closeOnOutsideClick: true,
      backdrop: true,
      callback: () => {
        this.props.closeModal();
      },
      profileAccount: this.props.profileAccount,
    });
  }

  @bind
  updateAccountData(res) {
    this.setState({ accountData: res });
  }

  render() {
    const { t } = this.props;
    const accountData = this.state.accountData;

    if (!accountData)
      return <div></div>;

    return (<Link to={`/${accountData.slug}`} className="account-card">
        <div className="height-decider"></div>
        {/* <div className="ellipsis">
          <Icon type="fontawesome" src="fas fa-ellipsis-h" />
        </div> */}
        <div className="content">
          <div className="image">
            <ProfileAvatar profileData={this.props.accountData} width={80} height={80} />
          </div>
          <div className="account-info mt-1">
            <div className="name">
              {accountData ? getProfileNameOrShortPublicAddress(accountData.slug) : ''}
            </div>
            <div className="category mt-1">{accountData.category?.name}</div>
          </div>
          <div className="follow-count pt-2">
            <span className="pb-1 follower">
              {' '}
              {accountData && millify(accountData.followersCount)} {t('public.followers')}
            </span>
          </div>
          <div className="follow-button mt-1">
            {!this.props.account || this.props.account.id !== accountData?.id ? (
              <Button
                className="mr-2"
                text={
                  accountData.actorFollowee
                    ? this.state.mouseOverFollowButton
                      ? t('public.unfollowButton')
                      : t('public.following')
                    : t('public.follow')
                }
                type={accountData.actorFollowee ? 'follow' : 'primary'}
                fullSize
                onMouseOver={(e) => {
                  this.setState({ mouseOverFollowButton: true });
                }}
                onMouseOut={(e) => {
                  this.setState({ mouseOverFollowButton: false });
                }}
                onClick={(e) => {
                  e.preventDefault();
                  
                  if (!accountData.actorFollowee) this.followAccount();
                  else this.unfollowAccount();
                }}
              />
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </Link>
    );
  }
}
AccountCard.propTypes = {
  accountData: PropTypes.object,
};

const component = connect(
  (state) => {
    return {
      ...state.account,
      ...state.auth,
      ...state.user.account,
      ...state.notifications,
      ...state.profile,
    };
  },
  { ...AccountActions, ...ModalActions },
)(AccountCard);
export default withRouter(withTranslation()(component));
