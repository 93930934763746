import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import { ModalActions } from '@Actions/ModalActions';
import { CollectibleActions, GET_COLLECTIBLES } from '@Actions/CollectibleActions';
import { HashtagActions } from '@Actions/HashtagActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { AccountActions } from '@Actions/AccountActions';
import { SearchActions } from '@Actions/SearchActions';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import { AnimatedPlaceholderRect } from '@Components/placeholder/PlaceholderComponents';
import { InlineLoader } from '@Components/shared/Spinner';
import CollectibleCard from '@Components/shared/CollectibleCard';
import AppScroller from '@Components/shared/AppScroller';
import millify from 'millify';
import { withTranslation } from 'react-i18next';
import { SearchFilterWrapper } from '@Components/searchFilters/FilterWrapper';
import cn from 'classnames';

class SearchAllE extends React.Component {
  TOP = 32;
  constructor(props) {
    super(props);

    this.state = {
      resultCount: 0,
      isFollowingHashtag: false,
      hashtagInfo: null,
      shouldFetch: true,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { cancelActionRequest, load, location } = this.props;

    if (prevProps.load !== load) {
      if (load) this.loadMore();
    }

    if (decodeURIComponent(prevProps.location.search) !== decodeURIComponent(location.search)) {
      cancelActionRequest(GET_COLLECTIBLES);
      this.init();
    }
  }

  init() {
    const { clearSearchCollectibles } = this.props;
    clearSearchCollectibles().then(() => {
      this.createRequest();
      this.checkFollowingHashtagState();
    });
  }

  checkFollowingHashtagState() {
    const { isFollowingHashtag, match, location, user } = this.props;
    let params = new URLSearchParams(location.search);
    const authenticated = user && user.profile && user.tokens && Date.now() < user.tokens.expires_at;
    if ((params.get('q')?.charAt(0) === '#' && params.get('q')?.search(' ') === -1) || match.params.tag) {
      let value = match.params.tag ? match.params.tag : params.get('q');
      if (value.charAt(0) === '#') {
        value = value.split('#')[1];
      }
      authenticated &&
        isFollowingHashtag(value).then((res) => {
          if (!res.error) {
            this.setState({
              isFollowingHashtag: res.payload.follows,
            });
          }
        });
      this.getHashtagInfo(value);
    }
  }

  createRequest() {
    const { getCollectiblesWithPost, collectiblesData, match, location, liveAuctions, onSale } = this.props;
    let query = new URLSearchParams(location.search);
    let value = match.params.tag ? '#' + match.params.tag : query.get('q') || '';
    value = encodeURIComponent(value);

    const filterArr = [];
    let filterQuery = '';
    let listingStatusQuery = '&listingStatus=';

    if (liveAuctions) listingStatusQuery += "Orderinbox.Business.Dto.CollectibleListingStatus'LiveAuctions'";
    else if (onSale) listingStatusQuery += "Orderinbox.Business.Dto.CollectibleListingStatus'OnSale'";
    else listingStatusQuery += 'null';

    const limitQuery = '&$skip=' + collectiblesData?.length + '&$top=' + this.TOP;
    const searchKeyQuery = value.length > 0 ? '&searchKey=' + value : '';

    const chains = query.get('chains');
    const categories = query.get('categories');
    const currencies = query.get('currencies');
    const priceMin = query.get('priceMin');
    const priceMax = query.get('priceMax');

    if (chains && !(liveAuctions || onSale)) {
      let chainsQuery = chains
        .split(',')
        .map((chainId) => `collection/chain/id eq ${chainId}`)
        .join(' or ');
      chainsQuery = `(${chainsQuery})`;
      filterArr.push(chainsQuery);
    }
    if (categories) {
      let categoriesQuery = categories
        .split(',')
        .map((categoryId) => `categoryId eq ${categoryId}`)
        .join(' or ');
      categoriesQuery = `(${categoriesQuery})`;
      filterArr.push(categoriesQuery);
    }
    if (liveAuctions || onSale) {
      if (currencies) {
        let currenciesQuery = currencies
          .split(',')
          .map((currency) => {
            const [chainId, symbol] = currency.split('-');
            return `(mostRecentListing/priceAssetType/chainId eq ${chainId} and mostRecentListing/priceAssetType/symbol eq '${symbol}')`;
          })
          .join(' or ');
        currenciesQuery = `(${currenciesQuery})`;
        filterArr.push(currenciesQuery);
      }
      if (priceMin) {
        let priceMinQuery = `(mostRecentListing/priceDecimal ge ${priceMin})`;
        filterArr.push(priceMinQuery);
      }
      if (priceMax) {
        let priceMaxQuery = `(mostRecentListing/priceDecimal le ${priceMax})`;
        filterArr.push(priceMaxQuery);
      }
    }

    if (filterArr.length) {
      filterQuery = `&$filter=${filterArr.join(' and ')}`;
    }

    const concattedQuery = `$count=true${limitQuery}${searchKeyQuery}${listingStatusQuery}${
      filterQuery.length > 0 ? filterQuery : ''
    }`;

    getCollectiblesWithPost(concattedQuery, value).then((res) => {
      if (!res.error)
        this.setState({ resultCount: res.payload['@odata.count'], shouldFetch: res.payload.value.length >= this.TOP });
    });
  }

  loadMore() {
    this.createRequest();
  }

  @bind
  hashtagFollowController() {
    const { location, followHashtag, unfollowHashtag, match } = this.props;
    const { isFollowingHashtag } = this.state;
    let params = new URLSearchParams(location.search);
    let value = match.params.tag ? match.params.tag : params.get('q');
    if (value.charAt(0) === '#') {
      value = value.split('#')[1];
    }
    if (isFollowingHashtag) {
      value &&
        unfollowHashtag(value).then((res) => {
          if (!res.error) {
            this.setState({ isFollowingHashtag: false });
            this.getHashtagInfo(value);
          }
        });
    } else {
      value &&
        followHashtag(value).then((res) => {
          if (!res.error) {
            this.setState({ isFollowingHashtag: true });
            this.getHashtagInfo(value);
          }
        });
    }
  }

  @bind
  getHashtagInfo(value) {
    const { getHashtag } = this.props;
    getHashtag(value).then((res) => {
      if (!res.error) {
        this.setState({ hashtagInfo: res.payload });
      } else {
        this.setState({ hashtagInfo: null });
      }
    });
  }

  render() {
    const { location, match, collectiblesData, fetchingCollectibles, isFilterBarOpen, liveAuctions, onSale, t } =
      this.props;
    const { hashtagInfo, isFollowingHashtag, resultCount, shouldFetch } = this.state;
    let params = new URLSearchParams(location.search);

    return (
      <>
        <SearchFilterWrapper
          isFilterBarOpen={isFilterBarOpen}
          showBlockchain={!(liveAuctions || onSale)}
          showCategory={true}
          showCurrency={liveAuctions || onSale}
          showPrice={liveAuctions || onSale}
        />
        <div className={cn('result-info', isFilterBarOpen && 'filter-open')}>
          {(params.get('q')?.charAt(0) === '#' && params.get('q')?.search(' ') === -1) || match.params.tag ? (
            <>
              <div className="result">
                &quot;
                <div className="hashtag">
                  {params.get('q')} {match.params.tag ? '#' + match.params.tag : ''}{' '}
                </div>
                &quot; {t('public.searchResult')}
              </div>
              {hashtagInfo ? (
                <div className="about mt-1">
                  {millify(hashtagInfo.followersCount)} {t('public.followers')}
                  <div
                    className="follow"
                    onClick={() => {
                      this.hashtagFollowController();
                    }}>
                    &nbsp; {isFollowingHashtag ? t('public.unfollowButton') : t('public.follow')}
                  </div>
                </div>
              ) : (
                <div> </div>
              )}
              <div className="about mt-1"> {t('public.aboutResult', { count: resultCount })} </div>
            </>
          ) : (
            <>
              <div className="result">
                &quot;<span className="text">{params.get('q')}</span>&quot; {t('public.searchResult')}
              </div>
              <div className="about mt-1"> {t('public.aboutResult', { count: resultCount })} </div>
            </>
          )}
        </div>
        {collectiblesData?.length == 0 && fetchingCollectibles ? (
          <DiscoverCardGroup key={'discover-group-2'} hideCarousel hideInfo className="mt-3">
            {new Array(this.TOP).fill(0).map((i) => {
              return (
                <div key={'account' + i} className="w-100 placeholder-card">
                  <div style={{ position: 'relative' }}>
                    <div style={{ display: 'block', paddingBottom: '100%' }} />
                    <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                  </div>
                </div>
              );
            })}
          </DiscoverCardGroup>
        ) : (
          <AppScroller
            dataLength={collectiblesData?.length || 0}
            next={() => this.loadMore()}
            hasMore={shouldFetch && collectiblesData?.length >= this.TOP}
            loader={<InlineLoader height={30} />}>
            <DiscoverCardGroup key={'discover-group-'} hideCarousel hideInfo className="mt-3">
              {collectiblesData.map((collectible) => {
                return <CollectibleCard key={'collectible-' + collectible.id} collectible={collectible} />;
              })}
            </DiscoverCardGroup>
          </AppScroller>
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...SearchActions,
  ...ModalActions,
  ...SpinnerActions,
  ...CollectibleActions,
  ...ServiceActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.search,
  ...state.service,
  ...state.user.account,
  ...state.auth,
});

const SearchAll = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation(undefined, { withRef: true })(SearchAllE),
);

export default SearchAll;
