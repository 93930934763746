import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import { UserActions } from '@Actions/UserActions';
import { ModalActions } from '@Actions/ModalActions';
import { Formik } from 'formik';
import AppVariables from '@AppVariables';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

class ChangeUsernameModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  submitUsernameChangeForm(values, action) {
    const { t } = this.props;

    this.props.enableLoader();
    this.props.changeUsername(values).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.toastSuccess(t('modals.usernameUpdated'));
        this.props.closeModal();
      } else {
        this.props.updatingUsernameError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, show, t } = this.props;
    let userNameChangeForm;
    return (
      <div>
        <Modal
          title={'Confirm Username Change'}
          subtext={''}
          backdrop={backdrop}
          show={show}
          closeOnOutsideClick={closeOnOutsideClick}
          onOutsideClick={onOutsideClick}
        >
          <div className="change-display-name-modal mx-2 my-2">
            <div className="change-display-name-container mt-2 mx-1 mb-2">
              <div className="text">
                <Trans i18nKey="modals.changeUsernameNote">
                  <span className="text">Please note:</span> If you changed your OIX display name, you can't change
                  it again for 2 weeks after you confirm this change.
                </Trans>
              </div>
              <div className="text mb-2 mt-3">
                <Trans i18nKey="modals.currentDisplayName" name={this.props.user?.profile?.name}>
                  <span className="text">Current Display Name:</span> {{name}}
                </Trans>
              </div>
              <Formik
                initialValues={{ password: '', newUsername: '', confirmNewUsername: '', acceptTerms: false }}
                onSubmit={this.submitUsernameChangeForm}
                validationSchema={AppVariables.validationSchemas.changeUsernameSchema}
              >
                {(formikProps) => {
                  userNameChangeForm = formikProps.submitForm;
                  return (
                    <form className="form mt-1" onSubmit={formikProps.handleSubmit}>
                      <Input.Password
                        label={t('modals.inputPasswordLabel')}
                        placeholder=""
                        className="w-100"
                        name="password"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.password}
                        error={formikProps.touched.password && t(formikProps.errors.password)}
                      />
                      <Input.Text
                        className="w-100 my-3"
                        label={t('modals.inputNewUserLabel')}
                        placeholder=""
                        name="newUsername"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.newUsername}
                        error={t(formikProps.errors.newUsername)}
                      />
                      <Input.Text
                        className="w-100 my-3"
                        label={t('modals.inputConfirmNewUserLabel')}
                        placeholder=""
                        name="confirmNewUsername"
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        value={formikProps.values.confirmNewUsername}
                        error={t(formikProps.errors.confirmNewUsername)}
                      />
                      <div className="switch-container  my-3">
                        <Input.Switch
                          name="acceptTerms"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleChange}
                          checked={formikProps.values.switch}
                          error={t(formikProps.errors.acceptTerms)}
                          className="mr-2"
                        />
                        <div>{t('modals.notChangeUsername2Week')}</div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
          <div className="footer mx-2 mt-1 ">
            <div></div>
            <div style={{ width: '40%' }}>
              <Button
                text={t('modals.confirmButton')}
                type="primary"
                fullSize
                onClick={() => userNameChangeForm()}
                disabled={this.props.updatingUsername}
              />
            </div>
          </div>
        </Modal>
      </div>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.auth, ...state.user.settings };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(ChangeUsernameModal));
export default component;
