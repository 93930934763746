export const CLEAR_SEARCH_COLLECTIBLES = 'CLEAR_SEARCH_COLLECTIBLES';

const clearSearchCollectibles = () => ({
  type: CLEAR_SEARCH_COLLECTIBLES,
});

export const CLEAR_SEARCH_CREATORS = 'CLEAR_SEARCH_CREATORS';

const clearSearchCreators = () => ({
  type: CLEAR_SEARCH_CREATORS,
});


export const CLEAR_SEARCH_COLLECTIONS = 'CLEAR_SEARCH_COLLECTIONS';

const clearSearchCollections = () => ({
  type: CLEAR_SEARCH_COLLECTIONS,
});

const actions = {
  clearSearchCollectibles,
  clearSearchCreators,
  clearSearchCollections
};

export const SearchActions = {
  clearSearchCollectibles: () => (dispatch) => dispatch(actions.clearSearchCollectibles()),
  clearSearchCreators: () => (dispatch) => dispatch(actions.clearSearchCreators()),
  clearSearchCollections: () => (dispatch) => dispatch(actions.clearSearchCollections()),
};
