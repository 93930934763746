import React from 'react';

import { ReactComponent as Ethereum } from '@Images/currency/icon/ETH.svg?component';
import { ReactComponent as Polygon } from '@Images/currency/icon/MATIC.svg?component';
import { ReactComponent as Avalanche } from '@Images/currency/icon/AVAX.svg?component';
import { ReactComponent as Binance } from '@Images/currency/icon/BNB.svg?component';
import { ReactComponent as Default } from '@Images/icons/coin.svg?component';

const icons = {
  ethereum: <Ethereum />,
  polygon: <Polygon />,
  avalanche: <Avalanche />,
  binance: <Binance />,
  default: <Default />,
};

const ChainIcon = ({ icon }) => {
  return (
    <div className={`chain-icon`}>
      <div className={`chain-icon chain-icon--${icon}`}>{icons[icon]}</div>
    </div>
  );
};

export default ChainIcon;
