import OiInput from '@Components/form/OiInput';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { FaChevronDown } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router';

export const PriceFilter = ({ isMobile }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [minValue, setMinValue] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [showPrice, setShowPrice] = useState(false);

  const handleChangeMinInput = (e) => setMinValue(e.target.value);

  const handleChangeMaxInput = (e) => setMaxValue(e.target.value);

  const handleShowPrice = () => setShowPrice((prevState) => !prevState);

  const applyPriceFilter = () => {
    const params = new URLSearchParams(location.search);

    const setPrice = (name, value) => {
      params.set(name, value);
      const totalParams = params.toString().length ? '?' + params.toString() : '';
      history.push(location.pathname + totalParams);
    };

    const deletePrice = (name) => {
      params.delete(name);
      const totalParams = params.toString().length ? '?' + params.toString() : '';
      history.push(location.pathname + totalParams);
    };

    if (minValue) {
      setPrice('priceMin', minValue.toString());
    } else {
      deletePrice('priceMin');
    }

    if (maxValue) {
      setPrice('priceMax', maxValue.toString());
    } else {
      deletePrice('priceMax');
    }
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const priceMaxFilter = params.get('priceMax');
    const priceMinFilter = params.get('priceMin');

    priceMaxFilter && setMaxValue(parseInt(priceMaxFilter));
    priceMinFilter && setMinValue(parseInt(priceMinFilter));
  }, []);

  return (
    <div className={cn('price-filter-container', isMobile && 'mobile')}>
      <div className="price-title" onClick={handleShowPrice}>
        {t('public.searchFilterPriceText')}
        {isMobile && <FaChevronDown className="ml-1" />}
      </div>
      {(!isMobile || showPrice) && (
        <>
          <div className="price-inputs">
            <OiInput
              className="oi-key-value-pair__input"
              placeholder={t('public.searchFilterMinPricePlaceholder')}
              type="number"
              value={minValue}
              onChange={handleChangeMinInput}
            />
            <OiInput
              className="oi-key-value-pair__input"
              placeholder={t('public.searchFilterMaxPricePlaceholder')}
              type="number"
              value={maxValue}
              onChange={handleChangeMaxInput}
            />
          </div>
          <div className={cn("price-apply", (minValue || maxValue) && 'active')} onClick={applyPriceFilter}>
            {t('public.searchFilterPriceApplyText')}
          </div>
        </>
      )}
    </div>
  );
};
