import * as React from 'react';
import { Helmet } from 'react-helmet';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';

import AppVariables from '@AppVariables';
import { AccountActions } from '@Actions/AccountActions';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { CommentActions } from '@Actions/CommentActions';
import Icon from '@Components/shared/Icon';
import CommentDisplay from '@Components/shared/CommentDisplay';
import ProgressBar from '@Components/form/ProgressBar';
import Input from '@Components/form/Input';
import Button from '@Components/form/Button';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import CollectibleGetButton from '@Components/form/CollectibleGetButton';
import VideoPlayer from '@Components/shared/VideoPlayer';
import AudioPlayer from '@Components/shared/AudioPlayer';
import LikeIndicator from '@Components/shared/LikeIndicator';
import AnnotatedContent from '@Components/shared/AnnotatedContent';
import moment from 'moment';
import logoIcon from '@Images/logo/oix-icon-white.svg';

class Collectible extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      init: false,
    };
  }

  componentDidMount() {
    this.setState({ init: true });

    this.props.getCollectible(this.props.match.params.id).then((result) => {
      if (!result.error) {
        // Record a collectible view here
        this.props.recordCollectibleView(this.props.collectibleData.id);
      }
    });
  }

  getLink() {
    return 'https://localhost:44348/collectible/' + this.props.match.params.id;
  }

  render() {
    if (!this.state.init) return <div></div>;

    if (this.props.collectibleData === null) return <div></div>;
    return (
      <div id="page-embed-collectible">
        <div className="area-container">
          <div className="collectible-container" onClick={() => window.open(this.getLink())}>
            <div className="user-info">
              <ProfileAvatar width={50} height={50} imageLink={this.props.collectibleData.account.profile.imageUrl} />
              <div className="ml-2">
                <div className="title">{this.props.collectibleData.account.profile.name}</div>
                <div className="category">{this.props.collectibleData.categoryName}</div>
                <div className="time">{moment(this.props.collectibleData.createdAt).fromNow()}</div>
              </div>
            </div>
            <div>
              <img width={24} src={logoIcon} alt="" />
            </div>
          </div>
          <div className="preview-container">
            <div className="preview">
              {/* <CollectiblePreviewCarousel collectibleData={this.props.collectibleData} /> */}
            </div>
          </div>
          <div className="footer" onClick={() => window.open(this.getLink())}>
            <div className="d-flex justify-space-between align-center">
              <div>
                <div className="name">{this.props.collectibleData.title}</div>
                <div className="category">{this.props.collectibleData.categoryName}</div>
              </div>
              <div className="button-container">
                {<Button type="primary" fullSize text={'$' + this.props.collectibleData.price} />}
              </div>
            </div>
            <hr className="my-2" />
            <div className="d-flex align-center justify-space-between">
              <div className="icons">
                <div className="d-flex align-center">
                  <Icon type="fontawesome" src="fas fa-heart" />
                  &nbsp;{this.props.collectibleData.likeCount}
                </div>
                <div className="d-flex align-center">
                  <Icon className="ml-3" type="fontawesome" src="fas fa-comment" />
                  &nbsp;{this.props.collectibleData.commentCount}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.auth, ...state.user.account, ...state.collectible, ...state.account };
  },
  { ...AccountActions, ...CollectibleActions, ...CommentActions },
)(Collectible);
export default component;
