import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import { UserActions } from '@Actions/UserActions';
import { Formik } from 'formik';
import AppVariables from '@AppVariables';
import { ModalActions } from '@Actions/ModalActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ToastActions } from '@Actions/ToastActions';
import { WalletActions } from '@Actions/WalletActions';
import withWallet from '@Components/hoc/withWallet';
import * as ethUtil from 'ethereumjs-util';
import { withTranslation } from 'react-i18next';

class ChangeEmailAddressModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  getSignature(values, action) {
    const { t } = this.props;
    let signer = this.props.wallet.account;

    let message = t('modals.signMessageConnect');
    let messageBuffer = Buffer.from(message);
    let hexMessage = ethUtil.bufferToHex(messageBuffer);
    let hash = ethUtil.hashPersonalMessage(messageBuffer);
    let hexHash = ethUtil.bufferToHex(hash); 

    let params = [hexMessage, signer];
    let method = 'personal_sign';

    this.props
      .ethereumRequest(this.props.wallet.ethereum, method, params)
      .then((signature) => {
        const payload = {
          ...values,
          message,
          hash: hexHash,
          signer,
          signature,
        };
        this.submitRequestEmailChangeForm(payload);
      })
      .catch((err) => {
        this.props.toastError('Error');
        this.props.closeAllModals();
      });
  }

  @bind
  submitRequestEmailChangeForm(values, action) {
    this.props.enableLoader();
    this.props.requestEmailChange(values).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.openModal('EmailChangeModal2', { backdrop: true, email: values, closeOnOutsideClick: true });
      } else {
        this.props.updatingEmailError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    let requestEmailChangeForm;
    return (
      <Modal
        title={t('modals.changeEmailModalTitle')}
        subtext={t('modals.changeEmailModalSubtext')}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}>
        <div className="change-email-modal my-2">
          <div className="change-email-container my-2">
            <Formik
              initialValues={{ newEmail: '' }}
              onSubmit={this.getSignature}
              validationSchema={AppVariables.validationSchemas.changeEmailSchema}>
              {(formikProps) => {
                requestEmailChangeForm = formikProps.submitForm;
                return (
                  <form className="form mt-1" onSubmit={formikProps.handleSubmit}>
                    <Input.Text
                      className="w-100 my-3"
                      label={t('modals.changeEmailModalInputLabel')}
                      placeholder=""
                      name="newEmail"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.newEmail}
                      error={t(formikProps.errors.newEmail)}
                    />
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="footer mx-2 mt-1 ">
          <div></div>
          <div style={{ width: '40%' }}>
            <Button
              text="Confirm"
              type="primary"
              fullSize
              onClick={() => requestEmailChangeForm()}
              disabled={this.props.updatingEmail}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

class VerifyEmailChangeModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  resendSecurityCode() {
    const { t } = this.props;

    this.props.enableLoader();
    this.props.requestEmailChange(this.props.email).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.toastSuccess(t('modals.emailSentToast'));
      } else {
        this.props.updatingEmailError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  @bind
  submitVerifyEmailChangeForm(values, action) {
    const { t } = this.props;

    this.props.enableLoader();
    const verifyCodeModel = {
      code: values.code,
      ...this.props.email,
    };
    this.props.verifyEmailChange(verifyCodeModel).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.toastSuccess(t('modals.emailUpdatedToast'));
        this.props.closeAllModals();
        this.props.refreshUserInfo();
        
      } else {
        this.props.verifyingEmailChangeError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, t } = this.props;
    let verifyEmailChangeForm;
    return (
      <Modal
        title={t('modals.verifyEmailModalTitle')}
        subtext={t('modals.verifyEmailModalSubtext')}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={this.props.resetEmailChangeData}>
        <div className="change-email-modal my-2">
          <div className="change-email-container my-2">
            <Formik
              initialValues={{ code: '' }}
              onSubmit={this.submitVerifyEmailChangeForm}
              validationSchema={AppVariables.validationSchemas.verificationCodeSchema}>
              {(formikProps) => {
                verifyEmailChangeForm = formikProps.submitForm;
                return (
                  <form className="form mt-1" onSubmit={formikProps.handleSubmit}>
                    <Input.Text
                      className="w-100 my-3"
                      label={t('modals.verifyEmailModalInputLabel')}
                      placeholder=""
                      name="code"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.code}
                      error={t(formikProps.errors.code)}
                    />
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="footer mx-2 mt-1 ">
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.resendButton')}
              type="primary"
              fullSize
              onClick={() => this.resendSecurityCode()}
              disabled={this.props.updatingEmail}
            />
          </div>

          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.confirmButton')}
              type="primary"
              fullSize
              onClick={() => verifyEmailChangeForm()}
              disabled={this.props.verifyingEmailChange}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export const EmailChangeModal = withWallet(
  connect(
    (state) => {
      return { ...state.user.twoFa, ...state.user.settings, ...state.auth };
    },
    { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions, ...WalletActions },
    null,
    { forwardRef: true },
  )(withTranslation()(ChangeEmailAddressModal)),
);

export const EmailChangeModal2 = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.user.settings, ...state.auth };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(VerifyEmailChangeModal));
