import * as SpinnerActionTypes from "@Actions/SpinnerActions";

const initialState = () => ({
  isSpinnerEnabled: false,
  isLoaderEnabled: false
});


export default (state = initialState(), action) => {

  switch (action.type) {
    case SpinnerActionTypes.ENABLE_SPINNER:
      return {
        ...state,
        isSpinnerEnabled: true
      }
    case SpinnerActionTypes.DISABLE_SPINNER:
      return {
        ...state,
        isSpinnerEnabled: false
      }
    case SpinnerActionTypes.ENABLE_LOADER:
      return {
        ...state,
        isLoaderEnabled: true
      }
    case SpinnerActionTypes.DISABLE_LOADER:
      return {
        ...state,
        isLoaderEnabled: false
      }

    default:
      return state;
  }
};