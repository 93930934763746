export const runtimeConfig =
  typeof window !== 'undefined'
    ? {
        // client
        serverEnv: window.env.serverEnv,
        apiPath: window.env.apiPath,
        acceptedChains: window.env.acceptedChains,
        defaultGasMultiplier: window.env.defaultGasMultiplier,
        gtmId: window.env.gtmId,
        enableFeaturedCollections: window.env.enableFeaturedCollections,
        publicPath: window.env.publicPath,
        enableDisplayTestNetworks: window.env.enableDisplayTestNetworks,
        wyreEnv: window.env.wyreEnv,
        maxCollectibleUploadSizeInMB: window.env.maxCollectibleUploadSizeInMB,
        unstoppableClientId: window.env.unstoppableClientId,
        unstoppableRedirectUri: window.env.unstoppableRedirectUri,
        anticEnabled: window.env.anticEnabled,
        anticTestMode: window.env.anticTestMode,
        anticBusinessId: window.env.anticBusinessId
      }
    : {
        // server
        serverEnv: process.env.NODE_ENV,
        apiPath: process.env.ORDERINBOX_API_PATH,
        acceptedChains: process.env.ACCEPTED_CHAINS,
        defaultGasMultiplier: process.env.DEFAULT_GAS_MULTIPLIER,
        gtmId: process.env.GTM_ID,
        enableDisplayTestNetworks: process.env.ENABLE_DISPLAY_TEST_NETWORKS,
        wyreEnv: process.env.WYRE_ENV,
        maxCollectibleUploadSizeInMB: process.env.MAX_COLLECTIBLE_UPLOAD_SIZE_IN_MB,
        enableFeaturedCollections: process.env.ENABLE_FEATURED_COLLECTIONS,
        unstoppableClientId: process.env.UNSTOPPABLE_CLIENT_ID,
        unstoppableRedirectUri: process.env.UNSTOPPABLE_REDIRECT_URI,
        anticEnabled: process.env.ANTIC_ENABLED,
        anticTestMode: process.env.ANTIC_TEST_MODE,
        anticBusinessId: process.env.ANTIC_API_KEY,
        // eslint-disable-next-line no-undef
        publicPath: __webpack_public_path__
      };
