import axios from 'axios';

const { AbortController } = require('@azure/abort-controller');
const { BlockBlobClient } = require('@azure/storage-blob');

const uploadEndpoint = (uploadId) => `v1/upload${uploadId ? `/${uploadId}` : ''}`;

export const INIT_UPLOAD_FILE = 'INIT_UPLOAD_FILE';
export const INIT_UPLOAD_FILE_SUCCESS = 'INIT_UPLOAD_FILE_SUCCESS';
export const INIT_UPLOAD_FILE_FAILURE = 'INIT_UPLOAD_FILE_FAILURE';

const initUploadFile = (initUploadFileModel) => ({
  type: INIT_UPLOAD_FILE,
  payload: {
    request: {
      method: 'POST',
      url: `${uploadEndpoint()}/init`,
      data: initUploadFileModel,
    },
  },
});

export const FINALIZE_UPLOAD_FILE = 'FINALIZE_UPLOAD_FILE';
export const FINALIZE_UPLOAD_FILE_SUCCESS = 'FINALIZE_UPLOAD_FILE_SUCCESS';
export const FINALIZE_UPLOAD_FILE_FAILURE = 'FINALIZE_UPLOAD_FILE_FAILURE';

const finalizeUploadFile = (uploadId) => ({
  type: FINALIZE_UPLOAD_FILE,
  payload: {
    request: {
      method: 'POST',
      url: `${uploadEndpoint(uploadId)}/finalize`,
    },
  },
});

export const GET_UPLOAD_FILE_STATUS = 'GET_UPLOAD_FILE_STATUS';
export const GET_UPLOAD_FILE_STATUS_SUCCESS = 'GET_UPLOAD_FILE_STATUS_SUCCESS';
export const GET_UPLOAD_FILE_STATUS_FAILURE = 'GET_UPLOAD_FILE_STATUS_FAILURE';

const getUploadFileStatus = (uploadId) => ({
  type: GET_UPLOAD_FILE_STATUS,
  payload: {
    request: {
      method: 'GET',
      url: `${uploadEndpoint(uploadId)}/status`,
    },
  },
});

export const UPLOAD_START = 'UPLOAD_START';
const uploadStart = (fileMarkId) => ({
  type: UPLOAD_START,
  payload: {
    fileMarkId,
  },
});

export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
const uploadFileSuccess = (fileMarkId) => ({
  type: UPLOAD_FILE_SUCCESS,
  payload: {
    fileMarkId,
  },
});

export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';
const uploadFileFailure = (message, fileMarkId) => ({
  type: UPLOAD_FILE_FAILURE,
  error: {
    message,
    fileMarkId,
  },
});

export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS';
const uploadProgress = (percentage, fileMarkId) => ({
  type: UPLOAD_PROGRESS,
  payload: {
    percentage,
    fileMarkId,
  },
});

export const CLEAR_PROGRESS = 'CLEAR_PROGRESS';
const clearProgress = (fileMarkId) => ({
  type: CLEAR_PROGRESS,
  payload: {
    fileMarkId,
  },
});

export const UPLOAD_CANCEL = 'UPLOAD_CANCEL';
const uploadCancel = (fileMarkId) => ({
  type: UPLOAD_CANCEL,
  payload: {
    fileMarkId,
  },
});

export const INIT_PINATA_UPLOAD = 'INIT_PINATA_UPLOAD';
export const INIT_PINATA_UPLOAD_SUCCESS = 'INIT_PINATA_UPLOAD_SUCCESS';
export const INIT_PINATA_UPLOAD_FAILURE = 'INIT_PINATA_UPLOAD_FAILURE';

const initPinataUpload = () => ({
  type: INIT_PINATA_UPLOAD,
  payload: {
    request: {
      method: 'POST',
      url: `${uploadEndpoint()}/init/pinata`,
    },
  },
});

export const FINALIZE_PINATA_UPLOAD = 'FINALIZE_PINATA_UPLOAD';
export const FINALIZE_PINATA_UPLOAD_SUCCESS = 'FINALIZE_PINATA_UPLOAD_SUCCESS';
export const FINALIZE_PINATA_UPLOAD_FAILURE = 'FINALIZE_PINATA_UPLOAD_FAILURE';

const finalizePinataUpload = (apiKey) => ({
  type: FINALIZE_PINATA_UPLOAD,
  payload: {
    request: {
      method: 'POST',
      url: `${uploadEndpoint()}/finalize/pinata`,
      params: {
        apiKey,
      },
    },
  },
});

export const PIN_FILE_TO_IPFS = 'PIN_FILE_TO_IPFS';
export const PIN_FILE_TO_IPFS_SUCCESS = 'PIN_FILE_TO_IPFS_SUCCESS';
export const PIN_FILE_TO_IPFS_FAILURE = 'PIN_FILE_TO_IPFS_FAILURE';

const pinFileToIpfs = (pinataJWT, file, fileName, keyValues, additionalConfig) => {
  // we gather a local file for this example, but any valid readStream source will work here.
  const data = new FormData();
  console.log(file)
  data.append('file', file, fileName);

  const pinataMetadata = JSON.stringify({
    name: fileName,
    keyvalues: keyValues,
  });
  data.append('pinataMetadata', pinataMetadata);

  // pinataOptions are optional
  const pinataOptions = JSON.stringify({
    wrapWithDirectory: true,
  });
  data.append('pinataOptions', pinataOptions);

  return {
    type: PIN_FILE_TO_IPFS,
    payload: {
      client: 'pinata',
      request: {
        method: 'POST',
        maxBodyLength: 'Infinity', // this is needed to prevent axios from erroring out with large files... We need to limit this to 25 MB
        headers: {
          'Content-Type': `multipart/form-data`,
          Authorization: `Bearer ${pinataJWT}`,
        },
        url: 'pinning/pinFileToIPFS',
        data,
        ...additionalConfig,
      },
    },
  };
};

const actions = {
  uploadStart,
  uploadProgress,
  uploadCancel,
  uploadFileSuccess,
  uploadFileFailure,
  clearProgress,

  initUploadFile,
  finalizeUploadFile,

  initPinataUpload,
  finalizePinataUpload,
  pinFileToIpfs,
};

export const UploadActions = {
  // TODO: Make uploadFile cancelable! Use AbortController and AbortSignal in the Azure JS SDK
  uploadFileToAzureBlobStorage: (uploadModel) => (dispatch) => {
    dispatch(uploadStart(uploadModel.fileMarkId));

    const abortController = new AbortController();
    let cancelResolve;
    const cancelPromise = new Promise((resolve, reject) => {
      cancelResolve = resolve;
    });
    const promise = new Promise((resolve, reject) => {
      dispatch(
        actions.initUploadFile({
          fileName: uploadModel.file.name,
          fileSize: uploadModel.file.size,
          contentType: uploadModel.file.type,
          fileUse: uploadModel.fileUse,
          typeId: uploadModel.typeId,
          categoryId: uploadModel.categoryId,
        }),
      ).then(async (result) => {
        if (result.error) {
          dispatch(uploadFileFailure(result.error.response.message));
          reject(result.error);
        } else {
          const upload = result.payload;
          try {
            const blockBlobClient = new BlockBlobClient(upload.sasTokenUrl);
            const blobOptions = {
              abortSignal: abortController.signal,
              blobHTTPHeaders: {
                blobContentType: uploadModel.file.type,
              },
              onProgress: (progress) => {
                const percentage = progress.loadedBytes / uploadModel.file.size;
                if (uploadModel.progressCallback) {
                  uploadModel.progressCallback(percentage);
                }
                dispatch(actions.uploadProgress(percentage, uploadModel.fileMarkId));
              },
            };
            await blockBlobClient.uploadBrowserData(uploadModel.file, blobOptions);

            // Now finalize the upload request
            dispatch(actions.finalizeUploadFile(upload.id)).then((result) => {
              if (result.error) {
                dispatch(uploadFileFailure(result.error.response.message, uploadModel.fileMarkId));
                reject(result.error);
              } else {
                // Now wait until the file is processed here.
                setTimeout(function fn() {
                  dispatch(actions.getUploadFileStatus(upload.id)).then((result) => {
                    if (result.error) {
                      dispatch(uploadFileFailure(result.error.response.message, uploadModel.fileMarkId));
                      reject(result.error);
                    } else {
                      const fileVirusState = result.payload.virusScanState;

                      if (fileVirusState === 'ScanClean') {
                        dispatch(uploadFileSuccess(result.Id, uploadModel.fileMarkId));
                        resolve(result.payload);
                      } else if (fileVirusState === 'ScanVirusDetected' || fileVirusState === 'ScanError') {
                        dispatch(uploadFileFailure(result.payload.virusScanRawState, uploadModel.fileMarkId));

                        reject({
                          message: result.payload.virusScanRawState,
                        });
                      } else {
                        // continue processing
                        // We should probably add a separate state for the
                        // after upload status checks with a marker and special text/state
                        dispatch(actions.uploadProgress(1, uploadModel.fileMarkId));

                        // We should add a maximum time to wait here
                        // And fail if that is the case.
                        setTimeout(fn, 2000);
                      }
                    }
                  });
                }, 0);
              }
            });
            // Call finalize here to commit the file as uploaded.
          } catch (error) {
            if (error.name === 'AbortError') {
              console.log('Operation aborted by the user');
              cancelResolve();
            }
            dispatch(uploadFileFailure(error.message, uploadModel.fileMarkId));
            reject(error);
          }
        }
      });
    });
    promise.cancel = () => {
      abortController.abort();
      return new Promise((resolve, reject) => {
        cancelResolve = resolve;
      });
    };
    return promise;
  },
  uploadFileToIpfs: (uploadModel) => (dispatch) => {
    dispatch(uploadStart(uploadModel.fileMarkId));

    let cancelResolve;
    const cancelSource = axios.CancelToken.source();

    const promise = new Promise((resolve, reject) => {
      try {
        dispatch(actions.initPinataUpload()).then((pinataApiKeyResult) => {
          if (pinataApiKeyResult.payload) {
            dispatch(
              actions.pinFileToIpfs(
                pinataApiKeyResult.payload.jwt,
                uploadModel.file,
                uploadModel.fileName,
                uploadModel.metadata,
                {
                  onUploadProgress: (progress) => {
                    const percentage = progress.loaded / progress.total;

                    if (uploadModel.progressCallback) {
                      uploadModel.progressCallback(percentage);
                    }

                    dispatch(actions.uploadProgress(percentage, uploadModel.fileMarkId));
                  },
                  cancelToken: cancelSource.token,
                },
              ),
            ).then((result) => {
              if (result.error) {
                dispatch(uploadFileFailure(result.error, uploadModel.fileMarkId));
                reject(result.error);
              } else {
                dispatch(uploadFileSuccess(uploadModel.fileMarkId));
                resolve(result);
              }

              // This will help revoke the api key and also start additional processing
              dispatch(actions.finalizePinataUpload(pinataApiKeyResult.payload.apiKey));
            });
          }

          if (pinataApiKeyResult.error) {
            throw pinataApiKeyResult.error;
          }
        });
      } catch (error) {
        if (error.message === 'UploadCancelled') {
          console.log('Operation aborted by the user');
          cancelResolve();
        }
        dispatch(uploadFileFailure(error.message, uploadModel.fileMarkId));
        reject(error);
      }
    });
    promise.cancel = () => {
      cancelSource.cancel('UploadCancelled');
      dispatch(uploadCancel(uploadModel.fileMarkId));

      return new Promise((resolve) => {
        cancelResolve = resolve;
      });
    };
    return promise;
  },
  clearProgress: (fileMarkId) => (dispatch) => {
    dispatch(actions.clearProgress(fileMarkId));
  },
};
