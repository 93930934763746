import { toast } from 'react-toastify';

export const ToastActions = {
  toastSuccess: (message) => (dispatch) => {
    toast.success(message);
  },
  toastError: (message) => (dispatch) => {
    toast.error(message);
  },
  toastWarning: (message) => (dispatch) => {
    toast.warning(message);
  },
  toastInfo: (message) => (dispatch) => {
    toast.info(message);
  },
  toastMessage: (message) => (dispatch) => {
    toast.message(message);
  },
};

export default ToastActions;
