import * as AccountActionTypes from '@Actions/AccountActions';
import * as CollectibleActionTypes from '@Actions/CollectibleActions';
import * as CommentActionTypes from '@Actions/CommentActions';
import * as HashtagActionTypes from '@Actions/HashtagActions';
import * as CollectionActionTypes from '@Actions/CollectionActions';
import * as UserActionTypes from '@Actions/UserActions';
import * as NotificationActionTypes from '@Actions/NotificationActions';
import { buildNotificationContent, concatCacheKey } from '@Utils';

const COLLECTIBLE = 'collectible';

const initialState = () => ({
  allCollectibles: {},
  allAccounts: {},
  allCollections: {},
  allHashtags: {},
  allComments: {},
  allNotifications: {},
  allDetailedCollectibles: {},
  commentDisplayMap: {},

  allDetailedAccounts: {},
  profileAccountList: [],
  activeProfileAccountId: null,

  allDetailedCollections: {},
  profileCollectionList: [],
  activeProfileCollectionId: null,

  editorImage: null,
  currentAccount: null,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    // #region Collectibles
    // TODO: Update collectibles on Like, Unlike actions...
    case CollectibleActionTypes.GET_COLLECTIBLES_SUCCESS:
    case CollectibleActionTypes.GET_TRENDING_ITEMS_SUCCESS:
    case CollectibleActionTypes.GET_ON_SALE_ITEMS_SUCCESS:
    case CollectibleActionTypes.GET_LIVE_AUCTION_ITEMS_SUCCESS: {
      const newCollectibles = action.payload.value.reduce((payload, collectible) => {
        // Set the unrestrict content flag as it is only available locally
        let existingCachedObject = state.allCollectibles[collectible.id];
        let cachedObject = {
          ...payload,
          [collectible.id]: { ...collectible, unrestrictContent: existingCachedObject?.unrestrictContent },
        };

        if (collectible.collection?.publicAddress && collectible.tokenId) {
          const cacheKey = concatCacheKey([
            collectible.collection.chain.id,
            collectible.collection.publicAddress,
            collectible.tokenId,
          ]);
          existingCachedObject = state.allCollectibles[cacheKey];
          // if the object has a publicAddress and tokenId. Also cache it in with the id generated using these
          cachedObject = {
            ...cachedObject,
            [cacheKey]: { ...collectible, unrestrictContent: existingCachedObject?.unrestrictContent },
          };
        }

        return cachedObject;
      }, {});
      return {
        ...state,
        allCollectibles: Object.assign({}, state.allCollectibles, newCollectibles),
      };
    }
    // #endregion

    // #region Accounts
    // ? We might need to consider update accounts on block and mute actions...
    case AccountActionTypes.GET_ACCOUNTS_SUCCESS:
    case AccountActionTypes.GET_RECOMMENDED_ACCOUNTS_SUCCESS: {
      const newAccounts = action.payload.value.reduce(
        (payload, account) => ({ ...payload, [account.id]: account }),
        {},
      );

      return {
        ...state,
        allAccounts: Object.assign({}, state.allAccounts, newAccounts),
      };
    }

    case CollectionActionTypes.GET_COLLECTIONS_SUCCESS: {
      const newCollections = action.payload.value.reduce(
        (payload, collection) => ({ ...payload, [collection.id]: collection }),
        {},
      );

      return {
        ...state,
        allCollections: Object.assign({}, state.allCollections, newCollections),
      };
    }
    case AccountActionTypes.FOLLOW_ACCOUNT_SUCCESS:
    case AccountActionTypes.UNFOLLOW_ACCOUNT_SUCCESS: {
      const accountId = action.meta.previousAction.payload.followeeAccountId;
      let followAccount = state.allDetailedAccounts[accountId]
        ? { ...state.allDetailedAccounts[accountId] }
        : { ...state.allAccounts[accountId] };

      followAccount.followersCount = action.payload.followersCount;
      followAccount.actorFollowee = action.payload.actorFollowee;

      const newAllCollectibles = {};

      for (const key in state.allCollectibles) {
        const collectible = state.allCollectibles[key];
        if (collectible.account.id === accountId) {
          const newCollectible = {
            ...collectible,
            account: {
              ...collectible.account,
              actorFollowee: action.payload.actorFollowee,
              followersCount: action.payload.followersCount,
            },
          };
          newAllCollectibles[key] = newCollectible;
        } else {
          newAllCollectibles[key] = collectible;
        }
      }

      return {
        ...state,
        allDetailedAccounts: state.allDetailedAccounts[action.payload.id]
          ? {
              ...state.allDetailedAccounts,
              [action.payload.id]: followAccount,
            }
          : state.allDetailedAccounts,
        allAccounts: state.allAccounts[action.payload.id]
          ? {
              ...state.allAccounts,
              [action.payload.id]: followAccount,
            }
          : state.allAccounts,
        allCollectibles: newAllCollectibles,
      };
    }
    case AccountActionTypes.REMOVE_FOLLOWER_SUCCESS: {
      const accountId = action.meta.previousAction.payload.followerAccountId;
      let followerAccount = state.allDetailedAccounts[accountId]
        ? { ...state.allDetailedAccounts[accountId] }
        : { ...state.allAccounts[accountId] };
      let _currentAccount = state.allDetailedAccounts[action.payload.id]
      ? { ...state.allDetailedAccounts[action.payload.id] }
      : { ...state.allAccounts[action.payload.id] };

      followerAccount.followeesCount = followerAccount.followeesCount - 1;
      followerAccount.actorFollowee = false;
      followerAccount.followees = followerAccount.followees?.filter((id) => id !== _currentAccount.id);

      _currentAccount.followersCount = action.payload.followersCount;
      _currentAccount.actorFollower = action.payload.actorFollower;
      _currentAccount.followers = _currentAccount.followers?.filter((id) => id !== accountId);

      const newAllCollectibles = {};

      for (const key in state.allCollectibles) {
        const collectible = state.allCollectibles[key];
        if (collectible.account.id === accountId) {
          const newCollectible = {
            ...collectible,
            account: {
              ...collectible.account,
              actorFollowee: false,
              followeesCount: collectible.account.followeesCount - 1,
            },
          };
          newAllCollectibles[key] = newCollectible;
        } else if (collectible.account.id === _currentAccount.id) {
          const newCollectible = {
            ...collectible,
            account: {
              ...collectible.account,
              actorFollower: action.payload.actorFollower,
              followersCount: action.payload.followersCount,
            },
          };
          newAllCollectibles[key] = newCollectible;
        } else {
          newAllCollectibles[key] = collectible;
        }
      }

      return {
        ...state,
        allDetailedAccounts: state.allDetailedAccounts[followerAccount.id]
          ? {
              ...state.allDetailedAccounts,
              [followerAccount.id]: followerAccount,
              [_currentAccount.id]: _currentAccount,
            }
          : {
            ...state.allDetailedAccounts,
            [_currentAccount.id]: _currentAccount,
          },
        allAccounts: state.allAccounts[followerAccount.id]
          ? {
              ...state.allAccounts,
              [followerAccount.id]: followerAccount,
              [_currentAccount.id]: _currentAccount,
            }
          : {
            ...state.allAccounts,
            [_currentAccount.id]: _currentAccount,
          },
        allCollectibles: newAllCollectibles,
      };
    }
    // #endregion

    // #region Hashtags
    case HashtagActionTypes.GET_HASHTAGS_SUCCESS:
    case HashtagActionTypes.GET_RECOMMENDED_HASHTAGS_SUCCESS: {
      const newHashtags = action.payload.value.reduce(
        (payload, hashtag) => ({ ...payload, [hashtag.tag]: hashtag }),
        {},
      );
      return {
        ...state,
        allHashtags: Object.assign({}, state.allHashtags, newHashtags),
      };
    }
    case AccountActionTypes.FOLLOW_HASHTAG_SUCCESS: {
      const tag = action.meta.previousAction.payload.tag;
      const hashtag = state.allHashtags[tag];

      if (hashtag) {
        hashtag.actorFollows = true;
        hashtag.followersCount += 1;
      }

      return {
        ...state,
        allHashtags: Object.assign({}, state.allHashtags, { [tag]: hashtag }),
      };
    }
    case AccountActionTypes.UNFOLLOW_HASHTAG_SUCCESS: {
      const tag = action.meta.previousAction.payload.tag;
      const hashtag = state.allHashtags[tag];

      if (hashtag) {
        hashtag.actorFollows = false;
        hashtag.followersCount -= 1;
      }
      return {
        ...state,
        allHashtags: Object.assign({}, state.allHashtags, { [tag]: hashtag }),
      };
    }
    case CollectibleActionTypes.LIKE_COLLECTIBLE_SUCCESS: {
      let { collectibleId, cacheKey } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles } = state;
      let collectible = allCollectibles[collectibleId];

      if (collectible && !cacheKey) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      let likeCount = action.payload.likeCount;

      const newAllCollectibles = { ...state.allCollectibles };

      if (collectible) {
        collectible.actorAccountLikes = true;
        collectible.likeCount = likeCount;
        newAllCollectibles[collectibleId] = collectible;

        if (cacheKey) {
          newAllCollectibles[cacheKey] = collectible;
        }
      }

      let detailedCollectible = allDetailedCollectibles[cacheKey];

      const newAllDetailedCollectibles = { ...state.allDetailedCollectibles };

      if (detailedCollectible) {
        detailedCollectible.actorAccountLikes = true;
        detailedCollectible.likeCount = likeCount;
        newAllDetailedCollectibles[cacheKey] = detailedCollectible;
      }

      return {
        ...state,
        allCollectibles,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case CollectibleActionTypes.UNLIKE_COLLECTIBLE_SUCCESS: {
      let { collectibleId, cacheKey } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles } = state;
      let collectible = allCollectibles[collectibleId];

      if (collectible && !cacheKey) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      let likeCount = action.payload.likeCount;

      const newAllCollectibles = { ...state.allCollectibles };

      if (collectible) {
        collectible.actorAccountLikes = false;
        collectible.likeCount = likeCount;
        newAllCollectibles[collectibleId] = collectible;

        if (cacheKey) {
          newAllCollectibles[cacheKey] = collectible;
        }
      }

      let detailedCollectible = allDetailedCollectibles[cacheKey];

      const newAllDetailedCollectibles = { ...state.allDetailedCollectibles };

      if (detailedCollectible) {
        detailedCollectible.actorAccountLikes = false;
        detailedCollectible.likeCount = likeCount;
        newAllDetailedCollectibles[cacheKey] = detailedCollectible;
      }

      return {
        ...state,
        allCollectibles,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case CollectibleActionTypes.SHARE_COLLECTIBLE_SUCCESS: {
      let { collectibleId, cacheKey } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles } = state;
      let collectible = allCollectibles[collectibleId];

      if (collectible && !cacheKey) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      let shareCount = action.payload.shareCount;

      const newAllCollectibles = { ...state.allCollectibles };

      if (collectible) {
        collectible.actorAccountShared = true;
        collectible.shareCount = shareCount;
        newAllCollectibles[collectibleId] = collectible;

        if (cacheKey) {
          newAllCollectibles[cacheKey] = collectible;
        }
      }

      let detailedCollectible = allDetailedCollectibles[cacheKey];

      const newAllDetailedCollectibles = { ...state.allDetailedCollectibles };

      if (detailedCollectible) {
        detailedCollectible.actorAccountShared = true;
        detailedCollectible.shareCount = shareCount;
        newAllDetailedCollectibles[cacheKey] = detailedCollectible;
      }

      return {
        ...state,
        allCollectibles,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case CollectibleActionTypes.UNSHARE_COLLECTIBLE_SUCCESS: {
      let { collectibleId, cacheKey } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles } = state;
      let collectible = allCollectibles[collectibleId];

      if (collectible && !cacheKey) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      let shareCount = action.payload.shareCount;

      const newAllCollectibles = { ...state.allCollectibles };

      if (collectible) {
        collectible.actorAccountShared = false;
        collectible.shareCount = shareCount;
        newAllCollectibles[collectibleId] = collectible;
        if (cacheKey) {
          newAllCollectibles[cacheKey] = collectible;
        }
      }

      let detailedCollectible = allDetailedCollectibles[cacheKey];

      const newAllDetailedCollectibles = { ...state.allDetailedCollectibles };

      if (detailedCollectible) {
        detailedCollectible.actorAccountShared = false;
        detailedCollectible.shareCount = shareCount;
        newAllDetailedCollectibles[cacheKey] = detailedCollectible;
      }

      return {
        ...state,
        allCollectibles,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case CollectibleActionTypes.BOOKMARK_COLLECTIBLE_SUCCESS: {
      let { collectibleId, cacheKey } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles } = state;
      let collectible = allCollectibles[collectibleId];

      if (collectible && !cacheKey) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      const newAllCollectibles = { ...state.allCollectibles };

      if (collectible) {
        collectible.actorAccountBookmarked = true;
        newAllCollectibles[collectibleId] = collectible;
        if (cacheKey) {
          newAllCollectibles[cacheKey] = collectible;
        }
      }

      let detailedCollectible = allDetailedCollectibles[cacheKey];

      const newAllDetailedCollectibles = { ...state.allDetailedCollectibles };

      if (detailedCollectible) {
        detailedCollectible.actorAccountBookmarked = true;
        newAllDetailedCollectibles[cacheKey] = detailedCollectible;
      }

      return {
        ...state,
        allCollectibles,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case CollectibleActionTypes.UNBOOKMARK_COLLECTIBLE_SUCCESS: {
      let { collectibleId, cacheKey } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles } = state;
      let collectible = allCollectibles[collectibleId];

      if (collectible && !cacheKey) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      const newAllCollectibles = { ...state.allCollectibles };

      if (collectible) {
        collectible.actorAccountBookmarked = false;
        newAllCollectibles[collectibleId] = collectible;
        if (cacheKey) {
          newAllCollectibles[cacheKey] = collectible;
        }
      }

      let detailedCollectible = allDetailedCollectibles[cacheKey];

      const newAllDetailedCollectibles = { ...state.allDetailedCollectibles };

      if (detailedCollectible) {
        detailedCollectible.actorAccountBookmarked = false;
        newAllDetailedCollectibles[cacheKey] = detailedCollectible;
      }

      return {
        ...state,
        allCollectibles,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case CollectibleActionTypes.ADD_COLLECTIBLE_TRANSACTION_SUCCESS: {
      let { collectibleId, cacheKey } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles } = state;
      let collectible = allCollectibles[collectibleId];

      if (collectible.collection?.publicAddress && collectible.tokenId && !cacheKey) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      let pendingTransactions = action.payload;

      const newAllCollectibles = { ...state.allCollectibles };

      if (collectible) {
        collectible.pendingTransactions = pendingTransactions;
        newAllCollectibles[collectibleId] = collectible;

        if (cacheKey) {
          newAllCollectibles[cacheKey] = collectible;
        }
      }

      let detailedCollectible = allDetailedCollectibles[cacheKey];

      const newAllDetailedCollectibles = { ...state.allDetailedCollectibles };

      if (detailedCollectible) {
        detailedCollectible.pendingTransactions = pendingTransactions;
        newAllDetailedCollectibles[cacheKey] = detailedCollectible;
      }

      return {
        ...state,
        allCollectibles,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case AccountActionTypes.GET_FEED_ITEMS_SUCCESS: {
      let newCollectibles = action.payload.reduce((payload, feedItem) => {
        if (feedItem.targetCollectible) {
          const existingCachedObject = state.allCollectibles[feedItem.targetCollectible.id];
          return {
            ...payload,
            [feedItem.targetCollectible.id]: {
              ...feedItem.targetCollectible,
              unrestrictContent: existingCachedObject?.unrestrictContent,
            },
          };
        }

        return payload;
      }, {});

      const newAccounts = action.payload.reduce((payload, feedItem) => {
        return feedItem.sourceAccount ? { ...payload, [feedItem.sourceAccount.id]: feedItem.sourceAccount } : payload;
      }, {});

      let newCommentDisplayMap = {};
      let newComments = {};
      let key;
      action.payload.forEach((fi) => {
        if (fi.targetCommentParent && fi.targetComment) {
          key = concatCacheKey(['activity', fi.id, 'collectible', fi.targetCollectible.id, fi.targetCommentParent.id]);
          newCommentDisplayMap[key] = [fi.targetComment.id];

          key = concatCacheKey(['activity', fi.id, 'collectible', fi.targetCollectible.id]);
          newCommentDisplayMap[key] = [fi.targetCommentParent.id];

          newComments[fi.targetComment.id] = fi.targetComment;
          newComments[fi.targetCommentParent.id] = fi.targetCommentParent;
        } else if (fi.targetComment) {
          key = concatCacheKey(['activity', fi.id, 'collectible', fi.targetCollectible.id]);
          newCommentDisplayMap[key] = [fi.targetComment.id];

          newComments[fi.targetComment.id] = fi.targetComment;
        }
      });

      return {
        ...state,
        allCollectibles: Object.assign({}, state.allCollectibles, newCollectibles),
        allAccounts: Object.assign({}, state.allAccounts, newAccounts),
        allComments: Object.assign({}, state.allComments, newComments),
        commentDisplayMap: Object.assign({}, state.commentDisplayMap, newCommentDisplayMap),
      };
    }
    case CollectibleActionTypes.GET_COLLECTIBLE_COMMENTS_SUCCESS: {
      let { allComments, commentDisplayMap } = state;
      let { key, flag } = action.meta.previousAction.payload;

      if (flag || !commentDisplayMap[key]) {
        commentDisplayMap[key] = [];
      }

      action.payload.value.map((comment) => {
        allComments[comment.id] = comment;

        if (commentDisplayMap[key]) {
          commentDisplayMap[key] = [comment.id, ...commentDisplayMap[key]];
        } else {
          commentDisplayMap[key] = [comment.id];
        }
      });

      return {
        ...state,
        allComments: Object.assign({}, allComments),
        commentDisplayMap: Object.assign({}, commentDisplayMap),
      };
    }
    case CommentActionTypes.DELETE_COMMENT_SUCCESS: {
      let { commentId, key } = action.meta.previousAction.payload;
      let { allComments, commentDisplayMap, allCollectibles, allDetailedCollectibles } = state;

      if (allComments[commentId].parentCommentId) {
        let parentComment = { ...allComments[allComments[commentId].parentCommentId] };
        parentComment.replyCount -= 1;
        allComments[parentComment.id] = parentComment;
      }

      delete allComments[commentId];

      let parsedKeys = key.split('-');
      if (parsedKeys[0] === COLLECTIBLE) {
        for (const [key, value] of Object.entries(commentDisplayMap)) {
          let parsedActivityKeys = key.split('-');
          if (parsedActivityKeys[3] === parsedKeys[1] || parsedActivityKeys[1] === parsedKeys[1]) {
            commentDisplayMap[key] = value.filter((comment) => {
              return comment !== commentId;
            });
          }
        }
      } else {
        for (const [key, value] of Object.entries(commentDisplayMap)) {
          let parsedCollectibleKeys = key.split('-');
          if (parsedCollectibleKeys[1] === parsedKeys[3] || parsedCollectibleKeys[3] === parsedKeys[3]) {
            commentDisplayMap[key] = value.filter((comment) => {
              return comment !== commentId;
            });
          }
        }
      }

      if (parsedKeys[0] !== COLLECTIBLE && !parsedKeys[4]) {
        let collectible = { ...allCollectibles[parsedKeys[3]] };
        collectible.commentCount -= 1;
        allCollectibles[parsedKeys[3]] = collectible;
      } else if (parsedKeys[0] === COLLECTIBLE && !parsedKeys[2]) {
        Object.keys(allDetailedCollectibles).map((key, index) => {
          if (parseInt(allDetailedCollectibles[key].id) === parseInt(parsedKeys[1])) {
            let collectible = { ...allDetailedCollectibles[key] };
            collectible.commentCount -= 1;
            allDetailedCollectibles[key] = collectible;
          }
        });
      }

      return {
        ...state,
        allComments: Object.assign({}, allComments),
        allCollectibles: Object.assign({}, allCollectibles),
        allDetailedCollectibles: Object.assign({}, allDetailedCollectibles),
        commentDisplayMap: Object.assign({}, commentDisplayMap),
      };
    }
    case CommentActionTypes.CREATE_COMMENT_SUCCESS: {
      let { key } = action.meta.previousAction.payload.request.data;
      let comment = action.payload;
      let { allComments, commentDisplayMap, allCollectibles, allDetailedCollectibles } = state;

      if (key) {
        allComments[comment.id] = comment;

        if (commentDisplayMap[key]) {
          commentDisplayMap[key] = [...commentDisplayMap[key], comment.id];
        } else {
          commentDisplayMap[key] = [comment.id];
        }

        let parsedKeys = key.split('-');
        if (parsedKeys[0] === COLLECTIBLE) {
          for (const [key, value] of Object.entries(commentDisplayMap)) {
            let parsedActivityKeys = key.split('-');
            if (parsedKeys[2] && parsedActivityKeys[3] === parsedKeys[1] && parsedActivityKeys[4] === parsedKeys[2]) {
              commentDisplayMap[key] = [...value, comment.id];
            } else if (!parsedKeys[2] && parsedActivityKeys[3] === parsedKeys[1]) {
              commentDisplayMap[key] = [...value, comment.id];
            }
          }
        } else {
          for (const [key, value] of Object.entries(commentDisplayMap)) {
            let parsedCollectibleKeys = key.split('-');
            if (
              parsedKeys[4] &&
              parsedCollectibleKeys[1] === parsedKeys[3] &&
              parsedCollectibleKeys[2] === parsedKeys[4]
            ) {
              commentDisplayMap[key] = [...value, comment.id];
            } else if (!parsedKeys[4] && parsedCollectibleKeys[1] === parsedKeys[3]) {
              commentDisplayMap[key] = [...value, comment.id];
            }
          }
        }

        if (parsedKeys[0] !== COLLECTIBLE && !parsedKeys[4]) {
          let collectible = { ...allCollectibles[parsedKeys[3]] };
          collectible.commentCount += 1;
          allCollectibles[parsedKeys[3]] = collectible;
        } else if (parsedKeys[0] === COLLECTIBLE && !parsedKeys[2]) {
          Object.keys(allDetailedCollectibles).map((key, index) => {
            if (parseInt(allDetailedCollectibles[key].id) === parseInt(parsedKeys[1])) {
              let collectible = { ...allDetailedCollectibles[key] };
              collectible.commentCount += 1;
              allDetailedCollectibles[key] = collectible;
            }
          });
        }
      }

      if (comment.parentCommentId) {
        let parentComment = { ...allComments[comment.parentCommentId] };
        parentComment.replyCount += 1;
        allComments[comment.parentCommentId] = parentComment;
      }

      return {
        ...state,
        allComments: Object.assign({}, allComments),
        allCollectibles: Object.assign({}, allCollectibles),
        allDetailedCollectibles: Object.assign({}, allDetailedCollectibles),
        commentDisplayMap: Object.assign({}, commentDisplayMap),
      };
    }
    case CommentActionTypes.LIKE_COMMENT_SUCCESS: {
      let { commentId } = action.meta.previousAction.payload;
      let { allComments } = state;
      allComments[commentId] = {
        ...allComments[commentId],
        ...action.payload,
        actorAccountLikes: true,
      };

      return {
        ...state,
        allComments: Object.assign({}, allComments),
      };
    }
    case CommentActionTypes.UNLIKE_COMMENT_SUCCESS: {
      let { commentId } = action.meta.previousAction.payload;
      let { allComments } = state;
      allComments[commentId] = {
        ...allComments[commentId],
        ...action.payload,
        actorAccountLikes: false,
      };

      return {
        ...state,
        allComments: Object.assign({}, allComments),
      };
    }
    case CommentActionTypes.UPDATE_COMMENT_SUCCESS: {
      let { commentId } = action.meta.previousAction.payload;
      let { allComments } = state;
      allComments[commentId] = action.payload;

      return {
        ...state,
        allComments: Object.assign({}, allComments),
      };
    }
    case CommentActionTypes.GET_COMMENT_REPLIES_SUCCESS: {
      let { allComments, commentDisplayMap } = state;
      let { key, flag } = action.meta.previousAction.payload;

      if (flag) {
        commentDisplayMap[key] = [];
      }

      action.payload.value.map((comment) => {
        allComments[comment.id] = comment;

        if (commentDisplayMap[key]) {
          commentDisplayMap[key] = [comment.id, ...commentDisplayMap[key]];
        } else {
          commentDisplayMap[key] = [comment.id];
        }
      });

      return {
        ...state,
        allComments: Object.assign({}, allComments),
        commentDisplayMap: Object.assign({}, commentDisplayMap),
      };
    }
    case CollectibleActionTypes.CREATE_COLLECTIBLE_SUCCESS: {
      let { id, tokenId, account } = action.payload;
      let { allDetailedCollectibles, allCollectibles, allDetailedAccounts } = state;
      let cacheKey = concatCacheKey([id, tokenId]);

      allDetailedCollectibles[cacheKey] = action.payload;
      allCollectibles[cacheKey] = action.payload;

      let detailedAccount = allDetailedAccounts[account.id];

      if (detailedAccount) {
        detailedAccount.created = [id, ...(detailedAccount.created || [])];
      }

      return {
        ...state,
        allCollectibles: Object.assign({}, allCollectibles),
        allDetailedCollectibles: Object.assign({}, allDetailedCollectibles),
        allDetailedAccounts: { ...allDetailedAccounts, [account.id]: detailedAccount },
      };
    }
    case CollectionActionTypes.CREATE_COLLECTION_SUCCESS: {
      let { id, account } = action.payload;
      let { allDetailedCollections, allCollections, allDetailedAccounts } = state;

      allDetailedCollections[id] = action.payload;
      allCollections[id] = action.payload;

      let detailedAccount = allDetailedAccounts[account.id];

      if (detailedAccount) {
        detailedAccount.createdCollections = [id, ...(detailedAccount.createdCollections || [])];
      }

      return {
        ...state,
        allCollections: Object.assign({}, allCollections),
        allDetailedCollections: Object.assign({}, allDetailedCollections),
        allDetailedAccounts: { ...allDetailedAccounts, [account.id]: detailedAccount },
      };
    }
    case CollectibleActionTypes.UNRESTRICT_CONTENT: {
      let { collectibleId, cacheKey } = action.payload;
      let { allCollectibles, allDetailedCollectibles } = state;

      let collectible = allCollectibles[collectibleId];

      if (collectible?.collection && !cacheKey) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      collectible = { ...(collectible || {}), unrestrictContent: true };

      const newAllCollectibles = { ...state.allCollectibles };
      newAllCollectibles[collectibleId] = collectible;
      if (cacheKey) {
        newAllCollectibles[cacheKey] = collectible;
      }

      const newAllDetailedCollectibles = {
        ...state.allDetailedCollectibles,
      };
      if (cacheKey) {
        const detailedCollectible = { ...(allDetailedCollectibles[cacheKey] || {}), unrestrictContent: true };
        newAllDetailedCollectibles[cacheKey] = detailedCollectible;
      }

      return {
        ...state,
        allCollectibles,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case CollectibleActionTypes.GET_COLLECTIBLE_SUCCESS: {
      //      let { chainId, collectibleOrCollectionId, tokenId } = action.meta.previousAction.payload;
      let { allDetailedCollectibles, allCollectibles } = state;

      let cacheKey = null;
      const collectible = action.payload;

      if (collectible.collection?.publicAddress && collectible?.tokenId) {
        cacheKey = concatCacheKey([
          collectible.collection.chain.id,
          collectible.collection.publicAddress,
          collectible.tokenId,
        ]);
      }

      allDetailedCollectibles[collectible.id] = {
        ...action.payload,
        unrestrictContent: allDetailedCollectibles[collectible.id]?.unrestrictContent,
      };

      allCollectibles[collectible.id] = {
        ...collectible,
        unrestrictContent: allCollectibles[collectible.id]?.unrestrictContent,
      };

      if (cacheKey) {
        allDetailedCollectibles[cacheKey] = {
          ...collectible,
          unrestrictContent: allDetailedCollectibles[cacheKey]?.unrestrictContent,
        };

        allCollectibles[cacheKey] = {
          ...collectible,
          unrestrictContent: allCollectibles[cacheKey]?.unrestrictContent,
        };
      }

      return {
        ...state,
        allDetailedCollectibles: Object.assign({}, allDetailedCollectibles),
        allCollectibles: Object.assign({}, allCollectibles),
      };
    }
    case CollectionActionTypes.UPDATE_COLLECTION_SUCCESS: {
      const newCollection = action.payload;
      const newCollectionId = newCollection.id;

      let { allCollections, allDetailedCollections } = state;

      const newAllCollections = {
        ...allCollections,
        [newCollectionId]: { ...allCollections[newCollectionId], ...newCollection },
      };
      const newAllDetailedCollections = {
        ...allDetailedCollections,
        [newCollectionId]: { ...allDetailedCollections[newCollectionId], ...newCollection },
      };

      return {
        ...state,
        allCollections: newAllCollections,
        allDetailedCollections: newAllDetailedCollections,
      };
    }
    case CollectibleActionTypes.UPDATE_COLLECTIBLE_SUCCESS: {
      let { collectibleId } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles, allCollections, allDetailedCollections } = state;

      const collectionId = action.payload.collection?.id;
      const currentCollection = state.allDetailedCollections[collectionId] || action.payload.collection;

      const newAllCollectibles = { ...allCollectibles };
      newAllCollectibles[collectibleId] = action.payload;

      const newAllDetailedCollectibles = { ...allDetailedCollectibles };
      newAllDetailedCollectibles[collectibleId] = action.payload;

      if (currentCollection) {
        const createdCollectibles = currentCollection?.created || [];
        const newCollectibleForCurrentCollection = !createdCollectibles.includes(Number(collectibleId));

        const updatedCollection = {
          ...currentCollection,
          created: newCollectibleForCurrentCollection
            ? [...createdCollectibles, Number(collectibleId)] // REMOVE FROM OTHERS
            : createdCollectibles,
        };

        const newAllCollections = { ...allCollections };
        const newAllDetailedCollections = { ...allDetailedCollections };

        if (newCollectibleForCurrentCollection) {
          for (const collectionKey in newAllCollections) {
            const collection = newAllCollections[collectionKey];
            if (collection.created?.length > 0) {
              collection.created = collection.created.filter((c) => c !== Number(collectibleId));
            }
          }
          for (const detailedCollectionKey in newAllDetailedCollections) {
            const detailedCollection = newAllDetailedCollections[detailedCollectionKey];
            if (detailedCollection?.created?.length > 0) {
              detailedCollection.created = detailedCollection.created.filter((c) => c !== Number(collectibleId));
            }
          }
        }

        newAllCollections[collectionId] = updatedCollection;
        newAllDetailedCollections[collectionId] = updatedCollection;

        return {
          ...state,
          allDetailedCollectibles: newAllDetailedCollectibles,
          allCollectibles: newAllCollectibles,
          allCollections: newAllCollections,
          allDetailedCollections: newAllDetailedCollections,
        };
      }

      return {
        ...state,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case CollectibleActionTypes.DELETE_COLLECTIBLE_SUCCESS: {
      let { collectibleId, accountId } = action.meta.previousAction.payload;
      let { allCollectibles, allDetailedCollectibles, allDetailedAccounts, allCollections, allDetailedCollections } =
        state;

      delete allCollectibles[collectibleId];
      delete allDetailedCollectibles[collectibleId];

      const newAllCollections = { ...allCollections };
      const newAllDetailedCollections = { ...allDetailedCollections };

      for (const collectionId in allCollections) {
        newAllCollections[collectionId].created = allCollections[collectionId]?.created?.filter(
          (id) => id !== Number(collectibleId),
        );
      }

      for (const detailedCollectionId in allDetailedCollections) {
        newAllDetailedCollections[detailedCollectionId].created = allDetailedCollections[
          detailedCollectionId
        ]?.created?.filter((id) => id !== Number(collectibleId));
      }

      let detailedAccount = {
        ...state.allDetailedAccounts[accountId],
        created: allDetailedAccounts[accountId]?.created?.filter((c) => c.toString() !== collectibleId),
      };

      return {
        ...state,
        allCollectibles: Object.assign({}, allCollectibles),
        allDetailedCollectibles: Object.assign({}, allDetailedCollectibles),
        allCollections: Object.assign({}, newAllCollections),
        allDetailedCollections: Object.assign({}, newAllDetailedCollections),
        allDetailedAccounts: { ...state.allDetailedAccounts, [accountId]: detailedAccount },
      };
    }
    case CollectionActionTypes.DELETE_COLLECTION_SUCCESS: {
      let { collectionId, accountId } = action.meta.previousAction.payload;
      let { allCollections, allDetailedCollections, allDetailedAccounts } = state;

      delete allCollections[collectionId];
      delete allDetailedCollections[collectionId];

      let detailedAccount = {
        ...state.allDetailedAccounts[accountId],
        createdCollections: allDetailedAccounts[accountId]?.createdCollections?.filter(
          (c) => c.toString() !== collectionId,
        ),
      };

      return {
        ...state,
        allCollections: Object.assign({}, allCollections),
        allDetailedCollections: Object.assign({}, allDetailedCollections),
        allDetailedAccounts: { ...state.allDetailedAccounts, [accountId]: detailedAccount },
      };
    }
    case AccountActionTypes.GET_ACCOUNT_BY_NAME_SUCCESS: {
      const profileAccountList = [...state.profileAccountList, action.payload.id];
      state.allDetailedAccounts[action.payload.id] = {
        ...(state.allDetailedAccounts[action.payload.id] && state.allDetailedAccounts[action.payload.id]),
        ...action.payload
      };

      if (state.profileAccountList.length > 10) {
        delete state.allDetailedAccounts[state.profileAccountList[0].id];
        profileAccountList.shift();
      }

      return {
        ...state,
        allDetailedAccounts: Object.assign({}, state.allDetailedAccounts),
        profileAccountList: profileAccountList,
      };
    }
    case CollectionActionTypes.GET_COLLECTION_SUCCESS: {
      const profileCollectionList = [...state.profileCollectionList, action.payload.id];
      state.allDetailedCollections[action.payload.id] = action.payload;
      state.allCollections[action.payload.id] = action.payload;

      if (state.profileCollectionList.length > 10) {
        delete state.allDetailedCollections[state.profileCollectionList[0].id];
        profileCollectionList.shift();
      }

      return {
        ...state,
        allDetailedCollections: Object.assign({}, state.allDetailedCollections),
        profileCollectionList: profileCollectionList,
      };
    }
    case CollectionActionTypes.GET_COLLECTION_STATS_SUCCESS: {
      const collectionId = action.meta.previousAction.payload.collectionId;

      const newAllCollections = { ...state.allCollections };
      const newAllDetailedCollections = { ...state.allDetailedCollections };

      newAllCollections[collectionId].stats = action.payload;
      newAllDetailedCollections[collectionId].stats = action.payload;

      return {
        ...state,
        allDetailedCollections: newAllDetailedCollections,
        allCollections: newAllCollections,
      };
    }
    case AccountActionTypes.UPDATE_ACCOUNT_PROFILE_SUCCESS: {
      let detailedAccount = { ...state.allDetailedAccounts[action.payload.accountId] };
      detailedAccount.profile = action.payload;

      return {
        ...state,
        allDetailedAccounts: { ...state.allDetailedAccounts, [action.payload.accountId]: detailedAccount },
      };
    }
    case AccountActionTypes.MUTE_ACCOUNT_SUCCESS: {
      let mutedAccount = { ...state.allDetailedAccounts[action.payload.id] };

      mutedAccount.actorMuted = true;

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [action.payload.id]: mutedAccount,
        },
      };
    }
    case AccountActionTypes.UNMUTE_ACCOUNT_SUCCESS: {
      let unmutedAccount = { ...state.allDetailedAccounts[action.payload.id] };

      unmutedAccount.actorMuted = false;

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [action.payload.id]: unmutedAccount,
        },
      };
    }
    case AccountActionTypes.BLOCK_ACCOUNT_SUCCESS: {
      let blockAccount = { ...state.allDetailedAccounts[action.payload.id] };

      if (action.payload) {
        blockAccount.actorBlocked = action.payload.actorBlocked;
        blockAccount.actorFollower = action.payload.actorFollower;
        blockAccount.actorFollowee = action.payload.actorFollowee;
      }

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [action.payload.id]: blockAccount,
        },
      };
    }
    case AccountActionTypes.UNBLOCK_ACCOUNT_SUCCESS: {
      let unblockAccount = { ...state.allDetailedAccounts[action.payload.id] };

      if (action.payload) {
        unblockAccount.actorBlocked = action.payload.actorBlocked;
        unblockAccount.actorFollower = action.payload.actorFollower;
        unblockAccount.actorFollowee = action.payload.actorFollowee;
      }

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [action.payload.id]: unblockAccount,
        },
      };
    }
    case AccountActionTypes.ENABLE_FOLLOWEE_NOTIFICATIONS_SUCCESS: {
      let { followeeAccountId } = action.meta.previousAction.payload;
      let followeeNotificationkAccount = { ...state.allDetailedAccounts[followeeAccountId] };

      followeeNotificationkAccount.actorFolloweeNotificationsEnabled = true;

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [followeeAccountId]: followeeNotificationkAccount,
        },
      };
    }
    case AccountActionTypes.DISABLE_FOLLOWEE_NOTIFICATIONS_SUCCESS: {
      let { followeeAccountId } = action.meta.previousAction.payload;
      let followeeNotificationkAccount = { ...state.allDetailedAccounts[followeeAccountId] };

      followeeNotificationkAccount.actorFolloweeNotificationsEnabled = false;

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [followeeAccountId]: followeeNotificationkAccount,
        },
      };
    }
    case AccountActionTypes.GET_ACCOUNT_OWNED_COLLECTIBLES_SUCCESS: {
      let profileIndex = action.meta.previousAction.payload.accountId;

      const ownedArr = state.allDetailedAccounts[profileIndex].owned || [];

      action.payload.value.forEach((item) => {
        const existingCachedObject = state.allCollectibles[item.id];
        state.allCollectibles[item.id] = { ...item, unrestrictContent: existingCachedObject?.unrestrictContent };
        ownedArr.push(item.id);
      });

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [profileIndex]: { ...state.allDetailedAccounts[profileIndex], owned: ownedArr },
        },
        allCollectibles: Object.assign({}, state.allCollectibles),
      };
    }
    case AccountActionTypes.GET_ACCOUNT_CREATED_COLLECTIONS_SUCCESS: {
      let profileIndex = action.meta.previousAction.payload.accountId;
      const createdArr = state.allDetailedAccounts[profileIndex]?.createdCollections || [];

      action.payload.value.forEach((item) => {
        const existingCachedObject = state.allCollections[item.id];
        state.allCollections[item.id] = { ...item, unrestrictContent: existingCachedObject?.unrestrictContent };
        createdArr.push(item.id);
      });

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [profileIndex]: { ...state.allDetailedAccounts[profileIndex], createdCollections: createdArr },
        },
        allCollections: state.allCollections,
      };
    }
    case AccountActionTypes.GET_ACCOUNT_CREATED_COLLECTIBLES_SUCCESS: {
      let profileIndex = action.meta.previousAction.payload.accountId;

      const createdArr = state.allDetailedAccounts[profileIndex].created || [];

      action.payload.value.forEach((item) => {
        const existingCachedObject = state.allCollectibles[item.id];
        state.allCollectibles[item.id] = { ...item, unrestrictContent: existingCachedObject?.unrestrictContent };
        createdArr.push(item.id);
      });

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [profileIndex]: { ...state.allDetailedAccounts[profileIndex], created: createdArr },
        },
        allCollectibles: state.allCollectibles,
      };
    }
    case CollectionActionTypes.GET_COLLECTION_COLLECTIBLES_SUCCESS: {
      let collectionIndex = action.meta.previousAction.payload.collectionId;

      const createdArr = state.allDetailedCollections[collectionIndex].created || [];

      action.payload.value.forEach((item) => {
        const existingCachedObject = state.allCollectibles[item.id];
        state.allCollectibles[item.id] = { ...item, unrestrictContent: existingCachedObject?.unrestrictContent };
        createdArr.push(item.id);
      });

      return {
        ...state,
        allDetailedCollections: {
          ...state.allDetailedCollections,
          [collectionIndex]: { ...state.allDetailedCollections[collectionIndex], created: createdArr },
        },
        allCollectibles: state.allCollectibles,
      };
    }
    case AccountActionTypes.GET_ACCOUNT_RESERVE_AUCTION_COLLECTIBLES_SUCCESS: {
      let profileIndex = action.meta.previousAction.payload.accountId;

      const liveAuctionArr = state.allDetailedAccounts[profileIndex].liveAuctions || [];

      action.payload.value.forEach((item) => {
        const existingCachedObject = state.allCollectibles[item.id];
        state.allCollectibles[item.id] = { ...item, unrestrictContent: existingCachedObject?.unrestrictContent };
        liveAuctionArr.push(item.id);
      });

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [profileIndex]: { ...state.allDetailedAccounts[profileIndex], liveAuctions: liveAuctionArr },
        },
        allCollectibles: Object.assign({}, state.allCollectibles),
      };
    }
    case CollectionActionTypes.GET_COLLECTION_RESERVE_AUCTION_COLLECTIONS_SUCCESS: {
      let collectionIndex = action.meta.previousAction.payload.collectionId;

      const liveAuctionArr = state.allDetailedCollections[collectionIndex].liveAuctions || [];

      action.payload.value.forEach((item) => {
        const existingCachedObject = state.allCollectibles[item.id];
        state.allCollectibles[item.id] = { ...item, unrestrictContent: existingCachedObject?.unrestrictContent };
        liveAuctionArr.push(item.id);
      });

      return {
        ...state,
        allDetailedCollections: {
          ...state.allDetailedCollections,
          [collectionIndex]: { ...state.allDetailedCollections[collectionIndex], liveAuctions: liveAuctionArr },
        },
        allCollectibles: Object.assign({}, state.allCollectibles),
      };
    }
    case AccountActionTypes.GET_ACCOUNT_ON_SALE_COLLECTIBLES_SUCCESS: {
      let profileIndex = action.meta.previousAction.payload.accountId;

      const onSaleArr = state.allDetailedAccounts[profileIndex].onSale || [];

      action.payload.value.forEach((item) => {
        const existingCachedObject = state.allCollectibles[item.id];
        state.allCollectibles[item.id] = { ...item, unrestrictContent: existingCachedObject?.unrestrictContent };
        onSaleArr.push(item.id);
      });

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [profileIndex]: { ...state.allDetailedAccounts[profileIndex], onSale: onSaleArr },
        },
        allCollectibles: Object.assign({}, state.allCollectibles),
      };
    }
    case CollectionActionTypes.GET_COLLECTION_ON_SALE_COLLECTIONS_SUCCESS: {
      let collectionIndex = action.meta.previousAction.payload.collectionId;

      const onSaleArr = state.allDetailedCollections[collectionIndex].onSale || [];

      action.payload.value.forEach((item) => {
        const existingCachedObject = state.allCollectibles[item.id];
        state.allCollectibles[item.id] = { ...item, unrestrictContent: existingCachedObject?.unrestrictContent };
        onSaleArr.push(item.id);
      });

      return {
        ...state,
        allDetailedCollections: {
          ...state.allDetailedCollections,
          [collectionIndex]: { ...state.allDetailedCollections[collectionIndex], onSale: onSaleArr },
        },
        allCollectibles: Object.assign({}, state.allCollectibles),
      };
    }
    case AccountActionTypes.GET_ACCOUNT_FOLLOWEES_SUCCESS: {
      let profileIndex = action.meta.previousAction.payload.accountId;

      const followeesArr = state.allDetailedAccounts[profileIndex].followees || [];

      action.payload.value.forEach((item) => {
        state.allAccounts[item.id] = item;
        followeesArr.push(item.id);
      });

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [profileIndex]: { ...state.allDetailedAccounts[profileIndex], followees: followeesArr },
        },
        allAccounts: state.allAccounts,
      };
    }
    case AccountActionTypes.GET_ACCOUNT_FOLLOWERS_SUCCESS: {
      let profileIndex = action.meta.previousAction.payload.accountId;

      const followersArr = state.allDetailedAccounts[profileIndex].followers || [];

      action.payload.value.forEach((item) => {
        state.allAccounts[item.id] = item;
        followersArr.push(item.id);
      });

      return {
        ...state,
        allDetailedAccounts: {
          ...state.allDetailedAccounts,
          [profileIndex]: { ...state.allDetailedAccounts[profileIndex], followers: followersArr },
        },
        allAccounts: state.allAccounts,
      };
    }
    case AccountActionTypes.SET_ACTIVE_PROFILE_ACCOUNT: {
      return {
        ...state,
        activeProfileAccountId: action.payload.activeProfileAccountId,
      };
    }
    case CollectionActionTypes.SET_ACTIVE_PROFILE_COLLECTION: {
      return {
        ...state,
        activeProfileCollectionId: action.payload.activeProfileCollectionId,
      };
    }
    case CollectibleActionTypes.UPDATE_COLLECTIBLE_STATE: {
      const { updatedCollectibleSummary } = action.payload;

      let collectibleId = updatedCollectibleSummary.id;
      let { allCollectibles, allDetailedCollectibles } = state;

      const cacheKey = concatCacheKey([
        updatedCollectibleSummary.collection.chain.id,
        updatedCollectibleSummary.collection.publicAddress,
        updatedCollectibleSummary.tokenId,
      ]);

      const extractAccount = (oldAcc, newAcc) => {
        return {
          account: {
            ...oldAcc.account,
            ...newAcc.account,
            profile: {
              ...oldAcc?.account?.profile,
              ...newAcc?.account?.profile,
            },
          },
        };
      };

      const newAllCollectibles = { ...allCollectibles };
      if (newAllCollectibles[collectibleId]) {
        newAllCollectibles[collectibleId] = {
          ...newAllCollectibles[collectibleId],
          ...updatedCollectibleSummary,
          ...extractAccount(newAllCollectibles[collectibleId], updatedCollectibleSummary),
        };
      }

      if (newAllCollectibles[cacheKey]) {
        newAllCollectibles[cacheKey] = {
          ...newAllCollectibles[cacheKey],
          ...updatedCollectibleSummary,
          ...extractAccount(newAllCollectibles[cacheKey], updatedCollectibleSummary),
        };
      }

      const newAllDetailedCollectibles = { ...allDetailedCollectibles };

      if (newAllDetailedCollectibles[collectibleId]) {
        newAllDetailedCollectibles[collectibleId] = {
          ...newAllDetailedCollectibles[collectibleId],
          ...updatedCollectibleSummary,
          ...extractAccount(newAllDetailedCollectibles[collectibleId], updatedCollectibleSummary),
        };
      }

      if (newAllDetailedCollectibles[cacheKey]) {
        newAllDetailedCollectibles[cacheKey] = {
          ...newAllDetailedCollectibles[cacheKey],
          ...updatedCollectibleSummary,
          ...extractAccount(newAllDetailedCollectibles[cacheKey], updatedCollectibleSummary),
        };
      }

      return {
        ...state,
        allDetailedCollectibles: newAllDetailedCollectibles,
        allCollectibles: newAllCollectibles,
      };
    }
    case AccountActionTypes.GET_NOTIFICATIONS_SUCCESS: {
      const newNotifications = action.payload.reduce(
        (payload, n) => ({
          ...payload,
          [n.notification.id]: {
            ...n,
            content: buildNotificationContent(n.notification, state.currentAccount),
          },
        }),
        {},
      );

      return {
        ...state,
        allNotifications: Object.assign({}, state.allNotifications, newNotifications),
      };
    }
    case AccountActionTypes.MARK_ALL_NOTIFICATIONS_AS_READ_SUCCESS: {
      const markedAllNotifications = Object.assign({}, state.allNotifications);

      Object.keys(markedAllNotifications).map(function (key) {
        markedAllNotifications[key].actorStatus.read = true;
      });

      return {
        ...state,
        allNotifications: Object.assign({}, markedAllNotifications),
      };
    }
    case AccountActionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS: {
      const notificationActorStatus = action.payload.actorStatus;
      const markedNotificationId = action.payload.notification.id;

      const _markedAllNotifications = Object.assign({}, state.allNotifications);
      _markedAllNotifications[markedNotificationId].actorStatus = notificationActorStatus;

      return {
        ...state,
        allNotifications: Object.assign({}, _markedAllNotifications),
      };
    }
    case NotificationActionTypes.NEW_NOTIFICATION: {
      // Do this only if the account is the current account...
      const _notification = action.payload.notification;

      const newNotification = {
        [_notification.id]: {
          notification: _notification,
          content: buildNotificationContent(_notification, state.currentAccount),
          actorStatus: {
            read: false,
          },
        },
      };

      return {
        ...state,
        allNotifications: Object.assign({}, state.allNotifications, newNotification),
      };
    }
    case NotificationActionTypes.REMOVED_NOTIFICATION: {
      const removedNotificationId = action.payload.notificationId;
      const removedAllNotification = Object.assign({}, state.allNotifications);

      delete removedAllNotification[removedNotificationId];

      return {
        ...state,
        allNotifications: Object.assign({}, removedAllNotification),
      };
    }
    case UserActionTypes.LOAD_ACCOUNT_SUCCESS: {
      return {
        ...state,
        currentAccount: action.payload,
      };
    }
    case CommentActionTypes.CLEAR_CACHE_COMMENT: {
      const { key } = action.payload;
      const newCommentDisplayMap = Object.assign({}, state.commentDisplayMap);

      delete newCommentDisplayMap[key];

      return {
        ...state,
        commentDisplayMap: Object.assign({}, newCommentDisplayMap),
      };
    }
    //#endregion
    default:
      return state;
  }
};
