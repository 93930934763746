import * as React from 'react';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import Icon from '@Components/shared/Icon';
import { UserActions } from '@Actions/UserActions';
import { ModalActions } from '@Actions/ModalActions';
import { Formik } from 'formik';
import AppVariables from '@AppVariables';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';

class SmsAuthenticationModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  resend() {
    this.props.enableLoader();
    this.props.requestEnable2faByPhone().then((result) => {
      this.props.disableLoader();
      if (!result.error) {
      }
    });
  }

  @bind
  submitVerificationCode(values, action) {
    this.props.enableLoader();
    this.props.verifyEnable2faByPhone(values.code).then((result) => {
      if (!result.error) {
        this.props.smsAuthSuccess();
        this.props.disableLoader();
        this.props.openModal('PhoneNumberChangeModal3', { show: true, backdrop: true });
      } else {
        this.props.verifying2FaByPhoneNumberError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  @bind
  changeNumberModalHandler() {
    this.props.openModal('PhoneNumberChangeModal', {
      backdrop: true,
      enableTwoFactor: true,
      smsAuthSuccess: this.props.smsAuthSuccess,
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    let submitForm;
    return (
      <Modal
        title={t('modals.verifyPhoneNumberTitle')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="sms-authentication-modal mx-2 my-2">
          <div className="sms-auth-container mt-2 mx-1 mb-2">
            <div className="text">{t('modals.smsAuthText', {phoneNumber: this.props.faStatusData?.phoneNumber})}</div>
            <Link to={'#'} onClick={this.changeNumberModalHandler}>
              {t('modals.changeMyPhoneNumber')}
            </Link>
            <div className="content my-4">
              <div className="icon-container">
                <Icon src="fas fa-mobile-alt" type="fontawesome" />
              </div>
              <div className="instruction-container">
                <div className="mb-3">{t('modals.enterVerificationCode')}</div>
                <Formik
                  initialValues={{ verificationCode: '' }}
                  onSubmit={this.submitVerificationCode}
                  validationSchema={AppVariables.validationSchemas.faVerificationCodeSchema}
                >
                  {(formikProps) => {
                    submitForm = formikProps.submitForm;
                    return (
                      <form className="form" onSubmit={formikProps.handleSubmit}>
                        <Input.Number
                          className="w-100 my-3"
                          label={t('modals.inputLabelSmsAuth')}	
                          placeholder={t('modals.inputPlaceholderSmsAuth')}	
                          name="code"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.code}
                          error={t(formikProps.errors.code)}
                        />
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.resendButton')}
              type="primary"
              fullSize
              onClick={this.resend}
              disabled={this.props.enabling2FaByPhoneNumber}
            />
          </div>
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.activateButton')}
              type="primary"
              fullSize
              onClick={() => submitForm()}
              disabled={this.props.verifying2FaByPhoneNumber}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export const SmsAuthModal = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.auth };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(SmsAuthenticationModal));
