const serviceEndpoint = () => 'v1/service';

export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_COUNTRIES_SUCCESS = 'GET_COUNTRIES_SUCCESS';
export const GET_COUNTRIES_FAILURE = 'GET_COUNTRIES_FAILURE';

const getCountries = () => ({
  type: GET_COUNTRIES,
  payload: {
    request: {
      url: `${serviceEndpoint()}/countries`,
    },
  },
});

export const GET_CURRENCIES = 'GET_CURRENCIES';
export const GET_CURRENCIES_SUCCESS = 'GET_CURRENCIES_SUCCESS';
export const GET_CURRENCIES_FAILURE = 'GET_CURRENCIES_FAILURE';

const getCurrencies = () => ({
  type: GET_CURRENCIES,
  payload: {
    request: {
      url: `${serviceEndpoint()}/currencies`,
    },
  },
});
export const GET_ARTICLES = 'GET_ARTICLES';
export const GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS';
export const GET_ARTICLES_FAILURE = 'GET_ARTICLES_FAILURE';

const getArticles = (locale) => ({
  type: GET_ARTICLES,
  payload: {
    client: 'selfClient',
    request: {
      method: 'GET',
      url: `/articles/${locale}`,
    },
  },
});

export const GET_LANGUAGES = 'GET_LANGUAGES';
export const GET_LANGUAGES_SUCCESS = 'GET_LANGUAGES_SUCCESS';
export const GET_LANGUAGES_FAILURE = 'GET_LANGUAGES_FAILURE';

const getLanguages = () => ({
  type: GET_LANGUAGES,
  payload: {
    request: {
      url: `${serviceEndpoint()}/languages`,
    },
  },
});

export const GET_CULTURES = 'GET_CULTURES';
export const GET_CULTURES_SUCCESS = 'GET_CULTURES_SUCCESS';
export const GET_CULTURES_FAILURE = 'GET_CULTURES_FAILURE';

const getCultures = () => ({
  type: GET_CULTURES,
  payload: {
    request: {
      url: `${serviceEndpoint()}/cultures`,
    },
  },
});

export const GET_INTERESTS = 'GET_INTERESTS';
export const GET_INTERESTS_SUCCESS = 'GET_INTERESTS_SUCCESS';
export const GET_INTERESTS_FAILURE = 'GET_INTERESTS_FAILURE';

const getInterests = () => ({
  type: GET_INTERESTS,
  payload: {
    request: {
      url: `${serviceEndpoint()}/interests`,
    },
  },
});

export const GET_ACCOUNT_CATEGORIES = 'GET_ACCOUNT_CATEGORIES';
export const GET_ACCOUNT_CATEGORIES_SUCCESS = 'GET_ACCOUNT_CATEGORIES_SUCCESS';
export const GET_ACCOUNT_CATEGORIES_FAILURE = 'GET_ACCOUNT_CATEGORIES_FAILURE';

const getAccountCategories = () => ({
  type: GET_ACCOUNT_CATEGORIES,
  payload: {
    request: {
      url: `${serviceEndpoint()}/accountcategories`,
    },
  },
});

export const GET_COLLECTIBLE_CATEGORIES = 'GET_COLLECTIBLE_CATEGORIES';
export const GET_COLLECTIBLE_CATEGORIES_SUCCESS = 'GET_COLLECTIBLE_CATEGORIES_SUCCESS';
export const GET_COLLECTIBLE_CATEGORIES_FAILURE = 'GET_COLLECTIBLE_CATEGORIES_FAILURE';

const getCollectibleCategories = () => ({
  type: GET_COLLECTIBLE_CATEGORIES,
  payload: {
    request: {
      url: `${serviceEndpoint()}/collectiblecategories`,
    },
  },
});

export const GET_PLATFORM_COLLECTIONS = 'GET_PLATFORM_COLLECTIONS';
export const GET_PLATFORM_COLLECTIONS_SUCCESS = 'GET_PLATFORM_COLLECTIONS_SUCCESS';
export const GET_PLATFORM_COLLECTIONS_FAILURE = 'GET_PLATFORM_COLLECTIONS_FAILURE';

const getPlatformCollections = () => ({
  type: GET_PLATFORM_COLLECTIONS,
  payload: {
    request: {
      url: `${serviceEndpoint()}/platformcollections`,
    },
  },
});

export const GET_ACCOUNT_SUGGESTIONS = 'GET_ACCOUNT_SUGGESTIONS';
export const GET_ACCOUNT_SUGGESTIONS_SUCCESS = 'GET_ACCOUNT_SUGGESTIONS_SUCCESS';
export const GET_ACCOUNT_SUGGESTIONS_FAILURE = 'GET_ACCOUNT_SUGGESTIONS_FAILURE';

const getAccountSuggestions = (hint, skip = 0, take = 25) => ({
  type: GET_ACCOUNT_SUGGESTIONS,
  payload: {
    request: {
      url: `${serviceEndpoint()}/suggestions/accounts`,
      params: {
        hint,
        skip,
        take,
      },
    },
  },
});

export const GET_HASHTAG_SUGGESTIONS = 'GET_HASHTAG_SUGGESTIONS';
export const GET_HASHTAG_SUGGESTIONS_SUCCESS = 'GET_HASHTAG_SUGGESTIONS_SUCCESS';
export const GET_HASHTAG_SUGGESTIONS_FAILURE = 'GET_HASHTAG_SUGGESTIONS_FAILURE';

const getHashtagSuggestions = (hint, skip = 0, take = 25) => ({
  type: GET_HASHTAG_SUGGESTIONS,
  payload: {
    request: {
      url: `${serviceEndpoint()}/suggestions/hashtags`,
      params: {
        hint,
        skip,
        take,
      },
    },
  },
});

export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const SEND_FEEDBACK_SUCCESS = 'SEND_FEEDBACK_SUCCESS';
export const SEND_FEEDBACK_FAILURE = 'SEND_FEEDBACK_FAILURE';

const sendFeedback = (message, screenshotBlob) => {
  const formData = new FormData();

  formData.set('message', message);

  if (screenshotBlob) {
    formData.append('screenshot', screenshotBlob);
  }

  return {
    type: SEND_FEEDBACK,
    payload: {
      request: {
        method: 'POST',
        url: `${serviceEndpoint()}/feedback`,
        data: formData,
      },
    },
  };
};

export const REPORT_ACCOUNT = 'REPORT_ACCOUNT';
export const REPORT_ACCOUNT_SUCCESS = 'REPORT_ACCOUNT_SUCCESS';
export const REPORT_ACCOUNT_FAILURE = 'REPORT_ACCOUNT_FAILURE';

const reportAccount = (accountReportModel) => ({
  type: REPORT_ACCOUNT,
  payload: {
    request: {
      method: 'POST',
      url: `${serviceEndpoint()}/report/account`,
      data: accountReportModel,
    },
  },
});

export const REPORT_COLLECTION = 'REPORT_COLLECTION';
export const REPORT_COLLECTION_SUCCESS = 'REPORT_COLLECTION_SUCCESS';
export const REPORT_COLLECTION_FAILURE = 'REPORT_COLLECTION_FAILURE';

const reportCollection = (collectionReportModel) => ({
  type: REPORT_COLLECTION,
  payload: {
    request: {
      method: 'POST',
      url: `${serviceEndpoint()}/report/collection`,
      data: collectionReportModel,
    },
  },
});

export const REPORT_POST = 'REPORT_POST';
export const REPORT_POST_SUCCESS = 'REPORT_POST_SUCCESS';
export const REPORT_POST_FAILURE = 'REPORT_POST_FAILURE';

const reportPost = (postReportModel) => ({
  type: REPORT_POST,
  payload: {
    request: {
      method: 'POST',
      url: `${serviceEndpoint()}/report/post`,
      data: postReportModel,
    },
  },
});

export const GET_TOKEN_PRICES_IN_USD = 'GET_TOKEN_PRICES_IN_USD';
export const GET_TOKEN_PRICES_IN_USD_SUCCESS = 'GET_TOKEN_PRICES_IN_USD_SUCCESS';
export const GET_TOKEN_PRICES_IN_USD_FAILURE = 'GET_TOKEN_PRICES_IN_USD_FAILURE';

const getTokenPricesInUSD = () => ({
  type: GET_TOKEN_PRICES_IN_USD,
  payload: {
    client: 'coinGecko',
    request: {
      method: 'GET',
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
      },
      url: `simple/price?vs_currencies=usd&ids=ethereum,weth,dai,usd-coin,matic-network,wmatic,binancecoin,wbnb,avalanche-2,wrapped-avax,binance-usd`,
    },
  },
});

export const PING = 'PING';
export const PING_SUCCESS = 'PING_SUCCESS';
export const PING_FAILURE = 'PING_FAILURE';

const ping = () => ({
  type: PING,
  payload: {
    request: {
      method: 'POST',
      url: `${serviceEndpoint()}/ping`,
    },
  },
});

export const PING_AUTH = 'PING_AUTH';
export const PING_AUTH_SUCCESS = 'PING_AUTH_SUCCESS';
export const PING_AUTH_FAILURE = 'PING_AUTH_FAILURE';

const pingAuth = () => ({
  type: PING_AUTH,
  payload: {
    request: {
      method: 'POST',
      url: `${serviceEndpoint()}/ping/auth`,
    },
  },
});

export const SUBSCRIBE_OIX_UPDATES = 'SUBSCRIBE_OIX_UPDATES';
export const SUBSCRIBE_OIX_UPDATES_SUCCESS = 'SUBSCRIBE_OIX_UPDATES_SUCCESS';
export const SUBSCRIBE_OIX_UPDATES_FAILURE = 'SUBSCRIBE_OIX_UPDATES_FAILURE';

const subscribeOixUpdates = (payload) => ({
  type: SUBSCRIBE_OIX_UPDATES,
  payload: {
    client: 'selfClient',
    request: {
      method: 'POST',
      url: `/subscribe`,
      data: payload,
    },
  },
});

export const GET_WYRE_RESERVATION = 'GET_WYRE_RESERVATION';
export const GET_WYRE_RESERVATION_SUCCESS = 'GET_WYRE_RESERVATION_SUCCESS';
export const GET_WYRE_RESERVATION_FAILURE = 'GET_WYRE_RESERVATION_FAILURE';

const getWyreReservation = (publicAddress, destCurrency) => ({
  type: GET_WYRE_RESERVATION,
  payload: {
    client: 'selfClient',
    request: {
      method: 'POST',
      url: `/wyre-reservation`,
      data: { publicAddress, destCurrency },
    },
  },
});

export const GET_ANTIC_TOKEN = 'GET_ANTIC_TOKEN';
export const GET_ANTIC_TOKEN_SUCCESS = 'GET_ANTIC_TOKEN_SUCCESS';
export const GET_ANTIC_TOKEN_FAILURE = 'GET_ANTIC_TOKEN_FAILURE';

const getAnticToken = () => ({
  type: GET_ANTIC_TOKEN,
  payload: {
    client: 'selfClient',
    request: {
      method: 'POST',
      url: `/antic-token`,
    },
  },
});

export const GET_NON_PROFIT_ORGANIZATIONS = 'GET_NON_PROFIT_ORGANIZATIONS';
export const GET_NON_PROFIT_ORGANIZATIONS_SUCCESS = 'GET_NON_PROFIT_ORGANIZATIONS_SUCCESS';
export const GET_NON_PROFIT_ORGANIZATIONS_FAILURE = 'GET_NON_PROFIT_ORGANIZATIONS_FAILURE';

const getNonProfitOrganizations = (hint, skip = 0, take = 25) => ({
  type: GET_NON_PROFIT_ORGANIZATIONS,
  payload: {
    request: {
      url: `${serviceEndpoint()}/nonprofit/tgb/organizations`,
      params: {
        hint,
        skip,
        take,
      },
    },
  },
});

export const GET_NON_PROFIT_DEPOSITE_ADDRESS = 'GET_NON_PROFIT_DEPOSITE_ADDRESS';
export const GET_NON_PROFIT_DEPOSITE_ADDRESS_SUCCESS = 'GET_NON_PROFIT_DEPOSITE_ADDRESS_SUCCESS';
export const GET_NON_PROFIT_DEPOSITE_ADDRESS_FAILURE = 'GET_NON_PROFIT_DEPOSITE_ADDRESS_FAILURE';

const getNonProfitDepositAddress = (organizationId, currencyCode, chainId) => ({
  type: GET_NON_PROFIT_DEPOSITE_ADDRESS,
  payload: {
    request: {
      url: `${serviceEndpoint()}/nonprofit/tgb/deposit`,
      method: 'POST',
      data: {
        organizationId,
        currencyCode,
        chainId,
      },
    },
  },
});

export const GET_SEARCH_SUGGESTION = 'GET_SEARCH_SUGGESTION';
export const GET_SEARCH_SUGGESTION_SUCCESS = 'GET_SEARCH_SUGGESTION_SUCCESS';
export const GET_SEARCH_SUGGESTION_FAILURE = 'GET_SEARCH_SUGGESTION_FAILURE';

const getSearchSuggestion = (key, itemCount) => ({
  type: GET_SEARCH_SUGGESTION,
  payload: {
    request: {
      url: `${serviceEndpoint()}/suggestions/search`,
      method: 'GET',
      params: {
        key,
        itemCount,
      },
    },
  },
});

export const INIT_WYRE = 'INIT_WYRE';
const initWyre = (destCurrency) => {
  return { type: INIT_WYRE, payload: destCurrency };
};

export const RESET_WYRE = 'RESET_WYRE';
const resetWyre = () => {
  return { type: RESET_WYRE };
};

export const START_SIGNALR_CONNECTION = 'START_SIGNALR_CONNECTION';
const startSignalRConnection = () => ({
  type: START_SIGNALR_CONNECTION,
});

export const STOP_SIGNALR_CONNECTION = 'STOP_SIGNALR_CONNECTION';
const stopSignalRConnection = () => ({
  type: STOP_SIGNALR_CONNECTION,
});

export const CANCEL_ACTION_REQUESTS = 'CANCEL_ACTION_REQUESTS';
const cancelActionRequest = (actionType) => {
  return { type: CANCEL_ACTION_REQUESTS, actionType };
};

export const CANCEL_ALL_ACTION_REQUESTS = 'CANCEL_ALL_ACTION_REQUESTS';
const cancelAllActionRequests = () => {
  return { type: CANCEL_ALL_ACTION_REQUESTS };
};

export const ENABLE_SUPPORT_CHAT_LAUNCHER = 'ENABLE_SUPPORT_CHAT_LAUNCHER';
const enableSupportChatLauncher = () => ({
  type: ENABLE_SUPPORT_CHAT_LAUNCHER,
});

export const DISABLE_SUPPORT_CHAT_LAUNCHER = 'DISABLE_SUPPORT_CHAT_LAUNCHER';
const disableSupportChatLauncher = () => ({
  type: DISABLE_SUPPORT_CHAT_LAUNCHER,
});

const actions = {
  getCountries,
  getCurrencies,
  getArticles,
  getLanguages,
  getCultures,
  getInterests,
  getAccountCategories,
  getCollectibleCategories,
  getPlatformCollections,
  getAccountSuggestions,
  getHashtagSuggestions,
  getNonProfitOrganizations,
  getNonProfitDepositAddress,
  getSearchSuggestion,
  sendFeedback,

  reportAccount,
  reportCollection,
  reportPost,

  ping,
  pingAuth,

  getTokenPricesInUSD,

  startSignalRConnection,
  stopSignalRConnection,

  subscribeOixUpdates,
  getWyreReservation,
  initWyre,
  resetWyre,

  enableSupportChatLauncher,
  disableSupportChatLauncher,

  getAnticToken,

  cancelActionRequest,
  cancelAllActionRequests,
};

export const ServiceActions = {
  getCountries: () => (dispatch) => dispatch(actions.getCountries()),
  getCurrencies: () => (dispatch) => dispatch(actions.getCurrencies()),
  getArticles: (locale) => (dispatch) => dispatch(actions.getArticles(locale)),
  getLanguages: () => (dispatch) => dispatch(actions.getLanguages()),
  getCultures: () => (dispatch) => dispatch(actions.getCultures()),
  getInterests: () => (dispatch) => dispatch(actions.getInterests()),
  getAccountCategories: () => (dispatch) => dispatch(actions.getAccountCategories()),
  getCollectibleCategories: () => (dispatch) => dispatch(actions.getCollectibleCategories()),
  getPlatformCollections: () => (dispatch) => dispatch(actions.getPlatformCollections()),
  getAccountSuggestions: (hint, skip, take) => (dispatch) => dispatch(actions.getAccountSuggestions(hint, skip, take)),
  getHashtagSuggestions: (hint, skip, take) => (dispatch) => dispatch(actions.getHashtagSuggestions(hint, skip, take)),
  getNonProfitOrganizations: (hint, skip, take) => (dispatch) =>
    dispatch(actions.getNonProfitOrganizations(hint, skip, take)),
  getNonProfitDepositAddress: (organizationId, currencyCode, chainId) => (dispatch) =>
    dispatch(actions.getNonProfitDepositAddress(organizationId, currencyCode, chainId)),
  getSearchSuggestion: (key, itemCount) => (dispatch) => dispatch(actions.getSearchSuggestion(key, itemCount)),
  sendFeedback: (message, screenshotBlob) => (dispatch) => dispatch(actions.sendFeedback(message, screenshotBlob)),
  reportAccount: (accountReportModel) => (dispatch) => dispatch(actions.reportAccount(accountReportModel)),
  reportCollection: (collectionReportModel) => (dispatch) => dispatch(actions.reportCollection(collectionReportModel)),
  reportPost: (postReportModel) => (dispatch) => dispatch(actions.reportPost(postReportModel)),
  ping: () => (dispatch) => dispatch(actions.ping()),
  pingAuth: () => (dispatch) => dispatch(actions.pingAuth()),
  getTokenPricesInUSD: () => (dispatch) => dispatch(actions.getTokenPricesInUSD()),
  startSignalRConnection: () => (dispatch) => dispatch(actions.startSignalRConnection()),
  stopSignalRConnection: () => (dispatch) => dispatch(actions.stopSignalRConnection()),
  subscribeOixUpdates: (email) => (dispatch) => dispatch(actions.subscribeOixUpdates(email)),
  getWyreReservation: (publicAddress, destCurrency) => (dispatch) =>
    dispatch(actions.getWyreReservation(publicAddress, destCurrency)),
  initWyre: (destCurrency) => (dispatch) => dispatch(actions.initWyre(destCurrency)),
  resetWyre: () => (dispatch) => dispatch(actions.resetWyre()),

  enableSupportChatLauncher: () => (dispatch) => dispatch(actions.enableSupportChatLauncher()),
  disableSupportChatLauncher: () => (dispatch) => dispatch(actions.disableSupportChatLauncher()),

  getAnticToken: () => (dispatch) => dispatch(actions.getAnticToken()),
  cancelActionRequest: (actionType) => (dispatch) => dispatch(actions.cancelActionRequest(actionType)),
  cancelAllActionRequests: () => (dispatch) => dispatch(actions.cancelAllActionRequests()),
};
