import React from 'react';

import PropTypes from 'prop-types';
import clsx from 'clsx';

const OiModalSeparator = ({ className, position }) => (
  <div className={clsx('oi-modal-separator', position ?? 'horizontal', className)} />
);

OiModalSeparator.propTypes = {
  className: PropTypes.string,
  position: PropTypes.oneOf(['horizontal', 'vertical']).isRequired,
};

export default OiModalSeparator;
