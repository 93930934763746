import {
  JsonHubProtocol,
  HubConnectionState,
  HubConnectionBuilder,
  HttpTransportType,
  LogLevel,
} from '@microsoft/signalr';
import * as UserActionTypes from '@Actions/UserActions';
import * as ServiceActionTypes from '@Actions/ServiceActions';
import { runtimeConfig } from '../config';
import { UserActions } from '@Actions/UserActions';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { NotificationActions } from '@Actions/NotificationActions';
import { AccountActions } from '@Actions/AccountActions';

const isDev = process.env.NODE_ENV === 'development';

// THIS IS INCREDIBLY DANGEROUS AND WILL OPEN THINGS UP FOR A MAN IN THE MIDDLE ATTACK
// HOWEVER, for a localhost development certificaite it will be fine.
// NEVER ENABLE IT IN PRODUCTION CODE
if (isDev) {
  process.env.NODE_TLS_REJECT_UNAUTHORIZED = '0';
}

const startSignalRConnection = async (connection) => {
  if (connection.state === HubConnectionState.Disconnected || connection.state === HubConnectionState.Disconnecting) {
    try {
      await connection.start();

      console.assert(connection.state === HubConnectionState.Connected);

      console.log('SignalR connection established');
    } catch (err) {
      console.assert(connection.state === HubConnectionState.Disconnected);

      console.error('Error while starting SignalR Connection: ', err);

      //            setTimeout(() => startSignalRConnection(connection), 5000);
    }
  }
};

const stopSignalRConnection = async (connection) => {
  if (connection.state !== HubConnectionState.Disconnected && connection.state !== HubConnectionState.Disconnecting) {
    try {
      await connection.stop();

      console.assert(
        connection.state === HubConnectionState.Disconnected,
        `Connection is not in disconnected state after stopping. Current state: ${connection.state}`,
      );

      console.log('SignalR connection stopped');
    } catch (err) {
      console.error('Error while stopping SignalR Connection: ', err);
    }
  }
};

// Set up a SignalR connection to the specified hub URL, and actionEventMap.
// actionEventMap should be an object mapping event names, to eventHandlers that will
// be dispatched with the message body.
export const setupSignalRHubConnection = (hubUrl, actionEventMap = {}, accessTokenFactory, dispatch, getState) => {
  const options = {
    logMessageContent: isDev,
    logger: isDev ? LogLevel.Warning : LogLevel.Error,
    accessTokenFactory: accessTokenFactory(dispatch, getState),
    //        skipNegotiation: true, // We cannot set this for Azure SignalR connection
    transport: HttpTransportType.WebSockets,
  };

  // create the connection instance
  // withAutomaticReconnect will automatically try to reconnect
  // and generate a new socket connection if needed
  const connection = new HubConnectionBuilder()
    .withUrl(hubUrl, options)
    .withAutomaticReconnect()
    .withHubProtocol(new JsonHubProtocol())
    .configureLogging(LogLevel.Information)
    .build();

  // Note: to keep the connection open the serverTimeout should be
  // larger than the KeepAlive value that is set on the server
  // keepAliveIntervalInMilliseconds default is 15000 and we are using default
  // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
  connection.serverTimeoutInMilliseconds = 60000;

  // re-establish the connection if connection dropped
  connection.onclose((error) => {
    console.assert(connection.state === HubConnectionState.Disconnected);
    if (error) {
      console.log('Connection closed due to error. Try refreshing this page to restart the connection', error);
    }
  });

  connection.onreconnecting((error) => {
    console.assert(connection.state === HubConnectionState.Reconnecting);
    if (error) {
      console.log('Connection lost due to error. Reconnecting.', error);
    } else {
      console.log('Reconnecting.');
    }
  });

  connection.onreconnected((connectionId) => {
    console.assert(connection.state === HubConnectionState.Connected);
    console.log('Connection reestablished. Connected with connectionId', connectionId);
  });

  connection.on('ReceiveEvent', (res) => {
    var resObj = JSON.parse(res);

    const eventHandler = actionEventMap[resObj.eventType];

    if (eventHandler) dispatch(eventHandler(resObj, getState));

    console.log('ReceiveEvent', resObj);
  });

  return connection;
};

const hubUrl = runtimeConfig.apiPath + 'hubs/events';

const eventActionMap = {
  Activity: (event) =>
    async function (dispatch, getState) {
      switch (event.activity.type) {
        // Just refresh the collectible so it gets updated anywhere it needs
        case 'CollectibleTransactionRequest':
        case 'CollectibleTransactionCompleted':
        case 'CollectibleWin': {
          CollectibleActions.getCollectible(event.activity.targetCollectible.id)(dispatch, getState);
        }
      }

      let state = getState();
      let tokens = state.auth.user?.tokens;
      let tokenExpired = tokens ? Date.now() >= tokens.expires_at : true;

      switch (event.activity.type) {
        // Just refresh the collectible so it gets updated anywhere it needs
        case 'CollectibleTransactionRequest': // This will give us the items that are just starting the bid or finalize
        case 'CollectibleBid':
        case 'CollectibleWin':
        case 'CollectibleFinalize': {
          if (!tokenExpired) {
            AccountActions.getPlacedBids()(dispatch, getState);
          }
        }
      }
      switch (event.activity.type) {
        // Just refresh the collectible so it gets updated anywhere it needs
        case 'CollectibleLike':
        case 'CollectibleUnlike':
        case 'CollectibleShare':
        case 'CollectibleUnshare': {
          const updatedCollectibleSummary = event.activity.targetCollectible;
          CollectibleActions.updateCollectibleState(updatedCollectibleSummary)(dispatch, getState);
        }
      }
    },
  NewNotification: (event) =>
    NotificationActions.newNotification(event.accountId, event.notification, event.numTotalUnviewedNotifications),
  RemovedNotification: (event) =>
    NotificationActions.removeNotification(event.accountId, event.notificationId, event.numTotalUnviewedNotifications),
  UnviewedNotificationCount: (event) =>
    NotificationActions.setUnviewedNotificationCount(event.accountId, event.numTotalUnviewedNotifications),
  NewFeedItem: (event) => AccountActions.setNewFeedItemAvailable(),
};

const accessTokenFactory = (dispatch, getState) => () => {
  return new Promise((resolve, reject) => {
    let tokens = getState().auth.user?.tokens;
    let tokenExpired = tokens ? Date.now() >= tokens.expires_at : true;

    if (!tokenExpired) {
      resolve(tokens.access_token);
    } 
/* else if (tokens && tokenExpired) {
      UserActions.refreshTokens()(dispatch, getState)
        .then((result) => {
          resolve(result.access_token);
        })
        .catch((error) => {
          resolve(null);
        });
    }*/
    else {
      resolve(null);
    }
  });
};

let hubConnection = null;

export function signalRStart(store) {
  if (!store || !store.dispatch) {
    throw new Error('SignalR: You need to pass the redux store into the signalRStart helper!');
  }

  hubConnection = setupSignalRHubConnection(hubUrl, eventActionMap, accessTokenFactory, store.dispatch, store.getState);
}

export function signalRMiddleware(/*store*/) {
  return (next) => async (action) => {
    switch (action.type) {
      // We are using this just because we know this is done at the start of the app
      // and once
      case ServiceActionTypes.START_SIGNALR_CONNECTION:
        await startSignalRConnection(hubConnection);
        break;
      case UserActionTypes.LOAD_ACCOUNT_SUCCESS:
      case UserActionTypes.LOGOUT_SUCCESS:
        await stopSignalRConnection(hubConnection);
        await startSignalRConnection(hubConnection);
        break;
      case ServiceActionTypes.STOP_SIGNALR_CONNECTION:
        await stopSignalRConnection(hubConnection);
        break;
      default:
        break;
    }

    return next(action);
  };
}
