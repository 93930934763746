import * as React from 'react';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Icon from '@Components/shared/Icon';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';

class ShareSocialModal extends React.Component {
  socialMediaArr = [
    {
      name: 'Facebook',
      iconSrc: 'fab fa-facebook',
    },
    {
      name: 'Twitter',
      iconSrc: 'fab fa-twitter',
    },
    {
      name: 'LinkedIn',
      iconSrc: 'fab fa-linkedin',
    },
    {
      name: 'Pinterest',
      iconSrc: 'fab fa-pinterest',
    },
    {
      name: 'Tumblr',
      iconSrc: 'fab fa-tumblr',
    },
    {
      name: 'WhatsApp',
      iconSrc: 'fab fa-whatsapp',
    },
    {
      name: 'Messenger',
      iconSrc: 'fab fa-facebook-messenger',
    },
    {
      name: 'Email',
      iconSrc: 'fas fa-envelope',
    },
  ];

  generateEmbedLink() {
    const link = window.location.origin + '/embed/' + this.props.link;
    return (
      '<iframe src="' +
      link +
      '" style="border:0px #ffffff none; border-radius:20px;" name="oix" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="460px" width="500px" allowfullscreen></iframe>'
    );
  }

  copyEmbedLink() {
    navigator.clipboard.writeText(this.generateEmbedLink());
    this.props.toastSuccess('Link copied!');
  }

  copyLink() {
    const link = window.location.origin + this.props.link;
    navigator.clipboard.writeText(link);
    this.props.toastSuccess('Link copied!');
  }

  shareSocialMedia(mediaName) {
    const link = window.location.origin + this.props.link;
    switch (mediaName) {
      case 'Facebook':
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + link, 'sharer', 'height:626,width=436');
        break;
      case 'Twitter':
        window.open(
          'https://www.twitter.com/intent/tweet?url=' + link + '&via=oixofficial',
          'sharer',
          'height:626,width=436',
        );
        break;
      case 'LinkedIn':
        window.open('https://www.linkedin.com/sharing/share-offsite/?url=' + link, 'sharer', 'height:626,width=436');
        break;
      case 'Pinterest':
        window.open('http://pinterest.com/pin/create/link/?url=' + link, 'sharer', 'height:626,width=436');
        break;
      case 'Tumblr':
        window.open('http://www.tumblr.com/share/link?url=' + link, 'sharer', 'height:626,width=436');
        break;
      case 'Messenger':
        window.open(
          'http://www.facebook.com/dialog/send?app_id=1106528183017076&link=' +
            link +
            '&redirect_uri=https://oix.app',
          'sharer',
          'height:626,width=436',
        );
        break;
      case 'WhatsApp':
        window.open('https://wa.me/?text=' + link, 'sharer', 'height:626,width=436');
        break;
      case 'Email':
        window.open(`mailto:?body=${this.props.mailBody} \n${link}&subject=Check this out`);
    }
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    return (
      <Modal
        title={t('modals.shareTo')}
        backdrop={backdrop}
        onOutsideClick={onOutsideClick}
        closeOnOutsideClick={closeOnOutsideClick}>
        <div className="share-social-modal mx-2">
          {this.socialMediaArr.map((item, index) => {
            return (
              <div className="row-element" key={item + index} onClick={() => this.shareSocialMedia(item.name)}>
                <div className="line-content" key={index}>
                  <div className="icon">
                    <Icon className="mr-3" type="fontawesome" src={item.iconSrc} />
                  </div>
                  <div>
                    {item === 'email' ? 'via ' : ''} {item.name}
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
          <div className="row-element">
            <div className="line-content" onClick={() => this.copyLink()}>
              <div className="icon">
                <Icon className="mr-2" type="fontawesome" src="fas fa-link" />
              </div>
              <div>Copy link</div>
            </div>
          </div>
          {/* TODO: Revise Embed component */}
          {/* <div className="row-element">
            <div className="line-content" onClick={() => this.copyEmbedLink()}>
              <div className="icon">
                <Icon className="mr-2" type="fontawesome" src="fas fa-link" />
              </div>
              <div>Copy Embed Link</div>
            </div>
          </div> */}
        </div>
      </Modal>
    );
  }
}

export default connect(
  (state) => {
    return {};
  },
  { ...ToastActions },
)(withTranslation()(ShareSocialModal));
