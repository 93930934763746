import * as AccountActionTypes from '@Actions/AccountActions';
import * as CollectibleActionTypes from '@Actions/CollectibleActions';
import * as SearchActions from '@Actions/SearchActions';
import * as CollectionActions from '@Actions/CollectionActions';
import * as HashtagActions from '@Actions/HashtagActions';

const initialState = () => ({
  fetchingCollectibles: false,
  collectiblesData: [],
  fetchingCollectiblesError: null,

  fetchingAccounts: false,
  accountsData: [],
  fetchingAccountsError: null,

  fetchingCollections: false,
  collectionsData: [],
  fetchingCollectionsError: null,

  fetchingHashtags: false,
  hashtagsData: [],
  fetchingHashtagsError: null,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case CollectibleActionTypes.GET_COLLECTIBLES:
      return {
        ...state,
        fetchingCollectibles: true,
      };
    case CollectibleActionTypes.GET_COLLECTIBLES_SUCCESS: {
      const collectiblesArr =
        state.collectiblesData.length > 0
          ? [...state.collectiblesData, ...action.payload?.value]
          : action.payload?.value;
      return {
        ...state,
        fetchingCollectibles: false,
        collectiblesData: collectiblesArr,
        fetchingCollectiblesError: false,
      };
    }
    case CollectibleActionTypes.GET_COLLECTIBLES_FAILURE:
      return {
        ...state,
        fetchingCollectibles: false,
        fetchingCollectiblesError: true,
      };
    case CollectibleActionTypes.GET_COLLECTIBLES_WITH_POST:
      return {
        ...state,
        fetchingCollectibles: true,
      };
    case CollectibleActionTypes.GET_COLLECTIBLES_WITH_POST_SUCCESS: {
      const collectiblesArr =
        state.collectiblesData.length > 0
          ? [...state.collectiblesData, ...action.payload?.value]
          : action.payload?.value;
      return {
        ...state,
        fetchingCollectibles: false,
        collectiblesData: collectiblesArr,
        fetchingCollectiblesError: false,
      };
    }
    case CollectibleActionTypes.GET_COLLECTIBLES_WITH_POST_FAILURE:
      return {
        ...state,
        fetchingCollectibles: false,
        fetchingCollectiblesError: true,
      };

    //
    case AccountActionTypes.GET_ACCOUNTS:
      return {
        ...state,
        fetchingAccounts: true,
      };
    case AccountActionTypes.GET_ACCOUNTS_SUCCESS: {
      const accountsArr =
        state.accountsData.length > 0 ? [...state.accountsData, ...action.payload.value] : action.payload.value;
      return {
        ...state,
        fetchingAccounts: false,
        accountsData: accountsArr,
        fetchingAccountsError: false,
      };
    }
    case AccountActionTypes.GET_ACCOUNTS_FAILURE:
      return {
        ...state,
        fetchingAccountsError: true,
      };
    case AccountActionTypes.GET_ACCOUNTS_WITH_POST:
      return {
        ...state,
        fetchingAccounts: true,
      };
    case AccountActionTypes.GET_ACCOUNTS_WITH_POST_SUCCESS: {
      const accountsArr =
        state.accountsData.length > 0 ? [...state.accountsData, ...action.payload.value] : action.payload.value;
      return {
        ...state,
        fetchingAccounts: false,
        accountsData: accountsArr,
        fetchingAccountsError: false,
      };
    }
    case AccountActionTypes.GET_ACCOUNTS_WITH_POST_FAILURE:
      return {
        ...state,
        fetchingAccountsError: true,
      };
    //

    case CollectionActions.GET_COLLECTIONS:
      return {
        ...state,
        fetchingCollections: true,
      };
    case CollectionActions.GET_COLLECTIONS_SUCCESS: {
      const collectionsData =
        state.collectionsData.length > 0 ? [...state.collectionsData, ...action.payload.value] : action.payload.value;
      return {
        ...state,
        fetchingCollections: false,
        collectionsData: collectionsData,
        fetchingCollectionsError: false,
      };
    }
    case CollectionActions.GET_COLLECTIONS_FAILURE:
      return {
        ...state,
        fetchingCollectionsError: true,
      };
    case CollectionActions.GET_COLLECTIONS_WITH_POST:
      return {
        ...state,
        fetchingCollections: true,
      };
    case CollectionActions.GET_COLLECTIONS_WITH_POST_SUCCESS: {
      const collectionsData =
        state.collectionsData.length > 0 ? [...state.collectionsData, ...action.payload.value] : action.payload.value;
      return {
        ...state,
        fetchingCollections: false,
        collectionsData: collectionsData,
        fetchingCollectionsError: false,
      };
    }
    case CollectionActions.GET_COLLECTIONS_WITH_POST_FAILURE:
      return {
        ...state,
        fetchingCollectionsError: true,
      };

    //
    case SearchActions.CLEAR_SEARCH_COLLECTIBLES:
      return {
        ...state,
        collectiblesData: [],
      };
    case SearchActions.CLEAR_SEARCH_CREATORS:
      return {
        ...state,
        accountsData: [],
      };
    case SearchActions.CLEAR_SEARCH_COLLECTIONS:
      return {
        ...state,
        collectionsData: [],
      };

    //
    case HashtagActions.GET_HASHTAGS:
      return {
        ...state,
        fetchingHashtags: true,
      };
    case HashtagActions.GET_HASHTAGS_SUCCESS: {
      const hashtagsArr =
        state.hashtagsData.length > 0 ? [...state.hashtagsData, ...action.payload.value] : action.payload.value;
      return {
        ...state,
        fetchingHashtags: false,
        hashtagsData: hashtagsArr,
        fetchingHashtagsError: false,
      };
    }
    case HashtagActions.GET_HASHTAGS_FAILURE:
      return {
        ...state,
        fetchingHashtagsError: true,
      };
    case AccountActionTypes.FOLLOW_HASHTAG_SUCCESS: {
      const followedTag = action.meta.previousAction.payload.tag;

      return {
        ...state,
        hashtagsData: state.hashtagsData.map((hastag) =>
          hastag.tag === followedTag ? { ...hastag, actorFollows: true } : hastag,
        ),
      };
    }
    case AccountActionTypes.UNFOLLOW_HASHTAG_SUCCESS: {
      const unfollowedTag = action.meta.previousAction.payload.tag;

      return {
        ...state,
        hashtagsData: state.hashtagsData.map((hastag) =>
          hastag.tag === unfollowedTag ? { ...hastag, actorFollows: false } : hastag,
        ),
      };
    }
    default:
      return state;
  }
};
