import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import bind from 'bind-decorator';

import { ModalActions } from '@Actions/ModalActions';
import AppHeader from '@Components/shared/Header';
import AppFooter from '@Components/shared/Footer';
import AppSidebar from '@Components/shared/Sidebar';
import ModalManager from '@Components/modal/ModalManager';
import { AppSpinner, AppLoader } from '@Components/shared/Spinner';
import { ToastContainer } from 'react-toastify';
import withWindowDimensions from '@Components/hoc/withWindowDimensions';
import OiSupportChatWidget from '@Components/shared/OiSupportChatWidget';
import { loadable } from '@Utils';

const OiZeroPercentModal = loadable(() => import('@Components/modal/OiZeroPercentModal'));

class AppLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isSidebarOpen: false
    };
  }

  getClassName(isSidebarEnabled, isSidebarOpen, isFooterEnabled, isHeaderEnabled) {
    let cname = 'layout layout-app';
    if (!isFooterEnabled) cname += ' without-footer';
    if (isSidebarEnabled) {
      cname += ' has-sidebar';
      if (isSidebarOpen) cname += ' sidebar-open';
    }
    if (!isHeaderEnabled) {
      cname += ' without-header';
    }
    return cname;
  }

  @bind
  toggleMenu(newState = !this.state.isSidebarOpen) {
    //    if(this.props.windowWidth  1200){
    this.setState({
      isSidebarOpen: newState,
    });
    //    }
  }

  hideShowZeroPercentModal = () => {
    this.props.setZeroPercentViewed();
  }

  render() {
    const isSidebarEnabled = this.props.sidebar !== false && this.props.account;
    const isSidebarOpen = this.state.isSidebarOpen;
    const isFooterEnabled = this.props.footer !== false;
    const isHeaderEnabled = this.props.header !== false;

    return (
      <div
        id="layout-app"
        className={this.getClassName(isSidebarEnabled, isSidebarOpen, isFooterEnabled, isHeaderEnabled)}>
        {this.props.account?.unviewedNotificationCount && this.props.account?.unviewedNotificationCount > 0 ? (
          <Helmet
            titleTemplate={'(' + this.props.account.unviewedNotificationCount + ') %s | OIX'}
            defaultTitle={'(' + this.props.account.unviewedNotificationCount + ') OIX'}
          />
        ) : (
          <Helmet titleTemplate={'%s | OIX'} defaultTitle={'OIX'} />
        )}
        <ModalManager />
        <AppSpinner />
        <AppLoader />
        {isHeaderEnabled ? <AppHeader toggleMenu={this.toggleMenu} sidebar={isSidebarEnabled} /> : null}
        {isSidebarEnabled ? (
          <AppSidebar
            isSidebarOpen={this.state.isSidebarOpen}
            toggleMenu={this.toggleMenu}
            account={this.props.account}
          />
        ) : null}
        <div id="app-content">{this.props.children}</div>
        {isFooterEnabled ? <AppFooter /> : null}
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme={'dark'}
        />
        <OiSupportChatWidget launcherEnabled={this.props.supportChatLauncherEnabled} />

        { !this.props.zeroPercentViewed &&
          <OiZeroPercentModal 
            onHide={() => this.hideShowZeroPercentModal()}
          />
        }        
      </div>
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.user.account, ...state.service, ...state.modal };
  },
  ModalActions,
  null,
  { forwardRef: true },
)(AppLayout);

export default withWindowDimensions(component);
