import { connect } from 'react-redux';
import React from 'react';
import * as ethUtil from 'ethereumjs-util';
import { WalletActions } from '@Actions/WalletActions';
import { UserActions } from '@Actions/UserActions';
import { ToastActions } from '@Actions/ToastActions';
import { ModalActions } from '@Actions/ModalActions';
import withWallet from '@Components/hoc/withWallet';
import { getChainName } from '@Utils';
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

import //    ConnectionRejectedError,
//    UseWalletProvider,
'use-wallet';
import bind from 'bind-decorator';
import { withTranslation } from 'react-i18next';


class WalletConnect extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { wallet, cachedWallet } = this.props;

    if (wallet.status === 'disconnected' && cachedWallet) {
      this.activateWallet(cachedWallet);
    }
  }

  componentDidUpdate(prevProps) {
    const prevWallet = prevProps.wallet;
    const { user, wallet, walletError, t } = this.props;
    const { account } = this.props.account;

    // The user logged out, reset the wallet as well
    if (prevProps.user && prevProps.user.profile && !(user && user.profile)) {
      wallet.reset();
      this.props.resetCachedWalletState(null, false);
    }

    // If the account is loaded but it is not matching what the wallet has
    // log out and provide the option to log back in
    if (!prevProps.account?.account && this.props.account?.account) {
      if (account?.publicAddress?.toLowerCase() !== wallet?.account?.toLowerCase()) {
        this.props.logout();
      }   
    }

    if (prevWallet.status !== wallet.status) {

      this.props.setWalletStatus(wallet.status);

      if (wallet.status === 'connected') {

        // When we are reconnecting to a wallet after a hard refresh, we need to check if the account is intact
        // and if not, we need to call connect again.
        // https://github.com/aragon/use-wallet/issues/82#issuecomment-792292804
        if (!wallet.account) {
          //this.props.setCachedWalletState(wallet.connector, true);
          this.activateWallet(wallet.connector);

          // We need to make sure that the access token we had is not for another account while the active wallet is a different one.
        } else {
          this.props.setCachedWalletState(wallet.connector, false);

          if (wallet.connector == 'uauth') {
            const subConnector = wallet._web3ReactContext?.connector?.subConnector;

            if (subConnector instanceof InjectedConnector) {
              this.props.setCachedWalletState('injected');
            }
            else if (subConnector instanceof WalletConnectConnector) {
              this.props.setCachedWalletState('walletconnect');
            }
          }

          // Let's now try and login
          // Get a nonce from the back-end and verify if the signature is correct at the API level.

          // If we haven't logged in yet
          if (!user || !user.profile) {
            // We should probably get a nonce from the server,
            // get that signed so that it is not registered
            let signer = wallet.account;

            let message = t('public.connectSignMessage');
            let messageBuffer = Buffer.from(message);
            let hexMessage = ethUtil.bufferToHex(messageBuffer);
            let hash = ethUtil.hashPersonalMessage(messageBuffer);
            let hexHash = ethUtil.bufferToHex(hash);

            let params = [hexMessage, signer];
            let method = 'personal_sign';

            this.props
              .ethereumRequest(wallet.ethereum, method, params)
              .then((signature) => {

                this.props.getUserByPublicAddress(wallet.account).then((res) => {
                  // User exists on OIX
                  // Lwt's try and login
                  if (res.payload) {
                    // We already have the user
                    // just get the token already
                    return this.login(signer, signature, hexHash, message);
                  } else if (res.error) {
                    if (res.error.response?.status === 404) {
                      return this.props
                        .register({
                          provider: 'ExtWallet',
                          signer,
                          signature,
                          hash: hexHash,
                          message,
                        })
                        .then((regRes) => {
                          if (regRes.payload) {
                            console.info('Registered');

                            // Now log in.
                            return this.login(signer, signature, hexHash, message);
                          }

                          if (regRes.error) {
                            this.toastAndResetWallet(regRes.error);
                          }
                        });
                    } else {
                      // Error registering the user
                      this.toastAndResetWallet(res.error);
                    }
                  }
                });
              })
              .catch((err) => {
                this.props.resetCachedWalletState(null, false);
                // this.toastAndResetWallet(err);
              });
          }
        }
      }

      if (wallet.status === 'disconnected') {

        if (prevWallet.status === 'connecting') {
          this.props.resetCachedWalletState(null, false);
        }
        else {
          // log out
          this.props.logout();
        }
      }

      if (wallet.status === 'error') {
        //        console.log(wallet);
        //this.props.closeAllModals();
        //
        this.props.setWalletError(wallet.error);
      }

      // We do not support contract wallets, error out accordingly
      if (wallet.type && wallet.type !== 'normal') {
        this.props.setWalletError({
          name: 'WalletTypeUnsupportedError',
          message: t('public.connectUnsupportedWallet'),
        }); // Let's switch to an enum for this?

        // we can also reset the wallet here.
        wallet.reset();
      }
    }

    if (wallet.status === 'error' && prevWallet.error !== wallet.error) {
      this.props.setWalletError(wallet.error);
    }

    if (prevProps.walletError !== walletError) {
      if (walletError?.name === 'ChainUnsupportedError') {
        const errMessage = t('public.connectUnsupportedWallet', {chainName: getChainName(wallet.chainId)});
        this.toastAndResetWallet(errMessage);
      }
    }
  }

  @bind
  activateWallet(connector) {
    this.props.wallet.connect(connector);
  }

  @bind
  toastAndResetWallet(message) {
    this.props.wallet.reset();
    this.props.toastError(message);
    console.log(message);
  }

  @bind
  login(signer, signature, hash, message) {
    // This is the equivalent of a login function
    let grant_type = 'eth_personal_sign';

    return this.props
      .login({
        grant_type,
        signer,
        signature,
        hash,
        message,
      })
      .then(() => {
        console.info('Logged in');
      })
      .catch((err) => {
        console.log(err);

        if (err.response.data.error_description === 'user suspended') {
          this.props.toastError(this.props.t('public.errorMapping.1017'));
        }
      });
  }

  render() {
    return <></>;
  }
}

const component = connect(
  (state) => ({ ...state.auth, ...state.user, ...state.wallet }),
  { ...WalletActions, ...UserActions, ...ModalActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(WalletConnect));

export default withWallet(component);
