import * as React from 'react';
import Button from '@Components/form/Button';
import Icon from '@Components/shared/Icon';
import millify from 'millify';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class HashtagCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  updateHashtag() {
    const { getHashtag } = this.props;
    const {
      hashtag: { tag },
    } = this.state;
    getHashtag(tag).then((res) => {
      this.setState({ hashtag: res.payload });
    });
  }

  followHashtag() {
    const {
      followHashtag,
      hashtag: { tag },
    } = this.props;
    followHashtag(tag);
  }

  unfollowHashtag() {
    const {
      unfollowHashtag,
      hashtag: { tag },
    } = this.props;
    unfollowHashtag(tag);
  }

  render() {
    const {
      hashtag: { tag, followersCount, actorFollows },
      t
    } = this.props;
    const { mouseOverFollowButton } = this.state;
    return (
      <Link to={`/hashtag/${tag}`} className="hashtag-card-square-2">
        <div className="height-decider"></div>
        <div className="content">
          <div className="img-container">
            <div className="hashtag-container">
              <Icon type="fontawesome" src="fas fa-hashtag" />
            </div>
          </div>
          <div className="hashtag-info mt-1">
            <div className="name">
              #{tag}
            </div>
            <div className="category mt-1"></div>
          </div>
          <div className="follow-count mt-3">{millify(followersCount)} {t('public.followers')}</div>
          <div className="follow-button mt-1">
            <Button
              text={actorFollows ? (mouseOverFollowButton ? t('public.unfollowButton') : t('public.following')) : t('public.follow')}
              type={actorFollows ? 'follow' : 'primary'}
              fullSize
              onMouseOver={(e) => {
                this.setState({ mouseOverFollowButton: true });
              }}
              onMouseOut={(e) => {
                this.setState({ mouseOverFollowButton: false });
              }}
              onClick={(e) => {
                e.preventDefault();

                if (!actorFollows) this.followHashtag();
                else this.unfollowHashtag();
              }}
            />
          </div>
        </div>
      </Link>
    );
  }
}
export default withTranslation()(HashtagCard);
