import * as React from 'react';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import Icon from '@Components/shared/Icon';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { Formik } from 'formik';
import { withRouter } from 'react-router';
import AppVariables from '@AppVariables';
import { UserActions } from '../../actions/UserActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { WalletActions } from '@Actions/WalletActions';
import { ToastActions } from '@Actions/ToastActions';
import Placeholder from '@Images/placeholder-images/placeholder-avatar.png';
import OixSpinner from '@Components/shared/OixSpinner';
import withWallet from '@Components/hoc/withWallet';
import * as ethUtil from 'ethereumjs-util';
import { ConnectionRejectedError } from 'use-wallet';
import {
  NoEthereumProviderError
} from '@web3-react/injected-connector';
import { withTranslation } from 'react-i18next';

class EditProfileModal extends React.Component {
  inputFileRef;
  formikRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      checkingUsername: false,
      usernameAvailable: true,
      initializeChecking: false,
    };
  }

  componentDidUpdate(prevProps) {
    const prevWallet = prevProps.wallet;
    const wallet = this.props.wallet;

    if (prevWallet.status !== wallet.status || prevProps.signing != this.props.signing) {
      if (wallet.status == 'connected' && this.props.signing) {
        this.onSigning();
      }
    }

    if (prevWallet.error !== wallet.error) {
      if (wallet.error) {
        if (wallet.error instanceof NoEthereumProviderError && this.state.selectedConnector == 'MetaMask') {
          this.setState({ selectedConnector: null }, () => this.onExtensionNotFound());
        } else if (
          wallet.error instanceof ConnectionRejectedError ||
          wallet.error.code === 4001 ||
          wallet.error == 'User denied login.'
        ) {
          this.setState({ selectedConnector: null }, () => this.onPermissionNeeded());
        }
      }
    }
  }

  @bind
  onSigning() {
    this.props.closeModal();
    this.props.openModal('SigningModal', {
      onOutsideClick: true,
      closeOnOutsideClick: true,
      backdrop: true,
      selectedConnector: this.state.selectedConnector,
    });
  }

  @bind
  getSignature(values, action) {
    const { t } = this.props;
    let signer = this.props.wallet.account;

    let message = t('modals.signMessageConnect');
    let messageBuffer = Buffer.from(message);
    let hexMessage = ethUtil.bufferToHex(messageBuffer);
    let hash = ethUtil.hashPersonalMessage(messageBuffer);
    let hexHash = ethUtil.bufferToHex(hash); 

    let params = [hexMessage, signer];
    let method = 'personal_sign';

    this.props
      .ethereumRequest(this.props.wallet.ethereum, method, params)
      .then((signature) => {
        const payload = {
          ...values,
          message,
          hash: hexHash,
          signer,
          signature,
        };
        this.submitEditProfile(payload);
      })
      .catch((err) => {
        this.props.toastError(t('modals.error'));
        this.props.closeAllModals();
      });
  }

  @bind
  checkUsername(username, oldUsername) {
    const { t } = this.props;

    this.formikRef.current.setFieldValue('username', username);
    if (username === oldUsername) {
      return this.setState({ checkingUsername: false, initializeChecking: false, usernameAvailable: true });
    }
    this.setState({ checkingUsername: true, initializeChecking: true });
    setTimeout(() => {
      this.props
        .checkUsernameAvailability(username)
        .then((res) => {
          this.setState({ checkingUsername: false });
          if (res.error) {
            this.setState({ usernameAvailable: false });
            this.formikRef.current.setFieldError('username', t('public.editProfileModalNotAvailableErrorMessage'));
          } else {
            this.setState({ usernameAvailable: true });
            this.formikRef.current.setFieldError("username", null);
          }
        })
        .catch(() => {
          this.setState({ checkingUsername: false });
        });
    }, 1000);
  }

  @bind
  submitEditProfile(values) {
    const { t, refreshUserInfo } = this.props;

    values = this.addPrefixWebsite(values);
    this.props.enableLoader();
    this.props.updateAccountProfile(values).then((result) => {
      if (!result.error) {
        this.props.updateUserAccountProfile(result.payload);
        this.props.history.replace('/' + result.payload.slug);
        this.props.disableLoader();
        this.props.closeAllModals();
        //this.props.toastSuccess('Your profile information has been successfully updated.');
         // window?.location.reload();
      } else if (result.error) {
        this.props.disableLoader();
        this.props.closeAllModals();
        this.props.toastError(t('modals.notUpdateProfileInfoToast'), {});
      }
    });
  }

  componentDidMount() {
    this.props.getAccountCategories();
  }

  inputChange(e) {
    const { t } = this.props;
    const file = e.target.files[0];

    if (file) {
      if (file.type === 'image/gif') {
      const url = URL.createObjectURL(file);
        this.getPicture(file, url);
      } else {
        this.props.openModal('EditProfilePictureModal', {
          backdrop: true,
          closeOnOutsideClick: true,
          getPicture: (blob, url) => this.getPicture(blob, url),
          file: file,
          title: t('public.Edit Profile Picture'),
        });
      }

      this.inputFileRef.value = '';
    }
  }

  @bind
  getPicture(blob, url) {
    this.formikRef.current.setFieldValue('url', url);
    this.formikRef.current.setFieldValue('profileImage', blob);
  }

  addPrefixWebsite = (values) => {
    if (values['website']?.length && !values['website'].startsWith('https://')) {
      values['website'] = 'https://' + values['website'];
    }

    return values;
  };

  checkWebsiteLink = (link) => {
    if (link?.length && link.startsWith('https://')) {
      link = link.replace('https://', '');
    }

    return link;
  };

  render() {
    const {
      backdrop,
      closeOnOutsideClick,
      activeProfileAccountId,
      onOutsideClick,
      fullscreen,
      allDetailedAccounts,
      AccountCategories,
      t
    } = this.props;
    let profileAccount = allDetailedAccounts[activeProfileAccountId];
    let submitEditProfile;

    return (
      <>
        <Modal
          backdrop={backdrop}
          closeOnOutsideClick={closeOnOutsideClick}
          fullscreen={fullscreen}
          subtext={t('modals.editProfileModalSubtitle')}
          title={t('modals.editProfileModalTitle')}
          onOutsideClick={onOutsideClick}>
          <Formik
            initialValues={{
              Name: profileAccount?.profile.name || '',
              category: profileAccount?.profile?.category || null,
              profileImage: null,
              url: profileAccount?.profile.imageUrl || null,
              about: profileAccount?.profile.about || '',
              username:
                (profileAccount?.profile.slug ? profileAccount?.profile.slug : profileAccount?.slug) || undefined,
              website: profileAccount?.profile.website || undefined,
              linkedin: profileAccount?.profile.linkedin || undefined,
              instagram: profileAccount?.profile.instagram || undefined,
              youTube: profileAccount?.profile.youTube || undefined,
              facebook: profileAccount?.profile.facebook || undefined,
              twitter: profileAccount?.profile.twitter || undefined,
              twitch: profileAccount?.profile.twitch || undefined,
              tikTok: profileAccount?.profile.tikTok || undefined,
              snapchat: profileAccount?.profile.snapchat || undefined,
              discord: profileAccount?.profile.discord || undefined,
            }}
            innerRef={this.formikRef}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={AppVariables.validationSchemas.editProfileSchema}
            onSubmit={this.getSignature}>
            {(formikProps) => {
              submitEditProfile = formikProps.submitForm;
              return (
                <form
                  className="form edit-profile-modal d-flex flex-wrap mx-2 my-2"
                  onSubmit={formikProps.handleSubmit}>
                  <div className="all-content">
                    <div className="img-container mb-3 mt-2 mr-2">
                      <ProfileAvatar
                        alt=""
                        height={140}
                        imageLink={formikProps.values.url || Placeholder}
                        width={140}
                      />
                      <div
                        className="shadow"
                        onClick={() => {
                          this.inputFileRef.click();
                        }}>
                        <Icon src="fas fa-camera" type="fontawesome" />
                      </div>
                    </div>
                    <input
                      ref={(x) => (this.inputFileRef = x)}
                      accept="image/png, image/jpeg, image/gif"
                      style={{ display: 'none' }}
                      type="file"
                      onChange={(e) => {
                        this.inputChange(e);
                      }}
                    />
                    <div className="image-error">{formikProps.errors.profileImage && t(formikProps.errors.profileImage)}</div>
                    <hr className="mb-4" />
                    <div className="w-100 d-flex align-center">
                      <Input.Text
                        className="mb-3 mr-2 w-100"
                        error={t(formikProps.errors.Name)}
                        label={t('modals.displayName')}
                        name="Name"
                        placeholder={t('modals.displayName')}
                        value={formikProps.values.Name}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                      <Input.Select
                        className="mb-3 w-100"
                        customOptionLabel={(item) => t(`public.${item.name}`)}
                        defaultValue={formikProps.values.category}
                        displayExpr={"name"}
                        error={t(formikProps.errors.category)}
                        items={AccountCategories}
                        label={t('modals.category')}
                        name="category"
                        setFieldTouched={formikProps.setFieldTouched}
                        setFieldValue={formikProps.setFieldValue}
                        value={formikProps.values.category}
                        valueExpr={'id'}
                      />
                    </div>
                    <Input.AnnotationEditor
                      stopFocus
                      className="w-100 mb-3"
                      error={t(formikProps.errors.about)}
                      label={t('modals.bio')}
                      name="about"
                      setFieldTouched={formikProps.setFieldTouched}
                      setFieldValue={formikProps.setFieldValue}
                      supportWhitespace={true}
                      value={formikProps.values.about}
                      onChange={formikProps.handleChange}
                    />
                    <Input.Text
                      className="mb-3 w-100"
                      label={t('modals.username')}
                      name="username"
                      placeholder={t('modals.username')}
                      error={t(formikProps.errors.username)}
                      prefix={<div>@</div>}
                      suffix={
                        this.state.initializeChecking ? (
                          this.state.checkingUsername ? (
                            <OixSpinner backgroundColor={'#2E3146'} size={16} thick={0.7} />
                          ) : this.state.usernameAvailable ? (
                            <Icon src="fas fa-check-circle" style={{ color: '#00DCAF' }} type="fontawesome" />
                          ) : (
                            <Icon src="fas fa-exclamation-circle" style={{ color: '#FF0000' }} type="fontawesome" />
                          )
                        ) : null
                      }
                      value={formikProps.values.username}
                      onBlur={formikProps.handleBlur}
                      onChange={(e) =>
                        this.checkUsername(
                          e.target.value,
                          profileAccount?.profile.slug ? profileAccount?.profile.slug : profileAccount?.slug,
                        )
                      }
                    />
                    <hr className="mt-3 mb-2" />
                    <div className="w-100 social-text-container mt-3">
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.website)}
                          info={{
                            icon: { src: 'fas fa-globe' },
                            text: 'Website',
                            extra: 'https://',
                          }}
                          name="website"
                          placeholder={t('public.editProfileModalWebsitePlaceholder')}
                          value={this.checkWebsiteLink(formikProps.values.website)}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.discord)}
                          info={{
                            icon: { src: 'fab fa-discord' },
                            text: 'Discord',
                          }}
                          name="discord"
                          placeholder={t('public.editProfileModalDiscordPlaceholder')}
                          value={formikProps.values.discord}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.youTube)}
                          info={{
                            icon: { src: 'fab fa-youtube' },
                            text: 'Youtube',
                          }}
                          name="youTube"
                          placeholder={t('public.editProfileModalYoutubePlaceholder')}
                          value={formikProps.values.youTube}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.instagram)}
                          info={{
                            icon: { src: 'fab fa-instagram' },
                            text: 'Instagram',
                          }}
                          name="instagram"
                          placeholder={t('public.editProfileModalSocialUsernamePlaceholder')}
                          value={formikProps.values.instagram}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.twitter)}
                          info={{
                            icon: { src: 'fab fa-twitter' },
                            text: 'Twitter',
                          }}
                          name="twitter"
                          placeholder={t('public.editProfileModalSocialUsernamePlaceholder')}
                          value={formikProps.values.twitter}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <div>
                        <Input.Text
                          className="mb-3 w-100 pr-1"
                          error={t(formikProps.errors.facebook)}
                          info={{
                            icon: { src: 'fab fa-facebook' },
                            text: 'Facebook',
                            extra: 'facebook.com/',
                          }}
                          name="facebook"
                          placeholder={t('public.editProfileModalSocialUsernamePlaceholder')}
                          value={formikProps.values.facebook}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange}
                        />
                      </div>
                      <Input.Text
                        className="mb-3 w-100 pr-1"
                        error={t(formikProps.errors.twitch)}
                        info={{
                          icon: { src: 'fab fa-twitch' },
                          text: 'Twitch',
                          extra: 'twitch.tv/',
                        }}
                        name="twitch"
                        placeholder={t('public.editProfileModalSocialUsernamePlaceholder')}
                        value={formikProps.values.twitch}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                      <Input.Text
                        className="mb-3 w-100 pr-1"
                        error={t(formikProps.errors.tikTok)}
                        info={{
                          icon: { src: 'fab fa-tiktok' },
                          text: 'TikTok',
                          extra: 'tiktok.com/',
                        }}
                        name="tikTok"
                        placeholder={t('public.editProfileModalSocialUsernamePlaceholder')}
                        value={formikProps.values.tikTok}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                      />
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
          <div className="footer mx-2">
            <Button
              fullSize
              disabled={!this.state.usernameAvailable || this.state.checkingUsername}
              text={t('modals.saveChangesButton')}
              type="primary"
              onClick={() => submitEditProfile()}
            />
          </div>
        </Modal>
      </>
    );
  }
}
const component = connect(
  (state) => {
    return {
      ...state.profile,
      ...state.service,
      ...state.onboarding,
      ...state.wallet,
      ...state.user.account,
      ...state.auth,
      ...state.cache,
    };
  },
  {
    ...ServiceActions,
    ...AccountActions,
    ...UserActions,
    ...ModalActions,
    ...ToastActions,
    ...SpinnerActions,
    ...WalletActions,
  },
  null,
  { forwardRef: true },
)(EditProfileModal);
export default withRouter(withWallet(withTranslation()(component)));