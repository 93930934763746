import * as React from 'react';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import { ModalActions } from '@Actions/ModalActions';
import bind from 'bind-decorator';
import OixSpinner from '@Components/shared/OixSpinner';
import { withTranslation } from 'react-i18next';

class _SigningModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  onRejection() {
    this.props.closeModal();
    this.props.openModal('SignatureRejectedModal', {
      onOutsideClick: true,
      closeOnOutsideClick: true,
      backdrop: true,
      onCloseButtonClick: this.props.onCloseButtonClick,
    });
  }

  @bind
  onSuccess() {
    this.props.closeAllModals();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.signing != this.props.signing) {
      if (this.props.signing == false) {
        this.onSuccess();
      }
    }
    if (prevProps.signatureError != this.props.signatureError) {
      if (this.props.signing == false) {
        this.onRejection();
      }
    }
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, onCloseButtonClick, t } = this.props;
    return (
      <Modal
        title={t('modals.signWalletTitle')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
        onCloseButtonClick={onCloseButtonClick}>
        <div className="signing-wallet-modal px-2 my-2">
          <div className="title">{t('modals.pleaseSignMessage')}</div>
          <OixSpinner size={36} thick={0.8} backgroundColor={'#0C0E19'} />

          {/* <div className="title">Please sign the message in your wallet to continue.</div>
          <div className="description mt-2 ">OIX uses this signature to verify that </div> 
          <div className="description  mb-2">you&apos;re the owner if this Ethereum address</div> 
          <OixSpinner size={40} thick={0.8} backgroundColor={"#0C0E19"} /> */}
        </div>
        <div className="footer mx-2 mt-1"></div>
      </Modal>
    );
  }
}

class _SignatureRejectedModal extends React.Component {
  onRetry() {
    this.props.closeModal();
    this.props.openModal('ConnectWalletModal', {
      onOutsideClick: true,
      closeOnOutsideClick: true,
      backdrop: true,
      onCloseButtonClick: this.props.onCloseButtonClick,
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, onCloseButtonClick, t } = this.props;
    return (
      <Modal
        title={t('modals.signatureRejectedTitle')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
        onCloseButtonClick={onCloseButtonClick}>
        <div className="signature-rejected-modal my-2">
          <div className="title my-3">{t('modals.pleaseSignMessage')}</div>
        </div>
        <div className="footer mx-2 mt-1">
          <div style={{ width: '100%' }}>
            <Button text={t('modals.connectSignInModalRetry')} type="primary" fullSize onClick={() => this.onRetry()} />
          </div>
        </div>
      </Modal>
    );
  }
}

class _PermissionNeededModal extends React.Component {
  onRetry() {
    this.props.closeModal();
    this.props.openModal('ConnectWalletModal', {
      onOutsideClick: true,
      closeOnOutsideClick: true,
      backdrop: true,
      onCloseButtonClick: this.props.onCloseButtonClick,
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, onCloseButtonClick, t } = this.props;
    return (
      <Modal
        title={t('modals.accountNeeded')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onCloseButtonClick={onCloseButtonClick}
        onOutsideClick={onOutsideClick}>
        <div className="access-needed-modal my-2">
          <div className="title my-3">{t('modals.permissionWalletAccount')}</div>
        </div>
        <div className="footer mx-2 mt-1">
          <div style={{ width: '100%' }}>
            <Button text={t('modals.connectSignInModalRetry')} type="primary" fullSize onClick={() => this.onRetry()} />
          </div>
        </div>
      </Modal>
    );
  }
}

class _ExtensionNotFoundModal extends React.Component {
  openMetamask() {
    this.props.closeModal();
    window.open('https://metamask.io');
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, onCloseButtonClick, t } = this.props;
    return (
      <Modal
        title={t('modals.installMetamaskTitle')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
        onCloseButtonClick={onCloseButtonClick}>
        <div className="access-needed-modal my-2">
          <div className="title my-3">{t('modals.installMetamaskConnect')}</div>
        </div>
        <div className="footer mx-2 mt-1">
          <div style={{ width: '100%' }}>
            <Button text={t('modals.connectModalGoToMetameskWebsite')} type="primary" fullSize onClick={() => this.openMetamask()} />
          </div>
        </div>
      </Modal>
    );
  }
}

export const SigningModal = connect(
  (state) => {
    return { ...state.auth, ...state.wallet };
  },
  { ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(_SigningModal));

export const SignatureRejectedModal = connect(
  (state) => {
    return { ...state.auth, ...state.wallet };
  },
  { ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(_SignatureRejectedModal));

export const PermissionNeededModal = connect(
  (state) => {
    return { ...state.auth, ...state.wallet };
  },
  { ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(_PermissionNeededModal));

export const ExtensionNotFoundModal = connect(
  (state) => {
    return { ...state.auth, ...state.wallet };
  },
  { ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(_ExtensionNotFoundModal));
