import * as ServiceActionTypes from '@Actions/ServiceActions';

const initialState = () => ({
  fetchingCountries: false,
  CountriesData: null,
  fetchingCountriesError: null,

  fetchingInterests: false,
  Interests: null,
  fetchingInterestsError: null,

  fetchingAccountCategories: false,
  AccountCategories: null,
  fetchingAccountCategoriesError: null,

  fetchingCollectibleCategories: false,
  CollectibleCategories: null,
  fetchingCollectibleCategoriesError: null,

  fetchingPlatformCollections: false,
  PlatformCollections: null,
  fetchingPlatformCollectionsError: null,

  fetchingLanguages: false,
  Languages: null,
  fetchingLanguagesError: null,

  tokenPricesInUsd: {},
  wyreStatus: false,
  destCurrency: null,

  fetchingArticles: false,
  articles: null,
  fetchingArticlesError: null,
  
  fetchingNonProfits: false,
  nonProfits: [],
  fetchingNonProfitsError: null,

  fetchingAnticToken: false,
  anticToken: null,
  fetchingAnticTokenError: null,

  supportChatLauncherEnabled: false
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case ServiceActionTypes.GET_COUNTRIES:
      return {
        ...state,
        fetchingCountries: true,
      };
    case ServiceActionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        CountriesData: action.payload,
        fetchingCountries: false,
      };
    case ServiceActionTypes.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        fetchingCountriesError: action.error,
        fetchingCountries: false,
      };
      case ServiceActionTypes.GET_ARTICLES:
        return {
          ...state,
          fetchingArticles: true,
        };
      case ServiceActionTypes.GET_ARTICLES_SUCCESS:
        return {
          ...state,
          articles: action.payload,
          fetchingArticles: false,
        };
      case ServiceActionTypes.GET_ARTICLES_FAILURE:
        return {
          ...state,
          fetchingArticlesError: action.error,
          fetchingArticles: false,
        };
    case ServiceActionTypes.GET_INTERESTS:
      return {
        ...state,
        fetchingInterests: true,
      };
    case ServiceActionTypes.GET_INTERESTS_SUCCESS:
      return {
        ...state,
        Interests: action.payload,
        fetchingInterests: false,
      };
    case ServiceActionTypes.GET_INTERESTS_FAILURE:
      return {
        ...state,
        fetchingInterestsError: action.error,
        fetchingInterests: false,
      };

    case ServiceActionTypes.GET_ACCOUNT_CATEGORIES:
      return {
        ...state,
        fetchingAccountCategories: true,
      };
    case ServiceActionTypes.GET_ACCOUNT_CATEGORIES_SUCCESS:
      return {
        ...state,
        AccountCategories: action.payload,
        fetchingAccountCategories: false,
      };
    case ServiceActionTypes.GET_ACCOUNT_CATEGORIES_FAILURE:
      return {
        ...state,
        fetchingAccountCategoriesError: action.error,
        fetchingAccountCategories: false,
      };
    case ServiceActionTypes.GET_PLATFORM_COLLECTIONS:
      return {
        ...state,
        fetchingPlatformCollections : true,
      };
    case ServiceActionTypes.GET_PLATFORM_COLLECTIONS_SUCCESS:
      return {
        ...state,
        PlatformCollections: action.payload,
        fetchingPlatformCollections: false,
      };
    case ServiceActionTypes.GET_PLATFORM_COLLECTIONS_FAILURE:
      return {
        ...state,
        fetchingPlatformCollectionsError: action.error,
        fetchingPlatformCollections: false,
      };
    case ServiceActionTypes.GET_LANGUAGES:
      return {
        ...state,
        fetchingLanguages: true,
      };
    case ServiceActionTypes.GET_LANGUAGES_SUCCESS:
      return {
        ...state,
        fetchingLanguages: false,
        languages: action.payload,
      };
    case ServiceActionTypes.GET_LANGUAGES_FAILURE:
      return {
        ...state,
        fetchingLanguages: false,
        fetchingLanguagesError: action.error,
      };
    case ServiceActionTypes.GET_COLLECTIBLE_CATEGORIES:
      return {
        ...state,
        fetchingCollectibleCategories: true,
      };
    case ServiceActionTypes.GET_COLLECTIBLE_CATEGORIES_SUCCESS:
      return {
        ...state,
        CollectibleCategories: action.payload,
        fetchingCollectibleCategories: false,
      };
    case ServiceActionTypes.GET_COLLECTIBLE_CATEGORIES_FAILURE:
      return {
        ...state,
        fetchingCollectibleCategoriesError: action.error,
        fetchingCollectibleCategories: false,
      };
    case ServiceActionTypes.GET_TOKEN_PRICES_IN_USD_SUCCESS:
      return {
        ...state,
        tokenPricesInUsd: action.payload,
      };
    case ServiceActionTypes.INIT_WYRE:
      return {
        ...state,
        wyreStatus: true,
        destCurrency: action.payload,
      };
    case ServiceActionTypes.RESET_WYRE:
      return {
        ...state,
        wyreStatus: false,
      };
    case ServiceActionTypes.GET_NON_PROFIT_ORGANIZATIONS:
      return {
        ...state,
        fetchingNonProfits: true,
      };
    case ServiceActionTypes.GET_NON_PROFIT_ORGANIZATIONS_SUCCESS:
      return {
        ...state,
        nonProfits: action.payload,
        fetchingNonProfits: false,
      };
    case ServiceActionTypes.GET_NON_PROFIT_ORGANIZATIONS_FAILURE:
      return {
        ...state,
        fetchingNonProfitsError: action.error,
        fetchingNonProfits: false,
      };

    case ServiceActionTypes.GET_ANTIC_TOKEN:
      return {
        ...state,
        fetchingAnticToken: true,
      };
    case ServiceActionTypes.GET_ANTIC_TOKEN_SUCCESS:
      return {
        ...state,
        fetchingAnticToken: false,
        anticToken: action.payload,
      };
    case ServiceActionTypes.GET_ANTIC_TOKEN_FAILURE:
      return {
        ...state,
        fetchingAnticToken: false,
        fetchingAnticTokenError: action.error,
      };      
    case ServiceActionTypes.ENABLE_SUPPORT_CHAT_LAUNCHER:
      return {
        ...state,
        supportChatLauncherEnabled: true,
      };      
    case ServiceActionTypes.DISABLE_SUPPORT_CHAT_LAUNCHER:
      return {
        ...state,
        supportChatLauncherEnabled: false,
      };      
      default:
      return state;
  }
};
