import React from 'react';
import { useSelector } from 'react-redux';

import ChangeUsernameModal from '@Components/modal/ChangeUsernameModal';
import { EmailChangeModal, EmailChangeModal2 } from '@Components/modal/ChangeEmailAddressModal';
import AccountDeleteModal from '@Components/modal/AccountDeleteModal';
import SendFeedbackModal from '@Components/modal/SendFeedbackModal';
import InviteSocialAccountModal from '@Components/modal/InviteSocialAccountModal';
import ShareSocialAccountModal from '@Components/modal/ShareSocialAccountModal';
import WarningModal from '@Components/modal/WarningModal';
import { AuthenticatorModal, AuthenticatorModal2 } from '@Components/modal/AuthenticatorAppModal';
import { SmsAuthModal } from '@Components/modal/SmsAuthenticationModal';
import EditProfileModal from '@Components/modal/EditProfileModal';
import EditCollectionModal from '@Components/modal/EditCollectionModal';
import { EmailAuthModal, EmailAuthModal2 } from '@Components/modal/EmailAuthenticationModal';
import GeneralInformationModal from '@Components/modal/GeneralInformationModal';
import WhitelistWarningModal from '@Components/modal/WhitelistWarningModal';
import {
  PhoneNumberChangeModal,
  PhoneNumberChangeModal2,
  PhoneNumberChangeModal3,
  PhoneNumberChangeModal4,
} from '@Components/modal/ChangePhoneNumberModal';
import EditProfilePictureModal from '@Components/modal/EditProfilePictureModal';
import VerifyUnfollowModal from '@Components/modal/VerifyUnfollowModal';
import PublishViaModal from '@Components/modal/PublishViaModal';
import UploadPromptModal from '@Components/modal/UploadPromptModal';
import BlockWarningModal from '@Components/modal/BlockWarningModal';
import UnblockWarningModal from '@Components/modal/UnblockWarningModal';
import { ReportModal, ConfirmReportModal } from '@Components/modal/ReportModal';
import {
  SigningModal,
  SignatureRejectedModal,
  PermissionNeededModal,
  ExtensionNotFoundModal,
} from '@Components/modal/ConnectionHelperModals';
import ConnectWalletModal from '@Components/modal/ConnectWalletModal';

const modalComponentLookupTable = {
  ChangeUsernameModal,
  EmailChangeModal,
  EmailChangeModal2,
  AccountDeleteModal,
  SendFeedbackModal,
  AuthenticatorModal,
  AuthenticatorModal2,
  WarningModal,
  SmsAuthModal,
  EmailAuthModal,
  EmailAuthModal2,
  PhoneNumberChangeModal,
  PhoneNumberChangeModal2,
  PhoneNumberChangeModal3,
  PhoneNumberChangeModal4,
  EditProfileModal,
  EditCollectionModal,
  EditProfilePictureModal,
  InviteSocialAccountModal,
  ShareSocialAccountModal,
  GeneralInformationModal,
  VerifyUnfollowModal,
  PublishViaModal,
  UploadPromptModal,
  BlockWarningModal,
  UnblockWarningModal,
  ReportModal,  
  ConfirmReportModal,
  ConnectWalletModal,
  SigningModal,
  SignatureRejectedModal,
  PermissionNeededModal,
  ExtensionNotFoundModal,
  WhitelistWarningModal
};

const ModalManager = () => {
  /**
   * redux state
   */
  const currentModals = useSelector((state) => state.modal.currentModals);

  /**
   * render
   */
  return currentModals.map((modalDescription, i) => {
    const { modalType, modalProps = {} } = modalDescription;
    const ModalComponent = modalComponentLookupTable[modalType];

    return (
      <span key={modalType} style={{ display: i < currentModals.length - 1 ? 'none' : '' }}>
        <ModalComponent {...modalProps} />
      </span>
    );
  });
};

export default ModalManager;
