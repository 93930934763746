import React, { useEffect } from 'react';

import Modal from 'react-overlays/Modal';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import OiModalBackdrop from './OiModalBackdrop';
import OiModalHeader from './OiModalHeader';
import OiModalSeparator from './OiModalSeparator';
import { useModal } from 'context/ModalContext';
import { borderTopStyle } from 'html2canvas/dist/types/css/property-descriptors/border-style';
import { setBodyPosition } from '@Utils';

const OiModal = ({
  children,
  className,
  footer,
  onHide,
  renderBackdrop,
  logo,
  show = true,
  showHeader = true,
  subtitle,
  title,
  hasBackButton = false,
  onBackButtonClick,
  enableBackdropAction,
  ...rest
}) => {
  /**
   * hooks
   */
  const { goBack } = useModal();

  /**
   * helpers
   */
  const onClose = onHide ?? goBack;

  useEffect(() => {
    const { openModal, closeModal } = setBodyPosition();
    openModal();    

    return () => closeModal();
  }, []);

  /**
   * render
   */
  return (
    <Modal
      className={clsx('oi-modal', className)}
      renderBackdrop={renderBackdrop === false ? undefined : renderBackdrop ?? OiModalBackdrop}
      show={show}
      onHide={enableBackdropAction && onClose}
      {...rest}>
      <>
        {Boolean(showHeader) && (
          <OiModalHeader
            hasBackButton={hasBackButton}
            onBackButtonClick={onBackButtonClick}
            logo={logo}
            subtitle={subtitle}
            title={title}
            onClose={onClose}
          />
        )}
        <OverlayScrollbarsComponent
          className="oi-modal__content"
          options={{
            scrollbars: {
              visibility: 'visible',
              autoHide: 'never',
            },
            nativeScrollbarsOverlaid: {
              showNativeScrollbars: false,
              initialize: true,
            },
          }}>
          {children}
        </OverlayScrollbarsComponent>

        {footer !== false && (
          <>
            <OiModalSeparator position="horizontal" />
            <div className="oi-modal__footer">{footer}</div>
          </>
        )}
      </>
    </Modal>
  );
};

OiModal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onHide: PropTypes.func,
  renderBackdrop: PropTypes.func,
  show: PropTypes.bool,
  showHeader: PropTypes.bool,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  title: PropTypes.string,
  rest: PropTypes.object,
};

export default OiModal;
