
export const ENABLE_SPINNER = "ENABLE_SPINNER";

const enableSpinner = () => ({
  type: ENABLE_SPINNER,
});

export const DISABLE_SPINNER = "DISABLE_SPINNER";

const disableSpinner = () => ({
  type: DISABLE_SPINNER,
});

export const ENABLE_LOADER = "ENABLE_LOADER";

const enableLoader = () => ({
  type: ENABLE_LOADER,
});

export const DISABLE_LOADER = "DISABLE_LOADER";

const disableLoader = () => ({
  type: DISABLE_LOADER,
});

const actions = {
  enableSpinner,
  disableSpinner,
  enableLoader,
  disableLoader
};

export const SpinnerActions = {
  enableSpinner: () => (dispatch) => {
    dispatch(actions.enableSpinner());
  },
  disableSpinner: () => (dispatch) => {
    dispatch(actions.disableSpinner());
  },
  enableLoader: () => (dispatch) => {
    dispatch(actions.enableLoader());
  },
  disableLoader: () => (dispatch) => {
    dispatch(actions.disableLoader());
  },

}