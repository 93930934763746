import * as React from 'react';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Icon from '@Components/shared/Icon';
import { WalletActions } from '@Actions/WalletActions';
import { ToastActions } from '@Actions/ToastActions';
import { ModalActions } from '@Actions/ModalActions';
import AppVariables from '@AppVariables';
import withWallet from '@Components/hoc/withWallet';
import { ConnectionRejectedError } from 'use-wallet';
import OixSpinner from '@Components/shared/OixSpinner';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { buildHelpCenterLink, openInNewTab } from '@Utils';

class ConnectWalletModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { showMore: false, selectedConnector: null };
  }

  componentDidUpdate(prevProps) {
    const prevWallet = prevProps.wallet;
    const wallet = this.props.wallet;

    if (prevWallet.status !== wallet.status || prevProps.signing != this.props.signing) {
      if (wallet.status == 'connected' && this.props.signing) {
        this.onSigning();
      }
      switch (wallet.status) {
        case 'connecting':
        case 'error':
        case 'connected':
      }
    }

    if (prevWallet.error !== wallet.error) {
      if (wallet.error) {
        if (wallet.error instanceof NoEthereumProviderError && this.state.selectedConnector == 'MetaMask') {
          this.setState({ selectedConnector: null }, () => this.onExtensionNotFound());
        } else if (
          wallet.error instanceof ConnectionRejectedError ||
          wallet.error.code === 4001 ||
          wallet.error == 'User denied login.'
        ) {
          this.setState({ selectedConnector: null }, () => this.onPermissionNeeded());
        }
      }
    }
  }

  @bind
  handleConnect(connector) {

    // Remove any lingering walletConnect values.
    // This could be done in the middleware we are using
    // such as use-wallet or web3react. However their
    // sequencing doesn't make sense when we try and
    // reconnect using cached wallet state (on refresh or so)
    // Instead, we are going to remove the cached value any
    // time any wallet connect button is pressed 
    if(typeof localStorage !== 'undefined') {
      localStorage.removeItem('walletconnect')
      localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE')
    }

    this.props.wallet.connect(connector);
    // We need to authenticate the user here.
  }

  @bind
  handleDisconnect() {
    this.props.wallet.reset();
  }

  @bind
  walletOption(wallet, shouldRedirect = false) {
    const { t } = this.props;
    const { value, name, image, redirect } = wallet;

    const connecting = this.state.selectedConnector == name && this.props.wallet.status == 'connecting';
    
    return (
      <div
        key={name}
        className={'wallet-option my-1 ' + (connecting ? ' connecting' : '')}
        onClick={() => {
          if (shouldRedirect) {
            return openInNewTab(redirect, true);
          }
          this.setState({ selectedConnector: name });
          value.length ? this.handleConnect(value) : this.handleConnect();
        }}>
        <div className="wallet-info-container">
          <div className="icon-container">
            <img src={require(`@Images/wallet-providers/${image}`)} width={40} />
          </div>
          <div className="wallet-name">{connecting ? t('modals.connectModalConnectingText') : name}</div>
        </div>
        {connecting ? (
          <div className="spinner-container">
            <OixSpinner size={40} thick={0.8} backgroundColor={'#252730'} />
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }

  @bind
  onSigning() {
    this.props.closeModal();
    this.props.openModal('SigningModal', {
      onOutsideClick: true,
      closeOnOutsideClick: false,
      backdrop: true,
      selectedConnector: this.state.selectedConnector,

      onCloseButtonClick: this.props.onCloseButtonClick,
    });
  }

  @bind
  onPermissionNeeded() {
    this.props.closeModal();
    this.props.openModal('PermissionNeededModal', {
      onOutsideClick: true,
      closeOnOutsideClick: false,
      backdrop: true,
      selectedConnector: this.state.selectedConnector,
      onCloseButtonClick: this.props.onCloseButtonClick,
    });
  }

  @bind
  onExtensionNotFound() {
    this.props.closeModal();
    this.props.openModal('ExtensionNotFoundModal', {
      onOutsideClick: true,
      closeOnOutsideClick: false,
      backdrop: true,
      selectedConnector: this.state.selectedConnector,
      onCloseButtonClick: this.props.onCloseButtonClick,
    });
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, onCloseButtonClick, onBackButtonClick, show, t } =
      this.props;
    const supportedWallets = AppVariables.supportedWallets;
    return (
      <Modal
        title={t('modals.connectWalletModalTitle')}
        subtext={
          <Trans i18nKey="modals.whatIsWallet">
            Connect with one of the available wallet providers or create a new wallet.
            <a
              href={buildHelpCenterLink('articles', 4553463596955)}
              target="_blank"
              rel="noreferrer">
              What is a wallet?
            </a>
          </Trans>
        }
        show={show}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
        onCloseButtonClick={onCloseButtonClick}
        onBackButtonClick={onBackButtonClick}>
        <div className="connect-wallet-modal ">
          {typeof window?.ethereum !== 'undefined' ? (
            window?.ethereum.isMetaMask ? (
              this.walletOption(supportedWallets[0])
            ) : (
              <>
                {this.walletOption(supportedWallets[1])}
                {this.walletOption(supportedWallets[2])}
              </>
            )
          ) : (
            this.walletOption(supportedWallets[1])
          )}
          {supportedWallets.slice(3, this.state.showMore ? supportedWallets.length : 6).map((wallet) => {
            return this.walletOption(wallet);
          })}
          {/*<div
            className="wallet-option my-1 px-1"
            onClick={() => {
              this.setState((prevState) => ({ showMore: !prevState.showMore }));
            }}>
            <div className="wallet-info-container">
              <div className="show-icon-container">
                <div className="square">
                  <Icon type="fontawesome" src={this.state.showMore ? 'fas fa-angle-up' : 'fas fa-angle-down'} />
                </div>
              </div>
              <div className="wallet-name">{this.state.showMore ? 'Show Less' : 'Show More'}</div>
            </div>
          </div>*/}
        </div>
        <div className="footer mx-2 mt-1 ">
          <div style={{ margin: '0 auto', textAlign: 'center', width: '80%', fontSize: '14px', color: '#606060' }}>
            <div className="sub-text">{t('modals.connectionWalletAgree')}</div>
            <div className="mt-1">
              <>
                <Trans i18nKey="modals.linkTerms">
                  <Link to="/privacy">Privacy Policy</Link>, <Link to="/terms">Terms of Service</Link> and
                </Trans>
              </>
              <>
                <Trans i18nKey="modals.linkCommunityGuidelines">
                  <Link to="/community-guidelines">Community Guidelines</Link>
                </Trans>
              </>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
const mapStateToProps = (state) => ({
  ...state.wallet,
});

const mapDispatchToProps = {
  ...WalletActions,
  ...ToastActions,
  ...ModalActions,
};

const component = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation()(ConnectWalletModal),
);

export default withWallet(component);
