import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { UserActions } from '@Actions/UserActions';
import Placeholder from '@Images/logo/oix-icon-white.svg';

class ProfileAvatar extends React.Component {
  constructor(props) {
    super(props);
  }

  getColor() {
    return 'white';
  }

  getImage() {
    if (this.props.profileData?.profile?.imageUrl) {
      return (
        <img
          src={this.props.profileData.profile.imageUrl}
          alt={this.props.alt}
          style={{
            width: this.props.width,
            height: this.props.height,
            border: this.props.border,
            borderColor: this.getColor(),
          }}
        />
      );
    } else if (this.props.imageLink) {
      return (
        <img
          src={this.props.imageLink || Placeholder}
          alt={this.props.alt}
          style={{
            width: this.props.width,
            height: this.props.height,
            border: this.props.border,
            borderColor: this.getColor(),
          }}
        />
      );
    } else {
      return (
        <div
          className="placeholder"
          style={{
            width: this.props.width,
            height: this.props.height,
          }}>
          <img
            src={Placeholder}
            alt={this.props.alt}
            style={{
              width: this.props.width * 0.5,
              height: this.props.height * 0.5,
              border: this.props.border,
              borderColor: this.getColor(),
            }}
          />
        </div>
      );
    }
  }

  render() {
    return (
      <div
        className={'profile-avatar' + (this.props.className ? ' ' + this.props.className : '')}
        style={{
          width: this.props.width + this.props.width / 10 - 2,
          height: this.props.height + this.props.height / 10 - 2,
        }}
        onClick={(e) => {
          if (this.props.onClick) {
            return this.props.onClick();
          }
          if (!this.props.preventNavigation && this.props.match.params.username !== this.props.profileData?.slug) {
            e.stopPropagation();
            e.preventDefault();
            this.props.profileData ? this.props.history.push(`/${this.props.profileData.slug}`) : null;
          }
        }}>
        {this.props.profileData ? <>{this.getImage()}</> : this.getImage()}
      </div>
    );
  }
}
ProfileAvatar.propTypes = {
  profileData: PropTypes.object,
  imageLink: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  alt: PropTypes.string,
  className: PropTypes.string,
};

//const component = connect(state => state.user, UserActions, null, { forwardRef: true })(ProfileAvatar);
export default withRouter(ProfileAvatar);
