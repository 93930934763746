import AppVariables, { ZERO_ADDRESS } from '@AppVariables';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import Input from '@Components/form/Input';
import React, { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { FaChevronDown, FaCheckSquare } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import OiInput from '@Components/form/OiInput';
import cn from 'classnames';
import { runtimeConfig } from 'config';
import { getCurrenciesFromChain } from '@Utils';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useHistory, useLocation } from 'react-router';
import { getERC20Address } from '@Lib/contracts';

const checkboxIcon = (
  <div style={{ width: '20px', height: '20px', border: '1px solid #ffffff80', borderRadius: '5px' }} />
);
const checkboxCheckedIcon = (
  <div style={{ width: '20px', height: '20px' }}>
    <FaCheckSquare />
  </div>
);

export const CurrencyFilter = ({ isMobile }) => {
  const acceptedChains = JSON.parse(runtimeConfig.acceptedChains);
  const chains = AppVariables.chains.filter((chain) => acceptedChains.includes(chain.id));

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const currencyDropdownRef = useRef();
  const [selectedCurrencies, setSelectedCurrencies] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isOpenBlockchain, setIsOpenBlockchain] = useState(false);

  const openDropdown = () => {
    isMobile && setIsOpenBlockchain((prevState) => !prevState);
    !isMobile && currencyDropdownRef.current?.open();
  };

  const filteredCurrencies = useMemo(() => {
    const currencies = [];
    chains.map((chain) => {
      let acceptedCurrencies = getCurrenciesFromChain(chain.id);
      currencies.push({
        chainId: chain.id,
        chainName: chain.name,
        currencies: acceptedCurrencies.filter(
          (cur) => cur && cur.Name.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      });
    });
    return currencies;
  }, [searchValue]);

  const handleInputChange = () => (e) => {
    setSearchValue(e.target.value);
  };

  const handleSelectcurrency = (currencyId) => (e) => {
    setSelectedCurrencies((prevState) => ({ ...prevState, [currencyId]: e.target.checked }));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedCurrencyKeys = [];
    Object.entries(selectedCurrencies).forEach(([key, value]) => value && selectedCurrencyKeys.push(key));

    if (selectedCurrencyKeys.length) {
      params.set('currencies', selectedCurrencyKeys.join(','));
      const totalParams = params.toString().length ? '?' + params.toString() : '';
      history.push(location.pathname + totalParams);
    } else {
      params.delete('currencies');
      const totalParams = params.toString().length ? '?' + params.toString() : '';
      history.push(location.pathname + totalParams);
    }
  }, [selectedCurrencies]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const currencies = params.get('currencies');

    if (currencies) {
      const decodedCategoriesStr = decodeURIComponent(currencies);
      const decodedCategoriesIds = decodedCategoriesStr.split(',');

      const _selectedCurrencies = decodedCategoriesIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      setSelectedCurrencies(_selectedCurrencies);
    }
  }, []);

  return (
    <div className={cn('currency-filter-container', isMobile && 'mobile')} id="currency-filter">
      <div className="currency-filter" onClick={openDropdown}>
        {t('public.searchFilterCurrencyText')}
        <FaChevronDown className="ml-1" />
      </div>
      {isMobile && isOpenBlockchain && (
        <>
          <OiInput
            options={{ leftComponent: 'searchIcon' }}
            placeholder={t('Filter')}
            value={searchValue}
            onChange={handleInputChange()}
          />
          <div className="currency-list">
            {filteredCurrencies.map((chain) => (
              <React.Fragment key={chain.chainId}>
                {!!chain.currencies.length && (
                  <>
                    <div className="chain-name"> {chain.chainName} </div>
                    {chain.currencies.map((cur) => (
                      <div key={cur.Id} className="currency">
                        <div className="currency-icon-container">
                          <img src={cur.CircleIcon} />
                        </div>
                        <div className="currency-name"> {cur.Name} </div>
                        <Input.CheckBox
                          checkedIcon={checkboxCheckedIcon}
                          icon={checkboxIcon}
                          value={selectedCurrencies[`${chain.chainId}-${cur.Symbol}`]}
                          onChange={handleSelectcurrency(`${chain.chainId}-${cur.Symbol}`)}
                        />
                      </div>
                    ))}
                  </>
                )}
              </React.Fragment>
            ))}
          </div>
        </>
      )}
      {!isMobile && (
        <ContextDropdown
          ref={currencyDropdownRef}
          anchorEl="currency-filter"
          className="currency-filter-popover"
          origin="left"
          transformOriginH="left"
          transformOriginV="top">
          <>
            <OiInput
              options={{ leftComponent: 'searchIcon' }}
              placeholder={t('Filter')}
              value={searchValue}
              onChange={handleInputChange()}
            />
            <div className="currency-list">
              <OverlayScrollbarsComponent style={{ maxHeight: '300px', overflow: 'auto' }}>
                {filteredCurrencies.map((chain) => (
                  <React.Fragment key={chain.chainId}>
                    {!!chain.currencies.length && (
                      <>
                        <div className="chain-name"> {chain.chainName} </div>
                        {chain.currencies.map((cur) => (
                          <div key={cur.Id} className="currency">
                            <div className="currency-icon-container">
                              <img src={cur.CircleIcon} />
                            </div>
                            <div className="currency-name"> {cur.Name} </div>
                            <Input.CheckBox
                              checkedIcon={checkboxCheckedIcon}
                              icon={checkboxIcon}
                              value={selectedCurrencies[`${chain.chainId}-${cur.Symbol}`]}
                              onChange={handleSelectcurrency(`${chain.chainId}-${cur.Symbol}`)}
                            />
                          </div>
                        ))}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </OverlayScrollbarsComponent>
            </div>
          </>
        </ContextDropdown>
      )}
    </div>
  );
};
