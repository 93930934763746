import React, { Component } from 'react';

import { Helmet } from 'react-helmet';
import { Redirect, Route, withRouter } from 'react-router';
import { Switch } from 'react-router-dom';
import { UseWalletProvider } from 'use-wallet';
import { connect } from 'react-redux';
import moment from 'moment';
import { runtimeConfig } from './config';
import AppLayout from '@Layouts/AppLayout';
import Collectible from '@Pages/Embed/Collectible';
import { getMomentRelativeTime, isAuthenticated, loadable } from '@Utils';
import { Editor } from '@Components/form/Input';
import { withTranslation } from 'react-i18next';

const CollectiblePage = loadable(() => import('@Pages/CollectiblePage'));
const ProfilePage = loadable(() => import('@Pages/ProfilePage'));
const DiscoverPage = loadable(() => import('@Pages/DiscoverPage'));
const CollectionPage = loadable(() => import('@Pages/CollectionPage'));
const CreatorCategoriesSelectionPage = loadable(() => import('@Pages/CreatorCategoriesSelectionPage'));
const BidsPage = loadable(() => import('@Pages/BidsPage'), {});
const DisplaySettingsPage = loadable(() => import('@Pages/DisplaySettingsPage'));
const FeedPage = loadable(() => import('@Pages/FeedPage'));
const LegalPage = loadable(() => import('@Pages/LegalPage'));
const NotificationsSettingsPage = loadable(() => import('@Pages/NotificationsSettingsPage'));
const NotificationsPage = loadable(() => import('@Pages/NotificationsPage'));
const PrivacySettingsPage = loadable(() => import('@Pages/PrivacySettingsPage'));
const InterestSelectionPage = loadable(() => import('@Pages/InterestSelectionPage'));
const InviteFriendsPage = loadable(() => import('@Pages/InviteFriendsPage'));
const SupportPage = loadable(() => import('@Pages/SupportPage'));
const NotFoundPage = loadable(() => import('@Pages/NotFoundPage'));

const OiCollectibleBurnModal = loadable(() => import('@Components/modal/OiCollectibleBurnModal'));
const OiCollectibleStatusModal = loadable(() => import('@Components/modal/OiCollectibleStatusModal'));
const OiCollectibleChangePriceModal = loadable(() => import('@Components/modal/OiCollectibleChangePriceModal'));
const OiCollectibleFinalizeModal = loadable(() => import('@Components/modal/OiCollectibleFinalizeModal'));
const OiCollectibleCreateModal = loadable(() => import('@Components/modal/OiCollectibleCreateModal'));
const OiCollectibleListModal = loadable(() => import('@Components/modal/OiCollectibleListModal'));
const OiCollectiblePlaceBidModal = loadable(() => import('@Components/modal/OiCollectiblePlaceBidModal'));
const OiCollectiblePurchaseModal = loadable(() => import('@Components/modal/OiCollectiblePurchaseModal'));
const OiCollectibleTransferModal = loadable(() => import('@Components/modal/OiCollectibleTransferModal'));
const OiCollectibleUnlistModal = loadable(() => import('@Components/modal/OiCollectibleUnlistModal'));
const OiCollectibleUnlistByAdminModal = loadable(() => import('@Components/modal/OiCollectibleUnlistByAdminModal'));
const OiCollectibleUploadModal = loadable(() => import('@Components/modal/OiCollectibleUploadModal'));
const OiCollectionCreateModal = loadable(() => import('@Components/modal/OiCollectionCreateModal'));
const OiCollectionStatusModal = loadable(() => import('@Components/modal/OiCollectionStatusModal'));
const OiConvertModal = loadable(() => import('@Components/modal/OiConvertModal'));

import EmbedLayout from '@Layouts/EmbedLayout';
import PrivateRoute from '@Components/shared/PrivateRoute';
import SearchPageE from '@Pages/SearchPageE';
import WalletConnect from '@Components/shared/WalletConnect';
import { ModalProvider } from 'context/ModalContext';
import { ServiceActions } from '@Actions/ServiceActions';
import { UserActions } from '@Actions/UserActions';
import { getInfuraEndpoint, toBN } from '@Utils';

import './fonts/font-awesome/css/all.css';
import AppVariables from '@AppVariables';
import i18n from './i18n';
//import WyreWidget from '@Components/shared/WyreWidget';

function Start() {
  return (
    <>
      <PrivateRoute exact component={() => <Redirect to="/start/interest" />} path="/start" />
      <PrivateRoute component={InterestSelectionPage} path="/start/interest" />
      <PrivateRoute component={CreatorCategoriesSelectionPage} path="/start/creator-category" />
    </>
  );
}

function AccountSettings() {
  return (
    <>
      <PrivateRoute exact component={() => <Redirect to="/settings/privacy" />} path="/settings" />
      <PrivateRoute component={NotificationsSettingsPage} path="/settings/notifications" />
      <PrivateRoute component={PrivacySettingsPage} path="/settings/privacy" />
      <PrivateRoute component={DisplaySettingsPage} path="/settings/display" />
    </>
  );
}

class App extends Component {
  componentDidMount() {
    const { 
      startSignalRConnection, 
      getAccountCategories, 
      getCollectibleCategories, 
      getPlatformCollections, 
      getTokenPricesInUSD,
      user 
    } = this.props;

    moment?.updateLocale('en', {
      relativeTime: getMomentRelativeTime(user?.profile?.locale),
    });

    // Load all app info needed
    getAccountCategories();
    getCollectibleCategories();
    getPlatformCollections();
    getTokenPricesInUSD();

    this.loadOrUnloadAccount(null);
    this.setLanguage();

    window.addEventListener('storage', this.onStorageUpdate);

    startSignalRConnection();

    setTimeout(() => {
      this.preloadEssentialComponents();
    }, AppVariables.preloadEssentialTimeout);

    const authenticated = user && user.tokens && Date.now() < user.tokens.expires_at;

    if (authenticated) {
      setTimeout(() => {
        this.preloadRoutedModals();
      }, AppVariables.preloadTimeout);
    }
  }

  componentDidUpdate(prevProps) {
    this.loadOrUnloadAccount(prevProps);

    const { user } = this.props;
    if (prevProps.user?.profile?.locale !== user?.profile?.locale) {
      this.setLanguage();

      moment?.updateLocale('en', {
        relativeTime: getMomentRelativeTime(user?.profile?.locale),
      });
    }
  }

  componentWillUnmount() {
    const { stopSignalRConnection } = this.props;

    stopSignalRConnection();

    window.removeEventListener('storage', this.onStorageUpdate);
  }

  preload = (component) => {
    component.preload && component.preload();
  };

  preloadEssentialComponents = () => {
    this.preload(Editor);
    this.preload(DiscoverPage);
    this.preload(CollectiblePage);
    this.preload(CollectionPage);
    this.preload(FeedPage);
    this.preload(ProfilePage);
  };

  preloadRoutedModals = () => {
    this.routedModals.forEach((modal) => this.preload(modal.component));
    this.routedCollectibleModals.forEach((modal) => this.preload(modal.component));
    this.routedCollectionModals.forEach((modal) => this.preload(modal.component));
  };

  onStorageUpdate = (event) => {
    const PROFILE = 'state.auth.user.profile';
    const { logout } = this.props;

    if (event.key === PROFILE) {
      if (event.newValue === null) {
        logout();
      } else {
        window.location.reload();
      }
    }
  };

  setLanguage = () => {
    const { user } = this.props;
    if (user.profile) {
      const {
        profile: { locale },
      } = user;
      i18n.changeLanguage(locale);
      // moment.locale(locale);
    }
  };

  loadOrUnloadAccount(prevProps) {
    const { user, loadAccount, unloadAccount } = this.props;

    const prevUserExists = !!prevProps?.user?.profile;

    const isAuthenticated = !!(user?.profile && user?.tokens); // && (Date.now() < user.tokens.expires_at);

    if (!prevUserExists && isAuthenticated) {
      const accountId = user.profile.default_account_id;
      loadAccount(accountId);
    } else if (prevUserExists && !user?.profile) {
      unloadAccount();
    }
  }

  idRegEx = '[0-9]+';
  slugRegEx = '[-\\w]*[0-9]+';
  ethAddressRegEx = '0[xX][0-9a-fA-F]{40}';
  usernameRegEx = '\\w{3,25}'; // we are setting it to 25 so that the users from other marketplaces can be retrieved as well.
  chainSlugRegEx = '\\w{3,25}'; 

  routedModals = [
    {
      pathname: `/collectible/upload`,
      component: OiCollectibleUploadModal,
      defaultBackgroundRoute: '/discover',
    },
    {
      pathname: `/collectible/:id(${this.idRegEx})/edit`,
      component: OiCollectibleCreateModal,
      defaultBackgroundRoute: '/feed',
    },
    {
      pathname: `/collection/create`,
      component: OiCollectionCreateModal, //?
      defaultBackgroundRoute: '/feed',
    },
    {
      pathname: `/collection/:id(${this.idRegEx})/edit`,
      component: OiCollectionCreateModal,
      defaultBackgroundRoute: '/feed',
    },
    {
      pathname: ['/convert', `/convert/:outputCurrency`],
      component: OiConvertModal,
      defaultBackgroundRoute: '/discover',
    },
  ];

  routedCollectibleModals = [
    {
      path: 'list',
      component: OiCollectibleListModal,
    },
    {
      path: 'unlist',
      component: OiCollectibleUnlistModal,
    },
    {
      path: 'unlist-by-admin',
      component: OiCollectibleUnlistByAdminModal,
    },
    {
      path: 'place-bid',
      component: OiCollectiblePlaceBidModal,
    },
    {
      path: 'purchase',
      component: OiCollectiblePurchaseModal,
    },
    {
      path: 'finalize',
      component: OiCollectibleFinalizeModal,
    },
    {
      path: 'change-price',
      component: OiCollectibleChangePriceModal,
    },
    {
      path: 'transfer',
      component: OiCollectibleTransferModal,
    },
    {
      path: 'burn',
      component: OiCollectibleBurnModal,
    },
    {
      path: 'status',
      component: OiCollectibleStatusModal,
    },
  ];

  routedCollectionModals = [
    {
      path: 'status',
      component: OiCollectionStatusModal,
    },
  ];

  render() {
    const dAppName = 'OIX';
    const chainId = 1; //parseInt(runtimeConfig.chainId);
    const infuraUrl = getInfuraEndpoint(chainId);

    const supportedChains = AppVariables.chains.map((chain) => chain.id);
    const supportedChainRpcUrls = AppVariables.chains.reduce(
      (prevVal, chain) => ({
        ...prevVal,
        [chain.id]: chain.chainInfo.rpcUrls?.[0],
      }),
      {},
    );

    const portisSupportedChains = AppVariables.chains.filter((chain) => chain.wallets?.portis).map((chain) => chain.id);

    const injectedConfig = { chainId: supportedChains };
    const walletconnectConfig = {
      rpc: supportedChainRpcUrls,
      chainId: supportedChains,
    }; 

    const walletConnectors = {
      // This is how connectors get configured
      injected: injectedConfig,
      walletconnect: walletconnectConfig,
      walletlink: {
        url: infuraUrl,
        appName: dAppName,
        chainId: chainId, //supportedChains

        //        appLogoUrl?: ''
      },
      portis: {
        dAppId: process.env.RAZZLE_PORTIS_DAPP_ID,
        chainId: portisSupportedChains,
      },
      fortmatic: {
        apiKey: process.env.RAZZLE_FORTMATIC_API_KEY,
        chainId: chainId, // supportedChains
      },
      torus: {
        chainId,
        initOptions: {
          showTorusButton: false,
        },
      },
      uauth: {
        clientID: runtimeConfig.unstoppableClientId,
        redirectUri: runtimeConfig.unstoppableRedirectUri,
        scope:'openid wallet',
        injectedConnectorConfig: injectedConfig,
        walletconnectConnectorConfig: walletconnectConfig,
        supportedChainIds: supportedChains,
      }
    };

    const { t, location, user } = this.props;
    const background = location.state?.background;

    // This does not capture our slugs perfectly. However, react-router is stuck with path-to-regexp@1.7.0, so it can only handle this
    // with no proper support for parentheses or non-capturig groups.

    const siteTitle = t('public.siteTitle');
    const siteDescription = t('public.siteDescription');

    return (
      <UseWalletProvider connectors={walletConnectors}>
        <ModalProvider>
          <WalletConnect />

          <Helmet>
            <html lang="en" />
            <title>{`OIX | ${siteTitle}`}</title>
            <meta name="description" content={siteDescription} />
            <meta property="og:title" content="OIX" />
            <meta property="og:description" content={siteDescription} />
            <meta property="og:image" content={'https://cdn.oix.app/images/site/og/oix-og-image.png'} />
            <meta property="og:image:secure_url" content={'https://cdn.oix.app/images/site/og/oix-og-image.png'} />
            <meta property="og:image:type" content="image/png" />
            <meta property="og:image:width" content="1200" />
            <meta property="og:image:height" content="630" />
            <meta property="og:image:alt" content="OIX logo" />
            <meta property="og:type" content="website" />
            <meta property="fb:app_id" content="1106528183017076" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@oixofficial" />
            <meta name="twitter:title" content="OIX" />
            <meta name="twitter:description" content={siteDescription} />
            <meta name="twitter:image" content={'https://cdn.oix.app/images/site/og/oix-twitter-card-image.png'} />
          </Helmet>

          <Switch location={background ?? location}>
            <Route path="/embed/collectible/:id">
              <EmbedLayout>
                <Collectible />
              </EmbedLayout>
            </Route>

            <Route
              exact
              path={[
                `/collectible/:chainSlug(${this.chainSlugRegEx})/:collectionId(${this.ethAddressRegEx})/:slug(${this.slugRegEx})`,
                `/collectible/:collectionId(${this.ethAddressRegEx})/:slug(${this.slugRegEx})`,
                `/collectible/:slug(${this.slugRegEx})`,
              ]}>
              <AppLayout footer={false} header={false} sidebar={false}>
                <CollectiblePage />
              </AppLayout>
            </Route>

            <Route path="/discover">
              <AppLayout>
                <Route component={DiscoverPage} path="/discover/:name?" />
              </AppLayout>
            </Route>

            <Route exact path="/">
              {isAuthenticated(this.props.user) ? (
                <AppLayout footer={false}>
                  <PrivateRoute component={FeedPage} path="/" />
                </AppLayout>
              ) : (
                <AppLayout>
                  <Route component={DiscoverPage} path="/:name?" />
                </AppLayout>
              )}
            </Route>

            <Route>
              <AppLayout footer={false}>
                <Switch>
                  {/* Public Pages */}
                  {/* <Route exact component={() => <Redirect to="/discover" />} path="/" /> */}
                  <Route component={SearchPageE} path={['/search', '/hashtag/:tag']} />

                  <Route
                    exact
                    component={CollectionPage}
                    path={[
                      `/collection/:chainSlug(${this.chainSlugRegEx})/:collectionId(${this.ethAddressRegEx})`,
                      `/collection/:collectionId(${this.idRegEx})`,
                      `/collection/:slug(${this.usernameRegEx})/live-auctions`,
                      `/collection/:slug(${this.usernameRegEx})/on-sale`,
                      `/collection/:slug(${this.usernameRegEx})`, // collection slug is different than normal?
                    ]}></Route>

                  <Route path="/privacy">
                    <LegalPage markdown={'privacyPolicy'} pageTitle={t('public.privacyPolicyPageTitle')} />
                  </Route>
                  <Route path="/cookie-policy">
                    <LegalPage markdown={'cookiePolicy'} pageTitle={t('public.cookiePolicyPageTitle')} />
                  </Route>
                  <Route path="/terms">
                    <LegalPage markdown={'terms'} pageTitle={t('public.termsPageTitle')} />
                  </Route>
                  <Route path="/community-guidelines">
                    <LegalPage markdown={'communityGuidelines'} pageTitle={t('public.communityGuidelinesPageTitle')} />
                  </Route>

                  <Route component={SupportPage} path="/support" />

                  <PrivateRoute component={FeedPage} path="/feed" />

                  {this.routedModals.map((modal) => (
                    <PrivateRoute
                      key={modal.pathname}
                      exact
                      component={() => (
                        <Redirect
                          to={{
                            pathname: modal.defaultBackgroundRoute,
                            state: { modalPath: location.pathname },
                          }}
                        />
                      )}
                      path={modal.pathname}
                    />
                  ))}
                  {this.routedCollectibleModals.map((modal) => (
                    <PrivateRoute
                      key={modal.path}
                      exact
                      component={() => {
                        // remove trailing slash
                        const modalPath = location.pathname.replace(/\/$/, '');
                        const collectibleRoute = modalPath.substring(modalPath, modalPath.lastIndexOf('/'));
                        return (
                          <Redirect
                            to={{
                              pathname: collectibleRoute,
                              state: { modalPath },
                            }}
                          />
                        );
                      }}
                      path={[
                        `/collectible/:chainSlug(${this.chainSlugRegEx})/:collectionId(${this.ethAddressRegEx})/:slug(${this.slugRegEx})/${modal.path}`,
                        `/collectible/:collectionId(${this.ethAddressRegEx})/:slug(${this.slugRegEx})/${modal.path}`,
                        `/collectible/:slug(${this.slugRegEx})/${modal.path}`,
                      ]}
                    />
                  ))}
                  {this.routedCollectionModals.map((modal) => (
                      <PrivateRoute
                        key={modal.path}
                        exact
                        component={() => {
                          // remove trailing slash
                          const modalPath = location.pathname.replace(/\/$/, '');
                          const collectionRoute = modalPath.substring(modalPath, modalPath.lastIndexOf('/'));
                          return (
                            <Redirect
                              to={{
                                pathname: collectionRoute,
                                state: { modalPath },
                              }}
                            />
                          );
                        }}
                        path={[
                          `/collection/:chainSlug(${this.chainSlugRegEx})/:collectionId(${this.ethAddressRegEx})/${modal.path}`,
                          `/collection/:collectionId(${this.idRegEx})/${modal.path}`,
                          `/collection/:slug(${this.usernameRegEx})/${modal.path}`, // collection slug is different than normal?
                        ]}
                      />
                    ))}

                  {/* Private Pages */}
                  <PrivateRoute component={NotificationsPage} path="/notifications" />
                  <PrivateRoute component={InviteFriendsPage} path="/invite-friends" />
                  <PrivateRoute component={BidsPage} path="/bids" />
                  <PrivateRoute component={Start} path="/start" />
                  <PrivateRoute component={AccountSettings} path="/settings" />

                  <Route component={NotFoundPage} path="/404" />

                  <Route component={ProfilePage} path="/:username" />
                </Switch>
              </AppLayout>
            </Route>
          </Switch>

          {background && (
            <Switch>
              {this.routedModals.map((modal) => (
                <PrivateRoute key={modal.pathname} component={modal.component} path={modal.pathname} />
              ))}
              {this.routedCollectibleModals.map((modal) => (
                <PrivateRoute
                  key={modal.path}
                  component={modal.component}
                  path={[
                    `/collectible/:chainSlug(${this.chainSlugRegEx})/:collectionId(${this.ethAddressRegEx})/:slug(${this.slugRegEx})/${modal.path}`,
                    `/collectible/:collectionId(${this.ethAddressRegEx})/:slug(${this.slugRegEx})/${modal.path}`,
                    `/collectible/:slug(${this.slugRegEx})/${modal.path}`,
                  ]}
                />
              ))}
              {this.routedCollectionModals.map((modal) => (
                  <PrivateRoute
                    key={modal.path}
                    component={modal.component}
                    path={[
                      `/collection/:chainSlug(${this.chainSlugRegEx})/:collectionId(${this.ethAddressRegEx})/${modal.path}`,
                      `/collection/:collectionId(${this.idRegEx})/${modal.path}`,
                      `/collection/:slug(${this.usernameRegEx})/${modal.path}`, // collection slug is different than normal?
                    ]}
                  />
                ))}
            </Switch>
          )}
          {/*user && <WyreWidget />*/} 
        </ModalProvider>
      </UseWalletProvider>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.user.account,
  ...state.service
});

const mapDispatchToProps = {
  ...UserActions,
  ...ServiceActions,
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(App);

export default withTranslation()(withRouter(Connected));
