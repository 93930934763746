import * as UserActionTypes from "@Actions/UserActions";
import * as WalletActionTypes from "@Actions/WalletActions";

const initialState = () => ({
  walletStatus: null,
  walletError: null,

  cachedWallet: null,
  isCachedWalletLoading: true,
  cachedWalletExists: false,

  signing: false,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case WalletActionTypes.SET_WALLET_STATUS:
      return {
        ...state,
        walletStatus: action.payload.walletStatus,
      };
    case WalletActionTypes.SET_WALLET_ERROR:
      return {
        ...state,
        walletError: action.error,
      };
    case WalletActionTypes.SET_CACHED_WALLET_STATE:
      return {
        ...state,
        cachedWallet: action.payload.wallet,
        isCachedWalletLoading: action.payload.loading,
      };
    case WalletActionTypes.WALLET_SIGNATURE_REQUEST:
      return {
        ...state,
        signing: true,
      };
    case WalletActionTypes.WALLET_SIGNATURE_SUCCESS:
      return {
        ...state,
        signing: false,
        signature: action.payload.signature,
      };
    case WalletActionTypes.WALLET_SIGNATURE_FAILURE:
      return {
        ...state,
        signing: false,
        signatureError: action.error,
      };
    case UserActionTypes.UNLOAD_ACCOUNT:
    case UserActionTypes.LOGOUT_SUCCESS:
      return {
        ...state,
        walletStatus: null,
        walletError: null,
        signing: false,
        signatureError: null,
        signature: null,
      };
    default:
      return state;
  }
};
