import * as UserActionTypes from "@Actions/UserActions";

const initialState = () => ({
  changingPhoneNumber: false,
  phoneNumberChangeData: null,
  changingPhoneNumberError: null,

  verifyingPhoneNumber: false,
  phoneNumberVerifyData: null,
  verifyingPhoneNumberError: null,

  updatingUsername: false,
  usernameUpdated: null,
  updatingUsernameError: null,

  updatingEmail: false,
  emailUpdated: null,
  updatingEmailError: null,

  verifyingEmailChange: false,
  emailChangeVerified: null,
  verifyingEmailChangeError: null,
});

export default (state = initialState(), action) => {

  switch (action.type) {
    case UserActionTypes.RESET_PHONE_NUMBER_CHANGE_DATA:
      return {
        ...state,
        phoneNumberChangeData: null,
        phoneNumberVerifyData: null,
      }
    case UserActionTypes.RESET_EMAIL_CHANGE_DATA:
      return {
        ...state,
        emailUpdated: null
      }
    case UserActionTypes.VERIFY_EMAIL_CHANGE:
      return {
        ...state,
        verifyingEmailChange: true,
      }
    case UserActionTypes.VERIFY_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        verifyingEmailChange: false,
        emailChangeVerified: action.payload,
      }
    case UserActionTypes.VERIFY_EMAIL_CHANGE_FAILURE:
      return {
        ...state,
        verifyingEmailChange: false,
        verifyingEmailChangeError: action.error,
      }
    case UserActionTypes.REQUEST_EMAIL_CHANGE:
      return {
        ...state,
        updatingEmail: true
      }
    case UserActionTypes.REQUEST_EMAIL_CHANGE_SUCCESS:
      return {
        ...state,
        updatingEmail: false,
        emailUpdated: action.payload
      }
    case UserActionTypes.REQUEST_EMAIL_CHANGE_FAILURE:
      return {
        ...state,
        updatingEmail: false,
        updatingEmailError: action.error
      }
    case UserActionTypes.CHANGE_USERNAME:
      return {
        ...state,
        updatingUsername: true
      }
    case UserActionTypes.CHANGE_USERNAME_SUCCESS:
      return {
        ...state,
        updatingUsername: false,
        usernameUpdated: action.payload
      }
    case UserActionTypes.CHANGE_USERNAME_FAILURE:
      return {
        ...state,
        updatingUsername: false,
        updatingUsernameError: action.error
      }

    case UserActionTypes.REQUEST_PHONE_NUMBER_CHANGE:
      return {
        ...state,
        changingPhoneNumber: true
      }
    case UserActionTypes.REQUEST_PHONE_NUMBER_CHANGE_SUCCESS:
      return {
        ...state,
        changingPhoneNumber: false,
        phoneNumberChangeData: action.payload
      }
    case UserActionTypes.REQUEST_PHONE_NUMBER_CHANGE_FAILURE:
      return {
        ...state,
        changingPhoneNumber: false,
        changingPhoneNumberError: action.error
      }
    case UserActionTypes.VERIFY_PHONE_NUMBER_CHANGE:
      return {
        ...state,
        verifyingPhoneNumber: true
      }
    case UserActionTypes.VERIFY_PHONE_NUMBER_CHANGE_SUCCESS:
      return {
        ...state,
        verifyingPhoneNumber: false,
        phoneNumberVerifyData: action.payload,
        phoneNumberChangeData: null
      }
    case UserActionTypes.VERIFY_PHONE_NUMBER_CHANGE_FAILURE:
      return {
        ...state,
        verifyingPhoneNumber: false,
        verifyingPhoneNumberError: action.error
      }
    default:
      return state;
  }
};