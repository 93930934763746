import * as CollectibleActionTypes from '@Actions/CollectibleActions';
import * as CommentActionTypes from '@Actions/CommentActions';
import * as UploadActionTypes from '@Actions/UploadActions';

const initialState = () => ({
  uploading: false,
  uploadProgress: 0,
  uploadStatus: null,

  fetchingCollectibleData: false,
  collectibleData: null,
  fetchingCollectibleDataError: null,

  fetchingCollectibleComments: true,
  collectibleComments: [],
  fetchingCollectibleCommentsError: false,

  fetchingCollectibleActivities: false,
  collectibleActivities: [],
  fetchingCollectibleActivitiesError: false,

  updatingCollectibleLike: false,
  updatingCollectibleBookmarkState: false,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case UploadActionTypes.UPLOAD_START:
      return {
        ...state,
        uploading: true,
        uploadProgress: 0,
        uploadStatus: `Starting Upload...`,
      };
    case UploadActionTypes.UPLOAD_PROGRESS:
      return {
        ...state,
        uploadProgress: action.payload.percentage * 100,
        uploadStatus: `Uploading (${action.payload.percentage * 100}%)`,
      };

    case UploadActionTypes.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploading: false,
        uploadProgress: 100,
        uploadStatus: `Upload Complete`,
      };
    case UploadActionTypes.UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploading: false,
        uploadProgress: 0,
        uploadStatus: `Upload Failed: "${action.error}"`,
      };
    case UploadActionTypes.UPLOAD_CANCEL:
      return {
        ...state,
        uploading: false,
        uploadProgress: 0,
        uploadStatus: `Upload Canceled`,
      };
    case CollectibleActionTypes.GET_COLLECTIBLE_COMMENTS:
      return {
        ...state,
        fetchingCollectibleComments: true,
        fetchingCollectibleCommentsError: false,
      };
    case CollectibleActionTypes.GET_COLLECTIBLE_COMMENTS_SUCCESS: {
      let arr;
      if (action.meta.previousAction.payload.request.url.includes('createdAt')) {
        arr = action.payload.value.reverse();
        arr = [...arr, ...state.collectibleComments];
      } else {
        arr = [...state.collectibleComments, ...action.payload.value];
      }
      return {
        ...state,
        fetchingCollectibleComments: false,
        fetchingCollectibleCommentsError: false,
        collectibleComments: arr,
      };
    }
    case CollectibleActionTypes.GET_COLLECTIBLE_COMMENTS_FAILURE:
      return {
        ...state,
        fetchingCollectibleComments: false,
        fetchingCollectibleCommentsError: action.error,
        collectibleComments: [],
      };
    case CollectibleActionTypes.GET_COLLECTIBLE_ACTIVITIES:
      return {
        ...state,
        fetchingCollectibleActivities: true,
        fetchingCollectibleActivitiesError: false,
      };
    case CollectibleActionTypes.GET_COLLECTIBLE_ACTIVITIES_SUCCESS: {
      let arr = action.payload?.value 
        ? [...action.payload?.value.reverse(), ...state.collectibleActivities]
        : [];

      return {
        ...state,
        fetchingCollectibleActivities: false,
        fetchingCollectibleActivitiesError: false,
        collectibleActivities: arr,
      };
    }
    case CollectibleActionTypes.GET_COLLECTIBLE_ACTIVITIES_FAILURE:
      return {
        ...state,
        fetchingCollectibleActivities: false,
        fetchingCollectibleActivitiesError: action.error,
        collectibleActivities: [],
      };      
    case CollectibleActionTypes.CLEAR_COLLECTIBLE:
      return {
        ...state,
        collectibleComments: [],
        collectibleActivities: [],
        collectibleData: null
      };
    case CollectibleActionTypes.CLEAR_COMMENTS:
      return {
        ...state,
        collectibleComments: [],
      };
    case CollectibleActionTypes.CLEAR_ACTIVITIES:
      return {
        ...state,
        collectibleActivities: [],
      };
    case CommentActionTypes.CREATE_COMMENT:
      return {
        ...state,
        creatingComment: true,
      };
    case CommentActionTypes.CREATE_COMMENT_SUCCESS: {
      if (state.collectibleData == null || state.collectibleData.id !== action.payload.postId) {
        return state;
      }

      let comments = [...state.collectibleComments];

      if (!action.payload.parentCommentId) {
        comments = [action.payload, ...comments];
      }
      return {
        ...state,
        collectibleComments: comments,
        creatingComment: false,
      };
    }
    case CommentActionTypes.CREATE_COMMENT_FAILURE:
      return {
        creatingComment: false,
        creatingCommentError: action.error,
      };
    case CollectibleActionTypes.GET_COLLECTIBLE:
      return {
        ...state,
        fetchingCollectible: true,
      };
    case CollectibleActionTypes.GET_COLLECTIBLE_SUCCESS:
      return {
        ...state,
        fetchingCollectible: false,
        collectibleData: action.payload,
      };
    case CollectibleActionTypes.GET_COLLECTIBLE_FAILURE:
      return {
        ...state,
        fetchingCollectible: false,
        fetchingCollectibleDataError: action.error,
      };
    case CollectibleActionTypes.LIKE_COLLECTIBLE:
    case CollectibleActionTypes.UNLIKE_COLLECTIBLE:
      return {
        ...state,
        updatingCollectibleLike: true,
      };
    case CollectibleActionTypes.LIKE_COLLECTIBLE_SUCCESS:
      return {
        ...state,
        collectibleData: {
          ...state.collectibleData,
          ...action.payload,
          actorAccountLikes: true,
        },
        updatingCollectibleLike: false,
      };
    case CollectibleActionTypes.UNLIKE_COLLECTIBLE_SUCCESS:
      return {
        ...state,
        collectibleData: {
          ...state.collectibleData,
          ...action.payload,
          actorAccountLikes: false,
        },
        updatingCollectibleLike: false,
      };
    case CollectibleActionTypes.SHARE_COLLECTIBLE_SUCCESS:
      return {
        ...state,
        collectibleData: {
          ...state.collectibleData,
          ...action.payload,
          actorAccountShared: true,
        },
        updatingCollectibleShare: false,
      };
    case CollectibleActionTypes.UNSHARE_COLLECTIBLE_SUCCESS:
      return {
        ...state,
        collectibleData: {
          ...state.collectibleData,
          ...action.payload,
          actorAccountShared: false,
        },
        updatingCollectibleShare: false,
      };
    case CollectibleActionTypes.LIKE_COLLECTIBLE_FAILURE:
    case CollectibleActionTypes.UNLIKE_COLLECTIBLE_FAILURE:
      return {
        ...state,
        updatingCollectibleLike: false,
      };
    case CollectibleActionTypes.BOOKMARK_COLLECTIBLE:
    case CollectibleActionTypes.UNBOOKMARK_COLLECTIBLE:
      return {
        ...state,
        updatingCollectibleBookmarkState: true,
      };
    case CollectibleActionTypes.BOOKMARK_COLLECTIBLE_SUCCESS:
      return {
        ...state,
        collectibleData: {
          ...state.collectibleData,
          actorAccountBookmarked: true,
        },
        updatingCollectibleBookmarkState: false,
      };
    case CollectibleActionTypes.UNBOOKMARK_COLLECTIBLE_SUCCESS:
      return {
        ...state,
        collectibleData: {
          ...state.collectibleData,
          actorAccountBookmarked: false,
        },
        updatingCollectibleBookmarkState: false,
      };
    case CollectibleActionTypes.BOOKMARK_COLLECTIBLE_FAILURE:
    case CollectibleActionTypes.UNBOOKMARK_COLLECTIBLE_FAILURE:
      return {
        ...state,
        updatingCollectibleBookmarkState: false,
      };
    case CollectibleActionTypes.UPDATE_COLLECTIBLE:
    case CollectibleActionTypes.UPDATE_COLLECTIBLE_SUCCESS:
    case CollectibleActionTypes.UPDATE_COLLECTIBLE_FAILURE:
    case CollectibleActionTypes.RECORD_COLLECTIBLE_VIEW:
    case CollectibleActionTypes.RECORD_COLLECTIBLE_VIEW_SUCCESS:
    case CollectibleActionTypes.RECORD_COLLECTIBLE_VIEW_FAILURE:
    default:
      return state;
  }
};
