import * as AccountActionTypes from '@Actions/AccountActions';
import * as CollectibleActionTypes from '@Actions/CollectibleActions';
import * as HashtagActionTypes from '@Actions/HashtagActions';
import * as CollectionActionTypes from '@Actions/CollectionActions';
import { shuffleArray } from '@Utils';

const initialState = () => ({
  hashtags: {
    popular: [],
    trending: [],
    recent: [],
  },

  creations: {
    popular: [],
    trending: [],
    recent: [],
  },

  creators: {
    popular: [],
    trending: [],
    recent: [],
  },
  
  collections: {
    popular: [],
    trending: [],
    recent: [],
    featured: []
  },

  liveAuctions: {
    popular: [],
    trending: [],
    recent: [],
  },
  
  onSale: {
    popular: [],
    trending: [],
    recent: [],
  },

});

export default (state = initialState(), action) => {
  switch (action.type) {
    case HashtagActionTypes.GET_HASHTAGS_SUCCESS:
      const { group } = action.meta.previousAction.payload;
      const stateGroup = group ? state.hashtags[group] : [] ?? [];
      return {
        ...state,
        hashtags: {
          ...state.hashtags,
          [group]: [...stateGroup, ...action.payload.value.map((value) => value.tag)],
        },
      };
    case CollectibleActionTypes.GET_COLLECTIBLES_SUCCESS: {
      const { group, category } = action.meta.previousAction.payload;
      if (group || category) {
        return {
          ...state,
          fetchingCollectibles: false,
          creations: {
            ...state.creations,
            [group]: {
              ...state.creations[group],
              [category]: state.creations[group][category]
                ? [...state.creations[group][category], ...action.payload.value.map((value) => value.id)]
                : [...action.payload.value.map((value) => value.id)],
              [category + "-randomized"]: state.creations[group][category + "-randomized"]
                ? [...state.creations[group][category + "-randomized"], ...shuffleArray([...action.payload.value.map((value) => value.id)])]
                : shuffleArray([...action.payload.value.map((value) => value.id)]),
            },
          },
        };
      } 

      return state;
    }
    case CollectibleActionTypes.GET_LIVE_AUCTION_ITEMS_SUCCESS: {
      const { group, category } = action.meta.previousAction.payload;
      if (group || category) {
        return {
          ...state,
          liveAuctions: {
            ...state.liveAuctions,
            [group]: {
              ...state.liveAuctions[group],
              [category]: state.liveAuctions[group][category]
                ? [...state.liveAuctions[group][category], ...action.payload.value.map((value) => value.id)]
                : [...action.payload.value.map((value) => value.id)],
              [category + "-randomized"]: state.liveAuctions[group][category + "-randomized"]
                ? [...state.liveAuctions[group][category + "-randomized"], ...shuffleArray([...action.payload.value.map((value) => value.id)])]
                : shuffleArray([...action.payload.value.map((value) => value.id)]),
            },
          },
        };
      }   

      return state;
    }          
    
    case CollectibleActionTypes.GET_ON_SALE_ITEMS_SUCCESS: {
      const { group, category } = action.meta.previousAction.payload;
      if (group || category) {
        return {
          ...state,
          onSale: {
            ...state.onSale,
            [group]: {
              ...state.onSale[group],
              [category]: state.onSale[group][category]
                ? [...state.onSale[group][category], ...action.payload.value.map((value) => value.id)]
                : [...action.payload.value.map((value) => value.id)],
              [category + "-randomized"]: state.onSale[group][category + "-randomized"]
                ? [...state.onSale[group][category + "-randomized"], ...shuffleArray([...action.payload.value.map((value) => value.id)])]
                : shuffleArray([...action.payload.value.map((value) => value.id)]),
            },
          },
        };
      }   

      return state;
    }  

    case AccountActionTypes.GET_ACCOUNTS_SUCCESS: {
      const { group, category } = action.meta.previousAction.payload;
      if (group || category) {
        return {
          ...state,
          creators: {
            ...state.creators,
            [group]: {
              ...state.creators[group],
              [category]: state.creators[group][category]
                ? [...state.creators[group][category], ...action.payload.value.map((value) => value.id)]
                : [...action.payload.value.map((value) => value.id)],
            },
          },
        };
      } 

      return state;
    }
    
    case CollectionActionTypes.GET_COLLECTIONS_SUCCESS: {
      const { group, category } = action.meta.previousAction.payload;
      if (group || category) {
        return {
          ...state,
          collections: {
            ...state.collections,
            [group]: {
              ...state.collections[group],
              [category]: state.collections[group][category]
                ? [...state.collections[group][category], ...action.payload.value.map((value) => value.id)]
                : [...action.payload.value.map((value) => value.id)],
            },
          },
        };
      } 

      return state;
    }
    default:
      return state;
  }
};
