import * as React from 'react';
import PropTypes from 'prop-types';
import Icon from '@Components/shared/Icon';
import Carousel from 'react-multi-carousel';
import withWindowDimensions from '@Components/hoc/withWindowDimensions';
import { AnimatedPlaceholderLine } from '@Components/placeholder/PlaceholderComponents';
import { withTranslation } from 'react-i18next';

const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <div className="carousel-button-group">
      <div className="d-flex buttons">
        <div className="left-button" onClick={() => previous()}>
          <Icon src="fas fa-chevron-circle-left" type="fontawesome" />
        </div>
        <div className="right-button" onClick={() => next()}>
          <Icon src="fas fa-chevron-circle-right" type="fontawesome" />
        </div>
      </div>
    </div>
  );
};
const CarouselButtonGroup = ({ next, previous, goToSlide, ...rest }) => {
  const {
    carouselState: { currentSlide },
  } = rest;
  return (
    <>
      <div className="left-button carousel-button" onClick={() => previous()}>
        <Icon src="fas fa-chevron-circle-left" type="fontawesome" />
      </div>
      <div className="right-button carousel-button" onClick={() => next()}>
        <Icon src="fas fa-chevron-circle-right" type="fontawesome" />
      </div>
    </>
  );
};

class DiscoverCardGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1800 },
      items: 7,
      slidesToSlide: 7,
    },
    desktopBig: {
      breakpoint: { max: 1800, min: 1368 },
      items: this.props.mini ? 4 : 6,
      slidesToSlide: this.props.mini ? 4 : 6,
    },
    desktop: {
      breakpoint: { max: 1368, min: 1180 },
      items: 5,
      slidesToSlide: 5,
    },
    tabletBig: {
      breakpoint: { max: 1180, min: 990 },
      items: 4,
      slidesToSlide: 4,
    },
    tablet: {
      breakpoint: { max: 990, min: 730 },
      items: 3,
      slidesToSlide: 3,
    },
    mobileBig: {
      breakpoint: { max: 730, min: 0 },
      items: 2,
      slidesToSlide: 2,
    },
  };

  responsiveCarousel = {
    desktopBig: {
      breakpoint: { max: 4000, min: 1500 },
      items: 5,
      slidesToSlide: 5,
    },
    desktop: {
      breakpoint: { max: 1500, min: 1000 },
      items: 4,
      slidesToSlide: 4,
    },
    tabletBig: {
      breakpoint: { max: 1000, min: 700 },
      items: 2,
      slidesToSlide: 2,
    },
    mobileBig: {
      breakpoint: { max: 700, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  render() {
    const { t } = this.props;
    let maxChildrenLength;
    const PAGE_NUMBER = 4;
    for (const prop in this.responsive) {
      const size = this.responsive[prop];
      const { windowWidth } = this.props;
      if (size.breakpoint.min < windowWidth && size.breakpoint.max > windowWidth) {
        maxChildrenLength = size.items * PAGE_NUMBER;
      }
    }
    return this.props.children?.length > 0 ? (
      <div className="discover-card-group-container mt-4">
        <div className="discover-card-group">
          {this.props.hideInfo ? null : (
            <div className="group-header">
              <div className="group-title d-flex justify-space-between">
                <div className="w-100 group-title-text">
                  {this.props.title ? this.props.title : <AnimatedPlaceholderLine width={'20%'} />}
                </div>
                {this.props.seeAll ? (
                  <div
                    className="link see-all"
                    style={{ width: '100px', textAlign: 'right' }}
                    onClick={() => {
                      this.props.seeAll({ type: this.props.type });
                    }}>
                    {t('public.seeAll')}
                  </div>
                ) : null}
              </div>
              <hr className="my-1" />
              {this.props.subtitle ? (
                <div className="subtitle w-100 d-flex justify-space-between mt-1">
                  <div>{this.props.subtitle ? this.props.subtitle : <AnimatedPlaceholderLine width={'15%'} />}</div>
                </div>
              ) : null}
            </div>
          )}
          {!this.props.hideCarousel ? (
            <div className="group-items">
              <Carousel
                infinite
                renderButtonGroupOutside
                arrows={false}
                customButtonGroup={!this.props.isMobileSized ? <CarouselButtonGroup /> : null}
                draggable={this.props.isMobileSized}
                itemClass=""
                minimumTouchDrag={20}
                renderDotsOutside={false}
                responsive={this.props.carousel ? this.responsiveCarousel : this.responsive}
                showDots={!this.props.isMobileSized && this.props.carousel ? false : true}
                dotListClass={this.props.carousel && this.props.isMobileSized ? '' : 'custom-dot-list'}
                swipeable={true}>
                {this.props.children.slice(0, maxChildrenLength).map((p, index) => {
                  return (
                    <div
                      key={p?.props?.collectibleId || index}
                      className={this.props.carousel ? 'carousel-card-wrapper' : 'collectible-card-wrapper'}>
                      {p}
                    </div>
                  );
                })}
              </Carousel>
            </div>
          ) : (
            <div className="all-group-items">
              {this.props.children.map((p, index) => {
                return (
                  <div key={p?.props?.collectibleId || index} className="collectible-card-wrapper">
                    {p}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    ) : null;
  }
}

DiscoverCardGroup.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

export default withWindowDimensions(withTranslation()(DiscoverCardGroup));
