import * as React from 'react';
import ReactDOM from 'react-dom';
import bind from 'bind-decorator';
import Icon from '../shared/Icon';
import PropTypes from 'prop-types';
import { ModalActions } from '@Actions/ModalActions';
import { connect } from 'react-redux';
import logoIcon from '@Images/logo/oix-icon-color.svg';
import { setBodyPosition } from '@Utils';
class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
      modalOpening: false,
      modalClosing: false,
    };
    const { openModal, closeModal } = setBodyPosition();
    this.openModal = openModal;
    this.closeModal = closeModal;
  }

  componentDidMount() {
    this.openModal();    
  }

  componentDidUpdate(prevProps, prevState) {
    const { show } = this.state;

    if (prevState.show && !show) {
      this.setState({ modalOpening: undefined, modalClosing: true });
    }

    if (!prevState.show && show) {
      this.setState({ modalOpening: true, modalClosing: undefined });
    }
  }

  componentWillUnmount() {
      this.closeModal();
  }

  getClassName() {
    let cname = 'modal';
    if (this.state.show) cname += ' modal-show';
    if (this.props.fullscreen) cname += ' modal-fullscreen';
    if (this.props.centerButtons) cname += ' modal-center-buttons';
    if (this.props.className) cname += ' ' + this.props.className;
    return cname;
  }

  @bind
  clickHandler(e) {
    // Check if clicked outside
    if (e.target.classList.contains('modal-backdrop') || e.target.classList.contains('modal')) {
      const { closeOnOutsideClick, onOutsideClick } = this.props;

      if (closeOnOutsideClick) {
        this.props.closeAllModals();
      }

      if (onOutsideClick && typeof onOutsideClick == 'function') onOutsideClick();
    }
  }

  @bind
  animationEndHandler(e) {
    const animationName = e.animationName;
    if (animationName === 'modal-closing') {
      this.setState({ modalClosing: undefined });
    }

    if (animationName === 'modal-opening') {
      this.setState({ modalOpening: undefined });
    }
  }

  @bind
  onCloseModal(count) {
    if (count == 'all') {
      if (this.props.onCloseButtonClick) this.props.onCloseButtonClick();
      else {
        this.props.closeAllModals();
      }
    } else {
      if (this.props.onBackButtonClick) this.props.onBackButtonClick();
      else {
        this.props.closeModal(count);
      }
    }
  }

  render() {
    const { backdrop, title, subtext } = this.props;
    const { currentModals } = this.props;
    const { modalOpening, modalClosing } = this.state;
    return (
      <div
        className={this.getClassName()}
        data-modal-closing={modalClosing}
        data-modal-opening={modalOpening}
        onAnimationEnd={this.animationEndHandler}
        onClick={this.clickHandler}>
        {backdrop ? <div className="modal-backdrop"></div> : null}
        <div className="modal-container">
          <div className="modal-box">
            <div className="modal-header">
              {currentModals.length > 1 ? (
                <div
                  className="icon-button mr-2"
                  onClick={() => {
                    this.onCloseModal();
                  }}>
                  <Icon type="fontawesome" src="fas fa-arrow-left" />
                </div>
              ) : title ? null : (
                <div style={{ width: '42px' }}> </div>
              )}
              <div className="modal-header-top">
                <div className="info">
                  {title ? (
                    <div className="w-100">
                      <div className="title">{title}</div>
                      <div className="subtext">{subtext}</div>
                    </div>
                  ) : (
                    <div className="w-100 text-center">
                      <img width={28} src={logoIcon} />
                    </div>
                  )}

                  {
                    // Uncomment this to hide close button after head modal
                    //currentModals.length == 1
                    //    ? <div className="icon-button " onClick={() => { this.props.closeAllModals(); }}>
                    //        <Icon type="fontawesome" src="fas fa-times" />
                    //    </div>
                    //    : null
                  }
                  <div className="icon-container">
                    <div
                      className="icon-button "
                      onClick={() => {
                        this.onCloseModal('all');
                      }}>
                      <Icon type="fontawesome" src="fas fa-times" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div className="modal-content">{this.props.children[0] || this.props.children}</div>
            {
              // Show footer if only second child exists
              this.props.children[1] ? (
                <>
                  <hr />
                  <div className="modal-footer">{this.props.children[1]}</div>
                </>
              ) : null
            }
          </div>
        </div>
      </div>
    );
  }
}
Modal.defaultProps = {
  show: false,
};
Modal.propTypes = {
  children: PropTypes.any.isRequired,
  title: PropTypes.any,
  subtext: PropTypes.any,
};

const mapState = (state) => ({ ...state.modal });
const component = connect(mapState, ModalActions, null, { forwardRef: true })(Modal);
export default component;
