import AppVariables from '@AppVariables';
import ContextDropdown from '@Components/dropdown/ContextDropdown';
import Input from '@Components/form/Input';
import React, { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { FaChevronDown, FaCheckSquare } from 'react-icons/fa';
import Icon from '@Components/shared/Icon';
import { useTranslation } from 'react-i18next';
import OiInput from '@Components/form/OiInput';
import cn from 'classnames';
import { connect } from 'react-redux';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { useHistory, useLocation } from 'react-router';

const checkboxIcon = (
  <div style={{ width: '20px', height: '20px', border: '1px solid #ffffff80', borderRadius: '5px' }} />
);
const checkboxCheckedIcon = (
  <div style={{ width: '20px', height: '20px' }}>
    <FaCheckSquare />
  </div>
);

const CategoryFilter = ({ isMobile, accountCategories, isAccountCategory }) => {
  const { collectibleCategories } = AppVariables;

  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const categoryDropdownRef = useRef();
  const [selectedCategories, setSelectedCategories] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isOpenBlockchain, setIsOpenBlockchain] = useState(false);

  const openDropdown = () => {
    isMobile && setIsOpenBlockchain((prevState) => !prevState);
    !isMobile && categoryDropdownRef.current?.open();
  };

  const filteredCollectibleCategories = useMemo(
    () =>
      (isAccountCategory ? accountCategories : collectibleCategories)?.filter((category) =>
        category[isAccountCategory ? 'name' : 'Name'].toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [searchValue],
  );

  const handleInputChange = () => (e) => {
    setSearchValue(e.target.value);
  };

  const handleSelectCategory = (categoryId) => (e) => {
    setSelectedCategories((prevState) => ({ ...prevState, [categoryId]: e.target.checked }));
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedCategoryKeys = [];
    Object.entries(selectedCategories).forEach(([key, value]) => value && selectedCategoryKeys.push(key));

    if (selectedCategoryKeys.length) {
      params.set('categories', selectedCategoryKeys.join(','));
      const totalParams = params.toString().length ? '?' + params.toString() : '';
      history.push(location.pathname + totalParams);
    } else {
      params.delete('categories');
      const totalParams = params.toString().length ? '?' + params.toString() : '';
      history.push(location.pathname + totalParams)
    }
  }, [selectedCategories]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const categories = params.get('categories');

    if (categories) {
      const decodedCategoriesStr = decodeURIComponent(categories);
      const decodedCategoriesIds = decodedCategoriesStr.split(',');

      const _selectedCategories = decodedCategoriesIds.reduce((acc, id) => {
        acc[id] = true;
        return acc;
      }, {});
      setSelectedCategories(_selectedCategories);
    }
  }, []);

  return (
    <div className={cn('category-filter-container', isMobile && 'mobile')} id="category-filter">
      <div className="category-filter" onClick={openDropdown}>
        {t('public.searchFilterCategoryText')}
        <FaChevronDown className="ml-1" />
      </div>
      {isMobile && isOpenBlockchain && (
        <>
          <OiInput
            options={{ leftComponent: 'searchIcon' }}
            placeholder={t('Filter')}
            value={searchValue}
            onChange={handleInputChange()}
          />
          <div className="category-list">
            {filteredCollectibleCategories.map((category) => (
              <div key={isAccountCategory ? category.id : category.Id} className="category">
                {!isAccountCategory && (
                  <div className="category-icon-container">
                    <Icon type="fontawesome" src={category.IconSrc} />
                  </div>
                )}
                <div className="category-name"> {t(`public.${category[isAccountCategory ? 'name' : 'Name']}`)} </div>
                <Input.CheckBox
                  checkedIcon={checkboxCheckedIcon}
                  icon={checkboxIcon}
                  value={selectedCategories[category.Id]}
                  onChange={handleSelectCategory(isAccountCategory ? category.id : category.Id)}
                />
              </div>
            ))}
          </div>
        </>
      )}
      {!isMobile && (
        <ContextDropdown
          ref={categoryDropdownRef}
          anchorEl="category-filter"
          className="category-filter-popover"
          origin="left"
          transformOriginH="left"
          transformOriginV="top">
          <>
            <OiInput
              options={{ leftComponent: 'searchIcon' }}
              placeholder={t('Filter')}
              value={searchValue}
              onChange={handleInputChange()}
            />
            <OverlayScrollbarsComponent style={{ maxHeight: '300px', overflow: 'auto' }}>
              {filteredCollectibleCategories.map((category) => (
                <div key={isAccountCategory ? category.id : category.Id} className="category">
                  {!isAccountCategory && (
                    <div className="category-icon-container">
                      <Icon type="fontawesome" src={category.IconSrc} />
                    </div>
                  )}
                  <div className="category-name"> {t(`public.${category[isAccountCategory ? 'name' : 'Name']}`)} </div>
                  <Input.CheckBox
                    checkedIcon={checkboxCheckedIcon}
                    icon={checkboxIcon}
                    value={selectedCategories[category.Id]}
                    onChange={handleSelectCategory(isAccountCategory ? category.id : category.Id)}
                  />
                </div>
              ))}
            </OverlayScrollbarsComponent>
          </>
        </ContextDropdown>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  accountCategories: state.service.AccountCategories,
});

const actions = {};

export default connect(mapStateToProps, actions)(CategoryFilter);
