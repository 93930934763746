import React from 'react';

import PropTypes from 'prop-types';
import cn from 'classnames';

import OiModalCloseButton from '@Components/shared/OiModalCloseButton';
import OiModalSeparator from './OiModalSeparator';

import logoImg from '@Images/logo/oix-icon-color.svg';
import OiModalBackButton from './OiModalBackButton';

const OiModalHeader = ({ logo, title, subtitle, hasBackButton, onBackButtonClick, onClose }) => {
  return (
    <div className="oi-modal-header">
      {logo ? (
        <>
          <div className="oi-modal-header__logo-container">
            <div className="oi-modal-header__button-placeholder"></div>
            <img src={logoImg} className="oi-modal-header__logo" />
            <OiModalCloseButton onClick={onClose} />
          </div>
          <OiModalSeparator position="horizontal" />
        </>
      ) : (
        <>
          <div className="oi-modal-header__title-container">
            {hasBackButton && <OiModalBackButton onClick={onBackButtonClick} />}
            <h1 className="oi-modal-header__title">{title}</h1>
            <OiModalCloseButton onClick={onClose} />
          </div>
          <h2 className={cn('oi-modal-header__subtitle', hasBackButton && 'active-back-button')}>{subtitle}</h2>
          <OiModalSeparator position="horizontal" />
        </>
      )}
    </div>
  );
};

OiModalHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onClose: PropTypes.func,
};

export default OiModalHeader;
