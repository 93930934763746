import * as AccountActionTypes from '@Actions/AccountActions';
import * as NotificationActionTypes from '@Actions/NotificationActions';
import * as UserActionTypes from '@Actions/UserActions';
import { buildNotificationContent } from '@Utils';

const initialState = () => ({
  fetchingAccountNotifications: false,
  accountNotifications: [],
  fetchingAccountNotificationsError: null,

  fetchingAccountReminders: false,
  accountReminders: [],
  fetchingAccountRemindersError: null,

  unviewedNotificationCount: 0,

  recentNotificationsCutoffDate: 0,

  account: null,
});

export default (state = initialState(), action) => {
  switch (action.type) {
    case AccountActionTypes.GET_NOTIFICATIONS:
      return {
        ...state,
        fetchingAccountNotifications: true,
      };
    case AccountActionTypes.GET_NOTIFICATIONS_SUCCESS: {
      let newNotifications = action.payload;
      newNotifications = newNotifications.map((n) => n.notification.id);

      return {
        ...state,
        fetchingAccountNotifications: false,
        // It is only added to the back of the list
        accountNotifications: [...state.accountNotifications, ...newNotifications],
      };
    }
    case AccountActionTypes.GET_NOTIFICATIONS_FAILURE:
      return {
        ...state,
        fetchingAccountNotifications: false,
        fetchingAccountNotificationsError: action.error,
      };
    case NotificationActionTypes.RESET_NOTIFICATIONS:
      return {
        ...state,
        fetchingAccountNotifications: false,
        accountNotifications: [],
        fetchingAccountNotificationsError: null,
      };
    case NotificationActionTypes.NEW_NOTIFICATION: {
      const notificationId = action.payload.notification.id;

      return {
        ...state,
        accountNotifications: [notificationId, ...state.accountNotifications],
      };
    }
    case NotificationActionTypes.REMOVED_NOTIFICATION: {
      const _accountNotifications = [...state.accountNotifications];
      const removedNotificationId = action.payload.notificationId;

      return {
        ...state,
        accountNotifications: _accountNotifications.filter((notification) => notification !== removedNotificationId),
      };
    }
    case NotificationActionTypes.SET_UNVIEWED_NOTIFICATION_COUNT:
      return {
        ...state,
        unviewedNotificationCount: action.payload.unviewedNotificationCount,
      };
    case UserActionTypes.LOAD_ACCOUNT_SUCCESS:
      return {
        ...state,
        loadingAccount: false,
        account: action.payload,
        accountLoadingError: null,
      };
    case AccountActionTypes.GET_REMINDERS:
      return {
        ...state,
        fetchingAccountReminders: true,
      };
    case AccountActionTypes.GET_REMINDERS_SUCCESS: {
      const remindersToNotificationType = [];
      let generatedContent = [];
      if (!action.payload.isEmailConfirmed) {
        remindersToNotificationType.push({ notification: { type: 'HAS_NOT_EMAIL' }, content: null });
      }
      if (action.payload.hasPendingClaims) {
        remindersToNotificationType.push({ notification: { type: 'HAS_PENDING_CLAIMS' }, content: null });
      }

      generatedContent = remindersToNotificationType.map((reminder) => ({
        ...reminder,
        content: buildNotificationContent(reminder.notification),
      }));

      return {
        ...state,
        fetchingAccountReminders: false,
        // It is only added to the back of the list
        accountReminders: generatedContent,
      };
    }
    case AccountActionTypes.GET_REMINDERS_FAILURE:
      return {
        ...state,
        fetchingAccountReminders: false,
        accountReminders: [],
        fetchingAccountRemindersError: action.error,
      };
    default:
      return state;
  }
};
