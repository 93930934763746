import * as React from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { ModalActions } from '@Actions/ModalActions';
import { CollectibleActions } from '@Actions/CollectibleActions';
import { HashtagActions } from '@Actions/HashtagActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { AccountActions } from '@Actions/AccountActions';
import { SearchActions } from '@Actions/SearchActions';
import DiscoverCardGroup from '@Components/shared/DiscoverCardGroup';
import { AnimatedPlaceholderRect } from '@Components/placeholder/PlaceholderComponents';
import { InlineLoader } from '@Components/shared/Spinner';
import AppScroller from '@Components/shared/AppScroller';
import { withTranslation } from 'react-i18next';
import cn from 'classnames';
import HashtagCard from '@Components/shared/HashtagCard';

class SearchHashtagsE extends React.Component {
  TOP = 32;
  constructor(props) {
    super(props);
    this.state = {
      shouldLoadMore: true,
      resultCount: 0,
      shouldFetch: true,
    };
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    const { load } = this.props;
    if (prevProps.load !== load) {
      if (load) this.loadMore();
    }
    if (decodeURIComponent(prevProps.location.search) !== decodeURIComponent(location.search)) {
      this.init();
    }
  }

  async init() {
    const { clearSearchCreators } = this.props;
    await clearSearchCreators();
    await this.createRequest();
  }

  splitSearchKey(value) {
    if (value.startsWith('#')) {
      return value.split('#')[1];
    }

    return value;
  }

  createRequest() {
    const { location, match, hashtagsData, getHashtags } = this.props;
    const query = new URLSearchParams(location.search);
    let value = match.params.tag ? '#' + match.params.tag : query.get('q') || '';
    value = encodeURIComponent(value);
    value = this.splitSearchKey(value);

    const limitQuery = '&$skip=' + hashtagsData?.length + '&$top=' + this.TOP;
    const searchKeyQuery = value.length > 0 ? '&searchKey=' + value : '';

    const concattedQuery = '$count=true' + limitQuery + searchKeyQuery;

    getHashtags(concattedQuery).then((res) => {
      if (!res.error) {
        this.setState({ resultCount: res.payload['@odata.count'], shouldFetch: res.payload.value.length >= this.TOP });
      }
    });
  }

  loadMore() {
    this.createRequest();
  }

  render() {
    const { location, hashtagsData, fetchingHashtags, isFilterBarOpen, t } = this.props;
    const { resultCount, shouldFetch } = this.state;
    let params = new URLSearchParams(location.search);
    return (
      <>
        <Helmet>
          <title> {t('public.searchHashtags')} </title>
        </Helmet>
        <div className={cn('result-info', isFilterBarOpen && 'filter-open')}>
          <div className="result">
            &quot;<span className="text">{this.splitSearchKey(params.get('q'))}</span>&quot; {t('public.searchResult')}
          </div>
          <div className="about mt-1"> {t('public.aboutResult', { count: resultCount })} </div>
        </div>
        {hashtagsData?.length == 0 && fetchingHashtags ? (
          <DiscoverCardGroup key={'discover-group-2'} hideCarousel hideInfo className="mt-3">
            {new Array(this.TOP).fill(0).map((i) => {
              return (
                <div key={'account' + i} className="w-100 placeholder-card">
                  <div style={{ position: 'relative' }}>
                    <div style={{ display: 'block', paddingBottom: '100%' }} />
                    <AnimatedPlaceholderRect style={{ position: 'absolute', top: '0', left: '0' }} />
                  </div>
                </div>
              );
            })}
          </DiscoverCardGroup>
        ) : (
          <AppScroller
            dataLength={hashtagsData?.length || 0}
            next={() => this.loadMore()}
            hasMore={shouldFetch && hashtagsData?.length >= this.TOP}
            loader={<InlineLoader height={30} />}>
            <DiscoverCardGroup key={'discover-group-'} hideCarousel hideInfo className="mt-3">
              {hashtagsData?.map((hastag) => {
                return (
                  <HashtagCard
                    key={'hashtag' + hastag.tag + hastag.group}
                    followHashtag={this.props.followHashtag}
                    getHashtag={this.props.getHashtag}
                    hashtag={hastag}
                    history={this.props.history}
                    unfollowHashtag={this.props.unfollowHashtag}
                  />
                );
              })}
            </DiscoverCardGroup>
          </AppScroller>
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  ...AccountActions,
  ...SearchActions,
  ...ModalActions,
  ...SpinnerActions,
  ...CollectibleActions,
  ...ServiceActions,
  ...HashtagActions,
};

const mapStateToProps = (state) => ({
  ...state.search,
  ...state.user.account,
});

const SearchHashtags = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(
  withTranslation()(SearchHashtagsE),
);

export default SearchHashtags;
