import React from 'react';
import { useEffect, useState } from 'react';
import { ReactComponent as SupportChatIcon } from '@Images/icons/support-chat.svg?component';

import PropTypes from 'prop-types';
import useScript from '@Hooks/use-script';
import { runtimeConfig } from 'config';

const OiSupportChatWidget = ({ launcherEnabled }) => {

  const [zendesk, setZendesk] = useState(null);
  const [scriptUrl, setScriptUrl] = useState(null);
  
  const [messengerOpen, setMessengerOpen] = useState(false);
  const [messengerOpenedOnce, setMessengerOpenedOnce] = useState(false);

  const scriptStatus = useScript(scriptUrl, "ze-snippet", runtimeConfig.nonce)

  const toggleMessenger = () => {
    zendesk?.('messenger', messengerOpen ? 'close' : 'open');
  };

  const populateUnreadIndicator = (count) => {
    console.log('unread messages', count);
  };

  useEffect(() => {
    if(launcherEnabled) {
      setScriptUrl(`https://static.zdassets.com/ekr/snippet.js?key=${process.env.RAZZLE_ZENDESK_KEY}`);
    }
  }, [launcherEnabled])
 
  useEffect(() => {
    if(scriptStatus === 'ready') {
      console.log('status', scriptStatus);
      setZendesk(window.zE);
    }
  }, [scriptStatus])


  useEffect(() => {
    if (zendesk) {

      // unread Message on listener
      zendesk('messenger:on', 'open', () => {
        setMessengerOpen(true);
        setMessengerOpenedOnce(true);
        console.log('messenger opened');
      })

      zendesk('messenger:on', 'close', () => {
        setMessengerOpen(false);
        console.log('messenger closed');
      })

      // unread Message on listener
      zendesk('messenger:on', 'unreadMessages', (count) => {
        populateUnreadIndicator(count)
        console.log('unreadMessages', count);
      })

      // on page load always close widget
      // zendesk('messenger', 'close');
    } 

  }, [zendesk])

  return null; 
  /*
  When building a custom launcher, we can use the code below
  (
    (launcherEnabled || messengerOpenedOnce) // once it's opened once, the launcher stays open
      ? (<div className="oi-support-chat-launcher" onClick={toggleMessenger}>
          <SupportChatIcon width={24} height={24} />
        </div>)
      : <></>
  ); */
};

OiSupportChatWidget.propTypes = {
  launcherEnabled: PropTypes.bool,
};

export default OiSupportChatWidget;
