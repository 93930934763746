import React, { Component } from 'react';

import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';

import { ModalActions } from '@Actions/ModalActions';
import { ServiceActions } from '@Actions/ServiceActions';
import { UserActions } from '@Actions/UserActions';
import { isBrowser } from '@Utils';

class PrivateRoute extends Component {

  constructor(props) {
    super(props);

    this.state = {
      connectWalletModalOpen: false
    }
  }

  componentDidMount() {
    this.checkAuthAndOpenConnectModal();
  }

  componentDidUpdate(prevProps) {
    const { user } = this.props;

    if (prevProps.user !== user) {
      this.checkAuthAndOpenConnectModal();
    }
  }

  layoutRef = React.createRef();
  isBrowser = isBrowser();

  onModalClosed = () => {
    const { user, history, closeAllModals } = this.props;
    const authenticated = user && user.tokens && Date.now() < user.tokens.expires_at;

    if(!authenticated) {
      history.push('/discover');
    }

    closeAllModals();
  }

  checkAuthAndOpenConnectModal() {
    const { user, openModal, currentModals, pingAuth } = this.props;
    const authenticated = user && user.tokens && Date.now() < user.tokens.expires_at;

    const connectWalletModalOpen = currentModals.filter(m => m.modalType === "ConnectWalletModal").length > 0;

    // If we have a token but it expired
    if(user && user.tokens && Date.now() >= user.tokens.expires_at){
      // Send a ping auth so that the middleware will try and refresh the tokens
      // if 401 is received and it will just continue on. Otherwise, if it cannot
      // refresh the tokens, it will finally log the user out, which will then trigger
      // the else if statement below
      pingAuth();
    }           
    else if (!authenticated && !connectWalletModalOpen) {
      openModal('ConnectWalletModal', {
        closeOnOutsideClick: false,
        backdrop: true,
        onCloseButtonClick: this.onModalClosed,
        onOutsideClick: this.onModalClosed,
        onBackButtonClick: this.onModalClosed
      });
    }
  }

  render() {
    const {
      isLoadingUser,
      loadingAccount,
      user,
      account
    } = this.props;

    const ready = this.isBrowser && !isLoadingUser && !loadingAccount;

    if (!ready) {
      return <div></div>;
    }

    const authenticated =
      user &&
      user.profile &&
      user.tokens &&
      Date.now() < user.tokens.expires_at;

    const { component: Component, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={(props) => {
          
          if (authenticated || (user?.isFetchingTokens || user?.isFetchingProfile)) {
            if (account) {
              return <Component {...props} layoutRef={this.layoutRef} />;
            }

            return <div></div>;
          }

          return <div></div>;
        }}
      />
    );
  }
}

const component = connect(
  (state) => {
    return { ...state.auth, ...state.user.account, ...state.modal };
  },
  { ...UserActions, ...ModalActions, ...ServiceActions },
  null,
  { forwardRef: true },
)(PrivateRoute);
export default withRouter(component);
