import * as React from 'react';
import Icon from '@Components/shared/Icon';
import ProfileAvatar from '@Components/profile/ProfileAvatar';
import { NavLink, Link, withRouter } from 'react-router-dom';
import logoImg from '@Images/logo/oix-icon-color.svg';
import PropTypes from 'prop-types';
import { ModalActions } from '@Actions/ModalActions';
import { connect } from 'react-redux';
import { ReactComponent as SwapIcon } from '@Images/icons/swap.svg?component';
import AppVariables from '@AppVariables';
import withWindowDimensions from '@Components/hoc/withWindowDimensions';
import { isAuthenticated, openInNewTab } from '@Utils';
import { withTranslation } from 'react-i18next';
import Profile from '@Reducers/Profile';

class MenuItem extends React.Component {
  render() {
    return (
      <NavLink
        exact={this.props.exact}
        to={this.props.to}
        className="menu-item"
        isActive={this.props.isActive}
        onClick={() => this.props.toggleMenu(false)}>
        {this.props.children}
      </NavLink>
    );
  }
}

class AppSidebar extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    showSettings: false,
    seeAllCategories: true,
  };

  getClassName() {
    let cname = 'sidebar text-white text-small';
    if (!this.props.isSidebarOpen) cname += ' sidebar-small';
    return cname;
  }

  toggleShowSettings() {
    this.setState({
      showSettings: !this.state.showSettings,
    });
  }

  toggleMenu = (menuState) => {
    if (this.props.windowWidth <= 1200) {
      this.props.toggleMenu(menuState);
    }
  };

  render() {
    const { t } = this.props;
    let params = new URLSearchParams(this.props.location.search);

    return (
      <div id="app-sidebar" className={this.getClassName()}>
        <div className="sidebar">
          <div className="sidebar-wrap">
            <div className="sidebar-content">
              <div className="top-content">
                <div className="header">
                  <div className="logo-cell">
                    <div className="logo" onClick={() => this.toggleMenu()}>
                      <Icon type="fontawesome" src="fas fa-bars" />
                    </div>
                    <MenuItem to="/" toggleMenu={this.toggleMenu}>
                      <div className=" text-bold home-link">
                        <img src={logoImg} className="pr-1" />
                        <span style={{ color: 'white' }}>OIX</span>
                      </div>
                    </MenuItem>
                  </div>
                </div>
                <div className="menu-links">
                  <MenuItem
                    to={'/feed'}
                    isActive={(match, location) => {
                      if (
                        (location.pathname === '/' && isAuthenticated(this.props.user)) ||
                        location.pathname === '/feed'
                      ) {
                        return true;
                      }
                      return false;
                    }}
                    toggleMenu={this.toggleMenu}>
                    <div className="borderless-icon-container notification">
                      {this.props.newFeedItemAvailable ? <div className="point"></div> : null}
                      <Icon src="fas fa-home" type="fontawesome" />
                    </div>
                    <div>{t('public.home')}</div>
                  </MenuItem>
                  <MenuItem
                    to="/discover"
                    isActive={(match, location) => {
                      if (
                        (location.pathname === '/' && !isAuthenticated(this.props.user)) ||
                        location.pathname.includes('/discover')
                      ) {
                        return true;
                      }
                      return false;
                    }}
                    toggleMenu={this.toggleMenu}>
                    <div className="borderless-icon-container">
                      <Icon src="fas fa-compass" type="fontawesome" />
                    </div>
                    <div>{t('public.discover')}</div>
                  </MenuItem>
                  <MenuItem to={'/bids'} toggleMenu={this.toggleMenu}>
                    <div className="borderless-icon-container">
                      <SwapIcon width={21} height={18} />
                    </div>
                    <div>{t('public.bids')}</div>
                  </MenuItem>
                  <MenuItem to={'/notifications'} toggleMenu={this.toggleMenu}>
                    <div className="borderless-icon-container notification">
                      {this.props.account?.unviewedNotificationCount > 0 ? <div className="point"></div> : null}
                      <Icon src="fas fa-bell" type="fontawesome" />
                    </div>
                    <div>{t('public.notifications')}</div>
                  </MenuItem>
                  <MenuItem to={'/' + this.props.account?.profile?.slug.toLowerCase()} toggleMenu={this.toggleMenu}>
                    <div className="borderless-icon-container">
                      <Icon src="fas fa-user" type="fontawesome" />
                    </div>
                    <div>{t('public.profile')}</div>
                  </MenuItem>
                </div>
                <div className="settings-links">
                  <div className="menu-dropdown" onClick={() => this.toggleShowSettings()}>
                    <div className="cell-1">
                      <div className="borderless-icon-container">
                        <Icon src="fas fa-cog" type="fontawesome" />
                      </div>
                      <div>{t('public.settings')}</div>
                    </div>
                  </div>
                  <div className="library-menu">
                    <MenuItem to={'/settings/notifications'} toggleMenu={this.toggleMenu}>
                      <div className="sub">{t('public.notificationSettings')} </div>
                    </MenuItem>
                    <MenuItem to={'/settings/display'} toggleMenu={this.toggleMenu}>
                      <div className="sub">{t('public.displayMenu')} </div>
                    </MenuItem>

                    <MenuItem to={'/settings/privacy'} toggleMenu={this.toggleMenu}>
                      <div className="sub">{t('public.privacySafety')}</div>
                    </MenuItem>
                  </div>
                  <Link to={'/support'} className="menu-item">
                    <div className="borderless-icon-container">
                      <Icon src="fas fa-question-circle" type="fontawesome" />
                    </div>
                    <div>{t('public.helpSupport')}</div>
                  </Link>
                  <Link
                    to={'#'}
                    className="menu-item"
                    onClick={() => {
                      this.props.openModal('SendFeedbackModal', {
                        show: true,
                        backdrop: true,
                        closeOnOutsideClick: true,
                      });
                    }}>
                    <div className="borderless-icon-container">
                      <Icon src="fas fa-comments" type="fontawesome" />
                    </div>
                    <div>{t('public.sendFeedback')}</div>
                  </Link>
                  <hr className="my-1" />
                  <div className="py-2 px-3">
                    <Link
                      className="create-box"
                      to={{ pathname: '/collectible/upload', state: { background: this.props.location } }}>
                      <div className="box-wrap"> {t('public.create')} </div>
                      <div className="border"></div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="bottom-content">
                <div className="bottom-info">
                  <Link to="/privacy" onClick={() => this.toggleMenu()}>
                    {t('public.privacyPolicy')}
                  </Link>
                  <Link to="/terms" onClick={() => this.toggleMenu()}>
                    {t('public.termOfService')}
                  </Link>
                  <Link to="/community-guidelines" onClick={() => this.toggleMenu()}>
                    {t('public.communityGuidelines')}
                  </Link>
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a href={AppVariables.externalUrls.blog} target="_blank" onClick={() => this.toggleMenu()}>
                    {t('public.blog')}
                  </a>
                </div>
                <div className="love">
                  <div className="subtext">© 2023 Orderinbox Inc.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.isSidebarOpen ? (
          <div className="click-block" onClick={() => this.toggleMenu()}>
            {' '}
          </div>
        ) : null}
      </div>
    );
  }
}

AppSidebar.propTypes = {
  isSidebarOpen: PropTypes.bool,
  toggleMenu: PropTypes.func.isRequired,
};

const component = connect(
  (state) => {
    return {
      ...state.feed,
      ...state.user.account,
      ...state.auth,
    };
  },
  {
    ...ModalActions,
  },
  null,
  { forwardRef: true },
)(AppSidebar);

export default withRouter(withWindowDimensions(withTranslation()(component)));
