import * as React from 'react';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import { withTranslation } from 'react-i18next';

class UploadPromptModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { backdrop, closeOnOutsideClick, show, t  } = this.props;
    return (
      <Modal
        subtext={''}
        onCloseButtonClick={() => this.props.handleStay()}
        show={show}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={() => this.props.handleStay()}
      >
        <div className="upload-prompt-modal mx-2 my-2">
          <div className="my-3">
            <div className="my-3"> {t('modals.uploadPrompt')} </div>
            {/*
              this.props.progresses?.map((progress, index) => {
                  return progress.state === "uploading" &&
                      <div className="upload-container my-3">
                          <div className="mr-2">File {index + 1}</div>
                          <div className="progress-container"><div className="progress" style={{ width: progress.percentage * 2.4 }}> </div> </div>
                      </div>
              })
            */}
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div style={{ width: '40%' }}>
            <Button text={t('modals.cancelButton')} type="primary" fullSize onClick={() => this.props.handleStay()} />
          </div>
          <div style={{ width: '40%' }}>
            <Button
              style={{ backgroundColor: '#ff0000' }}
              text={t('modals.leaveButton')}
              type="primary"
              fullSize
              onClick={() => this.props.handleLeave()}
            />
          </div>
        </div>
      </Modal>
    );
  }
}
UploadPromptModal.propTypes = {};

const component = connect((state) => {
  return {
    ...state.auth,
    ...state.user.account,
    ...state.account,
    ...state.collectible,
    ...state.service,
    ...state.upload,
  };
}, {})(withTranslation()(UploadPromptModal));
export default component;
