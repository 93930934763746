import i18n from 'i18next';
import Backend from 'i18next-http-backend';
// import Backend from 'i18next-fs-backend';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { runtimeConfig } from './config';

const fallbackLngCode = 'en';

const publicPath = (runtimeConfig.publicPath && runtimeConfig.serverEnv !== 'development') ? runtimeConfig.publicPath : '/';

const options = {
  lng: 'en',
  fallbackLng: fallbackLngCode,
  load: 'currentOnly',

  lowerCaseLng: true,
  supportedLngs: ['en', 'tr', 'fr', 'es', 'pt-br', 'ru', 'zh-cn'],
  // a common namespace used around the full app.
  ns: ['translations'],
  defaultNS: 'translations',

  backend: {
    loadPath: `${publicPath}locales/{{lng}}/{{ns}}.json`
  },

  // cache: {
  //   enabled: true
  // },

  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: true
  },
};

// for browser use xhr backend to load translations and browser lng detector
if (process && !process.release) {
  i18n
    .use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector);
}

// initialize if not already initialized
if (!i18n.isInitialized) {
  i18n.init(options);
}

export default i18n;