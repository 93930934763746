/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable react/no-array-index-key */
import * as React from 'react';

const StackableCard = ({ children }) => {
  return (
    <div className="stackable-card">
      {children.map((child, i) => {
        return (
          child && (
            <div className="stackable-card__element" key={`card@${i}`}>
              {child}
            </div>
          )
        );
      })}
    </div>
  );
};

export default StackableCard;
