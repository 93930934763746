import React, { Component } from 'react';
import { Player, ControlBar, BigPlayButton, LoadingSpinner } from 'video-react';
import PropTypes from 'prop-types';
// Documentation: https://video-react.js.org/components/player/

export default class VideoPlayer extends Component {
  player;

  constructor(props, context) {
    super(props, context);

    this.state = { player: null };
  }

  componentDidMount() {
    // subscribe state change
    this.player.subscribeToStateChange(this.handleStateChange.bind(this));
    const videoElement = this.player.video.video;
    if (this.props.videoHasAudio && typeof this.props.videoHasAudio !== 'undefined') {
      const { videoHasAudio } = this.props;
      videoElement.addEventListener('loadeddata', function () {
        videoHasAudio(
          videoElement.mozHasAudio ||
            Boolean(videoElement.webkitAudioDecodedByteCount) ||
            Boolean(videoElement.audioTracks && videoElement.audioTracks.length),
        );
      });
    }
  }

  handleStateChange(state) {
    // copy player state to this component's state
    this.setState({
      player: state,
    });
  }

  componentDidUpdate() {
    if (this.state.player?.paused) {
      this.player.play();
    }
  }

  render() {
    return (
      <Player
        key={this.props.source}
        ref={(player) => {
          this.player = player;
        }}
        preload={this.props.preload ?? 'metadata'}
        autoPlay={this.props.autoPlay}
        poster={this.props.poster}
        width="100%"
        height="100%"
        fluid={false}
        loop={this.props.loop}
        muted={this.props.muted}
        className={this.props.className}
        playsInline={this.props.playsInline}>
        <source src={this.props.source} />
        <LoadingSpinner />
        {this.props.showPlayButton ? <BigPlayButton position="center" /> : null}
        <ControlBar autoHide={true} disableCompletely={!this.props.showControlBar} />
      </Player>
    );
  }
}
VideoPlayer.propTypes = {
  source: PropTypes.any,
  poster: PropTypes.string,
  autoPlay: PropTypes.bool,
  showPlayButton: PropTypes.bool,
  showControlBar: PropTypes.bool,
};
