import * as React from 'react';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';

class VerifyUnfollowModal extends React.Component {
  verifyUnfollowAccount() {
    if (this.props.account?.id !== this.props.followerData.id) {
      this.props.unfollowAccount(this.props.followerData.id).then((res) => {
        if (res.error) {
          this.props.toastError('Error unfollowing user');
        } else if (res.payload && this.props.updateAccountData) {
          this.props.updateAccountData(res.payload);
        }
      });
      this.props.closeAllModals();
    }
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, show, t } = this.props;
    return (
      <Modal show={show} backdrop={backdrop} closeOnOutsideClick={closeOnOutsideClick} onOutsideClick={onOutsideClick}>
        <div className="verify-unfollow-modal mx-2">
          <div className="my-2 px-1">
            <div className="title mb-1">{t('modals.unfollowTitle', {slug: this.props.followerData?.slug})}</div>
            <div className="description">
              {t('modals.unfollowDesc')}
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1 ">
          <div></div>
          <div style={{ width: '40%' }}>
            <Button text={t('modals.unfollowButton')} type="primary" fullSize onClick={() => this.verifyUnfollowAccount()} />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.user.account,
  ...state.notifications,
  ...state.profile,
});

export default connect(mapStateToProps, { ...AccountActions, ...ModalActions, ...ToastActions })(
  withTranslation()(VerifyUnfollowModal),
);
