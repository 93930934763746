import React, { createContext, useContext, useEffect } from 'react';

import { useHistory } from 'react-router';
import { useLocation } from 'react-use';

const ModalContext = createContext(undefined);

export const ModalProvider = ({ children }) => {
  /**
   * hooks
   */
  const history = useHistory();
  const location = useLocation();

  /**
   * variables
   */
  // if user clicked an external link that points to this modal
  const isFromExternalLink = history.length === 1;
  // if user entered the url for this modal directly in the search bar
  const isFromUrlEnter = history.length === 2 && history.action === 'PUSH';
  // if user clicked an internal link within the app
  const canGoBack = !isFromExternalLink && !isFromUrlEnter;

  /**
   * effects
   */

  // if modalPath is defined, show the modal by history.push and include the current background
  useEffect(() => {
    if (!history.location.state?.modalPath) return;
    history.replace({
      pathname: history.location.state.modalPath,
      state: { background: location },
    });
  }, [history, location]);

  /**
   * helpers
   */
  function goBack(url) {
    const goToUrl = url ?? '/discover';
    canGoBack ? history.goBack() : history.push(goToUrl);
  }

  /**
   * context value
   */
  const value = {
    goBack,
  };

  return <ModalContext.Provider value={value}>{children}</ModalContext.Provider>;
};

export function useModal() {
  const context = useContext(ModalContext);

  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }

  return context;
}
