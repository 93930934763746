import * as React from "react";
import PropTypes from 'prop-types';

export default class Icon extends React.Component {
    constructor(props) {
        super(props);
    }

    getClassName() {
        let cname = "icon";
        if (this.props.className)
            cname += " " + this.props.className;

        switch (this.props.type) {
            case "fontawesome":
                cname += " " + this.props.src;
                break;
            default:
                console.warn("Icon component needs a type: " + this.props.type + " is not a valid type.");
        }

        return cname;
    }

    render() {
        return (
            <i className={this.getClassName()} onClick={this.props.onClick} style={this.props.style}></i>
        );
    }
}
Icon.propTypes = {
    type: PropTypes.oneOf(["fontawesome"]).isRequired,
    src: PropTypes.string.isRequired,
    className: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.object
}