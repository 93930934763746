import * as AccountActionTypes from "@Actions/AccountActions";

const initialState = () => ({});

export default (state = initialState(), action) => {
  switch (action.type) {
    case AccountActionTypes.GET_ACCOUNT:
    case AccountActionTypes.GET_ACCOUNT_SUCCESS:
    case AccountActionTypes.GET_ACCOUNT_FAILURE:
    case AccountActionTypes.GET_ACCOUNT_BY_NAME:
    case AccountActionTypes.GET_ACCOUNT_BY_NAME_SUCCESS:
    case AccountActionTypes.GET_ACCOUNT_BY_NAME_FAILURE:
    case AccountActionTypes.GET_ACCOUNT_CREATED_COLLECTIBLES:
    case AccountActionTypes.GET_ACCOUNT_CREATED_COLLECTIBLES_SUCCESS:
    case AccountActionTypes.GET_ACCOUNT_CREATED_COLLECTIBLES_FAILURE:
    case AccountActionTypes.FOLLOW_ACCOUNT:
    case AccountActionTypes.FOLLOW_ACCOUNT_SUCCESS:
    case AccountActionTypes.FOLLOW_ACCOUNT_FAILURE:
    case AccountActionTypes.UNFOLLOW_ACCOUNT:
    case AccountActionTypes.UNFOLLOW_ACCOUNT_SUCCESS:
    case AccountActionTypes.UNFOLLOW_ACCOUNT_FAILURE:
    case AccountActionTypes.IS_FOLLOWING_ACCOUNT:
    case AccountActionTypes.IS_FOLLOWING_ACCOUNT_SUCCESS:
    case AccountActionTypes.IS_FOLLOWING_ACCOUNT_FAILURE:
    case AccountActionTypes.FOLLOW_HASHTAG:
    case AccountActionTypes.FOLLOW_HASHTAG_SUCCESS:
    case AccountActionTypes.FOLLOW_HASHTAG_FAILURE:
    case AccountActionTypes.UNFOLLOW_HASHTAG:
    case AccountActionTypes.UNFOLLOW_HASHTAG_SUCCESS:
    case AccountActionTypes.UNFOLLOW_HASHTAG_FAILURE:
    case AccountActionTypes.IS_FOLLOWING_HASHTAG:
    case AccountActionTypes.IS_FOLLOWING_HASHTAG_SUCCESS:
    case AccountActionTypes.IS_FOLLOWING_HASHTAG_FAILURE:
    default:
      return state;
  }
};
