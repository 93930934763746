import * as React from 'react';
import { Link, Redirect } from 'react-router-dom';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import ButtonGroup from '@Components/form/ButtonGroup';
import Button from '@Components/form/Button';
import { AccountActions } from '@Actions/AccountActions';
import { ModalActions } from '@Actions/ModalActions';
import { ToastActions } from '@Actions/ToastActions';

class WhitelistWarningModal extends React.Component {
  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, show, callback, onCloseButtonClick } = this.props;
    const discordLink = 'https://discord.gg/3ZjhN9TR7T';

    return (
      <Modal
        show={show}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
        onCloseButtonClick={onCloseButtonClick}>
        <div className="verify-unfollow-modal mx-2">
          <div className="my-2 px-1">
            <div className="title mb-1">Not Whitelisted</div>
            <div className="description">
              Join our{" "}
              <Link to={{ pathname: discordLink }} target="_blank">
                Discord
              </Link>
              {" "}and get your golden ticket.
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1 ">
          <div></div>
          <div className="discord" style={{ width: '40%' }}>
            <Button
              style={{ backgroundColor: '#7289DA' }}
              text="Join Discord"
              type="primary"
              fullSize
              onClick={() => {
                window?.open(discordLink, '_blank');
                callback();
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.auth,
  ...state.user.account,
  ...state.notifications,
  ...state.profile,
});

export default connect(mapStateToProps, { ...AccountActions, ...ModalActions, ...ToastActions })(WhitelistWarningModal);
