import OiInfoTooltip from '@Components/shared/OiInfoTooltip';
import clsx from 'clsx';
import React from 'react';

import { FaInfoCircle } from 'react-icons/fa';

const OiInfoIcon = ({ className, isHidden, infoText, ...rest }) => (
  <OiInfoTooltip placement="right" title={infoText}>
    <div>
      <FaInfoCircle {...rest} className={clsx('oi-info-icon', isHidden && 'display-none', className)} />
    </div>
  </OiInfoTooltip>
);

export default OiInfoIcon;
