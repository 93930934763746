import * as UserActionTypes from '@Actions/UserActions';

const initialState = () => ({
  isAuthenticated: false,
    
  isFetchingTokens: false,
  tokens: null,
  tokensError: null,

  isFetchingProfile: false,
  profile: null,
  profileError: null,

  user: null,
});

export default (state = initialState(), action) => {
    switch (action.type) {
        case UserActionTypes.TOKEN:
            return {
                ...state,
                isFetchingTokens: true,
            }
        case UserActionTypes.TOKEN_SUCCESS:
            {
                // Extract the expiry from the ticket and use that for quick tests
                const jwt = JSON.parse(atob(action.payload.access_token.split('.')[1]));

                // convert to milliseconds
                action.payload.expires_at = jwt.exp * 1000;

                return {
                    ...state,
                    isFetchingTokens: false,
                    user: {
                        ...state.user,
                        tokens: action.payload
                    }
                }
            }
        case UserActionTypes.TOKEN_FAILURE:
            return {
                ...state,
                isFetchingTokens: false,
                tokensError: action.error
            }
        case UserActionTypes.USERINFO:
            return {
                ...state,
                isFetchingProfile: true,
            }
        case UserActionTypes.USERINFO_SUCCESS:
            return {
                ...state,
                isFetchingProfile: false,
                user: {
                    ...state.user,
                    profile: action.payload
                }
            }
        case UserActionTypes.USERINFO_FAILURE:
            return {
                ...state,
                isFetchingProfile: false,
                profileError: action.error
            }
        case UserActionTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                user: null
            }
        default:
            return state;
    }
};