import * as React from 'react';
import PropTypes from 'prop-types';
import bind from 'bind-decorator';

export class AnimatedPlaceholderRect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="placeholder-component m-1">
        <div
          className="rectangle is-load-animated"
          style={{
            width: this.props.width,
            height: this.props.height,
            borderRadius: this.props.borderRadius,
            ...this.props.style,
          }}
        />
      </div>
    );
  }
}

AnimatedPlaceholderRect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export class AnimatedPlaceholderLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="placeholder-component m-1">
        <div className="line is-load-animated" style={{ width: this.props.width }} />
      </div>
    );
  }
}

AnimatedPlaceholderLine.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export class PlaceholderRect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="placeholder-component m-1">
        <div
          className="rectangle"
          style={{ width: this.props.width, height: this.props.height, borderRadius: this.props.borderRadius }}
        />
      </div>
    );
  }
}

PlaceholderRect.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  borderRadius: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export class PlaceholderLine extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="placeholder-component m-1">
        <div className="line" style={{ width: this.props.width }} />
      </div>
    );
  }
}

export const AccountCardPlaceholder = () => {
  return (
    <div className="account-card" style={{ width: '100%', height: '100%', backgroundColor: '#2e3045' }}>
      <div className="height-decider"> </div>
    </div>
  );
};

export const CollectibleCardPlaceholder = () => {
  return (
    <div className="collectible-card2" style={{ width: '100%', height: '100%', backgroundColor: '#2e3045' }}>
      <div className="height-decider"> </div>
    </div>
  );
};

PlaceholderLine.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};
