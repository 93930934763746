import * as React from "react";

export default class OixSpinner extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { size, thick, backgroundColor } = this.props;
    return (
      <div className="oix-spinner">
        <div className="spinner-area" style={{ width: size, height: size }}>
          <div className="spinner" style={{ width: size * thick, height: size * thick, backgroundColor: backgroundColor }}></div>
        </div>
      </div>
    );
  }
}
