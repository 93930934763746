import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BlockchainFilter } from './BlockchainFilter';
import { OiSearchFilterModal } from './SearchFilterModal';
import { FaSlidersH } from 'react-icons/fa';
import { CurrencyFilter } from './CurrencyFilter';
import { PriceFilter } from './PriceFilter';
import CategoryFilter from './CategoryFilter';

export const SearchFilterWrapper = (props) => {
  const { showBlockchain, showCategory, showCurrency, showPrice, isFilterBarOpen, isAccountCategory } = props;
  const { t } = useTranslation();
  const [windowDimensions, setWindowDimensions] = useState({});
  const [showFilterModal, setShowFilterModal] = useState(false);

  const handleShowFilterModal = () => {
    setShowFilterModal((showFilterModal) => !showFilterModal);
  };

  const isMobile = useMemo(() => windowDimensions.width < 576, [windowDimensions]);

  const updateWindowDimensions = () => {
    setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {
    window.addEventListener('resize', updateWindowDimensions);

    setWindowDimensions({ width: window?.innerWidth, height: window?.innerHeight });

    return () => window.removeEventListener('resize', updateWindowDimensions);
  }, []);

  return (
    <>
      {isFilterBarOpen && !isMobile && (
        <div className="search-filter-wrapper">
          {showCategory && !isMobile && <CategoryFilter isAccountCategory={isAccountCategory} />}
          {showBlockchain && !isMobile && <BlockchainFilter />}
          {showCurrency && !isMobile && <CurrencyFilter />}
          {showPrice && !isMobile && <PriceFilter />}
        </div>
      )}
      {isMobile && (
        <div className="search-mobile-filter-wrapper">
          <div className="filter-modal-button" onClick={handleShowFilterModal}>
            <FaSlidersH className="mr-1" /> {t('public.searchFilterFiltersText')}
          </div>
          {showFilterModal && <OiSearchFilterModal onHide={handleShowFilterModal} {...props} />}
        </div>
      )}
    </>
  );
};
