import * as React from 'react';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import Icon from '@Components/shared/Icon';
import { UserActions } from '@Actions/UserActions';
import { ModalActions } from '@Actions/ModalActions';
import * as QRCode from 'qrcode.react';
import { Formik } from 'formik';
import AppVariables from '@AppVariables';
import { SpinnerActions } from '@Actions/SpinnerActions';
import { ToastActions } from '@Actions/ToastActions';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

class AuthenticatorAppModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  submit(values, action) {
    this.props.enableLoader();
    this.props.verifyEnable2faByAuthenticator(values.code).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.AuthenticatorEnabledSuccess();
        this.props.generateRecoveryCodes();
        this.props.openModal('AuthenticatorModal2', { show: true, backdrop: true });
      } else {
        this.props.enablingAuthenticatorError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  render() { // TODO: Please fixed text in html attribute and use i18n
    const { backdrop, closeOnOutsideClick, onOutsideClick, show, t } = this.props;
    let submitForm;
    return (
      <Modal
        title={t('modals.enableAuthenticatorTitle')}
        subtext={''}
        show={show}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="authenticator-app-modal mx-2 my-2">
          <div className="authenticator-app-container mt-2 mx-1 mb-2">
            <div className="title mb-4">{t('modals.easyStepText')}</div>
            <div className="row">
              <div className="icon-container">
                <Icon src="fas fa-rocket" type="fontawesome" />
              </div>
              <div className="instruction-container">
                <div className="text mb-1">{t('modals.downloadAuthenticator')}</div>
                <div className="sub-text">
                <Trans i18nKey="modals.downloadAndInstallAuthApp">
                  Download and Install an <Link to="#">Authenticator App</Link> for your phone or tablet
                </Trans> 
                </div>
              </div>
            </div>
            <hr className="my-3" />
            <div className="row ">
              <div className="qr-container">
                {this.props.authenticatorData ? (
                  <QRCode value={this.props.authenticatorData?.authenticatorUri} level={'L'} />
                ) : (
                  <div></div>
                )}
              </div>
              <div className="instruction-container">
                <div className="text mb-1">{t('modals.scanQR')}</div>
                <div className="sub-text">
                  <Trans i18nKey="modals.instructionOpenAuth">
                    Open the <Link to="">Authenticator App</Link>, tap the '+' and scan the image on the left using your
                    phone or tablet.
                  </Trans> 
                </div>
                <div className="sub-text">
                  <Trans i18nKey="modals.downloadAndInstallAuthApp">
                    <span className="text">Note:</span> Closing this window before entering your security code will
                    require you to rescan your QR code
                  </Trans> 
                </div>
                <div className="text mt-2">{t('modals.manualEntry')}</div>
                <div className="sub-text">{this.props.authenticatorData?.sharedKey}</div>
              </div>
            </div>
            <hr className="my-3" />
            <div className="row mb-1 ">
              <div className="icon-container">
                <Icon src="fas fa-mobile-alt" type="fontawesome" />
              </div>
              <div className="instruction-container">
                <div className="text mb-1">{t('modals.signInSecureCode')}</div>
                <Formik
                  initialValues={{ code: '' }}
                  onSubmit={this.submit}
                  validationSchema={AppVariables.validationSchemas.faVerificationCodeSchema}
                >
                  {(formikProps) => {
                    submitForm = formikProps.submitForm;
                    return (
                      <form className="form" onSubmit={formikProps.handleSubmit}>
                        <Input.Text
                          className="w-100 my-3"
                          label={t('modals.inputSecureCodeLabel')}
                          placeholder={t('modals.inputSecureCodePlaceholder')}
                          name="code"
                          onChange={formikProps.handleChange}
                          onBlur={formikProps.handleBlur}
                          value={formikProps.values.code}
                          error={t(formikProps.errors.code)}
                        />
                      </form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1 ">
          <div> </div>
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.activateButton')}
              type="primary"
              fullSize
              onClick={() => submitForm()}
              disabled={this.props.enablingAuthenticator}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

class AuthenticatorActivatedAppModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  getCurrentDate() {
    const currentdate = new Date();
    const datetime =
      '' +
      currentdate.getDate() +
      '/' +
      (currentdate.getMonth() + 1) +
      '/' +
      currentdate.getFullYear() +
      '  ' +
      currentdate.getHours() +
      ':' +
      currentdate.getMinutes() +
      ':' +
      currentdate.getSeconds();
    return datetime;
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, show, t } = this.props;
    return (
      <Modal
        title={t('modals.enableAuthenticatorTitle')}
        subtext={''}
        show={show}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="authenticator-app-modal mx-2 my-2">
          <div className="authenticator-app-container mt-2 mx-1 mb-2">
            <div className="title mb-2 mt-3">{t('modals.accountName', {name: this.props.user?.profile?.name})}</div>
            <div className="sub-text mb-3">{t('modals.backupAccessible')}</div>
            <div className="text mb-1">{t('modals.code')}</div>
            <div className="backup-code-section">
              {this.props.recoveryCodesData?.recoveryCodes.map((i, index) => {
                return (
                  <div key={index} className="code-container">
                    {' '}
                    <div className="code text" key={index}>
                      {i + ' '}
                    </div>{' '}
                  </div>
                );
              })}
            </div>
            <hr className="my-2" />
            <div className="text">{t('modals.note')}</div>
            <div className="sub-text">{t('modals.onceUseBackupCode')}</div>
            <div className="sub-text">{t('modals.generatedDate', {date: this.getCurrentDate()})}</div>
          </div>
        </div>
        <div className="footer mx-2 mt-1 ">
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.generateNewCodeButton')}
              type="primary"
              fullSize
              onClick={() => this.props.generateRecoveryCodes()}
            />
          </div>
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.doneButton')}
              type="primary"
              fullSize
              onClick={() => {
                this.props.closeAllModals();
              }}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export const AuthenticatorModal = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.auth };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(AuthenticatorAppModal));
export const AuthenticatorModal2 = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.auth };
  },
  { ...UserActions, ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(AuthenticatorActivatedAppModal));
