import React from 'react';

import PropTypes from 'prop-types';
import { FaArrowLeft } from 'react-icons/fa';

const OiModalBackButton = ({ onClick }) => (
  <div className="oi-modal-back-button" onClick={onClick}>
    <FaArrowLeft />
  </div>
);

OiModalBackButton.propTypes = {
  onClick: PropTypes.func,
};

export default OiModalBackButton;
