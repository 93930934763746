import * as React from 'react';
import { Link } from 'react-router-dom';
import bind from 'bind-decorator';
import { connect } from 'react-redux';
import Modal from '@Components/modal/Modal';
import Button from '@Components/form/Button';
import Input from '@Components/form/Input';
import { UserActions } from '@Actions/UserActions';
import { Formik } from 'formik';
import AppVariables from '@AppVariables';
import { ModalActions } from '@Actions/ModalActions';
import { SpinnerActions } from '../../actions/SpinnerActions';
import { ToastActions } from '../../actions/ToastActions';
import { withTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

class EmailAuthenticationModal extends React.Component {
  constructor(props) {
    super(props);
  }

  @bind
  submit(values, actions) {
    this.props.enableLoader();
    this.props.verifyEnable2faByEmail(values.code).then((result) => {
      if (!result.error) {
        this.props.disableLoader();
        this.props.EmailEnabledSuccess();
        this.props.openModal('EmailAuthModal2', { backdrop: true });
      } else {
        this.props.verifying2FaByEmailError?.response.data['_generic'].map((error) => {
          this.props.disableLoader();
          this.props.toastError(error, {});
        });
      }
    });
  }

  @bind
  getEncryptedEmail() {
    // TODO: Encrypt
    const email = this.props.faStatusData?.email;
    if (email) {
      const arr = email.split('@');
      return arr[0][0] + '***' + arr[0][arr[0].length - 1] + '@' + arr[1] + '.';
    }

    return '';
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    let submitForm;
    return (
      <Modal
        title={t('modals.emailAuthModalTitle')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="email-authentication-modal mx-2 my-2">
          <div className="email-auth-container mt-2 mx-1 mb-2">
            <div className="title">{t('modals.enterSecurityCode')}</div>
            <div className="text mt-2">
              <div>
                <Trans encryptedEmail={this.getEncryptedEmail()} i18nKey="modals.emailAuthSentMail">
                  To enable email authentication, a security code has been sent to your email address at
                    <span className="mx-1" style={{ fontWeight: '600' }}>
                      {this.getEncryptedEmail()}
                    </span>
                  Enter the security code below to continue.
                </Trans>
              </div>
              <Link to=''>{t('modals.learnMoreLink')}</Link>
            </div>
            <Formik
              initialValues={{ code: '' }}
              onSubmit={this.submit}
              validationSchema={AppVariables.validationSchemas.faVerificationCodeSchema}
            >
              {(formikProps) => {
                submitForm = formikProps.submitForm;
                return (
                  <form className="form" onSubmit={formikProps.handleSubmit}>
                    <Input.Text
                      className="w-100 my-3"
                      label={t('modals.emailAuthInputLabel')}
                      placeholder={t('modals.inputSecureCodePlaceholder')}
                      name="code"
                      onChange={formikProps.handleChange}
                      onBlur={formikProps.handleBlur}
                      value={formikProps.values.code}
                      error={t(formikProps.errors.code)}
                    />
                  </form>
                );
              }}
            </Formik>
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div> </div>
          <div style={{ width: '40%' }}>
            <Button
              text={t('modals.continueButton')}
              type="primary"
              fullSize
              submit="true"
              onClick={() => submitForm()}
              disabled={this.props.verifying2FaByEmail}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

class EmailAuthenticationActivatedModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { backdrop, closeOnOutsideClick, onOutsideClick, t } = this.props;
    return (
      <Modal
        ref={(x) => (this.modal = x)}
        title={t('modals.emailAuthActivatedTitle')}
        subtext={''}
        backdrop={backdrop}
        closeOnOutsideClick={closeOnOutsideClick}
        onOutsideClick={onOutsideClick}
      >
        <div className="email-authentication-modal mx-2 my-2">
          <div className="email-auth-container mt-2 mx-1 mb-2">
            <div className="text mt-2">{t('modals.whatNext')}</div>
            <div className="sub-text mt-3 mb-2" style={{ lineHeight: '1.2rem' }}>
              {t('modals.secureCodeSendPhoneText')}
            </div>
          </div>
        </div>
        <div className="footer mx-2 mt-1">
          <div> </div>
          <div style={{ width: '40%' }}>
            <Button text={t('modals.doneButton')} type="primary" fullSize onClick={() => this.props.closeAllModals()} />
          </div>
        </div>
      </Modal>
    );
  }
}

export const EmailAuthModal = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.auth };
  },
  { ...UserActions, ...ModalActions, ...SpinnerActions, ...ToastActions },
  null,
  { forwardRef: true },
)(withTranslation()(EmailAuthenticationModal));
export const EmailAuthModal2 = connect(
  (state) => {
    return { ...state.user.twoFa, ...state.auth };
  },
  { ...UserActions, ...ModalActions },
  null,
  { forwardRef: true },
)(withTranslation()(EmailAuthenticationActivatedModal));
